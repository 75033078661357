/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AppAssociation
 */
export interface AppAssociation {
    /**
     * iOS application identifier and bundle identifier
     * @type {string}
     * @memberof AppAssociation
     */
    appAssociationIosIdentifier?: string;
    /**
     * Android package name
     * @type {string}
     * @memberof AppAssociation
     */
    appAssociationAndroidPackageName?: string;
    /**
     * Android fingerprint
     * @type {string}
     * @memberof AppAssociation
     */
    appAssociationAndroidFingerprint?: string;
}

export function AppAssociationFromJSON(json: any): AppAssociation {
    return AppAssociationFromJSONTyped(json, false);
}

export function AppAssociationFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppAssociation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'appAssociationIosIdentifier': !exists(json, 'appAssociationIosIdentifier') ? undefined : json['appAssociationIosIdentifier'],
        'appAssociationAndroidPackageName': !exists(json, 'appAssociationAndroidPackageName') ? undefined : json['appAssociationAndroidPackageName'],
        'appAssociationAndroidFingerprint': !exists(json, 'appAssociationAndroidFingerprint') ? undefined : json['appAssociationAndroidFingerprint'],
    };
}

export function AppAssociationToJSON(value?: AppAssociation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'appAssociationIosIdentifier': value.appAssociationIosIdentifier,
        'appAssociationAndroidPackageName': value.appAssociationAndroidPackageName,
        'appAssociationAndroidFingerprint': value.appAssociationAndroidFingerprint,
    };
}

