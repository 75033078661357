import { useStore } from 'state/store'
import Loading from 'components/Loading'
import ComingSoon from 'views/ComingSoon'
import { useRemoteConfig } from 'lib/remoteConfig'
import AuditLog from '../views/AuditLog'

const AuditLogContainer = () => {
  const workspaceId = useStore(state => state.workspace.id)
  const organizationId = useStore(state => state.organization.id)
  const { getBoolean } = useRemoteConfig()
  const auditEnabled = getBoolean('hera_workspace_history_enabled')

  if (!workspaceId || !organizationId) {
    return <Loading />
  }
  if (auditEnabled) {
    return (
      <AuditLog organizationId={organizationId} workspaceId={workspaceId} />
    )
  }

  return (
    <ComingSoon
      title="pmportal.history.title"
      description="pmportal.history.description"
    />
  )
}

export default AuditLogContainer
