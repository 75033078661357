/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Eligibility,
    EligibilityFromJSON,
    EligibilityFromJSONTyped,
    EligibilityToJSON,
} from './Eligibility';
import {
    EmailConfirmAction,
    EmailConfirmActionFromJSON,
    EmailConfirmActionFromJSONTyped,
    EmailConfirmActionToJSON,
} from './EmailConfirmAction';
import {
    EmailConfirmResponse,
    EmailConfirmResponseFromJSON,
    EmailConfirmResponseFromJSONTyped,
    EmailConfirmResponseToJSON,
} from './EmailConfirmResponse';
import {
    OnboardingRecordErrorAllOf,
    OnboardingRecordErrorAllOfFromJSON,
    OnboardingRecordErrorAllOfFromJSONTyped,
    OnboardingRecordErrorAllOfToJSON,
} from './OnboardingRecordErrorAllOf';

/**
 * 
 * @export
 * @interface OnboardingRecordError
 */
export interface OnboardingRecordError {
    /**
     * 
     * @type {EmailConfirmAction}
     * @memberof OnboardingRecordError
     */
    action?: EmailConfirmAction;
    /**
     * an ID associated with the type of action. i.e. for a MEMBER_CAMPAIGN, this should be the MEMBER_CAMPAIGN_ID
     * @type {string}
     * @memberof OnboardingRecordError
     */
    actionId?: string;
    /**
     * 
     * @type {Eligibility}
     * @memberof OnboardingRecordError
     */
    eligibility?: Eligibility;
    /**
     * The reason that a user is ineligible
     * @type {string}
     * @memberof OnboardingRecordError
     */
    ineligibleReason?: OnboardingRecordErrorIneligibleReasonEnum;
    /**
     * The name of the organization associated with this action
     * @type {string}
     * @memberof OnboardingRecordError
     */
    ownerName?: string;
    /**
     * The ID of the organization associated with this action
     * @type {string}
     * @memberof OnboardingRecordError
     */
    ownerId?: string;
    /**
     * The icon of the organization associated with this action
     * @type {string}
     * @memberof OnboardingRecordError
     */
    ownerIconUrl?: string;
    /**
     * The name of the workspace associated with this action
     * @type {string}
     * @memberof OnboardingRecordError
     */
    ownerLocationName?: string;
    /**
     * The ID of the workspace associated with this action
     * @type {string}
     * @memberof OnboardingRecordError
     */
    ownerLocationid?: string;
    /**
     * Indicates why the user cannot create the workspace or organization.
     * * `ORGANIZATION_EXISTS_MEMBER_ACCEPT` - organization already exists and the current user has been added to the workspace
     * * `ORGANIZATION_EXISTS_MEMBER_BLOCK` - organization already exists and the current user is not eligible to join
     * * `GENERIC_ERROR` - any other error
     * @type {string}
     * @memberof OnboardingRecordError
     */
    code: OnboardingRecordErrorCodeEnum;
}


/**
 * @export
 */
export const OnboardingRecordErrorIneligibleReasonEnum = {
    AccountAlreadyRedeemed: 'ACCOUNT_ALREADY_REDEEMED',
    EmailAlreadyRedeemed: 'EMAIL_ALREADY_REDEEMED',
    EmailIneligible: 'EMAIL_INELIGIBLE',
    CampaignExpired: 'CAMPAIGN_EXPIRED',
    UnknownError: 'UNKNOWN_ERROR'
} as const;
export type OnboardingRecordErrorIneligibleReasonEnum = typeof OnboardingRecordErrorIneligibleReasonEnum[keyof typeof OnboardingRecordErrorIneligibleReasonEnum];

/**
 * @export
 */
export const OnboardingRecordErrorCodeEnum = {
    OrganizationExistsMemberAccept: 'ORGANIZATION_EXISTS_MEMBER_ACCEPT',
    OrganizationExistsMemberBlock: 'ORGANIZATION_EXISTS_MEMBER_BLOCK',
    GenericError: 'GENERIC_ERROR'
} as const;
export type OnboardingRecordErrorCodeEnum = typeof OnboardingRecordErrorCodeEnum[keyof typeof OnboardingRecordErrorCodeEnum];


export function OnboardingRecordErrorFromJSON(json: any): OnboardingRecordError {
    return OnboardingRecordErrorFromJSONTyped(json, false);
}

export function OnboardingRecordErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnboardingRecordError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action': !exists(json, 'action') ? undefined : EmailConfirmActionFromJSON(json['action']),
        'actionId': !exists(json, 'actionId') ? undefined : json['actionId'],
        'eligibility': !exists(json, 'eligibility') ? undefined : EligibilityFromJSON(json['eligibility']),
        'ineligibleReason': !exists(json, 'ineligibleReason') ? undefined : json['ineligibleReason'],
        'ownerName': !exists(json, 'ownerName') ? undefined : json['ownerName'],
        'ownerId': !exists(json, 'ownerId') ? undefined : json['ownerId'],
        'ownerIconUrl': !exists(json, 'ownerIconUrl') ? undefined : json['ownerIconUrl'],
        'ownerLocationName': !exists(json, 'ownerLocationName') ? undefined : json['ownerLocationName'],
        'ownerLocationid': !exists(json, 'ownerLocationid') ? undefined : json['ownerLocationid'],
        'code': json['code'],
    };
}

export function OnboardingRecordErrorToJSON(value?: OnboardingRecordError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action': EmailConfirmActionToJSON(value.action),
        'actionId': value.actionId,
        'eligibility': EligibilityToJSON(value.eligibility),
        'ineligibleReason': value.ineligibleReason,
        'ownerName': value.ownerName,
        'ownerId': value.ownerId,
        'ownerIconUrl': value.ownerIconUrl,
        'ownerLocationName': value.ownerLocationName,
        'ownerLocationid': value.ownerLocationid,
        'code': value.code,
    };
}

