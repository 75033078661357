/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Job,
    JobFromJSON,
    JobFromJSONTyped,
    JobToJSON,
} from './Job';
import {
    JobResult,
    JobResultFromJSON,
    JobResultFromJSONTyped,
    JobResultToJSON,
} from './JobResult';
import {
    QueryInfo,
    QueryInfoFromJSON,
    QueryInfoFromJSONTyped,
    QueryInfoToJSON,
} from './QueryInfo';

/**
 * 
 * @export
 * @interface JobResultsResponse
 */
export interface JobResultsResponse {
    /**
     * 
     * @type {Job}
     * @memberof JobResultsResponse
     */
    job: Job;
    /**
     * 
     * @type {Array<JobResult>}
     * @memberof JobResultsResponse
     */
    results: Array<JobResult>;
    /**
     * 
     * @type {QueryInfo}
     * @memberof JobResultsResponse
     */
    listResult: QueryInfo;
}

export function JobResultsResponseFromJSON(json: any): JobResultsResponse {
    return JobResultsResponseFromJSONTyped(json, false);
}

export function JobResultsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobResultsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'job': JobFromJSON(json['job']),
        'results': ((json['results'] as Array<any>).map(JobResultFromJSON)),
        'listResult': QueryInfoFromJSON(json['listResult']),
    };
}

export function JobResultsResponseToJSON(value?: JobResultsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'job': JobToJSON(value.job),
        'results': ((value.results as Array<any>).map(JobResultToJSON)),
        'listResult': QueryInfoToJSON(value.listResult),
    };
}

