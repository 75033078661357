/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MerchantListDisplayHint,
    MerchantListDisplayHintFromJSON,
    MerchantListDisplayHintFromJSONTyped,
    MerchantListDisplayHintToJSON,
} from './MerchantListDisplayHint';
import {
    MerchantListMerchant,
    MerchantListMerchantFromJSON,
    MerchantListMerchantFromJSONTyped,
    MerchantListMerchantToJSON,
} from './MerchantListMerchant';
import {
    MerchantListType,
    MerchantListTypeFromJSON,
    MerchantListTypeFromJSONTyped,
    MerchantListTypeToJSON,
} from './MerchantListType';

/**
 * 
 * @export
 * @interface MerchantList
 */
export interface MerchantList {
    /**
     * The merchant list unique identifier.
     * @type {string}
     * @memberof MerchantList
     */
    merchantListId?: string;
    /**
     * 
     * @type {MerchantListDisplayHint}
     * @memberof MerchantList
     */
    displayHint?: MerchantListDisplayHint;
    /**
     * The internal display name for the list.
     * @type {string}
     * @memberof MerchantList
     */
    name: string;
    /**
     * 
     * @type {MerchantListType}
     * @memberof MerchantList
     */
    type: MerchantListType;
    /**
     * 
     * @type {string}
     * @memberof MerchantList
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantList
     */
    qrCodeUrl?: string;
    /**
     * The number of meters to display nearby merchants.
     * @type {number}
     * @memberof MerchantList
     */
    nearbyMerchantRadius?: number;
    /**
     * The last modified time of the merchant list.
     * @type {Date}
     * @memberof MerchantList
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Array<MerchantListMerchant>}
     * @memberof MerchantList
     */
    merchants?: Array<MerchantListMerchant>;
    /**
     * The total number of merchants in this merchant list.
     * @type {number}
     * @memberof MerchantList
     */
    readonly merchantCount?: number;
}

export function MerchantListFromJSON(json: any): MerchantList {
    return MerchantListFromJSONTyped(json, false);
}

export function MerchantListFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'merchantListId': !exists(json, 'merchantListId') ? undefined : json['merchantListId'],
        'displayHint': !exists(json, 'displayHint') ? undefined : MerchantListDisplayHintFromJSON(json['displayHint']),
        'name': json['name'],
        'type': MerchantListTypeFromJSON(json['type']),
        'url': !exists(json, 'url') ? undefined : json['url'],
        'qrCodeUrl': !exists(json, 'qrCodeUrl') ? undefined : json['qrCodeUrl'],
        'nearbyMerchantRadius': !exists(json, 'nearbyMerchantRadius') ? undefined : json['nearbyMerchantRadius'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'merchants': !exists(json, 'merchants') ? undefined : ((json['merchants'] as Array<any>).map(MerchantListMerchantFromJSON)),
        'merchantCount': !exists(json, 'merchantCount') ? undefined : json['merchantCount'],
    };
}

export function MerchantListToJSON(value?: MerchantList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'merchantListId': value.merchantListId,
        'displayHint': MerchantListDisplayHintToJSON(value.displayHint),
        'name': value.name,
        'type': MerchantListTypeToJSON(value.type),
        'url': value.url,
        'qrCodeUrl': value.qrCodeUrl,
        'nearbyMerchantRadius': value.nearbyMerchantRadius,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'merchants': value.merchants === undefined ? undefined : ((value.merchants as Array<any>).map(MerchantListMerchantToJSON)),
    };
}

