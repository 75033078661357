/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmailToken
 */
export interface EmailToken {
    /**
     * **Email Token** A token to use in place of an email. The token is only provided after the user has verified their email. This should only have a value if emailExchangeToken does not.
     * @type {string}
     * @memberof EmailToken
     */
    emailToken: string;
}

export function EmailTokenFromJSON(json: any): EmailToken {
    return EmailTokenFromJSONTyped(json, false);
}

export function EmailTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailToken {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emailToken': json['emailToken'],
    };
}

export function EmailTokenToJSON(value?: EmailToken | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'emailToken': value.emailToken,
    };
}

