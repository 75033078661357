import React from 'react'
import { AuthInstance } from '..'

export const StorybookAuthContext = React.createContext<AuthInstance>(
  {} as AuthInstance
)

export const StorybookAuthProvider: React.FC = ({ children }) => {
  const contextValue = {
    authenticated: true,
    token: 'storybook-token',
    refreshToken: 'storybook-refresh-token',
    updateToken: () => Promise.resolve(),
    login: () => {},
    createLoginUrl: () => 'storybook-url',
    createLogoutUrl: () => 'storybook-url',
    createRegisterUrl: () => 'storybook-url',
    createAccountUrl: () => 'storybook-url',
    accountManagement: () => {},
    loadUserProfile: () => Promise.resolve({ email: 'internal@ritual.co' }),
    handleTokenRefresh: () => {},
  }
  return (
    <StorybookAuthContext.Provider value={contextValue}>
      {children}
    </StorybookAuthContext.Provider>
  )
}

export const useAuth = () => React.useContext(StorybookAuthContext)

const storybookAuthAdapter = {
  getProvider: () => StorybookAuthProvider,
  getContext: () => StorybookAuthContext,
}

export default storybookAuthAdapter
