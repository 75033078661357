import { createClient as createContentfulClient } from 'contentful'
import { MessageLoader } from 'lib/content/types'
import { ContentfulResourceSet } from './types'
import { mapToResourceSet } from './mappers'

export type ContentfulLoaderDeps = {
  space: string
  accessToken: string
  environment?: string
  host?: string
}

/** The contentful library can't handle undefined keys correctly so we have to filter them out ourselves if considered "optional" */
const removeUndefinedKeys = (deps: ContentfulLoaderDeps) =>
  Object.fromEntries(
    Object.entries(deps).filter(([, v]) => v)
  ) as ContentfulLoaderDeps

type FnCreateContentfulLoader = (deps: ContentfulLoaderDeps) => MessageLoader
export const createContentfulLoader: FnCreateContentfulLoader = deps => {
  const client = createContentfulClient(removeUndefinedKeys(deps))
  return {
    async fetchSelectedResourceSets({
      locale,
      resourceSets: resourceSetNames,
    }) {
      const resources = await client.getEntries<
        ContentfulResourceSet['fields']
      >({
        content_type: 'resourceSet',
        'fields.name[in]': resourceSetNames.join(','),
        include: 4, // retrieve nesting up to 4 layers deep
        locale,
      })

      return mapToResourceSet(resources)
    },
  }
}
