import { rgba } from 'polished'
import {
  Box,
  CSSObject,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  useDisclosure,
  useTheme,
  VStack,
} from '@ritualco/jutsu'
import { AddIcon, ChevronDownIcon } from '@chakra-ui/icons'
import BuildingIcon from 'assets/Icons/DefaultBuilding.svg'
import { useContent } from 'lib/content/react'
import { Link as RouterLink } from 'lib/router'
import { CreateWorkspaceModal } from 'components/Modals/CreateWorkspaceModal'
import { useCreateWorkspace } from 'api/endpoints/workspaces'
import { useStore } from 'state/store'
import { selectOrganizationId } from 'state/selectors/organization'
import { CreateWorkspace, PortalAdminRole } from 'lib/generated/api/models'
import { useMessage } from 'contexts/MessageContext'
import { useCheckOrganizationPermissions } from '../../hooks/useCheckOrganizationPermissions'

type Workspace = {
  workspaceId: string
  name: string
}

type Organization = {
  organizationId: string
  name: string
}

export type OrganizationMenuProps = {
  name: string
  iconUrl?: string
  workspaces: Workspace[]
  organizations: Organization[]
  currentWorkspaceId: string
  onWorkspaceChange: (workspaceId: string) => void
}

type WorkspaceMenuGroupProps = {
  onCloseMenu: Function
} & Pick<OrganizationMenuProps, 'workspaces' | 'onWorkspaceChange'>
const WorkspaceMenuGroup = ({
  workspaces,
  onCloseMenu,
  onWorkspaceChange,
}: WorkspaceMenuGroupProps) => {
  const { formatMessage } = useContent()

  if (!workspaces.length) return null

  return (
    <MenuGroup>
      <Box maxH="31rem" h="calc(100vh - 215px)" overflowY="auto">
        <Text fontSize="xs" color="grayscale.secondary" pt={4} px={3}>
          {formatMessage({ id: 'pmportal.nav.workspaces' })}
        </Text>
        {workspaces.map(({ workspaceId, name }) => (
          <MenuItem
            key={workspaceId}
            p={3}
            color="grayscale.primary"
            onClick={() => {
              onWorkspaceChange(workspaceId)
              onCloseMenu()
            }}
          >
            {name}
          </MenuItem>
        ))}
      </Box>
    </MenuGroup>
  )
}

export const OrganizationMenu = ({
  name,
  iconUrl,
  workspaces,
  organizations = [],
  currentWorkspaceId,
  onWorkspaceChange,
}: OrganizationMenuProps) => {
  const theme = useTheme()
  const { formatMessage } = useContent()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { addMessage } = useMessage()
  const organizationId = useStore(selectOrganizationId)!

  const create = useCreateWorkspace()
  const hasPermissions = useCheckOrganizationPermissions([
    PortalAdminRole.Owner,
    PortalAdminRole.Administrator,
  ])

  const orgMenuBtnStyles: CSSObject = {
    bgColor: 'grayscale.offWhite',
    border: 'none',
    borderRadius: 0,
    boxShadow: 'inset 0px -1px 0px #E0E0E2',
    color: 'grayscale.tertiary',
    px: 6,
    py: 4,
    width: '100%',
    height: '100%',
    borderRight: '1px solid',
    borderRightColor: 'grayscale.quinary',
    outline: 'revert',
    _hover: {
      bg: rgba(theme.colors.grayscale.quinary, 0.3),
    },
  }

  const currentWorkspace = workspaces.find(
    ({ workspaceId }) => workspaceId === currentWorkspaceId
  )

  const onSubmit = async (createWorkspace: CreateWorkspace) =>
    create.mutateAsync(
      { organizationId, createWorkspace },
      {
        onSuccess: () => {
          onClose()
          addMessage({
            status: 'success',
            description: formatMessage({
              id: 'pmportal.workspaces.createSuccess',
            }),
            isClosable: false,
          })
        },
      }
    )

  return (
    <>
      <Menu matchWidth placement="bottom-end" offset={[62, -14]} closeOnSelect>
        {({ onClose }) => (
          <>
            <MenuButton sx={orgMenuBtnStyles}>
              <Flex align="center">
                <Flex direction="column" align="flex-start">
                  <Text
                    isTruncated
                    maxW="215px"
                    color="grayscale.primary"
                    fontSize="medium"
                    fontWeight="medium"
                    textAlign="left"
                  >
                    {name}
                  </Text>
                  {currentWorkspace && (
                    <Text
                      isTruncated
                      maxW="210px"
                      color="grayscale.secondary"
                      fontSize="small"
                      fontWeight="medium"
                      textAlign="left"
                    >
                      {currentWorkspace.name}
                    </Text>
                  )}
                </Flex>
                <Spacer />
                <ChevronDownIcon ml={4} boxSize={24} />
              </Flex>
            </MenuButton>
            <MenuList zIndex="dropdown">
              <Flex px={3} py={4} align="center">
                <Image
                  borderRadius="full"
                  boxSize="48px"
                  fit="cover"
                  src={iconUrl || BuildingIcon}
                  alt={name}
                  mr={2}
                />
                <VStack align="flex-start" spacing={1}>
                  <Text fontSize="small" fontWeight="medium">
                    {name}
                  </Text>
                  {organizations.length > 1 && (
                    /* For Menu accessibility purposes, a MenuItem is used here */
                    <MenuItem
                      as={RouterLink}
                      to="/workspace-selector"
                      m={0}
                      p={0}
                      fontSize="xs"
                      color="primary.normal"
                      width="fit-content"
                      _active={{ background: 'unset' }}
                      _focus={{
                        background: 'unset',
                        textDecoration: 'underline',
                      }}
                    >
                      {formatMessage({
                        id: 'pmportal.global.seeAllWorkspaces',
                      })}
                    </MenuItem>
                  )}
                </VStack>
              </Flex>
              {workspaces.length > 1 && (
                <>
                  <MenuDivider m={0} />
                  <WorkspaceMenuGroup
                    workspaces={workspaces}
                    onWorkspaceChange={onWorkspaceChange}
                    onCloseMenu={onClose}
                  />
                </>
              )}
              {hasPermissions && (
                <>
                  <MenuDivider m={0} />
                  <MenuGroup>
                    <MenuItem
                      p={3}
                      color="grayscale.primary"
                      key="new-workspace"
                      onClick={onOpen}
                    >
                      <AddIcon w={10.5} h={10.5} mr={14} />
                      {formatMessage({
                        id: 'pmportal.workspaces.newWorkspace',
                      })}
                    </MenuItem>
                  </MenuGroup>
                </>
              )}
            </MenuList>
          </>
        )}
      </Menu>
      <CreateWorkspaceModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </>
  )
}
