import { ReactNode } from 'react'
import { useField } from 'formik'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea,
  TextareaProps,
} from '@ritualco/jutsu'

export type FormikTextareaProps = {
  label?: ReactNode
  name: string
  isDisabled?: boolean
} & TextareaProps

export const FormikTextarea = ({
  label,
  isDisabled = false,
  isRequired = false,
  ...props
}: FormikTextareaProps) => {
  const [field, { error, touched }] = useField(props.name)
  return (
    <FormControl
      id={props.name}
      isInvalid={!!error && touched}
      isDisabled={isDisabled}
      isRequired={isRequired}
    >
      {label && <FormLabel>{label}</FormLabel>}
      <Textarea size="lg" {...field} {...props} />
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  )
}
