import { useContent } from 'lib/content/react'
import { useAuth } from 'auth'
import { useStore } from 'state/store'
import { useHistory } from 'react-router-dom'
import { AppBar } from './AppBar'
import { HeaderSearchBar } from './HeaderSearchBar'
import { useSearchIndex } from '../utils/search'

interface HeaderProps {
  workspaceId: string
  name: string
  email: string
}

export const Header = ({ name, email, workspaceId }: HeaderProps) => {
  const { formatMessage } = useContent()
  const { createLogoutUrl } = useAuth()
  const { clearStorage } = useStore()
  const { index } = useSearchIndex()
  const history = useHistory()

  const onClickSignOut = () => {
    // After KeyCloak logs the user out, change redirectUri to the home page
    window.location.href = createLogoutUrl({
      redirectUri: `${window.location.protocol}//${window.location.host}`,
    })
    // Clean up
    clearStorage()
  }
  const menuItems = [
    {
      label: formatMessage({ id: 'pmportal.settings.profile.title' }),
      href: `/workspaces/${workspaceId}/settings/profile`,
    },
  ]
  const onResultSelect = (path: string) => {
    // todo: in future when detail paths with id's are shown in results, this logic needs to change as well
    const pathWithWorkspaceId = path.replace(':workspaceId', workspaceId)
    history.push(pathWithWorkspaceId)
  }

  return (
    <AppBar>
      <HeaderSearchBar
        searchItems={index}
        onResultSelect={result => onResultSelect(result.path)}
        // todo: add translations
        resultText={{
          empty: ({ inputValue }) => `No results for ${inputValue}`,
          present: ({ inputValue }) => `Showing results for ${inputValue}`,
        }}
        helpUrl={`/workspaces/${workspaceId}/settings/help`}
        email={email}
        name={name}
        items={menuItems}
        onSignOut={onClickSignOut}
      />
    </AppBar>
  )
}
