/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ScheduleEnd
 */
export interface ScheduleEnd {
    /**
     * End date for all-day events (formatted according to ISO 8601). Omit this field for timed events
     * @type {Date}
     * @memberof ScheduleEnd
     */
    date?: Date;
    /**
     * End date-time without time zone offset for timed events (formatted according to ISO 8601). Omit this field for all-day events
     * @type {Date}
     * @memberof ScheduleEnd
     */
    dateTime?: Date;
}

export function ScheduleEndFromJSON(json: any): ScheduleEnd {
    return ScheduleEndFromJSONTyped(json, false);
}

export function ScheduleEndFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleEnd {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'dateTime': !exists(json, 'dateTime') ? undefined : (new Date(json['dateTime'])),
    };
}

export function ScheduleEndToJSON(value?: ScheduleEnd | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date === undefined ? undefined : (value.date.toISOString().substr(0,10)),
        'dateTime': value.dateTime === undefined ? undefined : (value.dateTime.toISOString()),
    };
}

