import { format } from 'date-fns'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalCloseButton,
  Text,
} from '@ritualco/jutsu'
import { HeraModal } from 'components/HeraModal'
import { ReportDownloadModalForm } from 'components/Modals/ReportDownloadModal/ReportDownloadModalForm'
import {
  ReportDownloadModalProps,
  ReportDownloadModalValues,
} from 'components/Modals/ReportDownloadModal/types'
import { useContent } from 'lib/content/react'
import { IncentiveReportRunReportTypeEnum } from 'lib/generated/api'

const DATE_DISPLAY_FORMAT = 'ccc, MMM d'

export const ReportDownloadModal = ({
  startDate,
  endDate,
  incentivePlanName,
  isDownloadAllReportsModal = false,
  onClose,
  onSubmit,
  ...modalProps
}: ReportDownloadModalProps) => {
  const { formatMessage } = useContent()
  const ReportDownloadModalSchema = Yup.object({
    reportType: Yup.string().required(),
  })
  const initialValues: ReportDownloadModalValues = {
    reportType: IncentiveReportRunReportTypeEnum.IncentiveByUser,
  }
  const formattedStartDate = startDate
    ? format(startDate, DATE_DISPLAY_FORMAT)
    : null
  const formattedEndDate = endDate ? format(endDate, DATE_DISPLAY_FORMAT) : null

  return (
    <HeraModal size="2xl" onClose={onClose} {...modalProps}>
      <Formik<ReportDownloadModalValues>
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          await onSubmit(values, actions)
          actions.setSubmitting(false)
          onClose()
        }}
        validationSchema={ReportDownloadModalSchema}
      >
        {props => (
          <>
            <ModalHeader>
              {formatMessage(
                {
                  id: isDownloadAllReportsModal
                    ? 'pmportal.incentives.downloadAllReportHeader'
                    : 'pmportal.incentives.downloadReportHeader',
                },
                {
                  incentivePlanName,
                }
              )}
              {formattedStartDate && formattedEndDate && (
                <Text
                  fontSize="small"
                  color="grayscale.secondary"
                  fontWeight="normal"
                >
                  {formatMessage(
                    { id: 'pmportal.incentives.downloadReportDateRange' },
                    {
                      formattedStartDate,
                      formattedEndDate,
                    }
                  )}
                </Text>
              )}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <ReportDownloadModalForm />
            </ModalBody>
            <ModalFooter>
              <Button
                size="md"
                colorScheme="transparent"
                mr={2}
                onClick={onClose}
              >
                {formatMessage({ id: 'pmportal.global.actionCancel' })}
              </Button>
              <Button
                size="md"
                type="submit"
                form="downloadReport"
                isLoading={props.isSubmitting}
              >
                {formatMessage({ id: 'pmportal.global.actionDownload' })}
              </Button>
            </ModalFooter>
          </>
        )}
      </Formik>
    </HeraModal>
  )
}
