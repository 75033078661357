import React from 'react'
import { IApiClient } from 'api/clients'

interface IApiClientContext extends IApiClient {}

const ApiClientContext = React.createContext<IApiClientContext>(
  {} as IApiClientContext
)

type ApiClientProviderProps = { apiClient: IApiClient }
export const ApiClientProvider: React.FC<ApiClientProviderProps> = ({
  apiClient,
  children,
}) => (
  <ApiClientContext.Provider value={apiClient}>
    {children}
  </ApiClientContext.Provider>
)

export default ApiClientProvider

export const useApiClient = () => React.useContext(ApiClientContext)
