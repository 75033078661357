/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The status of the ticket.
 * @export
 */
export const WorkspaceConnectionStatus = {
    Pending: 'PENDING',
    Confirmed: 'CONFIRMED',
    Rejected: 'REJECTED',
    Revoked: 'REVOKED',
    Expired: 'EXPIRED'
} as const;
export type WorkspaceConnectionStatus = typeof WorkspaceConnectionStatus[keyof typeof WorkspaceConnectionStatus];


export function WorkspaceConnectionStatusFromJSON(json: any): WorkspaceConnectionStatus {
    return WorkspaceConnectionStatusFromJSONTyped(json, false);
}

export function WorkspaceConnectionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceConnectionStatus {
    return json as WorkspaceConnectionStatus;
}

export function WorkspaceConnectionStatusToJSON(value?: WorkspaceConnectionStatus | null): any {
    return value as any;
}

