import { useApiClient } from 'contexts/ApiClientContext'
import {
  CreateIncentiveReportRunRequest,
  CreateIncentiveReportsRunRequest,
  GetIncentiveReportsRequest,
} from 'lib/generated/api'
import { useMutation, useQuery } from 'react-query'

export const QUERY_KEYS = {
  incentiveReports: 'incentiveReports',
} as const

export const incentiveReportsQueryKey = ({
  organizationId,
  workspaceId,
  ...queryParams
}: GetIncentiveReportsRequest) => [
  QUERY_KEYS.incentiveReports,
  organizationId,
  workspaceId,
  queryParams,
]

export const useGetIncentiveReports = (request: GetIncentiveReportsRequest) => {
  const { incentiveReportsApi } = useApiClient()

  return useQuery(
    incentiveReportsQueryKey(request),
    () => incentiveReportsApi.getIncentiveReports(request),
    {
      enabled: !!request.organizationId && !!request.workspaceId,
      keepPreviousData: true,
    }
  )
}

export const useCreateIncentiveReportRun = () => {
  const { incentiveReportsApi } = useApiClient()

  return useMutation((request: CreateIncentiveReportRunRequest) =>
    incentiveReportsApi.createIncentiveReportRun(request)
  )
}

export const useCreateIncentiveReportsRun = () => {
  const { incentiveReportsApi } = useApiClient()

  return useMutation((request: CreateIncentiveReportsRunRequest) =>
    incentiveReportsApi.createIncentiveReportsRun(request)
  )
}
