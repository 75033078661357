import { Button, ButtonProps } from '@ritualco/jutsu'
import { useCheckPermissions } from 'hooks/useCheckPermissions'
import { PortalAdminRole } from 'lib/generated/api'

export type ProtectedButtonProps = {
  adminRole: PortalAdminRole | PortalAdminRole[]
} & ButtonProps

export const ProtectedButton = ({
  adminRole,
  children,
  ...props
}: ProtectedButtonProps) => {
  const hasPermissions = useCheckPermissions(adminRole)

  if (!hasPermissions) {
    return null
  }

  return <Button {...props}>{children}</Button>
}
