/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WorkspaceMember,
    WorkspaceMemberFromJSON,
    WorkspaceMemberFromJSONTyped,
    WorkspaceMemberToJSON,
} from './WorkspaceMember';

/**
 * 
 * @export
 * @interface WorkspaceMemberList
 */
export interface WorkspaceMemberList {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceMemberList
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceMemberList
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceMemberList
     */
    description?: string;
    /**
     * 
     * @type {Array<WorkspaceMember>}
     * @memberof WorkspaceMemberList
     */
    members: Array<WorkspaceMember>;
}

export function WorkspaceMemberListFromJSON(json: any): WorkspaceMemberList {
    return WorkspaceMemberListFromJSONTyped(json, false);
}

export function WorkspaceMemberListFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceMemberList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'members': ((json['members'] as Array<any>).map(WorkspaceMemberFromJSON)),
    };
}

export function WorkspaceMemberListToJSON(value?: WorkspaceMemberList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'members': ((value.members as Array<any>).map(WorkspaceMemberToJSON)),
    };
}

