import { ContentManager, MessageLoader, Resource } from './types'

type FnCreateContentManager = (deps: {
  messageLoader: MessageLoader
}) => ContentManager
export const createContentManager: FnCreateContentManager = deps => ({
  messagesLoader: deps.messageLoader,
  toMessageRecord(resourceSet) {
    const resources = resourceSet.resources.filter(
      x => x.contentType === 'resource'
    ) as Resource[]

    return Object.fromEntries(
      resources.map<[string, string]>(x => [x.fields.key, x.fields.value])
    )
  },
})
