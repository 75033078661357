import 'whatwg-fetch'

export const getAnonymousAccessToken = async ({
  realmUrl,
  clientId,
  clientSecret,
}) => {
  const url = `${realmUrl}/protocol/openid-connect/token`

  const params = new URLSearchParams()
  params.append('client_id', clientId)
  params.append('grant_type', 'client_credentials')
  params.append('client_secret', clientSecret)

  const response = await fetch(url, {
    method: 'POST',
    body: params,
  }).then(response => response.json())

  return {
    token: response.access_token,
    expiresIn: response.expires_in,
  }
}
