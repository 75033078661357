/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LatLong,
    LatLongFromJSON,
    LatLongFromJSONTyped,
    LatLongToJSON,
} from './LatLong';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    addressLine1?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    addressLine2?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    province?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    countryCode?: string;
    /**
     * 
     * @type {LatLong}
     * @memberof Address
     */
    latLong: LatLong;
    /**
     * Google place id for address
     * @type {string}
     * @memberof Address
     */
    googlePlaceId?: string;
}

export function AddressFromJSON(json: any): Address {
    return AddressFromJSONTyped(json, false);
}

export function AddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): Address {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'addressLine1': !exists(json, 'addressLine1') ? undefined : json['addressLine1'],
        'addressLine2': !exists(json, 'addressLine2') ? undefined : json['addressLine2'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'province': !exists(json, 'province') ? undefined : json['province'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'countryCode': !exists(json, 'countryCode') ? undefined : json['countryCode'],
        'latLong': LatLongFromJSON(json['latLong']),
        'googlePlaceId': !exists(json, 'googlePlaceId') ? undefined : json['googlePlaceId'],
    };
}

export function AddressToJSON(value?: Address | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'addressLine1': value.addressLine1,
        'addressLine2': value.addressLine2,
        'city': value.city,
        'province': value.province,
        'country': value.country,
        'postalCode': value.postalCode,
        'countryCode': value.countryCode,
        'latLong': LatLongToJSON(value.latLong),
        'googlePlaceId': value.googlePlaceId,
    };
}

