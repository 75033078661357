/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * COMPLETED: The job action has completed successfully.
 * FAILED: The job action has failed.
 * PENDING: The job action is currently pending.
 * CANCELLED: The job action has been cancelled.
 * @export
 */
export const JobResultStatus = {
    Completed: 'COMPLETED',
    Failed: 'FAILED',
    Pending: 'PENDING',
    Cancelled: 'CANCELLED'
} as const;
export type JobResultStatus = typeof JobResultStatus[keyof typeof JobResultStatus];


export function JobResultStatusFromJSON(json: any): JobResultStatus {
    return JobResultStatusFromJSONTyped(json, false);
}

export function JobResultStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobResultStatus {
    return json as JobResultStatus;
}

export function JobResultStatusToJSON(value?: JobResultStatus | null): any {
    return value as any;
}

