import { Button, ButtonProps, Text } from '@ritualco/jutsu'
import { IdentityProvider } from 'types/entities/IdentityProvider'

type ProviderButtonProps = IdentityProvider & ButtonProps

const ProviderButton = ({
  id,
  clickEvent,
  label,
  icon,
  ...props // extract id from props to avoid applying an ID to the button; clickEvent to avoid prop that React doesn't recognize
}: ProviderButtonProps) => (
  <Button
    variant="outline"
    colorScheme="transparent"
    w="100%"
    leftIcon={icon}
    {...props}
  >
    <Text>{label}</Text>
  </Button>
)

export default ProviderButton
