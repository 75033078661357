import { useState, ChangeEventHandler, MouseEventHandler } from 'react'
import { Flex, FlexProps, Select, Text } from '@ritualco/jutsu'
import { INITIAL_ROWS_PER_PAGE } from 'config'
import { useContent } from 'lib/content/react'
import { NavigationButton } from './NavigationButton'

export const useRowsPerPage = () => {
  const [rowsPerPage, setRowsPerPage] = useState(INITIAL_ROWS_PER_PAGE)
  return [rowsPerPage, setRowsPerPage] as const
}

export type PaginationProps = {
  /** The current number of rows per page */
  rowsPerPage?: number
  /** List of values to render as `<option />` */
  rowsPerPageOptions?: number[]
  /** Has a previous page to navigate to */
  hasPrev?: boolean
  /** Has a next page to navigate to */
  hasNext?: boolean
  /** Handler for changing rows per page */
  onRowsPerPageChange: ChangeEventHandler<HTMLSelectElement>
  /** Handler for clicking the prev button */
  onPrev: MouseEventHandler<HTMLButtonElement>
  /** Handler for clicking the next button */
  onNext: MouseEventHandler<HTMLButtonElement>
  /** Copy for prev button, will default to icon */
  prevText?: string
  /** Copy for next button, will default to icon */
  nextText?: string
  rowsPerPageText?: string
  /** Stretches container to cover full width */
  fullWidth?: boolean
  /** Disables all pagination inputs */
  isDisabled?: boolean
} & FlexProps

export const Pagination = (props: PaginationProps) => {
  const { formatMessage } = useContent()
  const {
    rowsPerPageOptions = [10, 20, 50],
    rowsPerPage = rowsPerPageOptions[0],
    hasPrev = false,
    hasNext = false,
    onRowsPerPageChange,
    onPrev,
    onNext,
    prevText,
    nextText,
    fullWidth = true,
    isDisabled = false,
    rowsPerPageText = formatMessage({
      id: 'pmportal.global.pagination.rowsPerPage',
    }),
    ...rest
  } = props
  return (
    <Flex
      justify={fullWidth ? 'flex-end' : undefined}
      align="center"
      width="100%"
      p="3"
      borderTop="1px solid"
      borderTopColor="grayscale.quinary"
      {...rest}
    >
      <Text
        color="grayscale.secondary"
        fontSize="xsmall"
        fontWeight="medium"
        mr="4"
      >
        {rowsPerPageText}
      </Text>
      <Select
        size="sm"
        w="unset"
        mr="4"
        isDisabled={isDisabled}
        onChange={onRowsPerPageChange}
        value={rowsPerPage}
      >
        {rowsPerPageOptions.map(val => (
          <option key={val} value={val}>
            {val}
          </option>
        ))}
      </Select>
      <Flex justify="space-between">
        <NavigationButton
          navType="prev"
          onClick={onPrev}
          isDisabled={isDisabled || !hasPrev}
          mr="1"
        >
          {prevText}
        </NavigationButton>
        <NavigationButton
          navType="next"
          onClick={onNext}
          isDisabled={isDisabled || !hasNext}
        >
          {nextText}
        </NavigationButton>
      </Flex>
    </Flex>
  )
}
