/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AccessCode,
    AccessCodeFromJSON,
    AccessCodeToJSON,
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    WorkspaceConnectionCreateRequest,
    WorkspaceConnectionCreateRequestFromJSON,
    WorkspaceConnectionCreateRequestToJSON,
    WorkspaceConnectionCreateResponse,
    WorkspaceConnectionCreateResponseFromJSON,
    WorkspaceConnectionCreateResponseToJSON,
    WorkspaceConnectionInvite,
    WorkspaceConnectionInviteFromJSON,
    WorkspaceConnectionInviteToJSON,
    WorkspaceConnectionInviteInfo,
    WorkspaceConnectionInviteInfoFromJSON,
    WorkspaceConnectionInviteInfoToJSON,
    WorkspaceConnectionInviteResponse,
    WorkspaceConnectionInviteResponseFromJSON,
    WorkspaceConnectionInviteResponseToJSON,
    WorkspaceConnectionStatus,
    WorkspaceConnectionStatusFromJSON,
    WorkspaceConnectionStatusToJSON,
    WorkspaceConnectionsResponse,
    WorkspaceConnectionsResponseFromJSON,
    WorkspaceConnectionsResponseToJSON,
} from '../models';

export interface AcceptWorkspaceConnectionCodeRequest {
    organizationId: string;
    workspaceId: string;
    body: AccessCode;
}

export interface CreateWorkspaceConnectionRequest {
    workspaceId: string;
    workspaceConnectionCreateRequest?: WorkspaceConnectionCreateRequest;
}

export interface CreateWorkspaceConnectionsRequest {
    organizationId: string;
    workspaceId: string;
    workspaceConnectionInvite?: WorkspaceConnectionInvite;
}

export interface DeleteExternalWorkspaceConnectionRequest {
    organizationId: string;
    workspaceId: string;
    connectionId: string;
}

export interface DeleteWorkspaceConnectionRequest {
    organizationId: string;
    workspaceId: string;
    connectionId: string;
}

export interface GetExternalWorkspaceConnectionsRequest {
    organizationId: string;
    workspaceId: string;
    limit?: number;
    nextPageToken?: string;
    previousPageToken?: string;
}

export interface GetWorkspaceConnectionsRequest {
    organizationId: string;
    workspaceId: string;
    excludeStatus?: Array<WorkspaceConnectionStatus>;
    includeStatus?: Array<WorkspaceConnectionStatus>;
    limit?: number;
    nextPageToken?: string;
    previousPageToken?: string;
    sortColumn?: string;
    sortDirection?: GetWorkspaceConnectionsSortDirectionEnum;
    query?: Array<string>;
}

export interface GetWorkspaceInviteRequest {
    connectionId: string;
}

export interface ResendWorkspaceConnectionRequest {
    organizationId: string;
    workspaceId: string;
    connectionId: string;
}

export interface ResendWorkspaceConnectionsRequest {
    organizationId: string;
    workspaceId: string;
}

/**
 * 
 */
export class ConnectionsApi extends runtime.BaseAPI {

    /**
     * Accept an invite using an access code
     */
    async acceptWorkspaceConnectionCodeRaw(requestParameters: AcceptWorkspaceConnectionCodeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling acceptWorkspaceConnectionCode.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling acceptWorkspaceConnectionCode.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling acceptWorkspaceConnectionCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/external-connections/redeem-code`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Accept an invite using an access code
     */
    async acceptWorkspaceConnectionCode(requestParameters: AcceptWorkspaceConnectionCodeRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.acceptWorkspaceConnectionCodeRaw(requestParameters, initOverrides);
    }

    /**
     * Creates a connection from one workspace to another. Automatically creates the connection (no invite)
     */
    async createWorkspaceConnectionRaw(requestParameters: CreateWorkspaceConnectionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WorkspaceConnectionCreateResponse>> {
        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling createWorkspaceConnection.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workspaces/{workspaceId}/connections/add`.replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WorkspaceConnectionCreateRequestToJSON(requestParameters.workspaceConnectionCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceConnectionCreateResponseFromJSON(jsonValue));
    }

    /**
     * Creates a connection from one workspace to another. Automatically creates the connection (no invite)
     */
    async createWorkspaceConnection(requestParameters: CreateWorkspaceConnectionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WorkspaceConnectionCreateResponse> {
        const response = await this.createWorkspaceConnectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Invite another workspace to connect to this one
     */
    async createWorkspaceConnectionsRaw(requestParameters: CreateWorkspaceConnectionsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WorkspaceConnectionInviteResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling createWorkspaceConnections.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling createWorkspaceConnections.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/connections`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WorkspaceConnectionInviteToJSON(requestParameters.workspaceConnectionInvite),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceConnectionInviteResponseFromJSON(jsonValue));
    }

    /**
     * Invite another workspace to connect to this one
     */
    async createWorkspaceConnections(requestParameters: CreateWorkspaceConnectionsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WorkspaceConnectionInviteResponse> {
        const response = await this.createWorkspaceConnectionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a connection this workspace accepted from another workspace
     */
    async deleteExternalWorkspaceConnectionRaw(requestParameters: DeleteExternalWorkspaceConnectionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling deleteExternalWorkspaceConnection.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling deleteExternalWorkspaceConnection.');
        }

        if (requestParameters.connectionId === null || requestParameters.connectionId === undefined) {
            throw new runtime.RequiredError('connectionId','Required parameter requestParameters.connectionId was null or undefined when calling deleteExternalWorkspaceConnection.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/external-connections/{connectionId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"connectionId"}}`, encodeURIComponent(String(requestParameters.connectionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a connection this workspace accepted from another workspace
     */
    async deleteExternalWorkspaceConnection(requestParameters: DeleteExternalWorkspaceConnectionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.deleteExternalWorkspaceConnectionRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a connection that this workspace extended to another
     */
    async deleteWorkspaceConnectionRaw(requestParameters: DeleteWorkspaceConnectionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling deleteWorkspaceConnection.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling deleteWorkspaceConnection.');
        }

        if (requestParameters.connectionId === null || requestParameters.connectionId === undefined) {
            throw new runtime.RequiredError('connectionId','Required parameter requestParameters.connectionId was null or undefined when calling deleteWorkspaceConnection.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/connections/{connectionId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"connectionId"}}`, encodeURIComponent(String(requestParameters.connectionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a connection that this workspace extended to another
     */
    async deleteWorkspaceConnection(requestParameters: DeleteWorkspaceConnectionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.deleteWorkspaceConnectionRaw(requestParameters, initOverrides);
    }

    /**
     * List the connections this workspace accepted from other workspaces
     */
    async getExternalWorkspaceConnectionsRaw(requestParameters: GetExternalWorkspaceConnectionsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WorkspaceConnectionsResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getExternalWorkspaceConnections.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling getExternalWorkspaceConnections.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.nextPageToken !== undefined) {
            queryParameters['nextPageToken'] = requestParameters.nextPageToken;
        }

        if (requestParameters.previousPageToken !== undefined) {
            queryParameters['previousPageToken'] = requestParameters.previousPageToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/external-connections`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceConnectionsResponseFromJSON(jsonValue));
    }

    /**
     * List the connections this workspace accepted from other workspaces
     */
    async getExternalWorkspaceConnections(requestParameters: GetExternalWorkspaceConnectionsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WorkspaceConnectionsResponse> {
        const response = await this.getExternalWorkspaceConnectionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List the workspace connections this workspace extended to other workspaces
     */
    async getWorkspaceConnectionsRaw(requestParameters: GetWorkspaceConnectionsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WorkspaceConnectionsResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getWorkspaceConnections.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling getWorkspaceConnections.');
        }

        const queryParameters: any = {};

        if (requestParameters.excludeStatus) {
            queryParameters['excludeStatus'] = requestParameters.excludeStatus.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.includeStatus) {
            queryParameters['includeStatus'] = requestParameters.includeStatus.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.nextPageToken !== undefined) {
            queryParameters['nextPageToken'] = requestParameters.nextPageToken;
        }

        if (requestParameters.previousPageToken !== undefined) {
            queryParameters['previousPageToken'] = requestParameters.previousPageToken;
        }

        if (requestParameters.sortColumn !== undefined) {
            queryParameters['sortColumn'] = requestParameters.sortColumn;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.query) {
            queryParameters['query'] = requestParameters.query;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/connections`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceConnectionsResponseFromJSON(jsonValue));
    }

    /**
     * List the workspace connections this workspace extended to other workspaces
     */
    async getWorkspaceConnections(requestParameters: GetWorkspaceConnectionsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WorkspaceConnectionsResponse> {
        const response = await this.getWorkspaceConnectionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the public details of the invite (a pending connection)
     */
    async getWorkspaceInviteRaw(requestParameters: GetWorkspaceInviteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WorkspaceConnectionInviteInfo>> {
        if (requestParameters.connectionId === null || requestParameters.connectionId === undefined) {
            throw new runtime.RequiredError('connectionId','Required parameter requestParameters.connectionId was null or undefined when calling getWorkspaceInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invitations/workspace-connections/{connectionId}`.replace(`{${"connectionId"}}`, encodeURIComponent(String(requestParameters.connectionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceConnectionInviteInfoFromJSON(jsonValue));
    }

    /**
     * Get the public details of the invite (a pending connection)
     */
    async getWorkspaceInvite(requestParameters: GetWorkspaceInviteRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WorkspaceConnectionInviteInfo> {
        const response = await this.getWorkspaceInviteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Resend the specified pending workspace connection
     */
    async resendWorkspaceConnectionRaw(requestParameters: ResendWorkspaceConnectionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling resendWorkspaceConnection.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling resendWorkspaceConnection.');
        }

        if (requestParameters.connectionId === null || requestParameters.connectionId === undefined) {
            throw new runtime.RequiredError('connectionId','Required parameter requestParameters.connectionId was null or undefined when calling resendWorkspaceConnection.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/connections/{connectionId}/resend`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"connectionId"}}`, encodeURIComponent(String(requestParameters.connectionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Resend the specified pending workspace connection
     */
    async resendWorkspaceConnection(requestParameters: ResendWorkspaceConnectionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.resendWorkspaceConnectionRaw(requestParameters, initOverrides);
    }

    /**
     * Resend all of a workspace\'s pending connections
     */
    async resendWorkspaceConnectionsRaw(requestParameters: ResendWorkspaceConnectionsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WorkspaceConnectionInviteResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling resendWorkspaceConnections.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling resendWorkspaceConnections.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/connections/resend`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceConnectionInviteResponseFromJSON(jsonValue));
    }

    /**
     * Resend all of a workspace\'s pending connections
     */
    async resendWorkspaceConnections(requestParameters: ResendWorkspaceConnectionsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WorkspaceConnectionInviteResponse> {
        const response = await this.resendWorkspaceConnectionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetWorkspaceConnectionsSortDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type GetWorkspaceConnectionsSortDirectionEnum = typeof GetWorkspaceConnectionsSortDirectionEnum[keyof typeof GetWorkspaceConnectionsSortDirectionEnum];
