import { useContent } from 'lib/content/react'

type Error = { error: { message: string } }

function ErrorPage({ error }: Error) {
  const { formatMessage } = useContent()
  return (
    <div role="alert">
      <p>{formatMessage({ id: 'pmportal.global.errorTitle' })}</p>
      <pre style={{ color: 'red' }}>{error.message}</pre>
    </div>
  )
}

export default ErrorPage
