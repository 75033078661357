/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConfirmPermissionsResponse
 */
export interface ConfirmPermissionsResponse {
    /**
     * 
     * @type {string}
     * @memberof ConfirmPermissionsResponse
     */
    ticketId: string;
    /**
     * `SUCCESS`: the current user has successfully accepted the invite.
     * 
     * `REQUIRES_MANUAL_VERIFICATION`: the invite is no longer available (e.g. a different user accepted it) but the user may try to join another way.
     * @type {string}
     * @memberof ConfirmPermissionsResponse
     */
    status: ConfirmPermissionsResponseStatusEnum;
    /**
     * Alternative URL user may be able to use to join the workspace if the invite is no longer available.
     * This should be present when the status is `REQUIRES_MANUAL_VERIFICATION`.
     * @type {string}
     * @memberof ConfirmPermissionsResponse
     */
    verificationUrl?: string;
}


/**
 * @export
 */
export const ConfirmPermissionsResponseStatusEnum = {
    Success: 'SUCCESS',
    RequiresManualVerification: 'REQUIRES_MANUAL_VERIFICATION'
} as const;
export type ConfirmPermissionsResponseStatusEnum = typeof ConfirmPermissionsResponseStatusEnum[keyof typeof ConfirmPermissionsResponseStatusEnum];


export function ConfirmPermissionsResponseFromJSON(json: any): ConfirmPermissionsResponse {
    return ConfirmPermissionsResponseFromJSONTyped(json, false);
}

export function ConfirmPermissionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfirmPermissionsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ticketId': json['ticketId'],
        'status': json['status'],
        'verificationUrl': !exists(json, 'verificationUrl') ? undefined : json['verificationUrl'],
    };
}

export function ConfirmPermissionsResponseToJSON(value?: ConfirmPermissionsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ticketId': value.ticketId,
        'status': value.status,
        'verificationUrl': value.verificationUrl,
    };
}

