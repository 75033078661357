/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkspaceIncentivePlanDelta
 */
export interface WorkspaceIncentivePlanDelta {
    /**
     * The next incentive cycle date
     * @type {Date}
     * @memberof WorkspaceIncentivePlanDelta
     */
    incentiveCycleDate?: Date;
    /**
     * List of workspace member ids that have been removed from the incentive plan
     * @type {Array<string>}
     * @memberof WorkspaceIncentivePlanDelta
     */
    memberRemovedList: Array<string>;
    /**
     * List of workspace member ids that have been added to the incentive plan
     * @type {Array<string>}
     * @memberof WorkspaceIncentivePlanDelta
     */
    memberAddedList: Array<string>;
}

export function WorkspaceIncentivePlanDeltaFromJSON(json: any): WorkspaceIncentivePlanDelta {
    return WorkspaceIncentivePlanDeltaFromJSONTyped(json, false);
}

export function WorkspaceIncentivePlanDeltaFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceIncentivePlanDelta {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incentiveCycleDate': !exists(json, 'incentiveCycleDate') ? undefined : (new Date(json['incentiveCycleDate'])),
        'memberRemovedList': json['memberRemovedList'],
        'memberAddedList': json['memberAddedList'],
    };
}

export function WorkspaceIncentivePlanDeltaToJSON(value?: WorkspaceIncentivePlanDelta | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incentiveCycleDate': value.incentiveCycleDate === undefined ? undefined : (value.incentiveCycleDate.toISOString()),
        'memberRemovedList': value.memberRemovedList,
        'memberAddedList': value.memberAddedList,
    };
}

