import {
  IncentivePlan as ApiIncentivePlan,
  IncentivePlanReadOnly,
  IncentivePlansResponse,
  IncentivePlanStatus,
  TargetingListType,
} from 'lib/generated/api'
import { IncentivePlan } from 'types/entities'
import { microAmountToFixed } from 'utils/conversion'
import {
  adjustZonedDateRestrictions,
  computeCompanies,
  computeMerchantList,
  computePeople,
  computeSchedule,
  computeStatus,
  merchantEligibilityTypeMap,
  parseIncentiveType,
} from './helpers'

export type IncentivePlanMapper = (
  data: ApiIncentivePlan,
  defaultTimezone: string
) => IncentivePlan
export const mapToIncentivePlan: IncentivePlanMapper = (
  data,
  defaultTimezone
) => {
  const {
    metadata,
    draftConfiguration: draft,
    launchedConfiguration: launched,
  } = data
  const meta = metadata as IncentivePlanReadOnly
  const name = draft?.name || launched?.name
  const description = draft?.description || launched?.description
  const incentiveTemplate =
    draft?.incentiveTemplate || launched?.incentiveTemplate
  const merchantEligibility =
    draft?.merchantEligibility || launched?.merchantEligibility
  const userTargeting = draft?.userTargeting || launched?.userTargeting
  const notifications = draft?.notifications || []
  const dateRestrictions = adjustZonedDateRestrictions(
    draft?.dateRestrictions || launched?.dateRestrictions || []
  )

  const schedule = computeSchedule(
    defaultTimezone,
    draft?.schedule || launched?.schedule,
    incentiveTemplate?.availableFrom,
    incentiveTemplate?.availableTo,
    incentiveTemplate?.duration,
    incentiveTemplate?.oneTimeStartDateTime,
    incentiveTemplate?.oneTimeEndDateTime
  )

  const incentivePlan: IncentivePlan = {
    id: meta.id,
    status: computeStatus(meta.status, meta.scheduleStatus),
    scheduleStatus: meta.scheduleStatus,
    canLaunch:
      !!meta.actions.publish && meta.status === IncentivePlanStatus.Draft,
    canRelaunch:
      !!meta.actions.publish && meta.status !== IncentivePlanStatus.Draft,
    canPause: !!meta.actions.pause,
    canResume: !!meta.actions.resume,
    canDelete: !!meta.actions._delete,
    incentiveDetails: {
      name,
      description,
      type: parseIncentiveType(incentiveTemplate, schedule?.type),
      currencyCode: incentiveTemplate?.currencyCode,
      creditAmount: microAmountToFixed(incentiveTemplate?.creditAmount),
      discountAmount: microAmountToFixed(incentiveTemplate?.discountAmount),
      discountPercentage: incentiveTemplate?.discountPercentage,
      discountValueMax: microAmountToFixed(incentiveTemplate?.discountValueMax),
      orderValueMin: microAmountToFixed(incentiveTemplate?.orderValueMin),
      schedule,
    },
    merchantParticipation: {
      original: merchantEligibility,
      type: merchantEligibility?.type
        ? merchantEligibilityTypeMap[merchantEligibility.type]
        : 'all_merchants',
      merchantList: computeMerchantList(merchantEligibility?.merchantListGroup),
      polygonRestrictions: merchantEligibility?.polygonRestrictions,
      radiusRestrictions: merchantEligibility?.radiusRestrictions,
    },
    recipients: {
      original: userTargeting,
      allCompanies: userTargeting?.worspaceGroupListType
        ? userTargeting.worspaceGroupListType === TargetingListType.All
        : false,
      companies: computeCompanies(userTargeting?.workspaceGroup),
      allPeople: userTargeting?.userGroupListType
        ? userTargeting.userGroupListType === TargetingListType.All
        : false,
      people: computePeople(userTargeting?.userGroup),
    },
    notifications,
    dateRestrictions,
    dataVersion: data.dataVersion,
  }
  return incentivePlan
}

export type IncentivePlansMapper = (
  data: IncentivePlansResponse,
  defaultTimezone: string
) => Omit<IncentivePlansResponse, 'results'> & { results: IncentivePlan[] }
export const mapToIncentivePlans: IncentivePlansMapper = (
  data,
  defaultTimezone
) => {
  const { results, ...rest } = data
  return {
    results: results.map(incentivePlan =>
      mapToIncentivePlan(incentivePlan, defaultTimezone)
    ),
    ...rest,
  }
}
