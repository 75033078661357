import { Box, Heading, Text } from '@ritualco/jutsu'
import queryString from 'query-string'
import { useGetWorkspaceAdminInviteDetails } from 'api/endpoints/portalAdmins'
import { useContent } from 'lib/content/react'
import { useLocation } from 'lib/router'
import { useEffect } from 'react'
import { useStore } from 'state/store'
import { LoginShell } from './LoginShell'
import { LoginProps } from './types'
import { LoginDefault } from './LoginDefault'

export const LoginAdminInvites = (loginProps: LoginProps) => {
  const { formatMessage } = useContent()
  const setRitualLogoType = useStore(state => state.setRitualLogoType)
  const location = useLocation()
  const { accessCode } = queryString.parse(location.search)
  const { status, data } = useGetWorkspaceAdminInviteDetails({
    invitationId: accessCode as string,
  })

  useEffect(() => {
    setRitualLogoType(data?.inviterOrganizationType)
  }, [data?.inviterOrganizationType, setRitualLogoType])

  if (status === 'error' || status === 'idle')
    return <LoginDefault {...loginProps} />

  if (status === 'loading' || !data) return null

  return (
    <LoginShell {...loginProps}>
      <Heading fontSize="2xlarge" as="h1">
        {formatMessage({ id: 'pmportal.login.adminInvitesSignInTitle' })}
      </Heading>
      <Box mt={5}>
        <Text fontSize="small" color="grayscale.secondary">
          {formatMessage({
            id: 'pmportal.login.adminInvitesSignInDescription',
          })}
        </Text>
        <Text fontSize="small" color="grayscale.secondary">
          {data.inviterWorkspaceName}
        </Text>
      </Box>
    </LoginShell>
  )
}
