/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Link,
    LinkFromJSON,
    LinkFromJSONTyped,
    LinkToJSON,
} from './Link';

/**
 * Indicates possible actions that can be performed for this incentive plan
 * @export
 * @interface IncentivePlanReadOnlyActions
 */
export interface IncentivePlanReadOnlyActions {
    /**
     * 
     * @type {Link}
     * @memberof IncentivePlanReadOnlyActions
     */
    _delete?: Link;
    /**
     * 
     * @type {Link}
     * @memberof IncentivePlanReadOnlyActions
     */
    publish?: Link;
    /**
     * 
     * @type {Link}
     * @memberof IncentivePlanReadOnlyActions
     */
    pause?: Link;
    /**
     * 
     * @type {Link}
     * @memberof IncentivePlanReadOnlyActions
     */
    resume?: Link;
}

export function IncentivePlanReadOnlyActionsFromJSON(json: any): IncentivePlanReadOnlyActions {
    return IncentivePlanReadOnlyActionsFromJSONTyped(json, false);
}

export function IncentivePlanReadOnlyActionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncentivePlanReadOnlyActions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        '_delete': !exists(json, 'delete') ? undefined : LinkFromJSON(json['delete']),
        'publish': !exists(json, 'publish') ? undefined : LinkFromJSON(json['publish']),
        'pause': !exists(json, 'pause') ? undefined : LinkFromJSON(json['pause']),
        'resume': !exists(json, 'resume') ? undefined : LinkFromJSON(json['resume']),
    };
}

export function IncentivePlanReadOnlyActionsToJSON(value?: IncentivePlanReadOnlyActions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delete': LinkToJSON(value._delete),
        'publish': LinkToJSON(value.publish),
        'pause': LinkToJSON(value.pause),
        'resume': LinkToJSON(value.resume),
    };
}

