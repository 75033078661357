/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const IncentiveType = {
    Credit: 'CREDIT',
    DiscountAmount: 'DISCOUNT_AMOUNT',
    DiscountPercentage: 'DISCOUNT_PERCENTAGE'
} as const;
export type IncentiveType = typeof IncentiveType[keyof typeof IncentiveType];


export function IncentiveTypeFromJSON(json: any): IncentiveType {
    return IncentiveTypeFromJSONTyped(json, false);
}

export function IncentiveTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncentiveType {
    return json as IncentiveType;
}

export function IncentiveTypeToJSON(value?: IncentiveType | null): any {
    return value as any;
}

