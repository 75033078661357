/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The status of the schedule. If not set, indicates that no schedule is set
 * @export
 */
export const ScheduleStatus = {
    Upcoming: 'UPCOMING',
    Ongoing: 'ONGOING',
    Ended: 'ENDED'
} as const;
export type ScheduleStatus = typeof ScheduleStatus[keyof typeof ScheduleStatus];


export function ScheduleStatusFromJSON(json: any): ScheduleStatus {
    return ScheduleStatusFromJSONTyped(json, false);
}

export function ScheduleStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleStatus {
    return json as ScheduleStatus;
}

export function ScheduleStatusToJSON(value?: ScheduleStatus | null): any {
    return value as any;
}

