/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncentivePlanWorkspaceMembersResponseItem,
    IncentivePlanWorkspaceMembersResponseItemFromJSON,
    IncentivePlanWorkspaceMembersResponseItemFromJSONTyped,
    IncentivePlanWorkspaceMembersResponseItemToJSON,
} from './IncentivePlanWorkspaceMembersResponseItem';

/**
 * 
 * @export
 * @interface IncentivePlanWorkspaceMembersResponse
 */
export interface IncentivePlanWorkspaceMembersResponse {
    /**
     * 
     * @type {Array<IncentivePlanWorkspaceMembersResponseItem>}
     * @memberof IncentivePlanWorkspaceMembersResponse
     */
    results?: Array<IncentivePlanWorkspaceMembersResponseItem>;
}

export function IncentivePlanWorkspaceMembersResponseFromJSON(json: any): IncentivePlanWorkspaceMembersResponse {
    return IncentivePlanWorkspaceMembersResponseFromJSONTyped(json, false);
}

export function IncentivePlanWorkspaceMembersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncentivePlanWorkspaceMembersResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(IncentivePlanWorkspaceMembersResponseItemFromJSON)),
    };
}

export function IncentivePlanWorkspaceMembersResponseToJSON(value?: IncentivePlanWorkspaceMembersResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(IncentivePlanWorkspaceMembersResponseItemToJSON)),
    };
}

