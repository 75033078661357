import cookie from 'cookie'

/**
 * @param {string} name The cookie name.
 * @returns The cookie value.
 */
export const getCookie = (name: string) => cookie.parse(document.cookie)?.[name]

/**
 * @param {string} name The cookie name.
 * @param {string} value The cookie value.
 * @param {Object} options Optional object of options.
 */
export const setCookie = (name: string, value: string, options = {}) => {
  const expires = new Date()
  expires.setHours(expires.getHours() + 24 * 30)

  document.cookie = cookie.serialize(name, value, {
    expires,
    sameSite: 'lax',
    ...options,
  })
}
