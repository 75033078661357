/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrganizationType,
    OrganizationTypeFromJSON,
    OrganizationTypeFromJSONTyped,
    OrganizationTypeToJSON,
} from './OrganizationType';
import {
    WorkspaceType,
    WorkspaceTypeFromJSON,
    WorkspaceTypeFromJSONTyped,
    WorkspaceTypeToJSON,
} from './WorkspaceType';

/**
 * 
 * @export
 * @interface WorkspaceCampaignInvitationResponse
 */
export interface WorkspaceCampaignInvitationResponse {
    /**
     * The campaign's internal ID (unique)
     * @type {string}
     * @memberof WorkspaceCampaignInvitationResponse
     */
    campaignId: string;
    /**
     * Public message for the campaign
     * @type {string}
     * @memberof WorkspaceCampaignInvitationResponse
     */
    campaignMessage?: string;
    /**
     * The name of the organization.
     * @type {string}
     * @memberof WorkspaceCampaignInvitationResponse
     */
    organizationName: string;
    /**
     * The URL for an image representing the organization
     * @type {string}
     * @memberof WorkspaceCampaignInvitationResponse
     */
    organizationImage?: string;
    /**
     * 
     * @type {OrganizationType}
     * @memberof WorkspaceCampaignInvitationResponse
     */
    organizationType: OrganizationType;
    /**
     * The name of the workspace.
     * @type {string}
     * @memberof WorkspaceCampaignInvitationResponse
     */
    workspaceName: string;
    /**
     * 
     * @type {WorkspaceType}
     * @memberof WorkspaceCampaignInvitationResponse
     */
    workspaceType: WorkspaceType;
}

export function WorkspaceCampaignInvitationResponseFromJSON(json: any): WorkspaceCampaignInvitationResponse {
    return WorkspaceCampaignInvitationResponseFromJSONTyped(json, false);
}

export function WorkspaceCampaignInvitationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceCampaignInvitationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'campaignId': json['campaignId'],
        'campaignMessage': !exists(json, 'campaignMessage') ? undefined : json['campaignMessage'],
        'organizationName': json['organizationName'],
        'organizationImage': !exists(json, 'organizationImage') ? undefined : json['organizationImage'],
        'organizationType': OrganizationTypeFromJSON(json['organizationType']),
        'workspaceName': json['workspaceName'],
        'workspaceType': WorkspaceTypeFromJSON(json['workspaceType']),
    };
}

export function WorkspaceCampaignInvitationResponseToJSON(value?: WorkspaceCampaignInvitationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'campaignId': value.campaignId,
        'campaignMessage': value.campaignMessage,
        'organizationName': value.organizationName,
        'organizationImage': value.organizationImage,
        'organizationType': OrganizationTypeToJSON(value.organizationType),
        'workspaceName': value.workspaceName,
        'workspaceType': WorkspaceTypeToJSON(value.workspaceType),
    };
}

