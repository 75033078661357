/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QueryInfo
 */
export interface QueryInfo {
    /**
     * The query string.
     * @type {string}
     * @memberof QueryInfo
     */
    query?: string;
    /**
     * The sort column.
     * @type {string}
     * @memberof QueryInfo
     */
    sortColumn?: string;
    /**
     * The column sort direction.
     * @type {string}
     * @memberof QueryInfo
     */
    sortDirection?: QueryInfoSortDirectionEnum;
}


/**
 * @export
 */
export const QueryInfoSortDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type QueryInfoSortDirectionEnum = typeof QueryInfoSortDirectionEnum[keyof typeof QueryInfoSortDirectionEnum];


export function QueryInfoFromJSON(json: any): QueryInfo {
    return QueryInfoFromJSONTyped(json, false);
}

export function QueryInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'query': !exists(json, 'query') ? undefined : json['query'],
        'sortColumn': !exists(json, 'sortColumn') ? undefined : json['sortColumn'],
        'sortDirection': !exists(json, 'sortDirection') ? undefined : json['sortDirection'],
    };
}

export function QueryInfoToJSON(value?: QueryInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'query': value.query,
        'sortColumn': value.sortColumn,
        'sortDirection': value.sortDirection,
    };
}

