import React, { FC, MouseEventHandler, ReactElement, ReactNode } from 'react'
import {
  Box,
  BoxProps,
  Button,
  Flex,
  Heading,
  Spacer,
  Text,
} from '@ritualco/jutsu'
import { useContent } from 'lib/content/react'
import { ProtectedButton } from 'components/ProtectedButton'
import { PortalAdminRole } from 'lib/generated/api'

export interface EditableSectionProps extends BoxProps {
  subtitle?: string
  action?: ReactNode
  onEditClick?: MouseEventHandler
  editPermission?: PortalAdminRole
  editButtonTextOverride?: string
  icon?: React.ReactElement
  showDivider?: boolean
  disableAction?: boolean
}

export interface EditableDefinitionProps extends BoxProps {
  isReadOnly?: boolean
  truncate?: boolean
}

const borderStyle = '1px solid'

export const EditableSection: FC<EditableSectionProps> = ({
  title,
  subtitle,
  action,
  onEditClick,
  editPermission,
  editButtonTextOverride,
  children,
  icon,
  showDivider = false,
  disableAction = false,
  ...props
}) => {
  const { formatMessage } = useContent()
  const dividerProps = {
    borderBottom: borderStyle,
    borderColor: 'grayscale.quinary',
  }
  return (
    <Box {...props}>
      <Flex align="center" {...(showDivider && dividerProps)} py={2} pl={2}>
        <Box>
          <Heading fontSize="large" fontWeight="medium" as="h3">
            {title}
          </Heading>
          {subtitle && (
            <Text
              color="grayscale.secondary"
              fontSize="small"
              fontWeight="medium"
              pb={2}
            >
              {subtitle}
            </Text>
          )}
        </Box>
        <Spacer />
        {!disableAction && (
          <>
            {action ||
              (editPermission ? (
                <ProtectedButton
                  adminRole={editPermission}
                  variant="outline"
                  colorScheme="transparent"
                  onClick={onEditClick}
                  leftIcon={icon as ReactElement}
                >
                  {editButtonTextOverride ||
                    formatMessage({ id: 'pmportal.global.actionEdit' })}
                </ProtectedButton>
              ) : (
                <Button
                  variant="outline"
                  colorScheme="transparent"
                  onClick={onEditClick}
                  leftIcon={icon as ReactElement}
                >
                  {editButtonTextOverride ||
                    formatMessage({ id: 'pmportal.global.actionEdit' })}
                </Button>
              ))}
          </>
        )}
      </Flex>
      {children}
    </Box>
  )
}

export const EditableSectionRow: FC<BoxProps> = props => (
  <Box
    py="3"
    pl="2"
    borderTop={borderStyle}
    borderColor="grayscale.quinary"
    {...props}
  />
)

export const EditableSectionTermRow: FC<BoxProps> = props => (
  <EditableSectionRow
    display="flex"
    justifyContent="space-between"
    {...props}
  />
)

export const EditableSectionTerm: FC<BoxProps> = props => (
  <Box
    as="dt"
    fontWeight="normal"
    fontSize="small"
    color="grayscale.secondary"
    mr={4}
    {...props}
  />
)

export const EditableSectionValue: FC<EditableDefinitionProps> = ({
  isReadOnly,
  truncate,
  ...props
}) => (
  <Box
    as="dd"
    fontSize="small"
    color={isReadOnly ? 'grayscale.secondary' : 'grayscale.primary'}
    textAlign="right"
    {...(truncate
      ? {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }
      : {})}
    {...props}
  />
)
