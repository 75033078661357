/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaymentMethodBrand,
    PaymentMethodBrandFromJSON,
    PaymentMethodBrandFromJSONTyped,
    PaymentMethodBrandToJSON,
} from './PaymentMethodBrand';

/**
 * Payment method details intended for display purpose
 * @export
 * @interface PaymentMethodDisplayDetails
 */
export interface PaymentMethodDisplayDetails {
    /**
     * 
     * @type {PaymentMethodBrand}
     * @memberof PaymentMethodDisplayDetails
     */
    brand?: PaymentMethodBrand;
    /**
     * The last 4 digits of a credit card
     * @type {string}
     * @memberof PaymentMethodDisplayDetails
     */
    last4?: string;
    /**
     * Two-digit number representing the card’s expiration month
     * @type {number}
     * @memberof PaymentMethodDisplayDetails
     */
    expirationMonth?: number;
    /**
     * Four-digit number representing the card’s expiration year
     * @type {number}
     * @memberof PaymentMethodDisplayDetails
     */
    expirationYear?: number;
}

export function PaymentMethodDisplayDetailsFromJSON(json: any): PaymentMethodDisplayDetails {
    return PaymentMethodDisplayDetailsFromJSONTyped(json, false);
}

export function PaymentMethodDisplayDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentMethodDisplayDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'brand': !exists(json, 'brand') ? undefined : PaymentMethodBrandFromJSON(json['brand']),
        'last4': !exists(json, 'last4') ? undefined : json['last4'],
        'expirationMonth': !exists(json, 'expirationMonth') ? undefined : json['expirationMonth'],
        'expirationYear': !exists(json, 'expirationYear') ? undefined : json['expirationYear'],
    };
}

export function PaymentMethodDisplayDetailsToJSON(value?: PaymentMethodDisplayDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'brand': PaymentMethodBrandToJSON(value.brand),
        'last4': value.last4,
        'expirationMonth': value.expirationMonth,
        'expirationYear': value.expirationYear,
    };
}

