import { ContentfulLoaderDeps } from 'lib/content/loaders/contentful'
import { KeycloakConfig } from 'lib/auth/adapters/react-keycloak'
import { PortalAdminRole } from 'lib/generated/api'

const getEnv = <T extends any = any>(str: string, defaultValue?: T) =>
  process.env[str] || defaultValue

const requiredEnv = <T extends any = any>(name: string, defaultValue?: T) => {
  const env = getEnv(name, defaultValue)
  if (!env) {
    throw new Error(`process.env.${name} is required but is undefined`)
  }

  return env
}

export const CONTENTFUL: ContentfulLoaderDeps = {
  space: requiredEnv('REACT_APP_CONTENTFUL_SPACE'),
  accessToken: requiredEnv('REACT_APP_CONTENTFUL_TOKEN'),
  environment: getEnv('REACT_APP_CONTENTFUL_ENV'),
  host: getEnv('REACT_APP_CONTENTFUL_HOST'),
} as const

export const CONTENTFUL_RESOURCESETS = [
  'pmportal.error',
  'pmportal.settings',
  'pmportal.global',
  'pmportal.nav',
  'pmportal.login',
  'pmportal.restaurantLists',
  'pmportal.notifications',
  'pmportal.drop',
  'pmportal.incentives',
  'pmportal.jobs',
  'pmportal.reports',
  'pmportal.home',
  'pmportal.history',
  'pmportal.billing',
  'pmportal.companies',
  'pmportal.connections',
  'pmportal.workspaces',
  'pmportal.onboarding',
  'pmportal.landing',
  'pmportal.alert',
  'pmportal.employees',
  'pmportal.invites',
  'pmportal.memberAccepted',
  'pmportal.memberCampaigns',
  'pmportal.groupOrders',
  'pmportal.workspaceCampaigns',
]

export const KEYCLOAK_AUTH: KeycloakConfig = {
  clientId: requiredEnv('REACT_APP_KEYCLOAK_AUTH_CLIENTID'),
  realm: requiredEnv('REACT_APP_KEYCLOAK_AUTH_REALM'),
  url: requiredEnv('REACT_APP_KEYCLOAK_AUTH_URL'),
}

export const INITIAL_ROWS_PER_PAGE = 10

export const PORTAL_ADMIN_ROLES_HIERARCHY: PortalAdminRole[] = [
  PortalAdminRole.Owner,
  PortalAdminRole.Administrator,
  PortalAdminRole.Analyst,
  PortalAdminRole.ViewOnly,
  PortalAdminRole.DropRunner,
]
