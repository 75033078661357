import {
  Button,
  Flex,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from '@ritualco/jutsu'
import { useState } from 'react'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { FormikGoogleMapsAutoComplete } from 'components/Formik'
import { HeraModal } from 'components/HeraModal'
import { useContent } from 'lib/content/react'
import { CreateWorkspaceFormType, CreateWorkspaceModalProps } from './types'

const initialValues: CreateWorkspaceFormType = {
  address: '',
}

export const CreateWorkspaceModal = ({
  onSubmit,
  onClose,
  ...modalProps
}: CreateWorkspaceModalProps) => {
  const { formatMessage } = useContent()
  const FormValueTypeSchema = Yup.object().shape({
    address: Yup.string().required(),
  })
  const [address, setAddress] = useState<google.maps.places.PlaceResult>()

  return (
    <HeraModal size="2xl" onClose={onClose} {...modalProps}>
      <Formik
        initialValues={initialValues}
        validationSchema={FormValueTypeSchema}
        onSubmit={async () => {
          const createWorkspace = {
            name: address?.name,
            googlePlaceId: address?.place_id || '',
          }
          await onSubmit(createWorkspace)
        }}
      >
        {props => (
          <>
            <ModalHeader>
              {formatMessage({ id: 'pmportal.workspaces.createModal.heading' })}
            </ModalHeader>
            <ModalBody>
              <Form id="workspaceCreate" noValidate>
                <Flex direction="column">
                  <Text fontSize="small" color="grayscale.secondary" mb={9}>
                    {formatMessage({
                      id: 'pmportal.workspaces.createModal.description',
                    })}
                  </Text>
                  <FormikGoogleMapsAutoComplete
                    label={formatMessage({
                      id: 'pmportal.workspaces.createModal.locationLabel',
                    })}
                    name="address"
                    placeholder={formatMessage({
                      id: 'pmportal.workspaces.createModal.locationPlaceholder',
                    })}
                    setAddress={setAddress}
                    isRequired
                  />
                </Flex>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="transparent" mr={2} onClick={onClose}>
                {formatMessage({ id: 'pmportal.global.actionCancel' })}
              </Button>
              <Button
                type="submit"
                form="workspaceCreate"
                isLoading={props.isSubmitting}
                disabled={!props.isValid}
              >
                {formatMessage({
                  id: 'pmportal.workspaces.createModal.create',
                })}
              </Button>
            </ModalFooter>
          </>
        )}
      </Formik>
    </HeraModal>
  )
}
