export const LOCALES = [
  'en-AU',
  'en-CA',
  'en-GB',
  'en-US',
  'fr-CA',
  'zh-HK',
] as const

export const DEFAULT_LOCALE = 'en-CA'

export type Locale = typeof LOCALES[number]
