/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    ConfirmPermissionsResponse,
    ConfirmPermissionsResponseFromJSON,
    ConfirmPermissionsResponseToJSON,
} from '../models';

export interface ConfirmTicketDecisionRequest {
    ticketId: string;
}

/**
 * 
 */
export class PermissionTicketApi extends runtime.BaseAPI {

    /**
     * Confirm that the recipient of a ticket has accepted their new access. The RPT\'s externalId will be registered as the ticket\'s recipient.
     */
    async confirmTicketDecisionRaw(requestParameters: ConfirmTicketDecisionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<ConfirmPermissionsResponse>> {
        if (requestParameters.ticketId === null || requestParameters.ticketId === undefined) {
            throw new runtime.RequiredError('ticketId','Required parameter requestParameters.ticketId was null or undefined when calling confirmTicketDecision.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/permission-ticket/{ticketId}/confirm`.replace(`{${"ticketId"}}`, encodeURIComponent(String(requestParameters.ticketId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConfirmPermissionsResponseFromJSON(jsonValue));
    }

    /**
     * Confirm that the recipient of a ticket has accepted their new access. The RPT\'s externalId will be registered as the ticket\'s recipient.
     */
    async confirmTicketDecision(requestParameters: ConfirmTicketDecisionRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<ConfirmPermissionsResponse> {
        const response = await this.confirmTicketDecisionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
