/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';

/**
 * Information about where food should be delivered. Only set for delivery fulfillments.
 * @export
 * @interface FulfillmentDeliveryDetails
 */
export interface FulfillmentDeliveryDetails {
    /**
     * ID of a user Ritual location, indicating where the order should be delivered
     * @type {string}
     * @memberof FulfillmentDeliveryDetails
     */
    locationId: string;
    /**
     * 
     * @type {Address}
     * @memberof FulfillmentDeliveryDetails
     */
    address: Address;
    /**
     * Instructions for the courier
     * @type {string}
     * @memberof FulfillmentDeliveryDetails
     */
    deliveryInstructions?: string;
}

export function FulfillmentDeliveryDetailsFromJSON(json: any): FulfillmentDeliveryDetails {
    return FulfillmentDeliveryDetailsFromJSONTyped(json, false);
}

export function FulfillmentDeliveryDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FulfillmentDeliveryDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'locationId': json['locationId'],
        'address': AddressFromJSON(json['address']),
        'deliveryInstructions': !exists(json, 'deliveryInstructions') ? undefined : json['deliveryInstructions'],
    };
}

export function FulfillmentDeliveryDetailsToJSON(value?: FulfillmentDeliveryDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'locationId': value.locationId,
        'address': AddressToJSON(value.address),
        'deliveryInstructions': value.deliveryInstructions,
    };
}

