/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import {
    InMerchantNetwork,
    InMerchantNetworkFromJSON,
    InMerchantNetworkFromJSONTyped,
    InMerchantNetworkToJSON,
} from './InMerchantNetwork';
import {
    Merchant,
    MerchantFromJSON,
    MerchantFromJSONTyped,
    MerchantToJSON,
} from './Merchant';
import {
    MerchantStatus,
    MerchantStatusFromJSON,
    MerchantStatusFromJSONTyped,
    MerchantStatusToJSON,
} from './MerchantStatus';
import {
    ModelIndex,
    ModelIndexFromJSON,
    ModelIndexFromJSONTyped,
    ModelIndexToJSON,
} from './ModelIndex';

/**
 * 
 * @export
 * @interface MerchantListMerchant
 */
export interface MerchantListMerchant {
    /**
     * The time at which the merchant was created in this merchant list.
     * @type {Date}
     * @memberof MerchantListMerchant
     */
    createdAt?: Date;
    /**
     * The time at which the merchant in this merchant list was last modified.
     * @type {Date}
     * @memberof MerchantListMerchant
     */
    updatedAt?: Date;
    /**
     * The merchant unique identifier.
     * @type {string}
     * @memberof MerchantListMerchant
     */
    merchantId: string;
    /**
     * The merchant name.
     * @type {string}
     * @memberof MerchantListMerchant
     */
    name?: string;
    /**
     * Merchant's name, excluding any location or intersection information
     * @type {string}
     * @memberof MerchantListMerchant
     */
    nameWithoutLocation?: string;
    /**
     * Nearby major intersection
     * @type {string}
     * @memberof MerchantListMerchant
     */
    intersection?: string;
    /**
     * List of search tags for this merchant
     * @type {Array<string>}
     * @memberof MerchantListMerchant
     */
    tags?: Array<string>;
    /**
     * 
     * @type {Address}
     * @memberof MerchantListMerchant
     */
    address?: Address;
    /**
     * 
     * @type {MerchantStatus}
     * @memberof MerchantListMerchant
     */
    merchantStatus?: MerchantStatus;
    /**
     * 
     * @type {string}
     * @memberof MerchantListMerchant
     */
    timezone?: string;
    /**
     * The preferred locale used for communication, in BCP 47 format.
     * @type {string}
     * @memberof MerchantListMerchant
     */
    locale?: string;
    /**
     * The full ROO menu URL for the merchant
     * @type {string}
     * @memberof MerchantListMerchant
     */
    menuUrl?: string;
    /**
     * Whether merchant is in the merchant network.
     * @type {boolean}
     * @memberof MerchantListMerchant
     */
    inMerchantNetwork?: boolean;
    /**
     * The zero-based index in the list.
     * @type {number}
     * @memberof MerchantListMerchant
     */
    index?: number;
}

export function MerchantListMerchantFromJSON(json: any): MerchantListMerchant {
    return MerchantListMerchantFromJSONTyped(json, false);
}

export function MerchantListMerchantFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantListMerchant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'merchantId': json['merchantId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'nameWithoutLocation': !exists(json, 'nameWithoutLocation') ? undefined : json['nameWithoutLocation'],
        'intersection': !exists(json, 'intersection') ? undefined : json['intersection'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'merchantStatus': !exists(json, 'merchantStatus') ? undefined : MerchantStatusFromJSON(json['merchantStatus']),
        'timezone': !exists(json, 'timezone') ? undefined : json['timezone'],
        'locale': !exists(json, 'locale') ? undefined : json['locale'],
        'menuUrl': !exists(json, 'menuUrl') ? undefined : json['menuUrl'],
        'inMerchantNetwork': !exists(json, 'inMerchantNetwork') ? undefined : json['inMerchantNetwork'],
        'index': !exists(json, 'index') ? undefined : json['index'],
    };
}

export function MerchantListMerchantToJSON(value?: MerchantListMerchant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'merchantId': value.merchantId,
        'name': value.name,
        'nameWithoutLocation': value.nameWithoutLocation,
        'intersection': value.intersection,
        'tags': value.tags,
        'address': AddressToJSON(value.address),
        'merchantStatus': MerchantStatusToJSON(value.merchantStatus),
        'timezone': value.timezone,
        'locale': value.locale,
        'menuUrl': value.menuUrl,
        'inMerchantNetwork': value.inMerchantNetwork,
        'index': value.index,
    };
}

