/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The brand of the payment method based on Stripe's available values:
 * https://stripe.com/docs/api/payment_methods/object#payment_method_object-card-brand
 * @export
 */
export const PaymentMethodBrand = {
    Amex: 'AMEX',
    Diners: 'DINERS',
    Discover: 'DISCOVER',
    Jcb: 'JCB',
    Mastercard: 'MASTERCARD',
    Unionpay: 'UNIONPAY',
    Visa: 'VISA',
    Unknown: 'UNKNOWN'
} as const;
export type PaymentMethodBrand = typeof PaymentMethodBrand[keyof typeof PaymentMethodBrand];


export function PaymentMethodBrandFromJSON(json: any): PaymentMethodBrand {
    return PaymentMethodBrandFromJSONTyped(json, false);
}

export function PaymentMethodBrandFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentMethodBrand {
    return json as PaymentMethodBrand;
}

export function PaymentMethodBrandToJSON(value?: PaymentMethodBrand | null): any {
    return value as any;
}

