/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkspaceConnectionCreateResponse
 */
export interface WorkspaceConnectionCreateResponse {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceConnectionCreateResponse
     */
    workspaceId?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceConnectionCreateResponse
     */
    connectedWorkspaceId?: string;
    /**
     * The unique identifier for the connection.
     * @type {string}
     * @memberof WorkspaceConnectionCreateResponse
     */
    connectionId?: string;
}

export function WorkspaceConnectionCreateResponseFromJSON(json: any): WorkspaceConnectionCreateResponse {
    return WorkspaceConnectionCreateResponseFromJSONTyped(json, false);
}

export function WorkspaceConnectionCreateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceConnectionCreateResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'workspaceId': !exists(json, 'workspaceId') ? undefined : json['workspaceId'],
        'connectedWorkspaceId': !exists(json, 'connectedWorkspaceId') ? undefined : json['connectedWorkspaceId'],
        'connectionId': !exists(json, 'connectionId') ? undefined : json['connectionId'],
    };
}

export function WorkspaceConnectionCreateResponseToJSON(value?: WorkspaceConnectionCreateResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'workspaceId': value.workspaceId,
        'connectedWorkspaceId': value.connectedWorkspaceId,
        'connectionId': value.connectionId,
    };
}

