/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessRestriction,
    AccessRestrictionFromJSON,
    AccessRestrictionFromJSONTyped,
    AccessRestrictionToJSON,
} from './AccessRestriction';
import {
    AppAssociation,
    AppAssociationFromJSON,
    AppAssociationFromJSONTyped,
    AppAssociationToJSON,
} from './AppAssociation';
import {
    CurrencyCode,
    CurrencyCodeFromJSON,
    CurrencyCodeFromJSONTyped,
    CurrencyCodeToJSON,
} from './CurrencyCode';

/**
 * 
 * @export
 * @interface UpdateWorkspace
 */
export interface UpdateWorkspace {
    /**
     * The preferred locale used for communication, in BCP 47 format.
     * @type {string}
     * @memberof UpdateWorkspace
     */
    locale?: string;
    /**
     * The name of the workspace.
     * @type {string}
     * @memberof UpdateWorkspace
     */
    name?: string;
    /**
     * The portal admin owner unique identifier. The owner is an administrator that cannot be removed or altered by other portal admins.
     * @type {string}
     * @memberof UpdateWorkspace
     */
    ownerId?: string;
    /**
     * The timezone of the workspace.
     * @type {string}
     * @memberof UpdateWorkspace
     */
    timezone?: string;
    /**
     * 
     * @type {AccessRestriction}
     * @memberof UpdateWorkspace
     */
    accessRestriction?: AccessRestriction;
    /**
     * 
     * @type {AppAssociation}
     * @memberof UpdateWorkspace
     */
    appAssociation?: AppAssociation;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkspace
     */
    googlePlaceId?: string;
    /**
     * 
     * @type {CurrencyCode}
     * @memberof UpdateWorkspace
     */
    currencyCode?: CurrencyCode;
    /**
     * Subdomain for workspace
     * @type {string}
     * @memberof UpdateWorkspace
     */
    subdomain?: string;
}

export function UpdateWorkspaceFromJSON(json: any): UpdateWorkspace {
    return UpdateWorkspaceFromJSONTyped(json, false);
}

export function UpdateWorkspaceFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateWorkspace {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'locale': !exists(json, 'locale') ? undefined : json['locale'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'ownerId': !exists(json, 'ownerId') ? undefined : json['ownerId'],
        'timezone': !exists(json, 'timezone') ? undefined : json['timezone'],
        'accessRestriction': !exists(json, 'accessRestriction') ? undefined : AccessRestrictionFromJSON(json['accessRestriction']),
        'appAssociation': !exists(json, 'appAssociation') ? undefined : AppAssociationFromJSON(json['appAssociation']),
        'googlePlaceId': !exists(json, 'googlePlaceId') ? undefined : json['googlePlaceId'],
        'currencyCode': !exists(json, 'currencyCode') ? undefined : CurrencyCodeFromJSON(json['currencyCode']),
        'subdomain': !exists(json, 'subdomain') ? undefined : json['subdomain'],
    };
}

export function UpdateWorkspaceToJSON(value?: UpdateWorkspace | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'locale': value.locale,
        'name': value.name,
        'ownerId': value.ownerId,
        'timezone': value.timezone,
        'accessRestriction': AccessRestrictionToJSON(value.accessRestriction),
        'appAssociation': AppAssociationToJSON(value.appAssociation),
        'googlePlaceId': value.googlePlaceId,
        'currencyCode': CurrencyCodeToJSON(value.currencyCode),
        'subdomain': value.subdomain,
    };
}

