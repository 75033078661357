/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WorkspaceConnectionInviteResponseEntry,
    WorkspaceConnectionInviteResponseEntryFromJSON,
    WorkspaceConnectionInviteResponseEntryFromJSONTyped,
    WorkspaceConnectionInviteResponseEntryToJSON,
} from './WorkspaceConnectionInviteResponseEntry';

/**
 * 
 * @export
 * @interface WorkspaceConnectionInviteResponse
 */
export interface WorkspaceConnectionInviteResponse {
    /**
     * 
     * @type {Array<WorkspaceConnectionInviteResponseEntry>}
     * @memberof WorkspaceConnectionInviteResponse
     */
    results?: Array<WorkspaceConnectionInviteResponseEntry>;
}

export function WorkspaceConnectionInviteResponseFromJSON(json: any): WorkspaceConnectionInviteResponse {
    return WorkspaceConnectionInviteResponseFromJSONTyped(json, false);
}

export function WorkspaceConnectionInviteResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceConnectionInviteResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(WorkspaceConnectionInviteResponseEntryFromJSON)),
    };
}

export function WorkspaceConnectionInviteResponseToJSON(value?: WorkspaceConnectionInviteResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(WorkspaceConnectionInviteResponseEntryToJSON)),
    };
}

