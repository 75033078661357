/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CurrencyCode = {
    UnknownCurrencyCode: 'UNKNOWN_CURRENCY_CODE',
    Aud: 'AUD',
    Cad: 'CAD',
    Eur: 'EUR',
    Gbp: 'GBP',
    Hkd: 'HKD',
    Jpy: 'JPY',
    Nzd: 'NZD',
    Sgd: 'SGD',
    Usd: 'USD'
} as const;
export type CurrencyCode = typeof CurrencyCode[keyof typeof CurrencyCode];


export function CurrencyCodeFromJSON(json: any): CurrencyCode {
    return CurrencyCodeFromJSONTyped(json, false);
}

export function CurrencyCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrencyCode {
    return json as CurrencyCode;
}

export function CurrencyCodeToJSON(value?: CurrencyCode | null): any {
    return value as any;
}

