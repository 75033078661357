import { useEffect, useRef, useState } from 'react'
import { Input } from '@ritualco/jutsu'

declare global {
  interface Window {
    onInitMap: (() => void)[]
  }
}

interface GoogleMapsAutoCompleteProps {
  onChange: (placeResult: google.maps.places.PlaceResult) => void
  onDelete?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  address?: string
}

export const GoogleMapsAutoComplete = ({
  onChange,
  onDelete,
  address,
  ...props
}: GoogleMapsAutoCompleteProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [searchText, setSearchText] = useState<string | undefined>(address)

  useEffect(() => {
    const enhanceMapsInput = () => {
      const InputBoxAutoComplete = new window.google.maps.places.Autocomplete(
        inputRef.current as HTMLInputElement
      )

      InputBoxAutoComplete.addListener('place_changed', () => {
        const place = InputBoxAutoComplete.getPlace()
        if (!place.geometry) {
          return
        }
        setSearchText(place.formatted_address)
        onChange(place)
      })

      InputBoxAutoComplete.addListener('keydown', e => {
        if (e.keyCode === 13) {
          e.preventDefault()
        }
      })
    }

    const runGoogleMapsFunc = (callbackFn: () => void) => {
      const MAPS_SCRIPT_ID = 'mapsApiScriptId'
      const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
      if (!document.getElementById(MAPS_SCRIPT_ID)) {
        const script = document.createElement('script')
        script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`
        script.async = true
        script.id = MAPS_SCRIPT_ID
        window.onInitMap = [callbackFn]
        script.onload = () => window.onInitMap.forEach(callback => callback())
        document.body.appendChild(script)

        // z-index style fix for the dropdown to render correctly with modals
        const styleText = '.pac-container {z-index: 1401 !important;}'
        const styleNode = document.createElement('style')
        styleNode.textContent = styleText
        document.body.appendChild(styleNode)
      } else if (!window.google) {
        window.onInitMap.push(callbackFn)
      } else {
        callbackFn()
      }
    }
    runGoogleMapsFunc(enhanceMapsInput)
  }, [onChange])
  return (
    <Input
      {...props}
      ref={inputRef}
      value={searchText}
      onChange={e => setSearchText(e.target.value)}
      onKeyDown={onDelete}
    />
  )
}
