import { useStore } from 'state/store'
import Loading from 'components/Loading'
import Reports from '../views/Reports'

const ReportsContainer = () => {
  const organizationId = useStore(state => state.organization.id)
  const workspaceId = useStore(state => state.workspace.id)
  const timezone = useStore(state => state.workspace.timezone)

  if (!workspaceId || !organizationId || !timezone) {
    return <Loading />
  }

  return (
    <Reports
      workspaceId={workspaceId}
      organizationId={organizationId}
      timezone={timezone}
    />
  )
}
export default ReportsContainer
