/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    WorkspaceIncentivesResponse,
    WorkspaceIncentivesResponseFromJSON,
    WorkspaceIncentivesResponseToJSON,
    WorkspaceIncentivesScheduledResponse,
    WorkspaceIncentivesScheduledResponseFromJSON,
    WorkspaceIncentivesScheduledResponseToJSON,
} from '../models';

export interface GetMemberIncentivesRequest {
    workspaceId: string;
    workspaceMemberId: string;
}

export interface GetUpcomingMemberWorkspaceIncentivesRequest {
    workspaceId: string;
    workspaceMemberId: string;
}

/**
 * 
 */
export class IncentivesApi extends runtime.BaseAPI {

    /**
     * Get a list of incentives (sorted in descending order) for a certain workspace member that have been used for the past six months
     */
    async getMemberIncentivesRaw(requestParameters: GetMemberIncentivesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WorkspaceIncentivesResponse>> {
        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling getMemberIncentives.');
        }

        if (requestParameters.workspaceMemberId === null || requestParameters.workspaceMemberId === undefined) {
            throw new runtime.RequiredError('workspaceMemberId','Required parameter requestParameters.workspaceMemberId was null or undefined when calling getMemberIncentives.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workspaces/{workspaceId}/workspace-members/{workspaceMemberId}/incentives`.replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"workspaceMemberId"}}`, encodeURIComponent(String(requestParameters.workspaceMemberId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceIncentivesResponseFromJSON(jsonValue));
    }

    /**
     * Get a list of incentives (sorted in descending order) for a certain workspace member that have been used for the past six months
     */
    async getMemberIncentives(requestParameters: GetMemberIncentivesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WorkspaceIncentivesResponse> {
        const response = await this.getMemberIncentivesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of incentives that are scheduled to be issued for the user (next issue cycle only)
     */
    async getUpcomingMemberWorkspaceIncentivesRaw(requestParameters: GetUpcomingMemberWorkspaceIncentivesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WorkspaceIncentivesScheduledResponse>> {
        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling getUpcomingMemberWorkspaceIncentives.');
        }

        if (requestParameters.workspaceMemberId === null || requestParameters.workspaceMemberId === undefined) {
            throw new runtime.RequiredError('workspaceMemberId','Required parameter requestParameters.workspaceMemberId was null or undefined when calling getUpcomingMemberWorkspaceIncentives.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workspaces/{workspaceId}/workspace-members/{workspaceMemberId}/upcoming`.replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"workspaceMemberId"}}`, encodeURIComponent(String(requestParameters.workspaceMemberId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceIncentivesScheduledResponseFromJSON(jsonValue));
    }

    /**
     * Get a list of incentives that are scheduled to be issued for the user (next issue cycle only)
     */
    async getUpcomingMemberWorkspaceIncentives(requestParameters: GetUpcomingMemberWorkspaceIncentivesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WorkspaceIncentivesScheduledResponse> {
        const response = await this.getUpcomingMemberWorkspaceIncentivesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
