import { ReactNode } from 'react'
import { useField } from 'formik'
import {
  Daypicker,
  DaypickerProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@ritualco/jutsu'

export type FormikDayPickerProps = {
  label?: ReactNode
  isRequired?: boolean
  name: string
} & Partial<DaypickerProps>

export const FormikDayPicker = ({
  label,
  isRequired,
  ...props
}: FormikDayPickerProps) => {
  const [field, { error, touched }, { setValue }] = useField(props.name)
  return (
    <FormControl
      id={props.name}
      isInvalid={!!error && touched}
      isRequired={isRequired}
    >
      {label && <FormLabel>{label}</FormLabel>}
      <Daypicker
        {...field}
        selected={field.value}
        onChange={selected => setValue(selected)}
        {...props}
      />
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  )
}
