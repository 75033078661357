import { useContent } from 'lib/content/react'
import { useLocation } from 'lib/router'
import queryString from 'query-string'
import { MdCheck, MdErrorOutline } from 'react-icons/md'
import {
  useExternalConnectionsQuery,
  useGetWorkspaceInvite,
} from 'api/endpoints/connections'
import { useStore } from 'state/store'
import {
  Alert,
  AlertIcon,
  AlertProps,
  CloseButton,
  Text,
  VStack,
} from '@ritualco/jutsu'
import { WorkspaceConnectionStatus } from 'lib/generated/api'

type WorkspaceConnectedProps = {
  onClose: () => void
} & AlertProps
const WorkspaceConnected = ({
  onClose,
  ...alertProps
}: WorkspaceConnectedProps) => {
  const { formatMessage } = useContent()
  const workspaceId = useStore(state => state.workspace.id)!
  const organizationId = useStore(state => state.organization.id)!

  const { connectionId: id } = queryString.parse(useLocation().search)
  const connectionId = (id as string) || ''
  const workspaceInvite = useGetWorkspaceInvite({ connectionId })
  const connections = useExternalConnectionsQuery({
    workspaceId,
    organizationId,
  })

  if (!workspaceInvite.data || !connections.data) {
    return null
  }

  const { inviterOrganizationName, inviterWorkspaceName } = workspaceInvite.data
  const connectionSuccessful = connections.data?.results?.some(
    connection =>
      connection.id === connectionId &&
      connection.status === WorkspaceConnectionStatus.Confirmed
  )

  if (connectionSuccessful) {
    return (
      <Alert variant="subtle" status="info" fontSize="sm" {...alertProps}>
        <AlertIcon as={MdCheck} w="1.5rem" />
        <span>
          {formatMessage(
            { id: 'pmportal.alert.workspaceConnected' },
            {
              organizationName: inviterOrganizationName,
              workspaceName: inviterWorkspaceName,
            }
          )}
        </span>
        <CloseButton position="absolute" right={2} onClick={onClose} />
      </Alert>
    )
  }

  return (
    <Alert
      variant="subtle"
      status="info"
      fontSize="sm"
      alignItems="flex-start"
      {...alertProps}
    >
      <AlertIcon as={MdErrorOutline} w="1.5rem" />
      <VStack spacing={1} align="flex-start">
        <Text fontWeight="medium">
          {formatMessage(
            { id: 'pmportal.alert.workspaceConnectedFailedTitle' },
            { organizationName: inviterOrganizationName }
          )}
        </Text>
        <Text>
          {formatMessage(
            { id: 'pmportal.alert.workspaceConnectedFailedDescription' },
            { organizationName: inviterOrganizationName }
          )}
        </Text>
      </VStack>
      <CloseButton position="absolute" right={2} top={2} onClick={onClose} />
    </Alert>
  )
}

export default WorkspaceConnected
