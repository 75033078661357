/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetupIntent
 */
export interface SetupIntent {
    /**
     * The setup intent client secret to be used to add payment methods client-side
     * @type {string}
     * @memberof SetupIntent
     */
    setupIntentClientSecret: string;
}

export function SetupIntentFromJSON(json: any): SetupIntent {
    return SetupIntentFromJSONTyped(json, false);
}

export function SetupIntentFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetupIntent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'setupIntentClientSecret': json['setupIntentClientSecret'],
    };
}

export function SetupIntentToJSON(value?: SetupIntent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'setupIntentClientSecret': value.setupIntentClientSecret,
    };
}

