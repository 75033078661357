import { SideNavItem, SideNavItemProps } from 'components/SideNavItem'
import { FormattedMessage } from 'lib/content/react'
import { generatePath, Link, RouteEntry, useRouteMatch } from 'lib/router'
import { useStore } from 'state/store'

export type AppSideNavLinkProps = {
  route: RouteEntry<SideNavItemProps>
}

export const AppSideNavLink = ({ route }: AppSideNavLinkProps) => {
  const match = useRouteMatch({
    path: route.path,
    exact: route.exact,
  })
  const workspaceId = useStore(state => state.workspace.id)
  if (!workspaceId) return null

  const path = generatePath(route.path, { workspaceId })

  return (
    <Link key={path} to={path}>
      <SideNavItem
        navIcon={route.navIcon}
        isActive={!!match}
        statusBadge={route.statusBadge}
        featureFlag={route.featureFlag}
        isInternal={route.isInternal}
      >
        {route.navTitle ? (
          <FormattedMessage id={route.navTitle} />
        ) : (
          'Unknown Route'
        )}
      </SideNavItem>
    </Link>
  )
}
