/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkspaceMembersBatchInviteRequest
 */
export interface WorkspaceMembersBatchInviteRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkspaceMembersBatchInviteRequest
     */
    emails: Array<string>;
    /**
     * The date-time the invite job is scheduled to be run at. If unspecified, the job is scheduled to be run immediately.
     * @type {Date}
     * @memberof WorkspaceMembersBatchInviteRequest
     */
    scheduledAt?: Date;
    /**
     * The time zone of scheduledAt (formatted according to IANA time zone database)
     * @type {string}
     * @memberof WorkspaceMembersBatchInviteRequest
     */
    timezone?: string;
}

export function WorkspaceMembersBatchInviteRequestFromJSON(json: any): WorkspaceMembersBatchInviteRequest {
    return WorkspaceMembersBatchInviteRequestFromJSONTyped(json, false);
}

export function WorkspaceMembersBatchInviteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceMembersBatchInviteRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emails': json['emails'],
        'scheduledAt': !exists(json, 'scheduledAt') ? undefined : (new Date(json['scheduledAt'])),
        'timezone': !exists(json, 'timezone') ? undefined : json['timezone'],
    };
}

export function WorkspaceMembersBatchInviteRequestToJSON(value?: WorkspaceMembersBatchInviteRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'emails': value.emails,
        'scheduledAt': value.scheduledAt === undefined ? undefined : (value.scheduledAt.toISOString()),
        'timezone': value.timezone,
    };
}

