import { useState, useCallback, useEffect } from 'react'
import { utcToZonedTime } from 'date-fns-tz'
import { MdFileDownload } from 'react-icons/md'
import {
  AlertStatus,
  Button,
  Datepicker,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Select,
  Stack,
  Text,
  useDisclosure,
} from '@ritualco/jutsu'
import { getTimeZones } from '@vvo/tzdb'
import { useGetIncentivePlans } from 'api/endpoints/incentivePlan'
import {
  useCreateIncentiveReportRun,
  useCreateIncentiveReportsRun,
} from 'api/endpoints/incentiveReports'
import { Pagination, useRowsPerPage } from 'components/Pagination'
import Loading from 'components/Loading'
import { FullWidthPage } from 'components/PageTemplates'
import { EditableSection } from 'components/EditableSection'
import { ProtectedButton } from 'components/ProtectedButton'
import { ReportDownloadModal } from 'components/Modals/ReportDownloadModal'
import { ReportsTable } from 'components/Tables/ReportsTable'
import { useMessage } from 'contexts/MessageContext'
import { useContent } from 'lib/content/react'
import {
  IncentivePlanStatus,
  PortalAdminRole,
  IncentiveReportRunReportTypeEnum,
} from 'lib/generated/api'
import { useAnalytics } from 'lib/simple-analytics'
import {
  PageTrackingNames,
  AnalyticEvents,
  IncentivePlan,
} from 'types/entities'
import { Error } from 'views/Error'

import {
  getReportTimeDetails,
  mapIncentiveReportTypeEnumToAllIncentiveReportTypeEnum,
} from './utils'

export type ReportsProps = {
  organizationId: string
  workspaceId: string
  timezone: string
}

const ReportsCampaigns = ({
  workspaceId,
  organizationId,
  timezone,
}: ReportsProps) => {
  const { formatMessage } = useContent()
  const { addMessage } = useMessage()
  const { track } = useAnalytics()
  const [rowsPerPage, setRowsPerPage] = useRowsPerPage()
  const [paginationTokenOption, setPaginationTokenOption] = useState({})
  const createReportRun = useCreateIncentiveReportRun()
  const createReportsRun = useCreateIncentiveReportsRun()
  const {
    isOpen: isDownloadReportOpen,
    onOpen: onDownloadReportOpen,
    onClose: onDownloadReportClose,
  } = useDisclosure()
  const {
    isOpen: isDownloadAllReportsOpen,
    onOpen: onDownloadAllReportsOpen,
    onClose: onDownloadAllReportsClose,
  } = useDisclosure()

  const [reportTimezone, setReportTimezone] = useState(timezone)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [
    targetIncentivePlan,
    setTargetIncentivePlan,
  ] = useState<IncentivePlan | null>(null)

  const now = utcToZonedTime(new Date(), reportTimezone)
  const timeZones = getTimeZones()
  const hasFilter =
    reportTimezone !== timezone || startDate !== null || endDate !== null

  // Ensure the start and end date are still valid when timezone changes
  useEffect(() => {
    if (endDate && endDate.getTime() > now.getTime()) {
      setEndDate(now)
    }

    if (startDate && startDate.getTime() > now.getTime()) {
      setStartDate(now)
    }
  }, [startDate, endDate, now, reportTimezone])

  const { data, status, isPreviousData } = useGetIncentivePlans(
    {
      organizationId,
      workspaceId,
      status: [IncentivePlanStatus.Launched, IncentivePlanStatus.Paused],
      limit: rowsPerPage,
      ...paginationTokenOption,
    },
    timezone
  )

  const toast = useCallback(
    (status: AlertStatus, id: string) => {
      addMessage({
        status,
        description: formatMessage({ id }),
        isClosable: false,
      })
    },
    [addMessage, formatMessage]
  )

  const onDownloadReport = (incentivePlan: IncentivePlan) => {
    setTargetIncentivePlan(incentivePlan)
    onDownloadReportOpen()
  }

  const createDownloadReport = async ({
    reportType,
  }: {
    reportType: IncentiveReportRunReportTypeEnum
  }) => {
    if (!targetIncentivePlan) {
      return
    }
    const reportTimeDetails = getReportTimeDetails({
      startDate,
      endDate,
      timezone: reportTimezone,
    })
    track(AnalyticEvents.reportsDownloadCsv)
    await createReportRun.mutateAsync(
      {
        organizationId,
        workspaceId,
        incentiveReportRun: {
          incentivePlanId: targetIncentivePlan.id,
          reportType,
          ...reportTimeDetails,
        },
      },
      {
        onSuccess: ({ downloadUrl }) => {
          window.open(downloadUrl)
        },
        onError: () => {
          track(AnalyticEvents.reportsDownloadCsvError)
          toast('error', 'pmportal.error.genericTryAgain')
        },
      }
    )
  }

  const createDownloadReports = async ({
    reportType,
  }: {
    reportType: IncentiveReportRunReportTypeEnum
  }) => {
    const allReportsType = mapIncentiveReportTypeEnumToAllIncentiveReportTypeEnum(
      reportType
    )
    const reportTimeDetails = getReportTimeDetails({
      startDate,
      endDate,
      timezone: reportTimezone,
    })
    track(AnalyticEvents.reportsDownloadAll)
    await createReportsRun.mutateAsync(
      {
        organizationId,
        workspaceId,
        allIncentivesReportRun: {
          reportType: allReportsType,
          ...reportTimeDetails,
        },
      },
      {
        onSuccess: ({ downloadUrl }) => {
          window.open(downloadUrl)
        },
        onError: () => {
          track(AnalyticEvents.reportsDownloadAllError)
          toast('error', 'pmportal.error.genericTryAgain')
        },
      }
    )
  }

  if (status === 'loading') {
    return <Loading />
  }

  if (status === 'error') {
    return <Error />
  }

  const updateStartDate = (newDate: Date) => {
    setStartDate(newDate)
  }

  const updateEndDate = (newDate: Date) => {
    setEndDate(newDate)
  }

  const clearAll = () => {
    setReportTimezone(timezone)
    setStartDate(null)
    setEndDate(null)
  }

  return (
    <FullWidthPage
      heading={formatMessage({ id: 'pmportal.incentives.reportTitle' })}
      subheading={
        <Stack spacing="24px">
          <Text color="grayscale.secondary" fontSize="small">
            {formatMessage({ id: 'pmportal.incentives.reportSubtitle' })}
          </Text>
          <Text color="grayscale.secondary" fontSize="small">
            {formatMessage({ id: 'pmportal.incentives.reportDesc' })}
          </Text>
        </Stack>
      }
      trackingName={PageTrackingNames.reports}
    >
      <HStack mt={3} spacing={1} alignItems="flex-end">
        <FormControl id="timezoneSelect" maxWidth={194}>
          <FormLabel>
            {formatMessage({
              id: 'pmportal.incentives.timezone',
            })}
          </FormLabel>
          <Select
            size="sm"
            onChange={e => setReportTimezone(e.target.value)}
            value={reportTimezone}
          >
            {timeZones.map(({ name }) => (
              <option value={name} key={name}>
                {name}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl
          id="startDatePicker"
          maxWidth={194}
          sx={{ position: 'unset' }}
        >
          <FormLabel>
            {formatMessage({
              id: 'pmportal.incentives.startDate',
            })}
          </FormLabel>
          <Datepicker
            size="sm"
            selected={startDate}
            onChange={updateStartDate}
            maxDate={endDate || now}
          />
        </FormControl>
        <Text h="28px"> - </Text>
        <FormControl
          id="endDatePicker"
          maxWidth={194}
          sx={{ position: 'unset' }}
        >
          <FormLabel>
            {formatMessage({
              id: 'pmportal.incentives.endDate',
            })}
          </FormLabel>
          <Datepicker
            size="sm"
            selected={endDate}
            onChange={updateEndDate}
            minDate={startDate}
            maxDate={now}
          />
        </FormControl>
        <Button
          size="sm"
          variant="ghost"
          onClick={clearAll}
          disabled={!hasFilter}
        >
          {formatMessage({ id: 'pmportal.incentives.clearAllFilter' })}
        </Button>
      </HStack>
      <EditableSection
        title={formatMessage({ id: 'pmportal.incentives.title' })}
        action={
          <ProtectedButton
            adminRole={PortalAdminRole.ViewOnly}
            disabled={data?.results.length === 0}
            colorScheme="transparent"
            variant="outline"
            leftIcon={<Icon w="18px" h="18px" as={MdFileDownload} />}
            onClick={onDownloadAllReportsOpen}
          >
            {formatMessage({ id: 'pmportal.incentives.reportDownloadAll' })}
          </ProtectedButton>
        }
        mt={6}
      >
        <ReportsTable reports={data?.results} onDownload={onDownloadReport} />
        <Pagination
          rowsPerPage={rowsPerPage}
          hasNext={data?.hasNextPage && !isPreviousData}
          hasPrev={data?.hasPreviousPage && !isPreviousData}
          onRowsPerPageChange={e => setRowsPerPage(Number(e.target.value))}
          onPrev={() => {
            setPaginationTokenOption({
              previousPageToken: data?.paginationToken,
            })
          }}
          onNext={() => {
            setPaginationTokenOption({ nextPageToken: data?.paginationToken })
          }}
        />
      </EditableSection>
      <ReportDownloadModal
        startDate={startDate}
        endDate={endDate}
        incentivePlanName={targetIncentivePlan?.incentiveDetails.name}
        onSubmit={createDownloadReport}
        isOpen={isDownloadReportOpen}
        onClose={onDownloadReportClose}
      />

      <ReportDownloadModal
        startDate={startDate}
        endDate={endDate}
        onSubmit={createDownloadReports}
        isOpen={isDownloadAllReportsOpen}
        onClose={onDownloadAllReportsClose}
        isDownloadAllReportsModal
      />
    </FullWidthPage>
  )
}

export default ReportsCampaigns
