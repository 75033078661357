import { WorkspaceConnectionsResponse } from 'lib/generated/api'
import { ExternalConnectionsTableRow } from 'types/entities'

export type ExternalConnectionsMapper = (
  response: WorkspaceConnectionsResponse
) => Omit<WorkspaceConnectionsResponse, 'results'> & {
  results: ExternalConnectionsTableRow[]
}
export const mapToExternalConnectionsList: ExternalConnectionsMapper = response => ({
  ...response,
  results: response.results.map(x => ({
    id: x.connectionId,
    organizationName: x.inviter?.organizationName || '',
    workspaceName: x.inviter?.workspaceName || '',
    connectionDate: x.connectedAt as Date,
    status: x.status,
  })),
})
