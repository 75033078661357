import { ReactNode } from 'react'
import { useField } from 'formik'
import {
  Datepicker,
  DatepickerProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@ritualco/jutsu'

export type FormikDatepickerProps = {
  label?: ReactNode
  name: string
  isRequired?: boolean
} & DatepickerProps

export const FormikDatepicker = ({
  label,
  isRequired = false,
  ...props
}: FormikDatepickerProps) => {
  const [field, { error, touched }, { setValue }] = useField(props.name)
  return (
    <FormControl
      id={props.name}
      isInvalid={!!error && touched}
      isRequired={isRequired}
      sx={{ position: 'unset' }}
    >
      {label && <FormLabel>{label}</FormLabel>}
      <Datepicker
        {...field}
        selected={field.value}
        onChange={(newDate: Date) => setValue(newDate)}
        size="lg"
        {...props}
      />
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  )
}
