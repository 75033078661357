import {
  Box,
  Flex,
  FnOnSearch,
  Grid,
  ResultText,
  SearchBar,
  Text,
} from '@ritualco/jutsu'
import { ReactComponent as HelpIcon } from 'assets/Icons/Lifebuoy.svg'
import { useContent } from 'lib/content/react'
import { Index, IndexEntry } from 'utils/search'
import { Link } from 'lib/router'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { getBreadcrumbs } from 'use-react-router-breadcrumbs'
import { Location } from 'history'
import * as ReactIs from 'react-is'
import { useRemoteConfig } from 'lib/remoteConfig'
import { AppBarDivider } from '../AppBar'
import { ProfileMenu, ProfileMenuProps } from '../ProfileMenu'

type HeaderSearchBarProps = {
  searchItems: Index
  onResultSelect: (item: IndexEntry, index: number) => void
  resultText: ResultText<IndexEntry>
  helpUrl: string
} & ProfileMenuProps

type HelpSectionProps = {
  url: string
}
const HelpSection = ({ url }: HelpSectionProps) => {
  const { formatMessage } = useContent()
  return (
    <Link to={url}>
      <Flex flexDirection="column" alignItems="center">
        <HelpIcon />
        <Text fontSize="xs" fontWeight="medium" color="grayscale.secondary">
          {formatMessage({
            id: 'pmportal.global.actionHelp',
          })}
        </Text>
      </Flex>
    </Link>
  )
}

const ProfileSection = ({
  email,
  name,
  avatar,
  items,
  onSignOut,
}: ProfileMenuProps) => (
  <Flex>
    <ProfileMenu
      avatar={avatar}
      email={email}
      name={name}
      items={items}
      onSignOut={onSignOut}
    />
  </Flex>
)

export const HeaderSearchBar = ({
  searchItems,
  onResultSelect,
  resultText,
  email,
  name,
  items,
  avatar,
  onSignOut,
  helpUrl,
}: HeaderSearchBarProps) => {
  const { formatMessage } = useContent()
  const { getBoolean } = useRemoteConfig()
  const searchEnabled = getBoolean('hera_search_enabled')
  const renderObjectRecord = (record: IndexEntry) => {
    const location: Location = {
      pathname: record.path,
      key: '',
      search: '',
      hash: '',
      state: '',
    }

    const breadcrumbs = getBreadcrumbs({
      routes: searchItems,
      location,
      options: { disableDefaults: true },
    })

    if (breadcrumbs.length === 0) return null
    if (breadcrumbs.length > 1) {
      // getBreadcrumbs always include an item for "Home", representing the leading slash.
      // This removes the item for every route other than Home itself (the only route with length 1)
      breadcrumbs.shift()
    }
    return (
      <>
        {breadcrumbs.map(({ breadcrumb }, index) => {
          let breadcrumbText = ''
          if (ReactIs.isElement(breadcrumb)) {
            breadcrumbText = breadcrumb?.props?.children
          } else if (typeof breadcrumb === 'string') {
            breadcrumbText = breadcrumb
          }
          return (
            <span key={`breadcrumb-${breadcrumbText}`}>
              {breadcrumbText}
              {index < breadcrumbs.length - 1 && (
                <ChevronRightIcon
                  color="grayscale.secondary"
                  mx={1}
                  h="24px"
                  w="24px"
                />
              )}
            </span>
          )
        })}
      </>
    )
  }

  const onSearch: FnOnSearch<IndexEntry> = ({ items, inputValue }) => {
    const inputLowerCase = inputValue.toLowerCase()
    return items.reduce((acc: Index, index) => {
      const breadcrumbMatched = index.breadcrumb
        ? index.breadcrumb.toLowerCase().includes(inputLowerCase)
        : false
      const navTitleMatched = index.navTitle
        ? index.navTitle.toLowerCase().includes(inputLowerCase)
        : false
      return breadcrumbMatched || navTitleMatched ? [...acc, index] : acc
    }, [])
  }
  return (
    <Grid width="100%" templateColumns="1fr auto" gridGap="6">
      <Box zIndex="dropdown">
        <Flex position="relative">
          <Box position="absolute" width="100%">
            {searchEnabled && (
              <SearchBar<IndexEntry>
                border="none"
                searchIconColor="grayscale.tertiary"
                placeholder={formatMessage({
                  id: 'pmportal.nav.search',
                })}
                items={searchItems}
                onResultSelect={onResultSelect}
                resultText={resultText}
                renderListRecord={renderObjectRecord}
                renderSelectedRecord={item => item.navTitle as string}
                onSearch={onSearch}
              />
            )}
          </Box>
        </Flex>
      </Box>
      <Flex align="center">
        <HelpSection url={helpUrl} />
        <AppBarDivider />
        <ProfileSection
          email={email}
          name={name}
          avatar={avatar}
          items={items}
          onSignOut={onSignOut}
        />
      </Flex>
    </Grid>
  )
}
