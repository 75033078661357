/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const BillingType = {
    CreditCard: 'CREDIT_CARD',
    Invoice: 'INVOICE'
} as const;
export type BillingType = typeof BillingType[keyof typeof BillingType];


export function BillingTypeFromJSON(json: any): BillingType {
    return BillingTypeFromJSONTyped(json, false);
}

export function BillingTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingType {
    return json as BillingType;
}

export function BillingTypeToJSON(value?: BillingType | null): any {
    return value as any;
}

