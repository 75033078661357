/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The criteria that determines which merchants the incentive will be redeemable at. One of:
 * * `ALL_MERCHANTS` - All Ritual merchants
 * * `WORKSPACE` - Workspace merchants only
 * * `MERCHANT_LISTS` - Merchants in the specified merchant lists
 * * `GEO_RESTRICTIONS` - Merchants located within the specified list of polygon and radius restrictions
 * @export
 */
export const MerchantEligibilityType = {
    AllMerchants: 'ALL_MERCHANTS',
    Workspace: 'WORKSPACE',
    MerchantLists: 'MERCHANT_LISTS',
    GeoRestrictions: 'GEO_RESTRICTIONS'
} as const;
export type MerchantEligibilityType = typeof MerchantEligibilityType[keyof typeof MerchantEligibilityType];


export function MerchantEligibilityTypeFromJSON(json: any): MerchantEligibilityType {
    return MerchantEligibilityTypeFromJSONTyped(json, false);
}

export function MerchantEligibilityTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantEligibilityType {
    return json as MerchantEligibilityType;
}

export function MerchantEligibilityTypeToJSON(value?: MerchantEligibilityType | null): any {
    return value as any;
}

