/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TargetingListType = {
    All: 'ALL',
    Custom: 'CUSTOM',
    SelectLists: 'SELECT_LISTS'
} as const;
export type TargetingListType = typeof TargetingListType[keyof typeof TargetingListType];


export function TargetingListTypeFromJSON(json: any): TargetingListType {
    return TargetingListTypeFromJSONTyped(json, false);
}

export function TargetingListTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TargetingListType {
    return json as TargetingListType;
}

export function TargetingListTypeToJSON(value?: TargetingListType | null): any {
    return value as any;
}

