import { mapToUser } from 'api/mappers/portalAdmin'
import { mapToTeamMembers } from 'api/mappers/teamMember'
import {
  CreateWorkspaceAdminRequest,
  DeleteWorkspaceAdminRequest,
  GetOrganizationAdminRequest,
  GetWorkspaceAdminInviteDetailsRequest,
  GetWorkspaceAdminRequest,
  GetWorkspaceAdminsRequest,
  ResendInvitationToOrganizationAdminRequest,
  ResendInvitationToOrganizationAdminsRequest,
  ResendInvitationToWorkspaceAdminsRequest,
  ResendInvitationWorkspaceAdminRequest,
  UpdateWorkspaceAdminRequest,
} from 'lib/generated/api'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useApiClient } from 'contexts/ApiClientContext'
import { QUERY_KEYS as ORG_QUERY_KEYS } from './organizations'

export const QUERY_KEYS = {
  organizationAdmin: 'organizationAdmin',
  workspaceAdmin: 'workspaceAdmin',
  workspaceAdmins: 'workspaceAdmins',
  workspaceAdminInviteDetails: 'workspaceAdminInviteDetails',
} as const

/* Organization Admin */

export const organizationAdminQueryKey = (
  request: GetOrganizationAdminRequest
) => [
  QUERY_KEYS.organizationAdmin,
  request.organizationId,
  request.portalAdminId,
]

export const useGetOrganizationAdmin = (
  request: GetOrganizationAdminRequest
) => {
  const { portalAdminApi } = useApiClient()

  return useQuery(
    organizationAdminQueryKey(request),
    () => portalAdminApi.getOrganizationAdmin(request),
    {
      enabled: !!request.organizationId && !!request.portalAdminId,
    }
  )
}

/* Workspace Admin */

export const workspaceAdminQueryKey = (request: GetWorkspaceAdminRequest) => [
  QUERY_KEYS.workspaceAdmin,
  request.organizationId,
  request.workspaceId,
  request.portalAdminId,
]

export const workspaceAdminsQueryKey = ({
  organizationId,
  workspaceId,
  ...queryParams
}: GetWorkspaceAdminsRequest) => [
  QUERY_KEYS.workspaceAdmins,
  organizationId,
  workspaceId,
  queryParams,
]

export const workspaceAdminInviteDetailsQueryKey = (
  request: GetWorkspaceAdminInviteDetailsRequest
) => [QUERY_KEYS.workspaceAdminInviteDetails, request.invitationId]

export const useGetWorkspaceAdmin = (request: GetWorkspaceAdminRequest) => {
  const { portalAdminApi } = useApiClient()
  return useQuery(
    workspaceAdminQueryKey(request),
    () => portalAdminApi.getWorkspaceAdmin(request),
    {
      enabled:
        !!request.portalAdminId &&
        !!request.workspaceId &&
        !!request.organizationId,
      select: mapToUser,
    }
  )
}

export const useGetWorkspaceAdmins = (request: GetWorkspaceAdminsRequest) => {
  const { portalAdminApi } = useApiClient()

  return useQuery(
    workspaceAdminsQueryKey(request),
    () => portalAdminApi.getWorkspaceAdmins(request),
    {
      enabled: !!request.organizationId && !!request.workspaceId,
    }
  )
}

export const useTeamMembersQuery = (request: GetWorkspaceAdminsRequest) => {
  const { portalAdminApi } = useApiClient()

  return useQuery(
    workspaceAdminsQueryKey(request),
    () => portalAdminApi.getWorkspaceAdmins(request),
    {
      enabled: !!request.organizationId && !!request.workspaceId,
      select: mapToTeamMembers,
    }
  )
}

export const useUpdateWorkspaceAdmin = () => {
  const queryClient = useQueryClient()
  const { portalAdminApi } = useApiClient()

  return useMutation(
    (request: UpdateWorkspaceAdminRequest) =>
      portalAdminApi.updateWorkspaceAdmin(request),
    {
      onSuccess: (data, variables) => {
        queryClient.setQueryData(
          workspaceAdminQueryKey({
            organizationId: variables.organizationId,
            workspaceId: variables.workspaceId,
            portalAdminId: variables.portalAdminId,
          }),
          data
        )
        queryClient.invalidateQueries(ORG_QUERY_KEYS.organizationUserProfiles)
      },
    }
  )
}

export const useDeleteWorkspaceAdmin = () => {
  const queryClient = useQueryClient()
  const { portalAdminApi } = useApiClient()

  return useMutation(
    (request: DeleteWorkspaceAdminRequest) =>
      portalAdminApi.deleteWorkspaceAdmin(request),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(
          workspaceAdminsQueryKey({
            organizationId: variables.organizationId,
            workspaceId: variables.workspaceId,
          })
        )
      },
    }
  )
}

export const useCreateWorkspaceAdmin = () => {
  const queryClient = useQueryClient()
  const { portalAdminApi } = useApiClient()

  return useMutation(
    (request: CreateWorkspaceAdminRequest) =>
      portalAdminApi.createWorkspaceAdmin(request),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(
          workspaceAdminsQueryKey({
            organizationId: variables.organizationId,
            workspaceId: variables.workspaceId,
          })
        )
      },
    }
  )
}

export const useGetWorkspaceAdminInviteDetails = (
  request: GetWorkspaceAdminInviteDetailsRequest
) => {
  const { portalAdminApi } = useApiClient()

  return useQuery(
    workspaceAdminInviteDetailsQueryKey(request),
    () => portalAdminApi.getWorkspaceAdminInviteDetails(request),
    { enabled: !!request.invitationId, refetchOnWindowFocus: false }
  )
}

export const useResendInvitationToWorkspaceAdminMembers = () => {
  const { portalAdminApi } = useApiClient()
  const queryClient = useQueryClient()
  return useMutation(
    (request: ResendInvitationToWorkspaceAdminsRequest) =>
      portalAdminApi.resendInvitationToWorkspaceAdmins(request),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(
          workspaceAdminsQueryKey({
            organizationId: variables.organizationId,
            workspaceId: variables.workspaceId,
          })
        )
      },
    }
  )
}

export const useResendInvitationToWorkspaceAdminMember = () => {
  const { portalAdminApi } = useApiClient()
  const queryClient = useQueryClient()
  return useMutation(
    (request: ResendInvitationWorkspaceAdminRequest) =>
      portalAdminApi.resendInvitationWorkspaceAdmin(request),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(
          workspaceAdminsQueryKey({
            organizationId: variables.organizationId,
            workspaceId: variables.workspaceId,
          })
        )
      },
    }
  )
}

export const useResendInvitationToOrganizationAdminMembers = () => {
  const { portalAdminApi } = useApiClient()
  const queryClient = useQueryClient()
  return useMutation(
    (request: ResendInvitationToOrganizationAdminsRequest) =>
      portalAdminApi.resendInvitationToOrganizationAdmins(request),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(
          organizationAdminQueryKey({
            organizationId: variables.organizationId,
            portalAdminId: variables.portalAdminId,
          })
        )
      },
    }
  )
}

export const useResendInvitationToOrganizationAdminMember = () => {
  const { portalAdminApi } = useApiClient()
  const queryClient = useQueryClient()
  return useMutation(
    (request: ResendInvitationToOrganizationAdminRequest) =>
      portalAdminApi.resendInvitationToOrganizationAdmin(request),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(
          organizationAdminQueryKey({
            organizationId: variables.organizationId,
            portalAdminId: variables.portalAdminId,
          })
        )
      },
    }
  )
}
