import { useState, useEffect, ComponentType, FC } from 'react'
import { rgba, transitions } from 'polished'
import {
  Badge,
  Box,
  Flex,
  ListItem,
  Icon,
  Spacer,
  useTheme,
  CSSObject,
} from '@ritualco/jutsu'
import { pick } from 'lodash'
import { useContent } from 'lib/content/react'
import { useRemoteConfig } from 'lib/remoteConfig'
import useIsInternalUser from 'hooks/useIsInternalUser'

type StatusBadge = 'comingSoon' | 'new'

export type SideNavItemProps = {
  navIcon?: ComponentType
  /** Special styling is applied when active */
  isActive?: boolean
  /** A badge is displayed depending on the status */
  statusBadge?: StatusBadge
  featureFlag?: string
  isInternal?: boolean
}

export const SideNavItem: FC<SideNavItemProps> = ({
  navIcon,
  isActive = false,
  statusBadge,
  children,
  featureFlag,
  isInternal = false,
}) => {
  const theme = useTheme()
  const { formatMessage } = useContent()
  const [flagEnabled, setFlagEnabled] = useState<boolean | undefined>(undefined)
  const { isInternalUser, isInternalUserLoaded } = useIsInternalUser()

  const { configLoaded, getBoolean } = useRemoteConfig()
  useEffect(() => {
    if (featureFlag) {
      // Only show "Coming Soon" when feature flag is loaded with `false`
      const enabled = !configLoaded || getBoolean(featureFlag)
      setFlagEnabled(enabled)
    } else {
      setFlagEnabled(true)
    }
  }, [configLoaded, featureFlag, getBoolean])

  const activeStyles = {
    bg: rgba(theme.colors.primary.normal, 0.15),
    color: 'primary.darker',
  }

  const inactiveStyles = {
    _focus: {
      bg: rgba(theme.colors.grayscale.quinary, 0.3),
    },
    _hover: {
      bg: rgba(theme.colors.grayscale.quinary, 0.3),
    },
  }

  const styles: CSSObject = {
    p: 3,
    color: 'grayscale.secondary',
    borderRadius: '6px',
    ...transitions(['color', 'background'], '0.3s ease'),
    _active: pick(activeStyles, 'color'),
    ...(isActive ? activeStyles : inactiveStyles),
    '& > svg': {
      color: isActive ? pick(activeStyles, 'color') : 'grayscale.tertiary',
      height: '24px',
      width: '24px',
    },
  }

  const getBadge = (statusBadge?: StatusBadge) => {
    if (
      statusBadge === 'comingSoon' ||
      !flagEnabled ||
      (isInternal && isInternalUserLoaded && !isInternalUser)
    ) {
      return (
        <Badge>
          {formatMessage({ id: 'pmportal.global.badgeComingSoon' })}
        </Badge>
      )
    }
    if (statusBadge === 'new') {
      return <Badge>{formatMessage({ id: 'pmportal.global.badgeNew' })}</Badge>
    }
    return null
  }

  return (
    <ListItem px={3} py={1}>
      <Flex align="center" sx={styles} py={2}>
        {navIcon && <Icon as={navIcon} />}
        <Box ml={6} fontSize="small" fontWeight="medium">
          {children}
        </Box>
        <Spacer />
        {getBadge(statusBadge)}
      </Flex>
    </ListItem>
  )
}
