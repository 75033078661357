/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const IncentiveReportColumnKnownIds = {
    DepartmentCode: 'departmentCode'
} as const;
export type IncentiveReportColumnKnownIds = typeof IncentiveReportColumnKnownIds[keyof typeof IncentiveReportColumnKnownIds];


export function IncentiveReportColumnKnownIdsFromJSON(json: any): IncentiveReportColumnKnownIds {
    return IncentiveReportColumnKnownIdsFromJSONTyped(json, false);
}

export function IncentiveReportColumnKnownIdsFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncentiveReportColumnKnownIds {
    return json as IncentiveReportColumnKnownIds;
}

export function IncentiveReportColumnKnownIdsToJSON(value?: IncentiveReportColumnKnownIds | null): any {
    return value as any;
}

