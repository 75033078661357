/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncentiveReport
 */
export interface IncentiveReport {
    /**
     * Unique identifier of the incentive plan
     * @type {string}
     * @memberof IncentiveReport
     */
    incentivePlanId?: string;
    /**
     * Unique identifier of the incentive report
     * @type {string}
     * @memberof IncentiveReport
     */
    incentiveReportId: string;
    /**
     * The incentive name
     * @type {string}
     * @memberof IncentiveReport
     */
    incentiveName?: string;
}

export function IncentiveReportFromJSON(json: any): IncentiveReport {
    return IncentiveReportFromJSONTyped(json, false);
}

export function IncentiveReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncentiveReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incentivePlanId': !exists(json, 'incentivePlanId') ? undefined : json['incentivePlanId'],
        'incentiveReportId': json['incentiveReportId'],
        'incentiveName': !exists(json, 'incentiveName') ? undefined : json['incentiveName'],
    };
}

export function IncentiveReportToJSON(value?: IncentiveReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incentivePlanId': value.incentivePlanId,
        'incentiveReportId': value.incentiveReportId,
        'incentiveName': value.incentiveName,
    };
}

