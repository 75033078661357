/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrganizationPublicConfig,
    OrganizationPublicConfigFromJSON,
    OrganizationPublicConfigFromJSONTyped,
    OrganizationPublicConfigToJSON,
} from './OrganizationPublicConfig';

/**
 * 
 * @export
 * @interface PublicConfigsResponse
 */
export interface PublicConfigsResponse {
    /**
     * 
     * @type {Array<OrganizationPublicConfig>}
     * @memberof PublicConfigsResponse
     */
    results: Array<OrganizationPublicConfig>;
}

export function PublicConfigsResponseFromJSON(json: any): PublicConfigsResponse {
    return PublicConfigsResponseFromJSONTyped(json, false);
}

export function PublicConfigsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicConfigsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(OrganizationPublicConfigFromJSON)),
    };
}

export function PublicConfigsResponseToJSON(value?: PublicConfigsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(OrganizationPublicConfigToJSON)),
    };
}

