/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WorkspaceConnectionList,
    WorkspaceConnectionListFromJSON,
    WorkspaceConnectionListFromJSONTyped,
    WorkspaceConnectionListToJSON,
} from './WorkspaceConnectionList';

/**
 * 
 * @export
 * @interface WorkspaceConnectionListGroup
 */
export interface WorkspaceConnectionListGroup {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceConnectionListGroup
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceConnectionListGroup
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceConnectionListGroup
     */
    description?: string;
    /**
     * 
     * @type {Array<WorkspaceConnectionList>}
     * @memberof WorkspaceConnectionListGroup
     */
    connectionLists: Array<WorkspaceConnectionList>;
}

export function WorkspaceConnectionListGroupFromJSON(json: any): WorkspaceConnectionListGroup {
    return WorkspaceConnectionListGroupFromJSONTyped(json, false);
}

export function WorkspaceConnectionListGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceConnectionListGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'connectionLists': ((json['connectionLists'] as Array<any>).map(WorkspaceConnectionListFromJSON)),
    };
}

export function WorkspaceConnectionListGroupToJSON(value?: WorkspaceConnectionListGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'connectionLists': ((value.connectionLists as Array<any>).map(WorkspaceConnectionListToJSON)),
    };
}

