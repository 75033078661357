import { ReactNode } from 'react'
import {
  chakra,
  CSSObject,
  Tag,
  TagCloseButton,
  BoxProps,
  FormErrorMessage,
  FormControl,
  FormLabel,
  MultiEmail,
} from '@ritualco/jutsu'
import { useField } from 'formik'

const ChakraReactMultiEmail = chakra(MultiEmail as any)

export type FormikMultiEmailInputProps = {
  placeholder?: ReactNode
  label?: string
  name: string
  isRequired?: boolean
  inputId?: string
} & BoxProps

export const FormikMultiEmailInput = ({
  label = '',
  placeholder = '',
  isRequired = false,
  inputId = 'multi-email-input',
  ...props
}: FormikMultiEmailInputProps) => {
  const [field, { error, touched }, helpers] = useField(props.name)
  const isInvalid = !!error && touched
  const sx: CSSObject = isInvalid
    ? {
        borderColor: 'red.normal',
      }
    : {}

  return (
    <FormControl
      id={props.name}
      isInvalid={isInvalid}
      isRequired={isRequired}
      mb={6}
    >
      {label && <FormLabel>{label}</FormLabel>}
      <div onBlur={() => helpers.setTouched(true, false)}>
        <ChakraReactMultiEmail
          inputId={inputId}
          placeholder={placeholder}
          emails={field.value}
          onChange={(_emails: string[]) => helpers.setValue(_emails)}
          isInvalid={isInvalid}
          sx={sx}
          getLabel={(
            email: string,
            index: number,
            removeEmail: (index: number) => void
          ) => (
            <Tag
              key={index}
              borderRadius="full"
              size="lg"
              fontSize="small"
              fontWeight="normal"
              backgroundColor="grayscale.greyFill"
              color="grayscale.primary"
              mr={2}
            >
              {email}
              <TagCloseButton onClick={() => removeEmail(index)} />
            </Tag>
          )}
          {...props}
        />
      </div>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  )
}
