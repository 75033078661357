import { ValueInitializer } from 'types'

type FnIsEnv = (env: 'development' | 'production' | 'testing') => boolean
export const isEnv: FnIsEnv = env => process.env.NODE_ENV === env

/** Remove duplicates from array */
export const deDupeArray = <T extends any[]>(arr: T) =>
  Array.from<T[number]>(new Set(arr))

type FnSleep = (timeout: number) => Promise<void>
export const sleep: FnSleep = timeout =>
  new Promise(resolve => setTimeout(() => resolve(), timeout))

export const callIfFn = <T>(x: ValueInitializer<T>): T =>
  typeof x === 'function' ? x() : x

interface EnumObject {
  [enumValue: string]: string
}

type FnGetEnumValues = (e: EnumObject) => string[]
export const getEnumValues: FnGetEnumValues = e => Object.keys(e).map(i => e[i])
