/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConnectionNode,
    ConnectionNodeFromJSON,
    ConnectionNodeFromJSONTyped,
    ConnectionNodeToJSON,
} from './ConnectionNode';
import {
    WorkspaceConnectionStatus,
    WorkspaceConnectionStatusFromJSON,
    WorkspaceConnectionStatusFromJSONTyped,
    WorkspaceConnectionStatusToJSON,
} from './WorkspaceConnectionStatus';

/**
 * 
 * @export
 * @interface WorkspaceConnection
 */
export interface WorkspaceConnection {
    /**
     * 
     * @type {WorkspaceConnectionStatus}
     * @memberof WorkspaceConnection
     */
    status: WorkspaceConnectionStatus;
    /**
     * The unique identifier for the connection.
     * @type {string}
     * @memberof WorkspaceConnection
     */
    connectionId: string;
    /**
     * 
     * @type {ConnectionNode}
     * @memberof WorkspaceConnection
     */
    inviter?: ConnectionNode;
    /**
     * 
     * @type {ConnectionNode}
     * @memberof WorkspaceConnection
     */
    invitee?: ConnectionNode;
    /**
     * The time the connection was confirmed
     * @type {Date}
     * @memberof WorkspaceConnection
     */
    connectedAt?: Date;
}

export function WorkspaceConnectionFromJSON(json: any): WorkspaceConnection {
    return WorkspaceConnectionFromJSONTyped(json, false);
}

export function WorkspaceConnectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceConnection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': WorkspaceConnectionStatusFromJSON(json['status']),
        'connectionId': json['connectionId'],
        'inviter': !exists(json, 'inviter') ? undefined : ConnectionNodeFromJSON(json['inviter']),
        'invitee': !exists(json, 'invitee') ? undefined : ConnectionNodeFromJSON(json['invitee']),
        'connectedAt': !exists(json, 'connectedAt') ? undefined : (new Date(json['connectedAt'])),
    };
}

export function WorkspaceConnectionToJSON(value?: WorkspaceConnection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': WorkspaceConnectionStatusToJSON(value.status),
        'connectionId': value.connectionId,
        'inviter': ConnectionNodeToJSON(value.inviter),
        'invitee': ConnectionNodeToJSON(value.invitee),
        'connectedAt': value.connectedAt === undefined ? undefined : (value.connectedAt.toISOString()),
    };
}

