import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  mapToIncentivePlan,
  mapToIncentivePlans,
} from 'api/mappers/incentivePlan'
import { useApiClient } from 'contexts/ApiClientContext'
import {
  CloneIncentivePlanRequest,
  CreateIncentivePlanRequest,
  DeleteIncentivePlanRequest,
  GetIncentivePlanDeltaRequest,
  GetIncentivePlanRequest,
  GetIncentivePlansRequest,
  PatchIncentivePlanRequest,
  PauseIncentivePlanRequest,
  PostIncentivePlanWorkspaceMembersRequest,
  PublishIncentivePlanRequest,
  ResumeIncentivePlanRequest,
} from 'lib/generated/api'
import { useHandleApiError } from 'api/utils'

export const QUERY_KEYS = {
  incentivePlanDelta: 'incentivePlanDelta',
  incentivePlan: 'incentivePlan',
  incentivePlans: 'incentivePlans',
} as const

export const incentivePlansQueryKey = ({
  workspaceId,
  ...queryParams
}: Omit<GetIncentivePlansRequest, 'organizationId'>) => [
  QUERY_KEYS.incentivePlans,
  workspaceId,
  queryParams,
]

export const useGetIncentivePlans = (
  request: GetIncentivePlansRequest,
  defaultTimezone?: string
) => {
  const { incentivePlanApi } = useApiClient()

  return useQuery(
    incentivePlansQueryKey(request),
    () => incentivePlanApi.getIncentivePlans(request),
    {
      enabled:
        !!request.organizationId && !!request.workspaceId && !!defaultTimezone,
      select: data => mapToIncentivePlans(data, defaultTimezone as string),
      keepPreviousData: true,
    }
  )
}

export const incentivePlanQueryKey = ({
  workspaceId,
  incentivePlanId,
}: Omit<GetIncentivePlanRequest, 'organizationId'>) => [
  QUERY_KEYS.incentivePlan,
  workspaceId,
  incentivePlanId,
]

export const useCreateIncentivePlan = () => {
  const queryClient = useQueryClient()
  const { incentivePlanApi } = useApiClient()

  return useMutation(
    (request: CreateIncentivePlanRequest) =>
      incentivePlanApi.createIncentivePlan(request),
    {
      onSuccess: (data, variables) => {
        queryClient.setQueryData(
          incentivePlanQueryKey({
            workspaceId: variables.workspaceId,
            incentivePlanId: data.metadata?.id as string,
          }),
          data
        )
        queryClient.invalidateQueries(
          incentivePlansQueryKey({
            workspaceId: variables.workspaceId,
          })
        )
      },
    }
  )
}

export const useGetIncentivePlan = (
  request: GetIncentivePlanRequest,
  defaultTimezone?: string
) => {
  const { incentivePlanApi } = useApiClient()

  return useQuery(
    incentivePlanQueryKey(request),
    () => incentivePlanApi.getIncentivePlan(request),
    {
      enabled:
        !!request.organizationId && !!request.workspaceId && !!defaultTimezone,
      select: data => mapToIncentivePlan(data, defaultTimezone as string),
    }
  )
}

export const usePatchIncentivePlan = () => {
  const queryClient = useQueryClient()
  const { incentivePlanApi } = useApiClient()
  const onError = useHandleApiError({ showMessage: false })

  return useMutation(
    (request: PatchIncentivePlanRequest) =>
      incentivePlanApi.patchIncentivePlan(request),
    {
      onSuccess: (data, variables) => {
        queryClient.setQueryData(incentivePlanQueryKey(variables), data)
        queryClient.invalidateQueries(
          incentivePlansQueryKey({
            workspaceId: variables.workspaceId,
          })
        )
      },
      onError,
    }
  )
}

export const usePublishIncentivePlan = () => {
  const queryClient = useQueryClient()
  const { incentivePlanApi } = useApiClient()
  const onError = useHandleApiError({ showMessage: false })

  return useMutation(
    (request: PublishIncentivePlanRequest) =>
      incentivePlanApi.publishIncentivePlan(request),
    {
      onSuccess: (data, variables) => {
        queryClient.setQueryData(incentivePlanQueryKey(variables), data)
        queryClient.invalidateQueries(
          incentivePlansQueryKey({
            workspaceId: variables.workspaceId,
          })
        )
      },
      onError,
    }
  )
}

export const usePauseIncentivePlan = () => {
  const queryClient = useQueryClient()
  const { incentivePlanApi } = useApiClient()

  return useMutation(
    (request: PauseIncentivePlanRequest) =>
      incentivePlanApi.pauseIncentivePlan(request),
    {
      onSuccess: (data, variables) => {
        queryClient.setQueryData(incentivePlanQueryKey(variables), data)
        queryClient.invalidateQueries(
          incentivePlansQueryKey({
            workspaceId: variables.workspaceId,
          })
        )
      },
    }
  )
}

export const useResumeIncentivePlan = () => {
  const queryClient = useQueryClient()
  const { incentivePlanApi } = useApiClient()

  return useMutation(
    (request: ResumeIncentivePlanRequest) =>
      incentivePlanApi.resumeIncentivePlan(request),
    {
      onSuccess: (data, variables) => {
        queryClient.setQueryData(incentivePlanQueryKey(variables), data)
        queryClient.invalidateQueries(
          incentivePlansQueryKey({
            workspaceId: variables.workspaceId,
          })
        )
      },
    }
  )
}

export const useDeleteIncentivePlan = () => {
  const queryClient = useQueryClient()
  const { incentivePlanApi } = useApiClient()

  return useMutation(
    (request: DeleteIncentivePlanRequest) =>
      incentivePlanApi.deleteIncentivePlan(request),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(incentivePlanQueryKey(variables))
        queryClient.invalidateQueries(
          incentivePlansQueryKey({
            workspaceId: variables.workspaceId,
          })
        )
      },
    }
  )
}

export const useCloneIncentivePlan = () => {
  const queryClient = useQueryClient()
  const { incentivePlanApi } = useApiClient()

  return useMutation(
    (request: CloneIncentivePlanRequest) =>
      incentivePlanApi.cloneIncentivePlan(request),
    {
      onSuccess: (data, variables) => {
        queryClient.setQueryData(
          incentivePlanQueryKey({
            workspaceId: variables.workspaceId,
            incentivePlanId: data.metadata?.id as string,
          }),
          data
        )
        queryClient.invalidateQueries(
          incentivePlansQueryKey({
            workspaceId: variables.workspaceId,
          })
        )
      },
    }
  )
}

export const useAddEmployeesToIncentivePlan = () => {
  const queryClient = useQueryClient()
  const { incentivePlanApi } = useApiClient()

  return useMutation(
    (request: PostIncentivePlanWorkspaceMembersRequest) =>
      incentivePlanApi.postIncentivePlanWorkspaceMembers(request),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(incentivePlanQueryKey(variables))
      },
    }
  )
}

export const incentivePlanDeltaQueryKey = ({
  workspaceId,
  incentivePlanId,
}: GetIncentivePlanDeltaRequest) => [
  QUERY_KEYS.incentivePlanDelta,
  workspaceId,
  incentivePlanId,
]

export const useGetIncentivePlanDelta = (
  request: GetIncentivePlanDeltaRequest,
  enabled = true
) => {
  const { incentivePlanApi } = useApiClient()

  return useQuery(
    incentivePlanDeltaQueryKey(request),
    () => incentivePlanApi.getIncentivePlanDelta(request),
    {
      enabled: !!request.workspaceId && !!request.incentivePlanId && enabled,
    }
  )
}
