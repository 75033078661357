/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaymentMethodDisplayDetails,
    PaymentMethodDisplayDetailsFromJSON,
    PaymentMethodDisplayDetailsFromJSONTyped,
    PaymentMethodDisplayDetailsToJSON,
} from './PaymentMethodDisplayDetails';

/**
 * A payment method associated with a workspace
 * @export
 * @interface PaymentMethod
 */
export interface PaymentMethod {
    /**
     * Unique identifier of the payment method
     * @type {string}
     * @memberof PaymentMethod
     */
    id: string;
    /**
     * Indicates if the payment method is selected for billing.
     * @type {boolean}
     * @memberof PaymentMethod
     */
    isDefault: boolean;
    /**
     * 
     * @type {PaymentMethodDisplayDetails}
     * @memberof PaymentMethod
     */
    paymentMethodDisplayDetails?: PaymentMethodDisplayDetails;
}

export function PaymentMethodFromJSON(json: any): PaymentMethod {
    return PaymentMethodFromJSONTyped(json, false);
}

export function PaymentMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentMethod {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'isDefault': json['isDefault'],
        'paymentMethodDisplayDetails': !exists(json, 'paymentMethodDisplayDetails') ? undefined : PaymentMethodDisplayDetailsFromJSON(json['paymentMethodDisplayDetails']),
    };
}

export function PaymentMethodToJSON(value?: PaymentMethod | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'isDefault': value.isDefault,
        'paymentMethodDisplayDetails': PaymentMethodDisplayDetailsToJSON(value.paymentMethodDisplayDetails),
    };
}

