/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ConnectedApplicationPlatform = {
    Android: 'ANDROID',
    Ios: 'IOS',
    Web: 'WEB',
    Universal: 'UNIVERSAL'
} as const;
export type ConnectedApplicationPlatform = typeof ConnectedApplicationPlatform[keyof typeof ConnectedApplicationPlatform];


export function ConnectedApplicationPlatformFromJSON(json: any): ConnectedApplicationPlatform {
    return ConnectedApplicationPlatformFromJSONTyped(json, false);
}

export function ConnectedApplicationPlatformFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectedApplicationPlatform {
    return json as ConnectedApplicationPlatform;
}

export function ConnectedApplicationPlatformToJSON(value?: ConnectedApplicationPlatform | null): any {
    return value as any;
}

