/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    MerchantResponse,
    MerchantResponseFromJSON,
    MerchantResponseToJSON,
} from '../models';

export interface GetMerchantsRequest {
    organizationId: string;
    workspaceId: string;
    merchantId?: Array<string>;
    query?: string;
    limit?: number;
    nextPageToken?: string;
    previousPageToken?: string;
    sortColumn?: string;
    sortDirection?: GetMerchantsSortDirectionEnum;
    latitude?: number;
    longitude?: number;
    supportsGroupOrdering?: boolean;
}

/**
 * 
 */
export class MerchantApi extends runtime.BaseAPI {

    /**
     * Returns a list of merchants.
     */
    async getMerchantsRaw(requestParameters: GetMerchantsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MerchantResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getMerchants.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling getMerchants.');
        }

        const queryParameters: any = {};

        if (requestParameters.merchantId) {
            queryParameters['merchantId'] = requestParameters.merchantId;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.nextPageToken !== undefined) {
            queryParameters['nextPageToken'] = requestParameters.nextPageToken;
        }

        if (requestParameters.previousPageToken !== undefined) {
            queryParameters['previousPageToken'] = requestParameters.previousPageToken;
        }

        if (requestParameters.sortColumn !== undefined) {
            queryParameters['sortColumn'] = requestParameters.sortColumn;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.latitude !== undefined) {
            queryParameters['latitude'] = requestParameters.latitude;
        }

        if (requestParameters.longitude !== undefined) {
            queryParameters['longitude'] = requestParameters.longitude;
        }

        if (requestParameters.supportsGroupOrdering !== undefined) {
            queryParameters['supportsGroupOrdering'] = requestParameters.supportsGroupOrdering;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/merchants`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MerchantResponseFromJSON(jsonValue));
    }

    /**
     * Returns a list of merchants.
     */
    async getMerchants(requestParameters: GetMerchantsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MerchantResponse> {
        const response = await this.getMerchantsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetMerchantsSortDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type GetMerchantsSortDirectionEnum = typeof GetMerchantsSortDirectionEnum[keyof typeof GetMerchantsSortDirectionEnum];
