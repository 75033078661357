import { FC, createContext, useContext } from 'react'
import { Mutable } from 'type-fest'

const EVENT_ACTION_PAGE = 'CLICK_THROUGH_FUNNEL_PAGE'
const EVENT_ACTION_TRACK = 'CLICK_THROUGH_FUNNEL_TRACK'

// extends window to avoid type errors
declare global {
  interface Window {
    dataLayer: DataLayer
  }
}

export type EventProperties = Record<string, any>
export type PageEvent = (properties?: EventProperties) => void
export type TrackEvent = (name: string, properties?: EventProperties) => void
export type Event = {
  event: string
  eventName?: string | undefined
  eventProperties: EventProperties
}
export type PushEvent = (event: Event) => void
export type DataLayer = Mutable<Event[]>

type AnalyticsProviderProps = {
  globalEventProperties: EventProperties
}
type AnalyticsContextProps = {
  page: PageEvent
  track: TrackEvent
}

const getAnalyticUtils = ({ globalProperties = {} }) => {
  const pushEvent: PushEvent = event => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(event)
  }

  const track: TrackEvent = (name, properties) => {
    pushEvent({
      event: EVENT_ACTION_TRACK,
      eventName: name,
      eventProperties: {
        ...globalProperties,
        ...properties,
      },
    })
  }

  const page: PageEvent = properties => {
    pushEvent({
      event: EVENT_ACTION_PAGE,
      eventProperties: {
        ...globalProperties,
        ...properties,
      },
    })
  }

  return {
    page,
    track,
  }
}

const AnalyticsContext = createContext<AnalyticsContextProps>(
  {} as AnalyticsContextProps
)

const AnalyticsProvider: FC<AnalyticsProviderProps> = ({
  globalEventProperties,
  children,
}) => {
  const analytics = getAnalyticUtils({
    globalProperties: globalEventProperties,
  })

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  )
}

export default AnalyticsProvider
export const useAnalytics = () => useContext(AnalyticsContext)
