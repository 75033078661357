/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    AuditEvent,
    AuditEventFromJSON,
    AuditEventToJSON,
    AuditHistory,
    AuditHistoryFromJSON,
    AuditHistoryToJSON,
    OnboardingPublishAction,
    OnboardingPublishActionFromJSON,
    OnboardingPublishActionToJSON,
    OnboardingRecord,
    OnboardingRecordFromJSON,
    OnboardingRecordToJSON,
    OnboardingRecordError,
    OnboardingRecordErrorFromJSON,
    OnboardingRecordErrorToJSON,
    Organization,
    OrganizationFromJSON,
    OrganizationToJSON,
    OrganizationCreationRequest,
    OrganizationCreationRequestFromJSON,
    OrganizationCreationRequestToJSON,
    OrganizationOnboarding,
    OrganizationOnboardingFromJSON,
    OrganizationOnboardingToJSON,
    OrganizationOnboardingDetails,
    OrganizationOnboardingDetailsFromJSON,
    OrganizationOnboardingDetailsToJSON,
    OrganizationPartnerConnection,
    OrganizationPartnerConnectionFromJSON,
    OrganizationPartnerConnectionToJSON,
    OrganizationPortalAdminsResponse,
    OrganizationPortalAdminsResponseFromJSON,
    OrganizationPortalAdminsResponseToJSON,
    OrganizationsResponse,
    OrganizationsResponseFromJSON,
    OrganizationsResponseToJSON,
    PartnersResponse,
    PartnersResponseFromJSON,
    PartnersResponseToJSON,
    PublicConfigsResponse,
    PublicConfigsResponseFromJSON,
    PublicConfigsResponseToJSON,
} from '../models';

export interface CreateOrganizationRequest {
    organizationCreationRequest: OrganizationCreationRequest;
}

export interface CreateOrganizationOnboardingRequest {
    organizationOnboarding: OrganizationOnboarding;
}

export interface GetOrganizationRequest {
    organizationId: string;
}

export interface GetOrganizationAuditEventRequest {
    organizationId: string;
    auditEventId: string;
}

export interface GetOrganizationAuditHistoryRequest {
    organizationId: string;
    limit?: number;
    nextPageToken?: string;
    previousPageToken?: string;
    sortColumn?: string;
    sortDirection?: GetOrganizationAuditHistorySortDirectionEnum;
}

export interface GetOrganizationOnboardingRequest {
    onboardingRecordId: string;
}

export interface GetOrganizationPartnerRequest {
    organizationId: string;
}

export interface GetOrganizationUserProfilesRequest {
    organizationId: string;
    externalUserId: string;
}

export interface GetOrganizationsRequest {
    organizationId?: Array<string>;
    limit?: number;
    nextPageToken?: string;
    previousPageToken?: string;
    sortColumn?: string;
    sortDirection?: GetOrganizationsSortDirectionEnum;
}

export interface GetPublicConfigsRequest {
    subdomain: string;
}

export interface PublishOrganizationOnboardingRequest {
    onboardingRecordId: string;
}

export interface SetOrganizationPartnerRequest {
    organizationId: string;
    organizationPartnerConnection: OrganizationPartnerConnection;
}

export interface UpdateOrganizationOnboardingRequest {
    onboardingRecordId: string;
    organizationOnboarding: OrganizationOnboarding;
}

/**
 * 
 */
export class OrganizationApi extends runtime.BaseAPI {

    /**
     * Create a new organization
     */
    async createOrganizationRaw(requestParameters: CreateOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Organization>> {
        if (requestParameters.organizationCreationRequest === null || requestParameters.organizationCreationRequest === undefined) {
            throw new runtime.RequiredError('organizationCreationRequest','Required parameter requestParameters.organizationCreationRequest was null or undefined when calling createOrganization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrganizationCreationRequestToJSON(requestParameters.organizationCreationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationFromJSON(jsonValue));
    }

    /**
     * Create a new organization
     */
    async createOrganization(requestParameters: CreateOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Organization> {
        const response = await this.createOrganizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create an onboarding record
     */
    async createOrganizationOnboardingRaw(requestParameters: CreateOrganizationOnboardingRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OnboardingRecord>> {
        if (requestParameters.organizationOnboarding === null || requestParameters.organizationOnboarding === undefined) {
            throw new runtime.RequiredError('organizationOnboarding','Required parameter requestParameters.organizationOnboarding was null or undefined when calling createOrganizationOnboarding.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization-onboarding`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrganizationOnboardingToJSON(requestParameters.organizationOnboarding),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OnboardingRecordFromJSON(jsonValue));
    }

    /**
     * Create an onboarding record
     */
    async createOrganizationOnboarding(requestParameters: CreateOrganizationOnboardingRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OnboardingRecord> {
        const response = await this.createOrganizationOnboardingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves the details of an existing organization
     */
    async getOrganizationRaw(requestParameters: GetOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Organization>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getOrganization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationFromJSON(jsonValue));
    }

    /**
     * Retrieves the details of an existing organization
     */
    async getOrganization(requestParameters: GetOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Organization> {
        const response = await this.getOrganizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the details of a specific audit event for an organization
     */
    async getOrganizationAuditEventRaw(requestParameters: GetOrganizationAuditEventRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AuditEvent>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getOrganizationAuditEvent.');
        }

        if (requestParameters.auditEventId === null || requestParameters.auditEventId === undefined) {
            throw new runtime.RequiredError('auditEventId','Required parameter requestParameters.auditEventId was null or undefined when calling getOrganizationAuditEvent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/audit-history/{auditEventId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"auditEventId"}}`, encodeURIComponent(String(requestParameters.auditEventId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuditEventFromJSON(jsonValue));
    }

    /**
     * Get the details of a specific audit event for an organization
     */
    async getOrganizationAuditEvent(requestParameters: GetOrganizationAuditEventRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AuditEvent> {
        const response = await this.getOrganizationAuditEventRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the audit history for an organization
     */
    async getOrganizationAuditHistoryRaw(requestParameters: GetOrganizationAuditHistoryRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AuditHistory>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getOrganizationAuditHistory.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.nextPageToken !== undefined) {
            queryParameters['nextPageToken'] = requestParameters.nextPageToken;
        }

        if (requestParameters.previousPageToken !== undefined) {
            queryParameters['previousPageToken'] = requestParameters.previousPageToken;
        }

        if (requestParameters.sortColumn !== undefined) {
            queryParameters['sortColumn'] = requestParameters.sortColumn;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/audit-history`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuditHistoryFromJSON(jsonValue));
    }

    /**
     * Get the audit history for an organization
     */
    async getOrganizationAuditHistory(requestParameters: GetOrganizationAuditHistoryRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AuditHistory> {
        const response = await this.getOrganizationAuditHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the details of an onboarding record
     */
    async getOrganizationOnboardingRaw(requestParameters: GetOrganizationOnboardingRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrganizationOnboardingDetails>> {
        if (requestParameters.onboardingRecordId === null || requestParameters.onboardingRecordId === undefined) {
            throw new runtime.RequiredError('onboardingRecordId','Required parameter requestParameters.onboardingRecordId was null or undefined when calling getOrganizationOnboarding.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization-onboarding/{onboardingRecordId}`.replace(`{${"onboardingRecordId"}}`, encodeURIComponent(String(requestParameters.onboardingRecordId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationOnboardingDetailsFromJSON(jsonValue));
    }

    /**
     * Get the details of an onboarding record
     */
    async getOrganizationOnboarding(requestParameters: GetOrganizationOnboardingRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrganizationOnboardingDetails> {
        const response = await this.getOrganizationOnboardingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the id of the partner who manages this organization, if any.
     */
    async getOrganizationPartnerRaw(requestParameters: GetOrganizationPartnerRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PartnersResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getOrganizationPartner.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organizations/{organizationId}/partner`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PartnersResponseFromJSON(jsonValue));
    }

    /**
     * Returns the id of the partner who manages this organization, if any.
     */
    async getOrganizationPartner(requestParameters: GetOrganizationPartnerRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PartnersResponse> {
        const response = await this.getOrganizationPartnerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Use the portal-admin endpoints instead. Returns the list of organization admin profiles associated with the current user.  This was marked as deprecated because we want to re-root them under `/admin/` in the future. See the Slack thread at https://ritualco.slack.com/archives/C01SV8YJSVB/p1639759409058500 . 
     */
    async getOrganizationUserProfilesRaw(requestParameters: GetOrganizationUserProfilesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrganizationPortalAdminsResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getOrganizationUserProfiles.');
        }

        if (requestParameters.externalUserId === null || requestParameters.externalUserId === undefined) {
            throw new runtime.RequiredError('externalUserId','Required parameter requestParameters.externalUserId was null or undefined when calling getOrganizationUserProfiles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/user-profiles/{externalUserId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"externalUserId"}}`, encodeURIComponent(String(requestParameters.externalUserId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationPortalAdminsResponseFromJSON(jsonValue));
    }

    /**
     * Use the portal-admin endpoints instead. Returns the list of organization admin profiles associated with the current user.  This was marked as deprecated because we want to re-root them under `/admin/` in the future. See the Slack thread at https://ritualco.slack.com/archives/C01SV8YJSVB/p1639759409058500 . 
     */
    async getOrganizationUserProfiles(requestParameters: GetOrganizationUserProfilesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrganizationPortalAdminsResponse> {
        const response = await this.getOrganizationUserProfilesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of organization. Note that the list will contain only organizations that the user has access to.
     */
    async getOrganizationsRaw(requestParameters: GetOrganizationsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrganizationsResponse>> {
        const queryParameters: any = {};

        if (requestParameters.organizationId) {
            queryParameters['organizationId'] = requestParameters.organizationId;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.nextPageToken !== undefined) {
            queryParameters['nextPageToken'] = requestParameters.nextPageToken;
        }

        if (requestParameters.previousPageToken !== undefined) {
            queryParameters['previousPageToken'] = requestParameters.previousPageToken;
        }

        if (requestParameters.sortColumn !== undefined) {
            queryParameters['sortColumn'] = requestParameters.sortColumn;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationsResponseFromJSON(jsonValue));
    }

    /**
     * Returns a list of organization. Note that the list will contain only organizations that the user has access to.
     */
    async getOrganizations(requestParameters: GetOrganizationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrganizationsResponse> {
        const response = await this.getOrganizationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of publicly available configuration details for organizations.
     */
    async getPublicConfigsRaw(requestParameters: GetPublicConfigsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PublicConfigsResponse>> {
        if (requestParameters.subdomain === null || requestParameters.subdomain === undefined) {
            throw new runtime.RequiredError('subdomain','Required parameter requestParameters.subdomain was null or undefined when calling getPublicConfigs.');
        }

        const queryParameters: any = {};

        if (requestParameters.subdomain !== undefined) {
            queryParameters['subdomain'] = requestParameters.subdomain;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organizations/configs/public`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublicConfigsResponseFromJSON(jsonValue));
    }

    /**
     * Returns a list of publicly available configuration details for organizations.
     */
    async getPublicConfigs(requestParameters: GetPublicConfigsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PublicConfigsResponse> {
        const response = await this.getPublicConfigsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Publish the organization and workspace represented by the onboarding record, with the current user as the owner
     */
    async publishOrganizationOnboardingRaw(requestParameters: PublishOrganizationOnboardingRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OnboardingPublishAction>> {
        if (requestParameters.onboardingRecordId === null || requestParameters.onboardingRecordId === undefined) {
            throw new runtime.RequiredError('onboardingRecordId','Required parameter requestParameters.onboardingRecordId was null or undefined when calling publishOrganizationOnboarding.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization-onboarding/{onboardingRecordId}/publish`.replace(`{${"onboardingRecordId"}}`, encodeURIComponent(String(requestParameters.onboardingRecordId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OnboardingPublishActionFromJSON(jsonValue));
    }

    /**
     * Publish the organization and workspace represented by the onboarding record, with the current user as the owner
     */
    async publishOrganizationOnboarding(requestParameters: PublishOrganizationOnboardingRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OnboardingPublishAction> {
        const response = await this.publishOrganizationOnboardingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Configure the partner/integrator who manages this organiztion. Currently, only property manager type organizations can have partners set.
     */
    async setOrganizationPartnerRaw(requestParameters: SetOrganizationPartnerRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PartnersResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling setOrganizationPartner.');
        }

        if (requestParameters.organizationPartnerConnection === null || requestParameters.organizationPartnerConnection === undefined) {
            throw new runtime.RequiredError('organizationPartnerConnection','Required parameter requestParameters.organizationPartnerConnection was null or undefined when calling setOrganizationPartner.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organizations/{organizationId}/partner`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrganizationPartnerConnectionToJSON(requestParameters.organizationPartnerConnection),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PartnersResponseFromJSON(jsonValue));
    }

    /**
     * Configure the partner/integrator who manages this organiztion. Currently, only property manager type organizations can have partners set.
     */
    async setOrganizationPartner(requestParameters: SetOrganizationPartnerRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PartnersResponse> {
        const response = await this.setOrganizationPartnerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an onboarding record
     */
    async updateOrganizationOnboardingRaw(requestParameters: UpdateOrganizationOnboardingRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OnboardingRecord>> {
        if (requestParameters.onboardingRecordId === null || requestParameters.onboardingRecordId === undefined) {
            throw new runtime.RequiredError('onboardingRecordId','Required parameter requestParameters.onboardingRecordId was null or undefined when calling updateOrganizationOnboarding.');
        }

        if (requestParameters.organizationOnboarding === null || requestParameters.organizationOnboarding === undefined) {
            throw new runtime.RequiredError('organizationOnboarding','Required parameter requestParameters.organizationOnboarding was null or undefined when calling updateOrganizationOnboarding.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization-onboarding/{onboardingRecordId}`.replace(`{${"onboardingRecordId"}}`, encodeURIComponent(String(requestParameters.onboardingRecordId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: OrganizationOnboardingToJSON(requestParameters.organizationOnboarding),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OnboardingRecordFromJSON(jsonValue));
    }

    /**
     * Update an onboarding record
     */
    async updateOrganizationOnboarding(requestParameters: UpdateOrganizationOnboardingRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OnboardingRecord> {
        const response = await this.updateOrganizationOnboardingRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetOrganizationAuditHistorySortDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type GetOrganizationAuditHistorySortDirectionEnum = typeof GetOrganizationAuditHistorySortDirectionEnum[keyof typeof GetOrganizationAuditHistorySortDirectionEnum];
/**
 * @export
 */
export const GetOrganizationsSortDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type GetOrganizationsSortDirectionEnum = typeof GetOrganizationsSortDirectionEnum[keyof typeof GetOrganizationsSortDirectionEnum];
