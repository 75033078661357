/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessRestriction,
    AccessRestrictionFromJSON,
    AccessRestrictionFromJSONTyped,
    AccessRestrictionToJSON,
} from './AccessRestriction';
import {
    AppAssociation,
    AppAssociationFromJSON,
    AppAssociationFromJSONTyped,
    AppAssociationToJSON,
} from './AppAssociation';
import {
    OrganizationType,
    OrganizationTypeFromJSON,
    OrganizationTypeFromJSONTyped,
    OrganizationTypeToJSON,
} from './OrganizationType';

/**
 * 
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * The organization's unique identifier.
     * @type {string}
     * @memberof Organization
     */
    organizationId: string;
    /**
     * 
     * @type {OrganizationType}
     * @memberof Organization
     */
    organizationType: OrganizationType;
    /**
     * The name of the organization.
     * @type {string}
     * @memberof Organization
     */
    name: string;
    /**
     * An icon URL for the organization. A square 128px x 128px.
     * @type {string}
     * @memberof Organization
     */
    iconUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    timezone?: string;
    /**
     * The preferred locale used for communication, in BCP 47 format.
     * @type {string}
     * @memberof Organization
     */
    locale?: string;
    /**
     * The portal admin owner unique identifier. The owner is an administrator that cannot be removed or altered by other portal admins.
     * @type {string}
     * @memberof Organization
     */
    ownerId: string;
    /**
     * 
     * @type {AccessRestriction}
     * @memberof Organization
     */
    accessRestriction?: AccessRestriction;
    /**
     * 
     * @type {AppAssociation}
     * @memberof Organization
     */
    appAssociation?: AppAssociation;
}

export function OrganizationFromJSON(json: any): Organization {
    return OrganizationFromJSONTyped(json, false);
}

export function OrganizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Organization {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organizationId': json['organizationId'],
        'organizationType': OrganizationTypeFromJSON(json['organizationType']),
        'name': json['name'],
        'iconUrl': !exists(json, 'iconUrl') ? undefined : json['iconUrl'],
        'timezone': !exists(json, 'timezone') ? undefined : json['timezone'],
        'locale': !exists(json, 'locale') ? undefined : json['locale'],
        'ownerId': json['ownerId'],
        'accessRestriction': !exists(json, 'accessRestriction') ? undefined : AccessRestrictionFromJSON(json['accessRestriction']),
        'appAssociation': !exists(json, 'appAssociation') ? undefined : AppAssociationFromJSON(json['appAssociation']),
    };
}

export function OrganizationToJSON(value?: Organization | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organizationId': value.organizationId,
        'organizationType': OrganizationTypeToJSON(value.organizationType),
        'name': value.name,
        'iconUrl': value.iconUrl,
        'timezone': value.timezone,
        'locale': value.locale,
        'ownerId': value.ownerId,
        'accessRestriction': AccessRestrictionToJSON(value.accessRestriction),
        'appAssociation': AppAssociationToJSON(value.appAssociation),
    };
}

