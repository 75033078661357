/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    AuditEvent,
    AuditEventFromJSON,
    AuditEventToJSON,
    AuditHistory,
    AuditHistoryFromJSON,
    AuditHistoryToJSON,
    CreateWorkspace,
    CreateWorkspaceFromJSON,
    CreateWorkspaceToJSON,
    UpdateWorkspace,
    UpdateWorkspaceFromJSON,
    UpdateWorkspaceToJSON,
    Workspace,
    WorkspaceFromJSON,
    WorkspaceToJSON,
    WorkspaceConnectedApplicationsResponse,
    WorkspaceConnectedApplicationsResponseFromJSON,
    WorkspaceConnectedApplicationsResponseToJSON,
    WorkspacePortalAdminsResponse,
    WorkspacePortalAdminsResponseFromJSON,
    WorkspacePortalAdminsResponseToJSON,
    WorkspacesResponse,
    WorkspacesResponseFromJSON,
    WorkspacesResponseToJSON,
} from '../models';

export interface CreateWorkspaceRequest {
    organizationId: string;
    createWorkspace: CreateWorkspace;
}

export interface GetWorkspaceRequest {
    organizationId: string;
    workspaceId: string;
}

export interface GetWorkspaceAuditEventRequest {
    organizationId: string;
    workspaceId: string;
    auditEventId: string;
}

export interface GetWorkspaceAuditHistoryRequest {
    organizationId: string;
    workspaceId: string;
    limit?: number;
    nextPageToken?: string;
    previousPageToken?: string;
    sortColumn?: string;
    sortDirection?: GetWorkspaceAuditHistorySortDirectionEnum;
}

export interface GetWorkspaceConnectedApplicationsRequest {
    organizationId: string;
    workspaceId: string;
    limit?: number;
    nextPageToken?: string;
    previousPageToken?: string;
}

export interface GetWorkspaceUserProfilesRequest {
    organizationId: string;
    externalUserId: string;
}

export interface GetWorkspacesRequest {
    organizationId: string;
    workspaceId?: Array<string>;
    query?: string;
    limit?: number;
    nextPageToken?: string;
    previousPageToken?: string;
    sortColumn?: string;
    sortDirection?: GetWorkspacesSortDirectionEnum;
}

export interface UpdateWorkspaceRequest {
    organizationId: string;
    workspaceId: string;
    updateWorkspace: UpdateWorkspace;
}

/**
 * 
 */
export class WorkspaceApi extends runtime.BaseAPI {

    /**
     * Create a workspace in an organization.
     */
    async createWorkspaceRaw(requestParameters: CreateWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Workspace>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling createWorkspace.');
        }

        if (requestParameters.createWorkspace === null || requestParameters.createWorkspace === undefined) {
            throw new runtime.RequiredError('createWorkspace','Required parameter requestParameters.createWorkspace was null or undefined when calling createWorkspace.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateWorkspaceToJSON(requestParameters.createWorkspace),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceFromJSON(jsonValue));
    }

    /**
     * Create a workspace in an organization.
     */
    async createWorkspace(requestParameters: CreateWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Workspace> {
        const response = await this.createWorkspaceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves the details of a workspace. A workspace in most cases but not limited to is a building.
     */
    async getWorkspaceRaw(requestParameters: GetWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Workspace>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getWorkspace.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling getWorkspace.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceFromJSON(jsonValue));
    }

    /**
     * Retrieves the details of a workspace. A workspace in most cases but not limited to is a building.
     */
    async getWorkspace(requestParameters: GetWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Workspace> {
        const response = await this.getWorkspaceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the details of a specific audit event.
     */
    async getWorkspaceAuditEventRaw(requestParameters: GetWorkspaceAuditEventRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AuditEvent>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getWorkspaceAuditEvent.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling getWorkspaceAuditEvent.');
        }

        if (requestParameters.auditEventId === null || requestParameters.auditEventId === undefined) {
            throw new runtime.RequiredError('auditEventId','Required parameter requestParameters.auditEventId was null or undefined when calling getWorkspaceAuditEvent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/audit-history/{auditEventId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"auditEventId"}}`, encodeURIComponent(String(requestParameters.auditEventId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuditEventFromJSON(jsonValue));
    }

    /**
     * Get the details of a specific audit event.
     */
    async getWorkspaceAuditEvent(requestParameters: GetWorkspaceAuditEventRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AuditEvent> {
        const response = await this.getWorkspaceAuditEventRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the audit history for a workspace.
     */
    async getWorkspaceAuditHistoryRaw(requestParameters: GetWorkspaceAuditHistoryRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<AuditHistory>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getWorkspaceAuditHistory.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling getWorkspaceAuditHistory.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.nextPageToken !== undefined) {
            queryParameters['nextPageToken'] = requestParameters.nextPageToken;
        }

        if (requestParameters.previousPageToken !== undefined) {
            queryParameters['previousPageToken'] = requestParameters.previousPageToken;
        }

        if (requestParameters.sortColumn !== undefined) {
            queryParameters['sortColumn'] = requestParameters.sortColumn;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/audit-history`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuditHistoryFromJSON(jsonValue));
    }

    /**
     * Get the audit history for a workspace.
     */
    async getWorkspaceAuditHistory(requestParameters: GetWorkspaceAuditHistoryRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<AuditHistory> {
        const response = await this.getWorkspaceAuditHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List the applications connected with the workspace.
     */
    async getWorkspaceConnectedApplicationsRaw(requestParameters: GetWorkspaceConnectedApplicationsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WorkspaceConnectedApplicationsResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getWorkspaceConnectedApplications.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling getWorkspaceConnectedApplications.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.nextPageToken !== undefined) {
            queryParameters['nextPageToken'] = requestParameters.nextPageToken;
        }

        if (requestParameters.previousPageToken !== undefined) {
            queryParameters['previousPageToken'] = requestParameters.previousPageToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/connected-applications`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceConnectedApplicationsResponseFromJSON(jsonValue));
    }

    /**
     * List the applications connected with the workspace.
     */
    async getWorkspaceConnectedApplications(requestParameters: GetWorkspaceConnectedApplicationsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WorkspaceConnectedApplicationsResponse> {
        const response = await this.getWorkspaceConnectedApplicationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Use the portal-admin endpoints instad. Returns the list of workspace admin profiles associated with the current user for a specific organization  This was marked as deprecated because we want to re-root them under `/admin/` in the future. See the Slack thread at https://ritualco.slack.com/archives/C01SV8YJSVB/p1639759409058500 . 
     */
    async getWorkspaceUserProfilesRaw(requestParameters: GetWorkspaceUserProfilesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WorkspacePortalAdminsResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getWorkspaceUserProfiles.');
        }

        if (requestParameters.externalUserId === null || requestParameters.externalUserId === undefined) {
            throw new runtime.RequiredError('externalUserId','Required parameter requestParameters.externalUserId was null or undefined when calling getWorkspaceUserProfiles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/user-profiles/{externalUserId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"externalUserId"}}`, encodeURIComponent(String(requestParameters.externalUserId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspacePortalAdminsResponseFromJSON(jsonValue));
    }

    /**
     * Use the portal-admin endpoints instad. Returns the list of workspace admin profiles associated with the current user for a specific organization  This was marked as deprecated because we want to re-root them under `/admin/` in the future. See the Slack thread at https://ritualco.slack.com/archives/C01SV8YJSVB/p1639759409058500 . 
     */
    async getWorkspaceUserProfiles(requestParameters: GetWorkspaceUserProfilesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WorkspacePortalAdminsResponse> {
        const response = await this.getWorkspaceUserProfilesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of an organization\'s workspaces. A workspace may be, but is not limited to, a building or an office. Note that the list will contain only workspaces that the user has access to.
     */
    async getWorkspacesRaw(requestParameters: GetWorkspacesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WorkspacesResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getWorkspaces.');
        }

        const queryParameters: any = {};

        if (requestParameters.workspaceId) {
            queryParameters['workspaceId'] = requestParameters.workspaceId;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.nextPageToken !== undefined) {
            queryParameters['nextPageToken'] = requestParameters.nextPageToken;
        }

        if (requestParameters.previousPageToken !== undefined) {
            queryParameters['previousPageToken'] = requestParameters.previousPageToken;
        }

        if (requestParameters.sortColumn !== undefined) {
            queryParameters['sortColumn'] = requestParameters.sortColumn;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspacesResponseFromJSON(jsonValue));
    }

    /**
     * Returns a list of an organization\'s workspaces. A workspace may be, but is not limited to, a building or an office. Note that the list will contain only workspaces that the user has access to.
     */
    async getWorkspaces(requestParameters: GetWorkspacesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WorkspacesResponse> {
        const response = await this.getWorkspacesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the specific workspace by setting the values of the parameters provided in the request. Any parameters not provided will be left unchanged.
     */
    async updateWorkspaceRaw(requestParameters: UpdateWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Workspace>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling updateWorkspace.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling updateWorkspace.');
        }

        if (requestParameters.updateWorkspace === null || requestParameters.updateWorkspace === undefined) {
            throw new runtime.RequiredError('updateWorkspace','Required parameter requestParameters.updateWorkspace was null or undefined when calling updateWorkspace.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateWorkspaceToJSON(requestParameters.updateWorkspace),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceFromJSON(jsonValue));
    }

    /**
     * Updates the specific workspace by setting the values of the parameters provided in the request. Any parameters not provided will be left unchanged.
     */
    async updateWorkspace(requestParameters: UpdateWorkspaceRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Workspace> {
        const response = await this.updateWorkspaceRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetWorkspaceAuditHistorySortDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type GetWorkspaceAuditHistorySortDirectionEnum = typeof GetWorkspaceAuditHistorySortDirectionEnum[keyof typeof GetWorkspaceAuditHistorySortDirectionEnum];
/**
 * @export
 */
export const GetWorkspacesSortDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type GetWorkspacesSortDirectionEnum = typeof GetWorkspacesSortDirectionEnum[keyof typeof GetWorkspacesSortDirectionEnum];
