/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The role / rights within the portal.
 * @export
 */
export const PortalAdminRole = {
    Owner: 'OWNER',
    Administrator: 'ADMINISTRATOR',
    Analyst: 'ANALYST',
    ViewOnly: 'VIEW_ONLY',
    DropRunner: 'DROP_RUNNER'
} as const;
export type PortalAdminRole = typeof PortalAdminRole[keyof typeof PortalAdminRole];


export function PortalAdminRoleFromJSON(json: any): PortalAdminRole {
    return PortalAdminRoleFromJSONTyped(json, false);
}

export function PortalAdminRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortalAdminRole {
    return json as PortalAdminRole;
}

export function PortalAdminRoleToJSON(value?: PortalAdminRole | null): any {
    return value as any;
}

