/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    IncentivePlan,
    IncentivePlanFromJSON,
    IncentivePlanToJSON,
    IncentivePlanConfiguration,
    IncentivePlanConfigurationFromJSON,
    IncentivePlanConfigurationToJSON,
    IncentivePlanStatus,
    IncentivePlanStatusFromJSON,
    IncentivePlanStatusToJSON,
    IncentivePlanWorkspaceMembersRequest,
    IncentivePlanWorkspaceMembersRequestFromJSON,
    IncentivePlanWorkspaceMembersRequestToJSON,
    IncentivePlanWorkspaceMembersResponse,
    IncentivePlanWorkspaceMembersResponseFromJSON,
    IncentivePlanWorkspaceMembersResponseToJSON,
    IncentivePlansResponse,
    IncentivePlansResponseFromJSON,
    IncentivePlansResponseToJSON,
    WorkspaceIncentivePlanDelta,
    WorkspaceIncentivePlanDeltaFromJSON,
    WorkspaceIncentivePlanDeltaToJSON,
} from '../models';

export interface CloneIncentivePlanRequest {
    workspaceId: string;
    incentivePlanId: string;
}

export interface CreateIncentivePlanRequest {
    organizationId: string;
    workspaceId: string;
    incentivePlanConfiguration: IncentivePlanConfiguration;
}

export interface DeleteIncentivePlanRequest {
    organizationId: string;
    workspaceId: string;
    incentivePlanId: string;
}

export interface GetIncentivePlanRequest {
    organizationId: string;
    workspaceId: string;
    incentivePlanId: string;
}

export interface GetIncentivePlanDeltaRequest {
    workspaceId: string;
    incentivePlanId: string;
}

export interface GetIncentivePlansRequest {
    organizationId: string;
    workspaceId: string;
    limit?: number;
    nextPageToken?: string;
    previousPageToken?: string;
    status?: Array<IncentivePlanStatus>;
}

export interface PatchIncentivePlanRequest {
    organizationId: string;
    workspaceId: string;
    incentivePlanId: string;
    incentivePlanConfiguration: IncentivePlanConfiguration;
    dataVersion?: number;
}

export interface PauseIncentivePlanRequest {
    organizationId: string;
    workspaceId: string;
    incentivePlanId: string;
}

export interface PostIncentivePlanWorkspaceMembersRequest {
    workspaceId: string;
    incentivePlanId: string;
    incentivePlanWorkspaceMembersRequest?: IncentivePlanWorkspaceMembersRequest;
}

export interface PublishIncentivePlanRequest {
    organizationId: string;
    workspaceId: string;
    incentivePlanId: string;
    dataVersion?: number;
    applyCurrentCycleForAddedMembers?: boolean;
    applyCurrentCycleForRemovedMembers?: boolean;
}

export interface ResumeIncentivePlanRequest {
    organizationId: string;
    workspaceId: string;
    incentivePlanId: string;
}

/**
 * 
 */
export class IncentivePlanApi extends runtime.BaseAPI {

    /**
     * Creates a clone of the passed incentive in draft state and returns the cloned incentive with the scheduling information (start and end dates) not set. 
     */
    async cloneIncentivePlanRaw(requestParameters: CloneIncentivePlanRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<IncentivePlan>> {
        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling cloneIncentivePlan.');
        }

        if (requestParameters.incentivePlanId === null || requestParameters.incentivePlanId === undefined) {
            throw new runtime.RequiredError('incentivePlanId','Required parameter requestParameters.incentivePlanId was null or undefined when calling cloneIncentivePlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workspaces/{workspaceId}/incentive-plans/{incentivePlanId}/clone`.replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"incentivePlanId"}}`, encodeURIComponent(String(requestParameters.incentivePlanId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncentivePlanFromJSON(jsonValue));
    }

    /**
     * Creates a clone of the passed incentive in draft state and returns the cloned incentive with the scheduling information (start and end dates) not set. 
     */
    async cloneIncentivePlan(requestParameters: CloneIncentivePlanRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<IncentivePlan> {
        const response = await this.cloneIncentivePlanRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates an incentive plan
     */
    async createIncentivePlanRaw(requestParameters: CreateIncentivePlanRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<IncentivePlan>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling createIncentivePlan.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling createIncentivePlan.');
        }

        if (requestParameters.incentivePlanConfiguration === null || requestParameters.incentivePlanConfiguration === undefined) {
            throw new runtime.RequiredError('incentivePlanConfiguration','Required parameter requestParameters.incentivePlanConfiguration was null or undefined when calling createIncentivePlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/incentive-plans`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncentivePlanConfigurationToJSON(requestParameters.incentivePlanConfiguration),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncentivePlanFromJSON(jsonValue));
    }

    /**
     * Creates an incentive plan
     */
    async createIncentivePlan(requestParameters: CreateIncentivePlanRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<IncentivePlan> {
        const response = await this.createIncentivePlanRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes an incentive plan
     */
    async deleteIncentivePlanRaw(requestParameters: DeleteIncentivePlanRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling deleteIncentivePlan.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling deleteIncentivePlan.');
        }

        if (requestParameters.incentivePlanId === null || requestParameters.incentivePlanId === undefined) {
            throw new runtime.RequiredError('incentivePlanId','Required parameter requestParameters.incentivePlanId was null or undefined when calling deleteIncentivePlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/incentive-plans/{incentivePlanId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"incentivePlanId"}}`, encodeURIComponent(String(requestParameters.incentivePlanId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes an incentive plan
     */
    async deleteIncentivePlan(requestParameters: DeleteIncentivePlanRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.deleteIncentivePlanRaw(requestParameters, initOverrides);
    }

    /**
     * Returns an incentive plan
     */
    async getIncentivePlanRaw(requestParameters: GetIncentivePlanRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<IncentivePlan>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getIncentivePlan.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling getIncentivePlan.');
        }

        if (requestParameters.incentivePlanId === null || requestParameters.incentivePlanId === undefined) {
            throw new runtime.RequiredError('incentivePlanId','Required parameter requestParameters.incentivePlanId was null or undefined when calling getIncentivePlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/incentive-plans/{incentivePlanId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"incentivePlanId"}}`, encodeURIComponent(String(requestParameters.incentivePlanId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncentivePlanFromJSON(jsonValue));
    }

    /**
     * Returns an incentive plan
     */
    async getIncentivePlan(requestParameters: GetIncentivePlanRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<IncentivePlan> {
        const response = await this.getIncentivePlanRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the changes of live incentive plan compared to draft
     */
    async getIncentivePlanDeltaRaw(requestParameters: GetIncentivePlanDeltaRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WorkspaceIncentivePlanDelta>> {
        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling getIncentivePlanDelta.');
        }

        if (requestParameters.incentivePlanId === null || requestParameters.incentivePlanId === undefined) {
            throw new runtime.RequiredError('incentivePlanId','Required parameter requestParameters.incentivePlanId was null or undefined when calling getIncentivePlanDelta.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workspaces/{workspaceId}/incentive-plans/{incentivePlanId}/delta`.replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"incentivePlanId"}}`, encodeURIComponent(String(requestParameters.incentivePlanId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceIncentivePlanDeltaFromJSON(jsonValue));
    }

    /**
     * Returns the changes of live incentive plan compared to draft
     */
    async getIncentivePlanDelta(requestParameters: GetIncentivePlanDeltaRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WorkspaceIncentivePlanDelta> {
        const response = await this.getIncentivePlanDeltaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of incentive plans
     */
    async getIncentivePlansRaw(requestParameters: GetIncentivePlansRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<IncentivePlansResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getIncentivePlans.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling getIncentivePlans.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.nextPageToken !== undefined) {
            queryParameters['nextPageToken'] = requestParameters.nextPageToken;
        }

        if (requestParameters.previousPageToken !== undefined) {
            queryParameters['previousPageToken'] = requestParameters.previousPageToken;
        }

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/incentive-plans`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncentivePlansResponseFromJSON(jsonValue));
    }

    /**
     * Returns a list of incentive plans
     */
    async getIncentivePlans(requestParameters: GetIncentivePlansRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<IncentivePlansResponse> {
        const response = await this.getIncentivePlansRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates are saved in a draft state and do not go live until the incentive plan is re-published
     * Updates an incentive plan
     */
    async patchIncentivePlanRaw(requestParameters: PatchIncentivePlanRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<IncentivePlan>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling patchIncentivePlan.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling patchIncentivePlan.');
        }

        if (requestParameters.incentivePlanId === null || requestParameters.incentivePlanId === undefined) {
            throw new runtime.RequiredError('incentivePlanId','Required parameter requestParameters.incentivePlanId was null or undefined when calling patchIncentivePlan.');
        }

        if (requestParameters.incentivePlanConfiguration === null || requestParameters.incentivePlanConfiguration === undefined) {
            throw new runtime.RequiredError('incentivePlanConfiguration','Required parameter requestParameters.incentivePlanConfiguration was null or undefined when calling patchIncentivePlan.');
        }

        const queryParameters: any = {};

        if (requestParameters.dataVersion !== undefined) {
            queryParameters['dataVersion'] = requestParameters.dataVersion;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/incentive-plans/{incentivePlanId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"incentivePlanId"}}`, encodeURIComponent(String(requestParameters.incentivePlanId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: IncentivePlanConfigurationToJSON(requestParameters.incentivePlanConfiguration),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncentivePlanFromJSON(jsonValue));
    }

    /**
     * Updates are saved in a draft state and do not go live until the incentive plan is re-published
     * Updates an incentive plan
     */
    async patchIncentivePlan(requestParameters: PatchIncentivePlanRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<IncentivePlan> {
        const response = await this.patchIncentivePlanRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Pauses an incentive plan
     */
    async pauseIncentivePlanRaw(requestParameters: PauseIncentivePlanRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<IncentivePlan>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling pauseIncentivePlan.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling pauseIncentivePlan.');
        }

        if (requestParameters.incentivePlanId === null || requestParameters.incentivePlanId === undefined) {
            throw new runtime.RequiredError('incentivePlanId','Required parameter requestParameters.incentivePlanId was null or undefined when calling pauseIncentivePlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/incentive-plans/{incentivePlanId}/pause`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"incentivePlanId"}}`, encodeURIComponent(String(requestParameters.incentivePlanId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncentivePlanFromJSON(jsonValue));
    }

    /**
     * Pauses an incentive plan
     */
    async pauseIncentivePlan(requestParameters: PauseIncentivePlanRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<IncentivePlan> {
        const response = await this.pauseIncentivePlanRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds workspace members to an incentive plan user targeting
     */
    async postIncentivePlanWorkspaceMembersRaw(requestParameters: PostIncentivePlanWorkspaceMembersRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<IncentivePlanWorkspaceMembersResponse>> {
        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling postIncentivePlanWorkspaceMembers.');
        }

        if (requestParameters.incentivePlanId === null || requestParameters.incentivePlanId === undefined) {
            throw new runtime.RequiredError('incentivePlanId','Required parameter requestParameters.incentivePlanId was null or undefined when calling postIncentivePlanWorkspaceMembers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workspaces/{workspaceId}/incentive-plans/{incentivePlanId}/workspace-members`.replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"incentivePlanId"}}`, encodeURIComponent(String(requestParameters.incentivePlanId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncentivePlanWorkspaceMembersRequestToJSON(requestParameters.incentivePlanWorkspaceMembersRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncentivePlanWorkspaceMembersResponseFromJSON(jsonValue));
    }

    /**
     * Adds workspace members to an incentive plan user targeting
     */
    async postIncentivePlanWorkspaceMembers(requestParameters: PostIncentivePlanWorkspaceMembersRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<IncentivePlanWorkspaceMembersResponse> {
        const response = await this.postIncentivePlanWorkspaceMembersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Publishes the pending draft of an incentive plan
     */
    async publishIncentivePlanRaw(requestParameters: PublishIncentivePlanRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<IncentivePlan>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling publishIncentivePlan.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling publishIncentivePlan.');
        }

        if (requestParameters.incentivePlanId === null || requestParameters.incentivePlanId === undefined) {
            throw new runtime.RequiredError('incentivePlanId','Required parameter requestParameters.incentivePlanId was null or undefined when calling publishIncentivePlan.');
        }

        const queryParameters: any = {};

        if (requestParameters.dataVersion !== undefined) {
            queryParameters['dataVersion'] = requestParameters.dataVersion;
        }

        if (requestParameters.applyCurrentCycleForAddedMembers !== undefined) {
            queryParameters['applyCurrentCycleForAddedMembers'] = requestParameters.applyCurrentCycleForAddedMembers;
        }

        if (requestParameters.applyCurrentCycleForRemovedMembers !== undefined) {
            queryParameters['applyCurrentCycleForRemovedMembers'] = requestParameters.applyCurrentCycleForRemovedMembers;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/incentive-plans/{incentivePlanId}/publish-draft`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"incentivePlanId"}}`, encodeURIComponent(String(requestParameters.incentivePlanId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncentivePlanFromJSON(jsonValue));
    }

    /**
     * Publishes the pending draft of an incentive plan
     */
    async publishIncentivePlan(requestParameters: PublishIncentivePlanRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<IncentivePlan> {
        const response = await this.publishIncentivePlanRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Resumes an incentive plan
     */
    async resumeIncentivePlanRaw(requestParameters: ResumeIncentivePlanRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<IncentivePlan>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling resumeIncentivePlan.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling resumeIncentivePlan.');
        }

        if (requestParameters.incentivePlanId === null || requestParameters.incentivePlanId === undefined) {
            throw new runtime.RequiredError('incentivePlanId','Required parameter requestParameters.incentivePlanId was null or undefined when calling resumeIncentivePlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/incentive-plans/{incentivePlanId}/resume`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"incentivePlanId"}}`, encodeURIComponent(String(requestParameters.incentivePlanId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncentivePlanFromJSON(jsonValue));
    }

    /**
     * Resumes an incentive plan
     */
    async resumeIncentivePlan(requestParameters: ResumeIncentivePlanRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<IncentivePlan> {
        const response = await this.resumeIncentivePlanRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
