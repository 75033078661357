/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CurrencyCode,
    CurrencyCodeFromJSON,
    CurrencyCodeFromJSONTyped,
    CurrencyCodeToJSON,
} from './CurrencyCode';

/**
 * 
 * @export
 * @interface WorkspaceScheduledIncentive
 */
export interface WorkspaceScheduledIncentive {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceScheduledIncentive
     */
    templateId: string;
    /**
     * id that links to the incentive plan that will issue the incentive
     * @type {string}
     * @memberof WorkspaceScheduledIncentive
     */
    incentivePlanId: string;
    /**
     * 
     * @type {Date}
     * @memberof WorkspaceScheduledIncentive
     */
    activationDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof WorkspaceScheduledIncentive
     */
    expiry: Date;
    /**
     * 
     * @type {CurrencyCode}
     * @memberof WorkspaceScheduledIncentive
     */
    currencyCode: CurrencyCode;
    /**
     * Incentive credit total
     * @type {number}
     * @memberof WorkspaceScheduledIncentive
     */
    creditAmountTotal: number;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceScheduledIncentive
     */
    incentiveSourceName: string;
    /**
     * The time zone
     * @type {string}
     * @memberof WorkspaceScheduledIncentive
     */
    timezone: string;
}

export function WorkspaceScheduledIncentiveFromJSON(json: any): WorkspaceScheduledIncentive {
    return WorkspaceScheduledIncentiveFromJSONTyped(json, false);
}

export function WorkspaceScheduledIncentiveFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceScheduledIncentive {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'templateId': json['templateId'],
        'incentivePlanId': json['incentivePlanId'],
        'activationDate': (new Date(json['activationDate'])),
        'expiry': (new Date(json['expiry'])),
        'currencyCode': CurrencyCodeFromJSON(json['currencyCode']),
        'creditAmountTotal': json['creditAmountTotal'],
        'incentiveSourceName': json['incentiveSourceName'],
        'timezone': json['timezone'],
    };
}

export function WorkspaceScheduledIncentiveToJSON(value?: WorkspaceScheduledIncentive | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'templateId': value.templateId,
        'incentivePlanId': value.incentivePlanId,
        'activationDate': (value.activationDate.toISOString()),
        'expiry': (value.expiry.toISOString()),
        'currencyCode': CurrencyCodeToJSON(value.currencyCode),
        'creditAmountTotal': value.creditAmountTotal,
        'incentiveSourceName': value.incentiveSourceName,
        'timezone': value.timezone,
    };
}

