import { Suspense, useMemo } from 'react'
import { Grid, GridItem } from '@ritualco/jutsu'
import { Header } from 'components/Header'
import Loading from 'components/Loading'
import { OrganizationMenu } from 'components/OrganizationMenu'
import { SideNav } from 'components/Sidenav'
import { SideNavItemProps } from 'components/SideNavItem'
import {
  Route,
  RouteMap,
  RouteWithSubRoutes,
  Switch,
  useHistory,
} from 'lib/router'
import { useHydrateStore, useStore } from 'state/store'
import { RouteQueryAlert } from 'components/RouteQueryAlert'
import { useLocaleSetup } from 'utils/locale'
import { AppSideNavLink } from './AppSideNavLink'

export interface AppShellProps {
  routes: RouteMap<SideNavItemProps>
  externalUserId: string
}

export const AppShell = ({ routes, externalUserId }: AppShellProps) => {
  useHydrateStore(externalUserId)
  useLocaleSetup()
  const workspaceId = useStore(state => state.workspace.id) as string
  const organizationName = useStore(state => state.organization.name) as string
  const organizationIcon = useStore(state => state.organization.iconUrl)

  const workspace = useStore(state => state.workspace)
  const userInfo = useStore(state => state.userInfo)
  const attachedWorkspaces = useStore(state => state.attachedWorkspaces)
  const attachedOrganizations = useStore(state => state.attachedOrganizations)
  const sideNavRoutes = useMemo(() => routes.filter(route => route.navIcon), [
    routes,
  ])
  const setRequestedWorkspaceId = useStore(
    state => state.setRequestedWorkspaceId
  )
  const history = useHistory()

  return (
    <Grid
      templateColumns="var(--chakra-space-sidebar-medium) repeat(9, 1fr)"
      templateRows="79px 1fr"
      h="100vh"
    >
      <GridItem colSpan={1}>
        <OrganizationMenu
          name={organizationName}
          workspaces={attachedWorkspaces.map(w => ({
            workspaceId: w.id as string,
            name: w.name as string,
          }))}
          organizations={attachedOrganizations.map(o => ({
            organizationId: o.id as string,
            name: o.name as string,
          }))}
          iconUrl={organizationIcon}
          currentWorkspaceId={workspace.id || ''}
          onWorkspaceChange={(id: string) => {
            setRequestedWorkspaceId(id)
            history.push('/')
          }}
        />
      </GridItem>
      <GridItem colSpan={9}>
        <Header
          workspaceId={workspaceId}
          name={userInfo.firstName as string}
          email={userInfo.email as string}
        />
      </GridItem>

      <GridItem colSpan={1} overflow="auto">
        <SideNav>
          {sideNavRoutes.map(route => (
            <AppSideNavLink key={route.path} route={route} />
          ))}
        </SideNav>
      </GridItem>
      <GridItem colSpan={9} overflow="auto">
        <RouteQueryAlert m={12} mb={-9} w="unset" />
        <Suspense fallback={<Loading />}>
          <Switch>
            {routes.map(({ routes: subRoutes = [], ...route }) =>
              subRoutes.length > 0 ? (
                <RouteWithSubRoutes
                  key={route.path}
                  routes={subRoutes}
                  {...route}
                />
              ) : (
                <Route key={route.path} {...route} />
              )
            )}
          </Switch>
        </Suspense>
      </GridItem>
    </Grid>
  )
}
