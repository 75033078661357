/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkspaceMemberCampaignListItem
 */
export interface WorkspaceMemberCampaignListItem {
    /**
     * The campaign's internal ID (unique)
     * @type {string}
     * @memberof WorkspaceMemberCampaignListItem
     */
    memberCampaignId: string;
    /**
     * The campaign's custom external ID (unique)
     * @type {string}
     * @memberof WorkspaceMemberCampaignListItem
     */
    externalCampaignId?: string;
    /**
     * Public message for the campaign
     * @type {string}
     * @memberof WorkspaceMemberCampaignListItem
     */
    campaignMessage?: string;
}

export function WorkspaceMemberCampaignListItemFromJSON(json: any): WorkspaceMemberCampaignListItem {
    return WorkspaceMemberCampaignListItemFromJSONTyped(json, false);
}

export function WorkspaceMemberCampaignListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceMemberCampaignListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'memberCampaignId': json['memberCampaignId'],
        'externalCampaignId': !exists(json, 'externalCampaignId') ? undefined : json['externalCampaignId'],
        'campaignMessage': !exists(json, 'campaignMessage') ? undefined : json['campaignMessage'],
    };
}

export function WorkspaceMemberCampaignListItemToJSON(value?: WorkspaceMemberCampaignListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'memberCampaignId': value.memberCampaignId,
        'externalCampaignId': value.externalCampaignId,
        'campaignMessage': value.campaignMessage,
    };
}

