/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WorkspaceMembersBatchUpdateDepartmentCodesResponseItem,
    WorkspaceMembersBatchUpdateDepartmentCodesResponseItemFromJSON,
    WorkspaceMembersBatchUpdateDepartmentCodesResponseItemFromJSONTyped,
    WorkspaceMembersBatchUpdateDepartmentCodesResponseItemToJSON,
} from './WorkspaceMembersBatchUpdateDepartmentCodesResponseItem';

/**
 * 
 * @export
 * @interface WorkspaceMembersBatchUpdateDepartmentCodesResponse
 */
export interface WorkspaceMembersBatchUpdateDepartmentCodesResponse {
    /**
     * 
     * @type {Array<WorkspaceMembersBatchUpdateDepartmentCodesResponseItem>}
     * @memberof WorkspaceMembersBatchUpdateDepartmentCodesResponse
     */
    results?: Array<WorkspaceMembersBatchUpdateDepartmentCodesResponseItem>;
}

export function WorkspaceMembersBatchUpdateDepartmentCodesResponseFromJSON(json: any): WorkspaceMembersBatchUpdateDepartmentCodesResponse {
    return WorkspaceMembersBatchUpdateDepartmentCodesResponseFromJSONTyped(json, false);
}

export function WorkspaceMembersBatchUpdateDepartmentCodesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceMembersBatchUpdateDepartmentCodesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(WorkspaceMembersBatchUpdateDepartmentCodesResponseItemFromJSON)),
    };
}

export function WorkspaceMembersBatchUpdateDepartmentCodesResponseToJSON(value?: WorkspaceMembersBatchUpdateDepartmentCodesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(WorkspaceMembersBatchUpdateDepartmentCodesResponseItemToJSON)),
    };
}

