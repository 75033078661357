/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const WorkspaceType = {
    Building: 'BUILDING',
    Office: 'OFFICE',
    Restaurant: 'RESTAURANT',
    Hangout: 'HANGOUT'
} as const;
export type WorkspaceType = typeof WorkspaceType[keyof typeof WorkspaceType];


export function WorkspaceTypeFromJSON(json: any): WorkspaceType {
    return WorkspaceTypeFromJSONTyped(json, false);
}

export function WorkspaceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceType {
    return json as WorkspaceType;
}

export function WorkspaceTypeToJSON(value?: WorkspaceType | null): any {
    return value as any;
}

