import {
  useTable,
  Cell,
  Column,
  HeaderGroup,
  Row,
  UseTableInstanceProps,
} from 'react-table'
import {
  Table as JutsuTable,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableProps as JutsuTableProps,
  TableHeadProps,
  TableBodyProps,
  TableRowProps,
  TableColumnHeaderProps,
  TableCellProps,
  Text,
} from '@ritualco/jutsu'

import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { SortDirectionEnum } from 'hooks/useHandleSort'

export type SortOptions = {
  onSort: (id: string) => void
  sortDirection: SortDirectionEnum
  sortColumn: string
}

export type BasicTableHeaderProps<D extends object = {}> = {
  headers?: 'all' | 'first' | 'none'
  theadProps?: () => TableHeadProps
  theadTrProps?: (headerGroup: HeaderGroup<D>) => TableRowProps
  thProps?: (column: HeaderGroup<D>) => TableColumnHeaderProps
  sortOptions?: SortOptions
} & Pick<UseTableInstanceProps<D>, 'headerGroups'>

export const BasicTableHeader = <D extends object = {}>(
  props: BasicTableHeaderProps<D>
) => {
  const {
    headers,
    headerGroups,
    theadProps = () => {},
    theadTrProps = () => {},
    thProps = () => {},
    sortOptions = {
      onSort: () => {},
      sortDirection: SortDirectionEnum.Desc,
      sortColumn: '',
    },
  } = props

  const cursorStyling = props.sortOptions ? { cursor: 'pointer' } : {}

  const { onSort, sortDirection, sortColumn } = sortOptions

  if (headers === 'none') {
    return null
  }
  const headerGroupsToRender =
    headers === 'all' ? headerGroups : [headerGroups[0]]

  return (
    <Thead {...theadProps()}>
      {headerGroupsToRender.map(headerGroup => (
        <Tr
          {...headerGroup.getHeaderGroupProps()}
          {...theadTrProps(headerGroup)}
        >
          {headerGroup.headers.map(column => {
            let SortIcon = null
            if (column.id === sortColumn) {
              if (sortDirection === SortDirectionEnum.Desc) {
                SortIcon = <ChevronDownIcon ml={4} boxSize={24} />
              } else {
                SortIcon = <ChevronUpIcon ml={4} boxSize={24} />
              }
            }
            return (
              <Th
                {...cursorStyling}
                {...column.getHeaderProps()}
                {...thProps(column)}
                onClick={() => {
                  onSort(column.id)
                }}
              >
                {column.render('Header')}
                {SortIcon}
              </Th>
            )
          })}
        </Tr>
      ))}
    </Thead>
  )
}

export type BasicTableProps<D extends object = {}> = {
  columns: Column<D>[]
  data: Array<D>
  headers?: 'all' | 'first' | 'none'
  /** Text shown when there are no rows to display */
  placeholder?: string | React.ReactNode
  tableProps?: () => JutsuTableProps
  theadProps?: () => TableHeadProps
  theadTrProps?: (headerGroup: HeaderGroup<D>) => TableRowProps
  thProps?: (column: HeaderGroup<D>) => TableColumnHeaderProps
  tbodyProps?: () => TableBodyProps
  trProps?: (row: Row<D>) => TableRowProps
  tdProps?: (cell: Cell<D>) => TableCellProps
  sortOptions?: SortOptions
}

export const BasicTable = <D extends object = {}>(
  props: BasicTableProps<D>
) => {
  const {
    columns,
    data,
    headers = 'first',
    placeholder,
    tableProps = () => {},
    theadProps,
    theadTrProps,
    thProps,
    tbodyProps = () => {},
    trProps = () => {},
    tdProps = () => {},
    sortOptions,
  } = props

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    visibleColumns,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  return (
    <JutsuTable {...getTableProps()} {...tableProps()}>
      <BasicTableHeader
        headers={headers}
        headerGroups={headerGroups}
        theadProps={theadProps}
        theadTrProps={theadTrProps}
        thProps={thProps}
        sortOptions={sortOptions}
      />
      <Tbody {...getTableBodyProps()} {...tbodyProps()}>
        {rows.length === 0 ? (
          <Tr>
            <Td colSpan={visibleColumns.length} color="grayscale.secondary">
              <Text whiteSpace="pre-line">{placeholder}</Text>
            </Td>
          </Tr>
        ) : (
          rows.map(row => {
            prepareRow(row)
            return (
              <Tr {...row.getRowProps()} {...trProps(row)}>
                {row.cells.map(cell => (
                  <Td {...cell.getCellProps()} {...tdProps(cell)}>
                    {cell.render('Cell')}
                  </Td>
                ))}
              </Tr>
            )
          })
        )}
      </Tbody>
    </JutsuTable>
  )
}
