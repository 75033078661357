/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * status of the fulfillment
 * @export
 */
export const FulfillmentStatus = {
    Definition: 'DEFINITION',
    Joinable: 'JOINABLE',
    LockedForReview: 'LOCKED_FOR_REVIEW',
    InProgress: 'IN_PROGRESS',
    Rejected: 'REJECTED',
    Complete: 'COMPLETE',
    Cancelled: 'CANCELLED'
} as const;
export type FulfillmentStatus = typeof FulfillmentStatus[keyof typeof FulfillmentStatus];


export function FulfillmentStatusFromJSON(json: any): FulfillmentStatus {
    return FulfillmentStatusFromJSONTyped(json, false);
}

export function FulfillmentStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): FulfillmentStatus {
    return json as FulfillmentStatus;
}

export function FulfillmentStatusToJSON(value?: FulfillmentStatus | null): any {
    return value as any;
}

