/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FulfillmentDeliveryDetails,
    FulfillmentDeliveryDetailsFromJSON,
    FulfillmentDeliveryDetailsFromJSONTyped,
    FulfillmentDeliveryDetailsToJSON,
} from './FulfillmentDeliveryDetails';
import {
    FulfillmentState,
    FulfillmentStateFromJSON,
    FulfillmentStateFromJSONTyped,
    FulfillmentStateToJSON,
} from './FulfillmentState';
import {
    FulfillmentStatus,
    FulfillmentStatusFromJSON,
    FulfillmentStatusFromJSONTyped,
    FulfillmentStatusToJSON,
} from './FulfillmentStatus';
import {
    FulfillmentType,
    FulfillmentTypeFromJSON,
    FulfillmentTypeFromJSONTyped,
    FulfillmentTypeToJSON,
} from './FulfillmentType';
import {
    Merchant,
    MerchantFromJSON,
    MerchantFromJSONTyped,
    MerchantToJSON,
} from './Merchant';
import {
    UserProfile,
    UserProfileFromJSON,
    UserProfileFromJSONTyped,
    UserProfileToJSON,
} from './UserProfile';

/**
 * 
 * @export
 * @interface Fulfillment
 */
export interface Fulfillment {
    /**
     * Unique identifier of the fulfillment
     * @type {string}
     * @memberof Fulfillment
     */
    fulfillmentId: string;
    /**
     * 
     * @type {FulfillmentType}
     * @memberof Fulfillment
     */
    fulfillmentType?: FulfillmentType;
    /**
     * 
     * @type {FulfillmentState}
     * @memberof Fulfillment
     * @deprecated
     */
    fulfillmentState?: FulfillmentState;
    /**
     * 
     * @type {FulfillmentStatus}
     * @memberof Fulfillment
     */
    status?: FulfillmentStatus;
    /**
     * Time when the user should receive their food by (time food is delivered for delivery, or time food is prepared for pickup). Only present for scheduled orders.
     * @type {Date}
     * @memberof Fulfillment
     */
    scheduledTime?: Date;
    /**
     * Latest time guests may place their orders by to be included in the order.
     * @type {Date}
     * @memberof Fulfillment
     */
    orderByTime?: Date;
    /**
     * Time the order was complete at. For pickup this is the ready time; for delivery fulfillments it is the time the food was delivered at.
     * @type {Date}
     * @memberof Fulfillment
     */
    completeTime?: Date;
    /**
     * 
     * @type {FulfillmentDeliveryDetails}
     * @memberof Fulfillment
     */
    deliveryDetails?: FulfillmentDeliveryDetails;
    /**
     * 
     * @type {UserProfile}
     * @memberof Fulfillment
     */
    host?: UserProfile;
    /**
     * Url to configure the fulfillment
     * @type {string}
     * @memberof Fulfillment
     */
    editUrl: string;
    /**
     * Url to see the status of the fulfillment
     * @type {string}
     * @memberof Fulfillment
     */
    statusUrl?: string;
    /**
     * Url to join the fulfillment as a guest
     * @type {string}
     * @memberof Fulfillment
     */
    joinUrl?: string;
    /**
     * 
     * @type {Merchant}
     * @memberof Fulfillment
     */
    merchant?: Merchant;
}

export function FulfillmentFromJSON(json: any): Fulfillment {
    return FulfillmentFromJSONTyped(json, false);
}

export function FulfillmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Fulfillment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fulfillmentId': json['fulfillmentId'],
        'fulfillmentType': !exists(json, 'fulfillmentType') ? undefined : FulfillmentTypeFromJSON(json['fulfillmentType']),
        'fulfillmentState': !exists(json, 'fulfillmentState') ? undefined : FulfillmentStateFromJSON(json['fulfillmentState']),
        'status': !exists(json, 'status') ? undefined : FulfillmentStatusFromJSON(json['status']),
        'scheduledTime': !exists(json, 'scheduledTime') ? undefined : (new Date(json['scheduledTime'])),
        'orderByTime': !exists(json, 'orderByTime') ? undefined : (new Date(json['orderByTime'])),
        'completeTime': !exists(json, 'completeTime') ? undefined : (new Date(json['completeTime'])),
        'deliveryDetails': !exists(json, 'deliveryDetails') ? undefined : FulfillmentDeliveryDetailsFromJSON(json['deliveryDetails']),
        'host': !exists(json, 'host') ? undefined : UserProfileFromJSON(json['host']),
        'editUrl': json['editUrl'],
        'statusUrl': !exists(json, 'statusUrl') ? undefined : json['statusUrl'],
        'joinUrl': !exists(json, 'joinUrl') ? undefined : json['joinUrl'],
        'merchant': !exists(json, 'merchant') ? undefined : MerchantFromJSON(json['merchant']),
    };
}

export function FulfillmentToJSON(value?: Fulfillment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fulfillmentId': value.fulfillmentId,
        'fulfillmentType': FulfillmentTypeToJSON(value.fulfillmentType),
        'fulfillmentState': FulfillmentStateToJSON(value.fulfillmentState),
        'status': FulfillmentStatusToJSON(value.status),
        'scheduledTime': value.scheduledTime === undefined ? undefined : (value.scheduledTime.toISOString()),
        'orderByTime': value.orderByTime === undefined ? undefined : (value.orderByTime.toISOString()),
        'completeTime': value.completeTime === undefined ? undefined : (value.completeTime.toISOString()),
        'deliveryDetails': FulfillmentDeliveryDetailsToJSON(value.deliveryDetails),
        'host': UserProfileToJSON(value.host),
        'editUrl': value.editUrl,
        'statusUrl': value.statusUrl,
        'joinUrl': value.joinUrl,
        'merchant': MerchantToJSON(value.merchant),
    };
}

