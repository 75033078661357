/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * MEMBER_INVITE: The job is for inviting members to the workspace.
 * @export
 */
export const JobType = {
    MemberInvite: 'MEMBER_INVITE'
} as const;
export type JobType = typeof JobType[keyof typeof JobType];


export function JobTypeFromJSON(json: any): JobType {
    return JobTypeFromJSONTyped(json, false);
}

export function JobTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobType {
    return json as JobType;
}

export function JobTypeToJSON(value?: JobType | null): any {
    return value as any;
}

