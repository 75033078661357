/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AllIncentivesReportRun,
    AllIncentivesReportRunFromJSON,
    AllIncentivesReportRunToJSON,
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    IncentiveReportRun,
    IncentiveReportRunFromJSON,
    IncentiveReportRunToJSON,
    IncentiveReportRunUrl,
    IncentiveReportRunUrlFromJSON,
    IncentiveReportRunUrlToJSON,
    IncentiveReportsResponse,
    IncentiveReportsResponseFromJSON,
    IncentiveReportsResponseToJSON,
} from '../models';

export interface CreateIncentiveReportRunRequest {
    organizationId: string;
    workspaceId: string;
    incentiveReportRun: IncentiveReportRun;
}

export interface CreateIncentiveReportsRunRequest {
    organizationId: string;
    workspaceId: string;
    allIncentivesReportRun?: AllIncentivesReportRun;
}

export interface DownloadReportRequest {
    organizationId: string;
    workspaceId: string;
    incentiveReportId: string;
}

export interface GetIncentiveReportsRequest {
    organizationId: string;
    workspaceId: string;
    limit?: number;
    nextPageToken?: string;
    previousPageToken?: string;
}

/**
 * 
 */
export class IncentiveReportsApi extends runtime.BaseAPI {

    /**
     * Request the download url of a single incentive report.
     */
    async createIncentiveReportRunRaw(requestParameters: CreateIncentiveReportRunRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<IncentiveReportRunUrl>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling createIncentiveReportRun.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling createIncentiveReportRun.');
        }

        if (requestParameters.incentiveReportRun === null || requestParameters.incentiveReportRun === undefined) {
            throw new runtime.RequiredError('incentiveReportRun','Required parameter requestParameters.incentiveReportRun was null or undefined when calling createIncentiveReportRun.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/incentive-reports/incentive`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncentiveReportRunToJSON(requestParameters.incentiveReportRun),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncentiveReportRunUrlFromJSON(jsonValue));
    }

    /**
     * Request the download url of a single incentive report.
     */
    async createIncentiveReportRun(requestParameters: CreateIncentiveReportRunRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<IncentiveReportRunUrl> {
        const response = await this.createIncentiveReportRunRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Request the download url of all incentive reports.
     */
    async createIncentiveReportsRunRaw(requestParameters: CreateIncentiveReportsRunRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<IncentiveReportRunUrl>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling createIncentiveReportsRun.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling createIncentiveReportsRun.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/incentive-reports/all`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AllIncentivesReportRunToJSON(requestParameters.allIncentivesReportRun),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncentiveReportRunUrlFromJSON(jsonValue));
    }

    /**
     * Request the download url of all incentive reports.
     */
    async createIncentiveReportsRun(requestParameters: CreateIncentiveReportsRunRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<IncentiveReportRunUrl> {
        const response = await this.createIncentiveReportsRunRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download incentive report as a csv file
     */
    async downloadReportRaw(requestParameters: DownloadReportRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling downloadReport.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling downloadReport.');
        }

        if (requestParameters.incentiveReportId === null || requestParameters.incentiveReportId === undefined) {
            throw new runtime.RequiredError('incentiveReportId','Required parameter requestParameters.incentiveReportId was null or undefined when calling downloadReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/external-downloads/organization/{organizationId}/workspaces/{workspaceId}/incentive-reports/{incentiveReportId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"incentiveReportId"}}`, encodeURIComponent(String(requestParameters.incentiveReportId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Download incentive report as a csv file
     */
    async downloadReport(requestParameters: DownloadReportRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<string> {
        const response = await this.downloadReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of incentive reports
     */
    async getIncentiveReportsRaw(requestParameters: GetIncentiveReportsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<IncentiveReportsResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getIncentiveReports.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling getIncentiveReports.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.nextPageToken !== undefined) {
            queryParameters['nextPageToken'] = requestParameters.nextPageToken;
        }

        if (requestParameters.previousPageToken !== undefined) {
            queryParameters['previousPageToken'] = requestParameters.previousPageToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/incentive-reports`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncentiveReportsResponseFromJSON(jsonValue));
    }

    /**
     * Get list of incentive reports
     */
    async getIncentiveReports(requestParameters: GetIncentiveReportsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<IncentiveReportsResponse> {
        const response = await this.getIncentiveReportsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
