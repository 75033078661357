import { format } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import { IncentiveReportRun } from 'lib/generated/api/models'
import {
  AllIncentivesReportRunReportTypeEnum,
  IncentiveReportRunReportTypeEnum,
} from 'lib/generated/api'

interface ReportTimeDetails {
  startDate: Date | null
  endDate: Date | null
  timezone: string
}

export const mapIncentiveReportTypeEnumToAllIncentiveReportTypeEnum = (
  reportType: IncentiveReportRunReportTypeEnum
) => {
  switch (reportType) {
    case IncentiveReportRunReportTypeEnum.Incentive:
      return AllIncentivesReportRunReportTypeEnum.Incentive
    case IncentiveReportRunReportTypeEnum.IncentiveByUser:
      return AllIncentivesReportRunReportTypeEnum.IncentiveByUser
    default:
      return undefined
  }
}

export const getReportTimeDetails = ({
  startDate,
  endDate,
  timezone,
}: ReportTimeDetails) => {
  const result: Omit<IncentiveReportRun, 'incentivePlanId'> = { timezone }
  if (startDate) {
    const startDateString = format(startDate, 'yyyy-MM-dd')
    const startGMTTime = zonedTimeToUtc(`${startDateString}T00:00:00`, timezone)
    result.startDateTime = startGMTTime
  }

  if (endDate) {
    const endDateString = format(endDate, 'yyyy-MM-dd')
    const endGMTTime = zonedTimeToUtc(`${endDateString}T23:59:59`, timezone)
    result.endDateTime = endGMTTime
  }
  return result
}
