/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const IncentivePlanStatus = {
    Draft: 'DRAFT',
    Launched: 'LAUNCHED',
    Paused: 'PAUSED',
    Archived: 'ARCHIVED'
} as const;
export type IncentivePlanStatus = typeof IncentivePlanStatus[keyof typeof IncentivePlanStatus];


export function IncentivePlanStatusFromJSON(json: any): IncentivePlanStatus {
    return IncentivePlanStatusFromJSONTyped(json, false);
}

export function IncentivePlanStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncentivePlanStatus {
    return json as IncentivePlanStatus;
}

export function IncentivePlanStatusToJSON(value?: IncentivePlanStatus | null): any {
    return value as any;
}

