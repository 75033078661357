/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * PENDING: The job has not started.
 * IN_PROGRESS: The job is in progress.
 * PARTIAL_SUCCESS: The job has completed with some operations failing.
 * COMPLETED: The job has completed with no failures.
 * FAILED: The job has failed.
 * CANCELLED: The job has been cancelled.
 * @export
 */
export const JobStatus = {
    Pending: 'PENDING',
    InProgress: 'IN_PROGRESS',
    PartialSuccess: 'PARTIAL_SUCCESS',
    Completed: 'COMPLETED',
    Failed: 'FAILED',
    Cancelled: 'CANCELLED'
} as const;
export type JobStatus = typeof JobStatus[keyof typeof JobStatus];


export function JobStatusFromJSON(json: any): JobStatus {
    return JobStatusFromJSONTyped(json, false);
}

export function JobStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobStatus {
    return json as JobStatus;
}

export function JobStatusToJSON(value?: JobStatus | null): any {
    return value as any;
}

