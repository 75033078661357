/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PortalAdmin,
    PortalAdminFromJSON,
    PortalAdminFromJSONTyped,
    PortalAdminToJSON,
} from './PortalAdmin';
import {
    QueryInfo,
    QueryInfoFromJSON,
    QueryInfoFromJSONTyped,
    QueryInfoToJSON,
} from './QueryInfo';

/**
 * 
 * @export
 * @interface PortalAdminResponse
 */
export interface PortalAdminResponse {
    /**
     * 
     * @type {Array<PortalAdmin>}
     * @memberof PortalAdminResponse
     */
    results: Array<PortalAdmin>;
    /**
     * 
     * @type {QueryInfo}
     * @memberof PortalAdminResponse
     */
    listResult: QueryInfo;
    /**
     * Pagination token used in retrieving another page of items
     * @type {string}
     * @memberof PortalAdminResponse
     */
    paginationToken?: string;
    /**
     * Whether there are more items in the next page
     * @type {boolean}
     * @memberof PortalAdminResponse
     */
    hasNextPage?: boolean;
    /**
     * Whether there are more items in the previous page
     * @type {boolean}
     * @memberof PortalAdminResponse
     */
    hasPreviousPage?: boolean;
}

export function PortalAdminResponseFromJSON(json: any): PortalAdminResponse {
    return PortalAdminResponseFromJSONTyped(json, false);
}

export function PortalAdminResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortalAdminResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(PortalAdminFromJSON)),
        'listResult': QueryInfoFromJSON(json['listResult']),
        'paginationToken': !exists(json, 'paginationToken') ? undefined : json['paginationToken'],
        'hasNextPage': !exists(json, 'hasNextPage') ? undefined : json['hasNextPage'],
        'hasPreviousPage': !exists(json, 'hasPreviousPage') ? undefined : json['hasPreviousPage'],
    };
}

export function PortalAdminResponseToJSON(value?: PortalAdminResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(PortalAdminToJSON)),
        'listResult': QueryInfoToJSON(value.listResult),
        'paginationToken': value.paginationToken,
        'hasNextPage': value.hasNextPage,
        'hasPreviousPage': value.hasPreviousPage,
    };
}

