import { Entry } from 'contentful'
import { ImageField } from 'lib/content/types'

export const contentfulLocales = ['en', 'fr', 'zh'] as const

export type ContentfulLocale = typeof contentfulLocales[number]

export type ContentfulResource = Entry<{
  key: string
  value: string
}>

export type ContentfulImageFieldEntry = Entry<{
  title: string
  file: {
    url: string
    details: {
      size: number
      image: { width: number; height: number }
    }
    fileName: string
    contentType: string
  }
}>

export type ContentfulImage = Entry<{
  entryTitle: string
  title: string
  alt: string
  mobileImg: ContentfulImageFieldEntry
  desktopImg?: ContentfulImageFieldEntry
  tabletImg?: ContentfulImageFieldEntry
}>
export type ContentfulResourceSet = Entry<{
  name: string
  resources: Array<ContentfulResource | ContentfulImage>
}>

// mobile is always required
export const optionalImgSizes = ['desktop', 'tablet'] as const

export type ImageKey<T extends string> = `${T}Img`

export type OptionalImageKey = ImageKey<typeof optionalImgSizes[number]>
export type OptionalImageRecord = Partial<Record<OptionalImageKey, ImageField>>

export type ImageKeys = OptionalImageKey | ImageKey<'mobile'>
