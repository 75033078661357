/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Eligibility,
    EligibilityFromJSON,
    EligibilityFromJSONTyped,
    EligibilityToJSON,
} from './Eligibility';

/**
 * 
 * @export
 * @interface EmailSubmitResponse
 */
export interface EmailSubmitResponse {
    /**
     * user's new relationship with the submitted email address
     * @type {string}
     * @memberof EmailSubmitResponse
     */
    status?: EmailSubmitResponseStatusEnum;
    /**
     * 
     * @type {Eligibility}
     * @memberof EmailSubmitResponse
     */
    eligibility?: Eligibility;
}


/**
 * @export
 */
export const EmailSubmitResponseStatusEnum = {
    Submitted: 'EMAIL_SUBMITTED',
    Confirmed: 'EMAIL_CONFIRMED'
} as const;
export type EmailSubmitResponseStatusEnum = typeof EmailSubmitResponseStatusEnum[keyof typeof EmailSubmitResponseStatusEnum];


export function EmailSubmitResponseFromJSON(json: any): EmailSubmitResponse {
    return EmailSubmitResponseFromJSONTyped(json, false);
}

export function EmailSubmitResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailSubmitResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : json['status'],
        'eligibility': !exists(json, 'eligibility') ? undefined : EligibilityFromJSON(json['eligibility']),
    };
}

export function EmailSubmitResponseToJSON(value?: EmailSubmitResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'eligibility': EligibilityToJSON(value.eligibility),
    };
}

