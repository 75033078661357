/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrganizationPublicConfig
 */
export interface OrganizationPublicConfig {
    /**
     * The organization's unique identifier.
     * @type {string}
     * @memberof OrganizationPublicConfig
     */
    organizationId: string;
    /**
     * The id of the client to use when accessing ritual content on behalf of a user, from a subdomain registered to this organization (or one of its workspaces).
     * @type {string}
     * @memberof OrganizationPublicConfig
     */
    webClientId?: string;
    /**
     * The id of the client to use when accessing ritual content without a user, from a subdomain registered to this organization (or one of its workspaces).
     * @type {string}
     * @memberof OrganizationPublicConfig
     */
    publicServiceClientId?: string;
    /**
     * The authentication secret to use in combination with the webServiceClientId.
     * @type {string}
     * @memberof OrganizationPublicConfig
     */
    publicServiceClientSecret?: string;
}

export function OrganizationPublicConfigFromJSON(json: any): OrganizationPublicConfig {
    return OrganizationPublicConfigFromJSONTyped(json, false);
}

export function OrganizationPublicConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationPublicConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organizationId': json['organizationId'],
        'webClientId': !exists(json, 'webClientId') ? undefined : json['webClientId'],
        'publicServiceClientId': !exists(json, 'publicServiceClientId') ? undefined : json['publicServiceClientId'],
        'publicServiceClientSecret': !exists(json, 'publicServiceClientSecret') ? undefined : json['publicServiceClientSecret'],
    };
}

export function OrganizationPublicConfigToJSON(value?: OrganizationPublicConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organizationId': value.organizationId,
        'webClientId': value.webClientId,
        'publicServiceClientId': value.publicServiceClientId,
        'publicServiceClientSecret': value.publicServiceClientSecret,
    };
}

