import { Flex, Image, Text } from '@ritualco/jutsu'
import ErrorImage from 'assets/Images/Error-404.svg'
import { useContent } from 'lib/content/react'

export const Error = () => {
  const { formatMessage } = useContent()
  return (
    <Flex
      direction="column"
      justify="center"
      alignItems="center"
      w="100%"
      h="100vh"
    >
      <Image src={ErrorImage} h="132px" w="202px" />
      <Text mt={4} fontSize="2xl" fontWeight="bold">
        {formatMessage({ id: 'pmportal.error.oops' })}
      </Text>
      <Text fontWeight="medium">
        {formatMessage({ id: 'pmportal.error.errorTryAgain' })}
      </Text>
    </Flex>
  )
}
