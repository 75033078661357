/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    WorkspaceCampaign,
    WorkspaceCampaignFromJSON,
    WorkspaceCampaignToJSON,
    WorkspaceCampaignInvitationResponse,
    WorkspaceCampaignInvitationResponseFromJSON,
    WorkspaceCampaignInvitationResponseToJSON,
    WorkspaceCampaignRequest,
    WorkspaceCampaignRequestFromJSON,
    WorkspaceCampaignRequestToJSON,
    WorkspaceCampaignsResponse,
    WorkspaceCampaignsResponseFromJSON,
    WorkspaceCampaignsResponseToJSON,
} from '../models';

export interface CreateWorkspaceCampaignRequest {
    organizationId: string;
    workspaceId: string;
    workspaceCampaignRequest: WorkspaceCampaignRequest;
}

export interface DeleteWorkspaceCampaignRequest {
    organizationId: string;
    workspaceId: string;
    workspaceCampaignId: string;
}

export interface GetWorkspaceCampaignRequest {
    organizationId: string;
    workspaceId: string;
    workspaceCampaignId: string;
}

export interface GetWorkspaceCampaignInvitationRequest {
    externalCampaignId: string;
}

export interface GetWorkspaceCampaignsRequest {
    organizationId: string;
    workspaceId: string;
    limit?: number;
    nextPageToken?: string;
    previousPageToken?: string;
}

export interface UpdateWorkspaceCampaignRequest {
    organizationId: string;
    workspaceId: string;
    workspaceCampaignId: string;
    workspaceCampaignRequest: WorkspaceCampaignRequest;
}

/**
 * 
 */
export class WorkspaceCampaignsApi extends runtime.BaseAPI {

    /**
     * Creates a workspace campaign
     */
    async createWorkspaceCampaignRaw(requestParameters: CreateWorkspaceCampaignRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WorkspaceCampaign>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling createWorkspaceCampaign.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling createWorkspaceCampaign.');
        }

        if (requestParameters.workspaceCampaignRequest === null || requestParameters.workspaceCampaignRequest === undefined) {
            throw new runtime.RequiredError('workspaceCampaignRequest','Required parameter requestParameters.workspaceCampaignRequest was null or undefined when calling createWorkspaceCampaign.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/workspace-campaigns`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WorkspaceCampaignRequestToJSON(requestParameters.workspaceCampaignRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceCampaignFromJSON(jsonValue));
    }

    /**
     * Creates a workspace campaign
     */
    async createWorkspaceCampaign(requestParameters: CreateWorkspaceCampaignRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WorkspaceCampaign> {
        const response = await this.createWorkspaceCampaignRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a workspace campaign
     */
    async deleteWorkspaceCampaignRaw(requestParameters: DeleteWorkspaceCampaignRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling deleteWorkspaceCampaign.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling deleteWorkspaceCampaign.');
        }

        if (requestParameters.workspaceCampaignId === null || requestParameters.workspaceCampaignId === undefined) {
            throw new runtime.RequiredError('workspaceCampaignId','Required parameter requestParameters.workspaceCampaignId was null or undefined when calling deleteWorkspaceCampaign.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/workspace-campaigns/{workspaceCampaignId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"workspaceCampaignId"}}`, encodeURIComponent(String(requestParameters.workspaceCampaignId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a workspace campaign
     */
    async deleteWorkspaceCampaign(requestParameters: DeleteWorkspaceCampaignRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.deleteWorkspaceCampaignRaw(requestParameters, initOverrides);
    }

    /**
     * Gets the workspace-campaigns campaign
     */
    async getWorkspaceCampaignRaw(requestParameters: GetWorkspaceCampaignRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WorkspaceCampaign>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getWorkspaceCampaign.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling getWorkspaceCampaign.');
        }

        if (requestParameters.workspaceCampaignId === null || requestParameters.workspaceCampaignId === undefined) {
            throw new runtime.RequiredError('workspaceCampaignId','Required parameter requestParameters.workspaceCampaignId was null or undefined when calling getWorkspaceCampaign.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/workspace-campaigns/{workspaceCampaignId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"workspaceCampaignId"}}`, encodeURIComponent(String(requestParameters.workspaceCampaignId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceCampaignFromJSON(jsonValue));
    }

    /**
     * Gets the workspace-campaigns campaign
     */
    async getWorkspaceCampaign(requestParameters: GetWorkspaceCampaignRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WorkspaceCampaign> {
        const response = await this.getWorkspaceCampaignRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets the public details of the campaign
     */
    async getWorkspaceCampaignInvitationRaw(requestParameters: GetWorkspaceCampaignInvitationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WorkspaceCampaignInvitationResponse>> {
        if (requestParameters.externalCampaignId === null || requestParameters.externalCampaignId === undefined) {
            throw new runtime.RequiredError('externalCampaignId','Required parameter requestParameters.externalCampaignId was null or undefined when calling getWorkspaceCampaignInvitation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/public/campaigns/workspace/{externalCampaignId}`.replace(`{${"externalCampaignId"}}`, encodeURIComponent(String(requestParameters.externalCampaignId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceCampaignInvitationResponseFromJSON(jsonValue));
    }

    /**
     * Gets the public details of the campaign
     */
    async getWorkspaceCampaignInvitation(requestParameters: GetWorkspaceCampaignInvitationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WorkspaceCampaignInvitationResponse> {
        const response = await this.getWorkspaceCampaignInvitationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets list of workspace campaigns
     */
    async getWorkspaceCampaignsRaw(requestParameters: GetWorkspaceCampaignsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WorkspaceCampaignsResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getWorkspaceCampaigns.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling getWorkspaceCampaigns.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.nextPageToken !== undefined) {
            queryParameters['nextPageToken'] = requestParameters.nextPageToken;
        }

        if (requestParameters.previousPageToken !== undefined) {
            queryParameters['previousPageToken'] = requestParameters.previousPageToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/workspace-campaigns`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceCampaignsResponseFromJSON(jsonValue));
    }

    /**
     * Gets list of workspace campaigns
     */
    async getWorkspaceCampaigns(requestParameters: GetWorkspaceCampaignsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WorkspaceCampaignsResponse> {
        const response = await this.getWorkspaceCampaignsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a workspace campaign
     */
    async updateWorkspaceCampaignRaw(requestParameters: UpdateWorkspaceCampaignRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WorkspaceCampaign>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling updateWorkspaceCampaign.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling updateWorkspaceCampaign.');
        }

        if (requestParameters.workspaceCampaignId === null || requestParameters.workspaceCampaignId === undefined) {
            throw new runtime.RequiredError('workspaceCampaignId','Required parameter requestParameters.workspaceCampaignId was null or undefined when calling updateWorkspaceCampaign.');
        }

        if (requestParameters.workspaceCampaignRequest === null || requestParameters.workspaceCampaignRequest === undefined) {
            throw new runtime.RequiredError('workspaceCampaignRequest','Required parameter requestParameters.workspaceCampaignRequest was null or undefined when calling updateWorkspaceCampaign.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/workspace-campaigns/{workspaceCampaignId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"workspaceCampaignId"}}`, encodeURIComponent(String(requestParameters.workspaceCampaignId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WorkspaceCampaignRequestToJSON(requestParameters.workspaceCampaignRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceCampaignFromJSON(jsonValue));
    }

    /**
     * Update a workspace campaign
     */
    async updateWorkspaceCampaign(requestParameters: UpdateWorkspaceCampaignRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WorkspaceCampaign> {
        const response = await this.updateWorkspaceCampaignRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
