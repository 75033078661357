/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaymentMethod,
    PaymentMethodFromJSON,
    PaymentMethodFromJSONTyped,
    PaymentMethodToJSON,
} from './PaymentMethod';

/**
 * 
 * @export
 * @interface PaymentMethodsResponse
 */
export interface PaymentMethodsResponse {
    /**
     * 
     * @type {Array<PaymentMethod>}
     * @memberof PaymentMethodsResponse
     */
    results: Array<PaymentMethod>;
}

export function PaymentMethodsResponseFromJSON(json: any): PaymentMethodsResponse {
    return PaymentMethodsResponseFromJSONTyped(json, false);
}

export function PaymentMethodsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentMethodsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(PaymentMethodFromJSON)),
    };
}

export function PaymentMethodsResponseToJSON(value?: PaymentMethodsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(PaymentMethodToJSON)),
    };
}

