/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkspaceMemberCampaignRequest
 */
export interface WorkspaceMemberCampaignRequest {
    /**
     * The campaign's custom external ID (unique)
     * @type {string}
     * @memberof WorkspaceMemberCampaignRequest
     */
    externalCampaignId?: string;
    /**
     * Public message for the campaign
     * @type {string}
     * @memberof WorkspaceMemberCampaignRequest
     */
    campaignMessage?: string;
    /**
     * The workspace ID of the workspace that is granting the incentive.
     * @type {string}
     * @memberof WorkspaceMemberCampaignRequest
     */
    incentiveWorkspaceId?: string;
}

export function WorkspaceMemberCampaignRequestFromJSON(json: any): WorkspaceMemberCampaignRequest {
    return WorkspaceMemberCampaignRequestFromJSONTyped(json, false);
}

export function WorkspaceMemberCampaignRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceMemberCampaignRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'externalCampaignId': !exists(json, 'externalCampaignId') ? undefined : json['externalCampaignId'],
        'campaignMessage': !exists(json, 'campaignMessage') ? undefined : json['campaignMessage'],
        'incentiveWorkspaceId': !exists(json, 'incentiveWorkspaceId') ? undefined : json['incentiveWorkspaceId'],
    };
}

export function WorkspaceMemberCampaignRequestToJSON(value?: WorkspaceMemberCampaignRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'externalCampaignId': value.externalCampaignId,
        'campaignMessage': value.campaignMessage,
        'incentiveWorkspaceId': value.incentiveWorkspaceId,
    };
}

