import * as Sentry from '@sentry/react'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { useMessage, MessageContextType } from 'contexts/MessageContext'
import { useContent } from 'lib/content/react'
import { useAuth } from 'auth'
import { hasStatus } from 'utils/error'

export type UseHandleApiError = (option?: {
  showMessage?: boolean
  refreshToken?: boolean
}) => (error: unknown) => void
export const useHandleApiError: UseHandleApiError = ({
  showMessage = true,
  refreshToken = true,
} = {}) => {
  const { formatMessage } = useContent()
  const { addError } = useMessage()
  const { handleTokenRefresh } = useAuth()
  const showError: MessageContextType['addError'] = args => {
    if (showMessage) addError(args)
  }

  return (error: unknown) => {
    if (hasStatus(error)) {
      switch (error.status) {
        case 400:
        case 401:
        case 403:
          if (refreshToken) handleTokenRefresh?.()
          showError({
            description: formatMessage({ id: `pmportal.error.403` }),
          })
          break
        case 404:
          showError({
            description: formatMessage({ id: `pmportal.error.404` }),
          })
          break
        default:
          showError({
            description: formatMessage({ id: 'pmportal.error.unknown' }),
          })
      }

      Sentry.captureException(error)
    }
  }
}

export type Delay = (ms: number) => Promise<void>
export const delay: Delay = ms =>
  new Promise(resolve => {
    setTimeout(resolve, ms)
  })

export const getExternalUserId = (token: string) => {
  if (!token) return ''
  const decoded = jwtDecode<JwtPayload>(token)
  const subInToken = decoded.sub as string
  return subInToken.split(':')[2]
}
