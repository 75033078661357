import { ReactComponent as HomeIcon } from 'assets/Icons/Home.svg'
import { ReactComponent as IncentivesIcon } from 'assets/Icons/Tag.svg'
import { ReactComponent as ReportsIcon } from 'assets/Icons/Pie-Chart.svg'
import { ReactComponent as SettingsIcon } from 'assets/Icons/Settings.svg'
import { ReactComponent as TeamIcon } from 'assets/Icons/Team.svg'
import { MdReceipt } from 'react-icons/md'
import { SideNavItemProps } from 'components/SideNavItem'
import { RouteMap } from 'lib/router'
import { lazy } from 'react'
import ReportsContainer from 'containers/ReportsContainer'
import AuditLogContainer from '../containers/AuditLogContainer'

const CompaniesHomeContainer = lazy(
  () => import('containers/CompaniesHomeContainer')
)
const IncentiveContainer = lazy(() => import('containers/IncentiveContainer'))
const IncentivesContainer = lazy(() => import('containers/IncentivesContainer'))
const BillingContainer = lazy(() => import('containers/BillingContainer'))
const GroupOrdersContainer = lazy(
  () => import('containers/GroupOrdersContainer')
)
const HelpContainer = lazy(() => import('containers/HelpContainer'))
const ConnectionsContainer = lazy(
  () => import('containers/ConnectionsContainer')
)
const ProfileContainer = lazy(() => import('containers/ProfileContainer'))
const TeamContainer = lazy(() => import('containers/TeamContainer'))
const IncentiveHistoryContainer = lazy(
  () => import('containers/IncentiveHistoryContainer')
)
const EmployeeContainer = lazy(() => import('containers/EmployeeContainer'))
const EmployeesContainer = lazy(() => import('containers/EmployeesContainer'))

const JobContainer = lazy(() => import('containers/JobContainer'))
const JobsContainer = lazy(() => import('containers/JobsContainer'))

const MemberCampaignContainer = lazy(
  () => import('containers/MemberCampaignContainer')
)
const MemberCampaignsContainer = lazy(
  () => import('containers/MemberCampaignsContainer')
)
const WorkspaceCampaignsContainer = lazy(
  () => import('containers/WorkspaceCampaignsContainer')
)
const WorkspaceCampaignContainer = lazy(
  () => import('containers/WorkspaceCampaignContainer')
)
const NotFound = lazy(() => import('views/NotFound'))
const CompanySettings = lazy(() => import('views/Settings/CompanySettings'))
const WorkspaceSettingsContainer = lazy(
  () => import('containers/WorkspaceSettingsContainer')
)

export const companyRoutes: RouteMap<SideNavItemProps> = [
  {
    path: '/',
    exact: true,
    component: CompaniesHomeContainer,
    navTitle: 'pmportal.nav.home',
    navIcon: HomeIcon,
  },
  {
    path:
      '/workspaces/:workspaceId/employees/:workspaceMemberId/incentive-history',
    component: IncentiveHistoryContainer,
    breadcrumb: 'pmportal.nav.incentiveHistory',
  },
  {
    path: '/workspaces/:workspaceId/employees/:workspaceMemberId',
    component: EmployeeContainer,
    breadcrumb: 'pmportal.nav.employee',
  },
  {
    path: '/workspaces/:workspaceId/employees',
    component: EmployeesContainer,
    navTitle: 'pmportal.nav.employees',
    breadcrumb: 'pmportal.nav.employees',
    navIcon: TeamIcon,
  },
  {
    path: '/workspaces/:workspaceId/incentives/:incentivePlanId',
    component: IncentiveContainer,
    navTitle: 'pmportal.nav.incentives',
    breadcrumb: 'pmportal.nav.incentiveDetails',
  },
  {
    path: '/workspaces/:workspaceId/incentives',
    component: IncentivesContainer,
    navTitle: 'pmportal.nav.incentives',
    navIcon: IncentivesIcon,
    breadcrumb: 'pmportal.nav.incentives',
  },
  {
    path: '/workspaces/:workspaceId/group-orders',
    component: GroupOrdersContainer,
    navTitle: 'pmportal.nav.groupOrders',
    navIcon: MdReceipt,
    breadcrumb: 'pmportal.nav.groupOrders',
  },
  {
    path: '/workspaces/:workspaceId/reports',
    component: ReportsContainer,
    navTitle: 'pmportal.nav.reports',
    navIcon: ReportsIcon,
  },
  {
    path: '/workspaces/:workspaceId/settings/help',
    component: HelpContainer,
    breadcrumb: 'pmportal.nav.help',
  },
  {
    path: '/workspaces/:workspaceId/settings/billing',
    component: BillingContainer,
    breadcrumb: 'pmportal.nav.billing',
  },
  {
    path: '/workspaces/:workspaceId/settings/history',
    render: () => <AuditLogContainer />,
    breadcrumb: 'pmportal.nav.history',
  },
  {
    path: '/workspaces/:workspaceId/settings/general',
    component: WorkspaceSettingsContainer,
    breadcrumb: 'pmportal.nav.workspaceSettings',
  },
  {
    path: '/workspaces/:workspaceId/settings/team',
    component: TeamContainer,
    breadcrumb: 'pmportal.nav.team',
  },
  {
    path: '/workspaces/:workspaceId/settings/profile',
    component: ProfileContainer,
    breadcrumb: 'pmportal.nav.myProfile',
  },
  {
    path: '/workspaces/:workspaceId/settings/connections',
    component: ConnectionsContainer,
    breadcrumb: 'pmportal.nav.connections',
  },
  {
    path: '/workspaces/:workspaceId/settings/member-campaigns/:campaignId',
    component: MemberCampaignContainer,
    breadcrumb: 'pmportal.nav.memberCampaign',
  },
  {
    path: '/workspaces/:workspaceId/settings/member-campaigns',
    component: MemberCampaignsContainer,
    breadcrumb: 'pmportal.nav.memberCampaigns',
  },
  {
    path: '/workspaces/:workspaceId/settings/workspace-campaigns/:campaignId',
    component: WorkspaceCampaignContainer,
    breadcrumb: 'pmportal.nav.workspaceCampaign',
  },
  {
    path: '/workspaces/:workspaceId/settings/workspace-campaigns',
    component: WorkspaceCampaignsContainer,
    breadcrumb: 'pmportal.nav.workspaceCampaigns',
  },
  {
    path: '/workspaces/:workspaceId/settings/jobs/:jobId',
    component: JobContainer,
    navTitle: 'pmportal.nav.jobResults',
    breadcrumb: 'pmportal.nav.jobResults',
  },
  {
    path: '/workspaces/:workspaceId/settings/jobs',
    component: JobsContainer,
    navTitle: 'pmportal.nav.jobs',
    breadcrumb: 'pmportal.nav.jobs',
  },
  {
    path: '/workspaces/:workspaceId/settings',
    component: CompanySettings,
    navTitle: 'pmportal.nav.settings',
    navIcon: SettingsIcon,
    breadcrumb: 'pmportal.nav.settings',
  },
  {
    path: '*',
    component: NotFound,
  },
]
