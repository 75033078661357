/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorFromJSONTyped,
    ApiErrorToJSON,
} from './ApiError';

/**
 * 
 * @export
 * @interface WorkspaceMembersBatchUpdateDepartmentCodesResponseItem
 */
export interface WorkspaceMembersBatchUpdateDepartmentCodesResponseItem {
    /**
     * An HTTP status code indicating the status of the request for an invited, removed, or updated workspace member.
     * @type {number}
     * @memberof WorkspaceMembersBatchUpdateDepartmentCodesResponseItem
     */
    statusCode: number;
    /**
     * The email address of the workspace member.
     * @type {string}
     * @memberof WorkspaceMembersBatchUpdateDepartmentCodesResponseItem
     */
    email: string;
    /**
     * The updated department code of the workspace member
     * @type {string}
     * @memberof WorkspaceMembersBatchUpdateDepartmentCodesResponseItem
     */
    departmentCode?: string;
    /**
     * 
     * @type {ApiError}
     * @memberof WorkspaceMembersBatchUpdateDepartmentCodesResponseItem
     */
    error?: ApiError;
}

export function WorkspaceMembersBatchUpdateDepartmentCodesResponseItemFromJSON(json: any): WorkspaceMembersBatchUpdateDepartmentCodesResponseItem {
    return WorkspaceMembersBatchUpdateDepartmentCodesResponseItemFromJSONTyped(json, false);
}

export function WorkspaceMembersBatchUpdateDepartmentCodesResponseItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceMembersBatchUpdateDepartmentCodesResponseItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'statusCode': json['statusCode'],
        'email': json['email'],
        'departmentCode': !exists(json, 'departmentCode') ? undefined : json['departmentCode'],
        'error': !exists(json, 'error') ? undefined : ApiErrorFromJSON(json['error']),
    };
}

export function WorkspaceMembersBatchUpdateDepartmentCodesResponseItemToJSON(value?: WorkspaceMembersBatchUpdateDepartmentCodesResponseItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'statusCode': value.statusCode,
        'email': value.email,
        'departmentCode': value.departmentCode,
        'error': ApiErrorToJSON(value.error),
    };
}

