import { OrganizationType } from 'lib/generated/api'
import { useStore } from 'state/store'

import { companyRoutes } from './companies'
import { defaultRoutes } from './default'
import { globalRoutes } from './global'
import { propertyManagerRoutes } from './propertyManagers'

export { companyRoutes, defaultRoutes, globalRoutes, propertyManagerRoutes }

export const useActiveRoutes = () => {
  const organizationType = useStore(state => state.organization.type)

  switch (organizationType) {
    case OrganizationType.PropertyManager:
      return propertyManagerRoutes
    case OrganizationType.Company:
      return companyRoutes
    default:
      return defaultRoutes
  }
}
