/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorFromJSONTyped,
    ApiErrorToJSON,
} from './ApiError';

/**
 * If a workspace member is successfully invited, in addition to the workspace member email, the workspaceMemberId will be returned. If there is an error inviting a particular workspace member, then no workspaceMemberId will be returned.
 * @export
 * @interface WorkspaceMembersInviteResponseItem
 */
export interface WorkspaceMembersInviteResponseItem {
    /**
     * An HTTP status code indicating the status of the request for an invited, removed, or updated workspace member.
     * @type {number}
     * @memberof WorkspaceMembersInviteResponseItem
     */
    statusCode: number;
    /**
     * A workspace member unique identifier.
     * @type {string}
     * @memberof WorkspaceMembersInviteResponseItem
     */
    workspaceMemberId?: string;
    /**
     * The email address of the workspace member.
     * @type {string}
     * @memberof WorkspaceMembersInviteResponseItem
     */
    email?: string;
    /**
     * The invites associated permission ticket id
     * @type {string}
     * @memberof WorkspaceMembersInviteResponseItem
     */
    ticketId?: string;
    /**
     * 
     * @type {ApiError}
     * @memberof WorkspaceMembersInviteResponseItem
     */
    error?: ApiError;
}

export function WorkspaceMembersInviteResponseItemFromJSON(json: any): WorkspaceMembersInviteResponseItem {
    return WorkspaceMembersInviteResponseItemFromJSONTyped(json, false);
}

export function WorkspaceMembersInviteResponseItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceMembersInviteResponseItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'statusCode': json['statusCode'],
        'workspaceMemberId': !exists(json, 'workspaceMemberId') ? undefined : json['workspaceMemberId'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'ticketId': !exists(json, 'ticketId') ? undefined : json['ticketId'],
        'error': !exists(json, 'error') ? undefined : ApiErrorFromJSON(json['error']),
    };
}

export function WorkspaceMembersInviteResponseItemToJSON(value?: WorkspaceMembersInviteResponseItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'statusCode': value.statusCode,
        'workspaceMemberId': value.workspaceMemberId,
        'email': value.email,
        'ticketId': value.ticketId,
        'error': ApiErrorToJSON(value.error),
    };
}

