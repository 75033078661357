/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PortalAdminRole,
    PortalAdminRoleFromJSON,
    PortalAdminRoleFromJSONTyped,
    PortalAdminRoleToJSON,
} from './PortalAdminRole';
import {
    PortalAdminStatus,
    PortalAdminStatusFromJSON,
    PortalAdminStatusFromJSONTyped,
    PortalAdminStatusToJSON,
} from './PortalAdminStatus';

/**
 * 
 * @export
 * @interface PortalAdmin
 */
export interface PortalAdmin {
    /**
     * A portal admin unique identifier.
     * @type {string}
     * @memberof PortalAdmin
     */
    portalAdminId?: string;
    /**
     * The email address of the workspace admin.
     * @type {string}
     * @memberof PortalAdmin
     */
    email: string;
    /**
     * The first name, displayed only within the portal workspace.
     * @type {string}
     * @memberof PortalAdmin
     */
    firstName?: string;
    /**
     * The last name, displayed only within the portal workspace.
     * @type {string}
     * @memberof PortalAdmin
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof PortalAdmin
     */
    timezone?: string;
    /**
     * The preferred locale used for communication, in BCP 47 format.
     * @type {string}
     * @memberof PortalAdmin
     */
    locale?: string;
    /**
     * 
     * @type {PortalAdminRole}
     * @memberof PortalAdmin
     */
    role: PortalAdminRole;
    /**
     * 
     * @type {PortalAdminStatus}
     * @memberof PortalAdmin
     */
    status?: PortalAdminStatus;
    /**
     * The timestamp that the audit event record was created.
     * @type {Date}
     * @memberof PortalAdmin
     */
    lastUpdatedInviteStatus?: Date;
    /**
     * The number of milliseconds that have elapsed since the Unix epoch. Last seen is an approximate of the user's last login / session.
     * @type {number}
     * @memberof PortalAdmin
     */
    lastSeen?: number;
}

export function PortalAdminFromJSON(json: any): PortalAdmin {
    return PortalAdminFromJSONTyped(json, false);
}

export function PortalAdminFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortalAdmin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'portalAdminId': !exists(json, 'portalAdminId') ? undefined : json['portalAdminId'],
        'email': json['email'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'timezone': !exists(json, 'timezone') ? undefined : json['timezone'],
        'locale': !exists(json, 'locale') ? undefined : json['locale'],
        'role': PortalAdminRoleFromJSON(json['role']),
        'status': !exists(json, 'status') ? undefined : PortalAdminStatusFromJSON(json['status']),
        'lastUpdatedInviteStatus': !exists(json, 'lastUpdatedInviteStatus') ? undefined : (new Date(json['lastUpdatedInviteStatus'])),
        'lastSeen': !exists(json, 'lastSeen') ? undefined : json['lastSeen'],
    };
}

export function PortalAdminToJSON(value?: PortalAdmin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'portalAdminId': value.portalAdminId,
        'email': value.email,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'timezone': value.timezone,
        'locale': value.locale,
        'role': PortalAdminRoleToJSON(value.role),
        'status': PortalAdminStatusToJSON(value.status),
        'lastUpdatedInviteStatus': value.lastUpdatedInviteStatus === undefined ? undefined : (value.lastUpdatedInviteStatus.toISOString()),
        'lastSeen': value.lastSeen,
    };
}

