/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OrganizationType = {
    PropertyManager: 'PROPERTY_MANAGER',
    Company: 'COMPANY',
    Merchant: 'MERCHANT',
    User: 'USER'
} as const;
export type OrganizationType = typeof OrganizationType[keyof typeof OrganizationType];


export function OrganizationTypeFromJSON(json: any): OrganizationType {
    return OrganizationTypeFromJSONTyped(json, false);
}

export function OrganizationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationType {
    return json as OrganizationType;
}

export function OrganizationTypeToJSON(value?: OrganizationType | null): any {
    return value as any;
}

