/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * MEMBER_INVITE: The job is completing a workspace member invite action.
 * @export
 */
export const JobResultAction = {
    MemberInvite: 'MEMBER_INVITE'
} as const;
export type JobResultAction = typeof JobResultAction[keyof typeof JobResultAction];


export function JobResultActionFromJSON(json: any): JobResultAction {
    return JobResultActionFromJSONTyped(json, false);
}

export function JobResultActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobResultAction {
    return json as JobResultAction;
}

export function JobResultActionToJSON(value?: JobResultAction | null): any {
    return value as any;
}

