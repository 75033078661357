/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Polygon
 */
export interface Polygon {
    /**
     * 
     * @type {string}
     * @memberof Polygon
     */
    id?: string;
    /**
     * The coordinates (latitude, longitude) of the polygon's path
     * @type {string}
     * @memberof Polygon
     */
    coordinates: string;
    /**
     * The internal display name
     * @type {string}
     * @memberof Polygon
     */
    name?: string;
}

export function PolygonFromJSON(json: any): Polygon {
    return PolygonFromJSONTyped(json, false);
}

export function PolygonFromJSONTyped(json: any, ignoreDiscriminator: boolean): Polygon {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'coordinates': json['coordinates'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function PolygonToJSON(value?: Polygon | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'coordinates': value.coordinates,
        'name': value.name,
    };
}

