/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    EmailAddress,
    EmailAddressFromJSON,
    EmailAddressToJSON,
    EmailConfirm,
    EmailConfirmFromJSON,
    EmailConfirmToJSON,
    EmailConfirmResponse,
    EmailConfirmResponseFromJSON,
    EmailConfirmResponseToJSON,
    EmailMasked,
    EmailMaskedFromJSON,
    EmailMaskedToJSON,
    EmailSubmit,
    EmailSubmitFromJSON,
    EmailSubmitToJSON,
    EmailSubmitResponse,
    EmailSubmitResponseFromJSON,
    EmailSubmitResponseToJSON,
    EmailToken,
    EmailTokenFromJSON,
    EmailTokenToJSON,
    EmailTokenize,
    EmailTokenizeFromJSON,
    EmailTokenizeToJSON,
    EmailTokens,
    EmailTokensFromJSON,
    EmailTokensToJSON,
} from '../models';

export interface CreateEmailTokenRequest {
    emailTokenize: EmailTokenize;
}

export interface EmailConfirmationConfirmRequest {
    emailConfirm?: EmailConfirm;
}

export interface EmailConfirmationSubmitRequest {
    emailSubmit?: EmailSubmit;
}

export interface GetEmailTokenRequest {
    emailToken: string;
}

export interface VerifyEmailRequest {
    emailAddress: EmailAddress;
}

/**
 * 
 */
export class EmailApi extends runtime.BaseAPI {

    /**
     * Tokenizes an email address.
     */
    async createEmailTokenRaw(requestParameters: CreateEmailTokenRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<EmailToken>> {
        if (requestParameters.emailTokenize === null || requestParameters.emailTokenize === undefined) {
            throw new runtime.RequiredError('emailTokenize','Required parameter requestParameters.emailTokenize was null or undefined when calling createEmailToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/emails/tokenize`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmailTokenizeToJSON(requestParameters.emailTokenize),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailTokenFromJSON(jsonValue));
    }

    /**
     * Tokenizes an email address.
     */
    async createEmailToken(requestParameters: CreateEmailTokenRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<EmailToken> {
        const response = await this.createEmailTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Confirm user\'s ownership of an email address with a confirmation code. This should not be entered by the user - it should be provided to the client to be sent to this endpoint automatically (i.e. read from a URL parameter and used to populate this request)
     */
    async emailConfirmationConfirmRaw(requestParameters: EmailConfirmationConfirmRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<EmailConfirmResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/emails/confirmation/confirm`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmailConfirmToJSON(requestParameters.emailConfirm),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailConfirmResponseFromJSON(jsonValue));
    }

    /**
     * Confirm user\'s ownership of an email address with a confirmation code. This should not be entered by the user - it should be provided to the client to be sent to this endpoint automatically (i.e. read from a URL parameter and used to populate this request)
     */
    async emailConfirmationConfirm(requestParameters: EmailConfirmationConfirmRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<EmailConfirmResponse> {
        const response = await this.emailConfirmationConfirmRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Begin email confirmation
     */
    async emailConfirmationSubmitRaw(requestParameters: EmailConfirmationSubmitRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<EmailSubmitResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/emails/confirmation/send`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmailSubmitToJSON(requestParameters.emailSubmit),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailSubmitResponseFromJSON(jsonValue));
    }

    /**
     * Begin email confirmation
     */
    async emailConfirmationSubmit(requestParameters: EmailConfirmationSubmitRequest = {}, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<EmailSubmitResponse> {
        const response = await this.emailConfirmationSubmitRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get details on an email token
     */
    async getEmailTokenRaw(requestParameters: GetEmailTokenRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<EmailMasked>> {
        if (requestParameters.emailToken === null || requestParameters.emailToken === undefined) {
            throw new runtime.RequiredError('emailToken','Required parameter requestParameters.emailToken was null or undefined when calling getEmailToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/emails/tokens/{emailToken}`.replace(`{${"emailToken"}}`, encodeURIComponent(String(requestParameters.emailToken))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailMaskedFromJSON(jsonValue));
    }

    /**
     * Get details on an email token
     */
    async getEmailToken(requestParameters: GetEmailTokenRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<EmailMasked> {
        const response = await this.getEmailTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Request verification of an email address. Also used to trigger a re-send of the verification code.
     */
    async verifyEmailRaw(requestParameters: VerifyEmailRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<EmailTokens>> {
        if (requestParameters.emailAddress === null || requestParameters.emailAddress === undefined) {
            throw new runtime.RequiredError('emailAddress','Required parameter requestParameters.emailAddress was null or undefined when calling verifyEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/emails/verify`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmailAddressToJSON(requestParameters.emailAddress),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailTokensFromJSON(jsonValue));
    }

    /**
     * Request verification of an email address. Also used to trigger a re-send of the verification code.
     */
    async verifyEmail(requestParameters: VerifyEmailRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<EmailTokens> {
        const response = await this.verifyEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
