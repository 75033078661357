import { ReactComponent as HomeIcon } from 'assets/Icons/Home.svg'
import { SideNavItemProps } from 'components/SideNavItem'
import { RouteMap } from 'lib/router'
import { Home } from 'views/Home/PlaceholderHome'
import NotFound from 'views/NotFound'

export const defaultRoutes: RouteMap<SideNavItemProps> = [
  {
    path: '/',
    exact: true,
    component: Home,
    navTitle: 'Home',
    navIcon: HomeIcon,
  },
  {
    path: '*',
    component: NotFound,
  },
]
