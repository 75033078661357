/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The status of the workspace member
 * @export
 */
export const WorkspaceMemberStatus = {
    Active: 'ACTIVE',
    Invited: 'INVITED',
    Rejected: 'REJECTED',
    Revoked: 'REVOKED',
    Expired: 'EXPIRED'
} as const;
export type WorkspaceMemberStatus = typeof WorkspaceMemberStatus[keyof typeof WorkspaceMemberStatus];


export function WorkspaceMemberStatusFromJSON(json: any): WorkspaceMemberStatus {
    return WorkspaceMemberStatusFromJSONTyped(json, false);
}

export function WorkspaceMemberStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceMemberStatus {
    return json as WorkspaceMemberStatus;
}

export function WorkspaceMemberStatusToJSON(value?: WorkspaceMemberStatus | null): any {
    return value as any;
}

