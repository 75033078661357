import { ComponentType } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { callIfFn } from 'utils'
import { ProtectedRouteProps, RouteEntry } from './types'

/**
 * A Route that renders a component depending on the end result of a `challenge` provided
 * A fallback component can be passed if the `challenge` is lost (defaults to null)
 */
export const ProtectedRoute = ({
  fallbackComponent,
  ...props
}: ProtectedRouteProps) => {
  const won = callIfFn(props.challenge)

  if (won) return <Route {...props} />
  if (props.redirectTo) return <Redirect to={props.redirectTo} />

  const { render, children, ...rest } = props
  return <Route {...rest} component={fallbackComponent} />
}

/**
 * https://reactrouter.com/web/example/route-config
 * A special wrapper for <Route> that knows how to handle "sub"-routes by passing them
 * in a `routes` prop to the component it renders.
 */
export const RouteWithSubRoutes = ({
  component,
  routes,
  ...rest
}: RouteEntry) => {
  const Component = component as ComponentType<Pick<RouteEntry, 'routes'>>
  return (
    <Route
      {...rest}
      render={props => (
        // pass the sub-routes down to keep nesting
        <Component {...props} routes={routes} />
      )}
    />
  )
}
