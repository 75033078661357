/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JobStatus,
    JobStatusFromJSON,
    JobStatusFromJSONTyped,
    JobStatusToJSON,
} from './JobStatus';
import {
    JobType,
    JobTypeFromJSON,
    JobTypeFromJSONTyped,
    JobTypeToJSON,
} from './JobType';

/**
 * 
 * @export
 * @interface Job
 */
export interface Job {
    /**
     * A job unique identifier.
     * @type {string}
     * @memberof Job
     */
    jobId: string;
    /**
     * The time at which the job was initially created.
     * @type {Date}
     * @memberof Job
     */
    createdAt: Date;
    /**
     * The time at which the job was completed.
     * @type {Date}
     * @memberof Job
     */
    completedAt?: Date;
    /**
     * The date-time the job is scheduled to be run at. If unspecified, the job is scheduled to be run immediately.
     * @type {Date}
     * @memberof Job
     */
    scheduledAt?: Date;
    /**
     * The time zone of scheduledAt (formatted according to IANA time zone database)
     * @type {string}
     * @memberof Job
     */
    timezone?: string;
    /**
     * The email address of the workspace admin.
     * @type {string}
     * @memberof Job
     */
    createdByEmail: string;
    /**
     * 
     * @type {JobStatus}
     * @memberof Job
     */
    status: JobStatus;
    /**
     * 
     * @type {JobType}
     * @memberof Job
     */
    type: JobType;
}

export function JobFromJSON(json: any): Job {
    return JobFromJSONTyped(json, false);
}

export function JobFromJSONTyped(json: any, ignoreDiscriminator: boolean): Job {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jobId': json['jobId'],
        'createdAt': (new Date(json['createdAt'])),
        'completedAt': !exists(json, 'completedAt') ? undefined : (new Date(json['completedAt'])),
        'scheduledAt': !exists(json, 'scheduledAt') ? undefined : (new Date(json['scheduledAt'])),
        'timezone': !exists(json, 'timezone') ? undefined : json['timezone'],
        'createdByEmail': json['createdByEmail'],
        'status': JobStatusFromJSON(json['status']),
        'type': JobTypeFromJSON(json['type']),
    };
}

export function JobToJSON(value?: Job | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'jobId': value.jobId,
        'createdAt': (value.createdAt.toISOString()),
        'completedAt': value.completedAt === undefined ? undefined : (value.completedAt.toISOString()),
        'scheduledAt': value.scheduledAt === undefined ? undefined : (value.scheduledAt.toISOString()),
        'timezone': value.timezone,
        'createdByEmail': value.createdByEmail,
        'status': JobStatusToJSON(value.status),
        'type': JobTypeToJSON(value.type),
    };
}

