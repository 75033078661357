/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * A display hint when rendering merchants to users. For example, DROP will render a countdown widget for the remaining time to join a Drop order.
 * @export
 */
export const MerchantListDisplayHint = {
    None: 'NONE',
    Drop: 'DROP'
} as const;
export type MerchantListDisplayHint = typeof MerchantListDisplayHint[keyof typeof MerchantListDisplayHint];


export function MerchantListDisplayHintFromJSON(json: any): MerchantListDisplayHint {
    return MerchantListDisplayHintFromJSONTyped(json, false);
}

export function MerchantListDisplayHintFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantListDisplayHint {
    return json as MerchantListDisplayHint;
}

export function MerchantListDisplayHintToJSON(value?: MerchantListDisplayHint | null): any {
    return value as any;
}

