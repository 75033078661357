import { PORTAL_ADMIN_ROLES_HIERARCHY } from 'config'
import { PortalAdminRole } from 'lib/generated/api'
import { selectUserInfo } from 'state/selectors/userInfo'
import { useStore } from 'state/store'

/**
 * `useCheckPermissions` is a custom hook that loads the user Profile
 * from the store and checks if the user has the necessary role.
 * `adminRole` is `PortalAdminRole | PortalAdminRole[]`
 * In the case that adminRole is singular, the user must have that role or a higher role (in the hierarchy)
 * In the case that adminRole is an array, the user must have one of the roles in the array
 * @returns boolean
 */
export const useCheckPermissions = (
  adminRole: PortalAdminRole[] | PortalAdminRole
) => {
  const { role } = useStore(selectUserInfo)

  if (Array.isArray(adminRole)) {
    // If an array, role needs to be in the provided array
    if (adminRole.some(value => value === role)) return true
  } else if (role) {
    // If a singular PortalAdminRole, role needs to be equal to the role or higher in the hierarchy
    const roleIndex = PORTAL_ADMIN_ROLES_HIERARCHY.indexOf(adminRole)
    if (
      PORTAL_ADMIN_ROLES_HIERARCHY.slice(0, roleIndex + 1).some(
        value => value === role
      )
    ) {
      return true
    }
  }

  return false
}
