import { useField } from 'formik'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputProps,
} from '@ritualco/jutsu'
import { GoogleMapsAutoComplete } from 'components/GoogleMapsAutoComplete'

export type FormikGoogleMapsAutoCompleteProps = {
  label?: string
  name: string
  address?: string
  isRequired?: boolean
  setAddress?: React.Dispatch<
    React.SetStateAction<google.maps.places.PlaceResult>
  >
  onChange?: (placeResult: google.maps.places.PlaceResult) => void
} & InputProps

export const FormikGoogleMapsAutoComplete = ({
  label,
  address = '',
  isRequired = false,
  setAddress = () => {},
  ...props
}: FormikGoogleMapsAutoCompleteProps) => {
  const [field, { error, touched }, { setValue }] = useField(props.name)
  const handleInputDelete = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (field.value && e.key === 'Backspace') setValue('', true)
  }
  return (
    <FormControl
      id={props.name}
      isInvalid={!!error && touched}
      isRequired={isRequired}
    >
      {label && <FormLabel>{label}</FormLabel>}
      <GoogleMapsAutoComplete
        {...field}
        address={address}
        onChange={value => {
          setAddress(value)
          setValue(value?.formatted_address)
        }}
        onDelete={handleInputDelete}
        {...props}
      />
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  )
}
