/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    PaymentMethod,
    PaymentMethodFromJSON,
    PaymentMethodToJSON,
    PaymentMethodConfiguration,
    PaymentMethodConfigurationFromJSON,
    PaymentMethodConfigurationToJSON,
    PaymentMethodsResponse,
    PaymentMethodsResponseFromJSON,
    PaymentMethodsResponseToJSON,
    SetupIntent,
    SetupIntentFromJSON,
    SetupIntentToJSON,
} from '../models';

export interface CreatePaymentMethodRequest {
    workspaceId: string;
    paymentMethodConfiguration: PaymentMethodConfiguration;
}

export interface CreateSetupIntentRequest {
    workspaceId: string;
}

export interface DeletePaymentMethodRequest {
    workspaceId: string;
    paymentMethodId: string;
}

export interface GetPaymentMethodsRequest {
    workspaceId: string;
}

export interface SelectPaymentMethodRequest {
    workspaceId: string;
    paymentMethodId: string;
}

/**
 * 
 */
export class PaymentMethodsApi extends runtime.BaseAPI {

    /**
     * Creates a payment method
     */
    async createPaymentMethodRaw(requestParameters: CreatePaymentMethodRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PaymentMethod>> {
        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling createPaymentMethod.');
        }

        if (requestParameters.paymentMethodConfiguration === null || requestParameters.paymentMethodConfiguration === undefined) {
            throw new runtime.RequiredError('paymentMethodConfiguration','Required parameter requestParameters.paymentMethodConfiguration was null or undefined when calling createPaymentMethod.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workspaces/{workspaceId}/payment-methods`.replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentMethodConfigurationToJSON(requestParameters.paymentMethodConfiguration),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentMethodFromJSON(jsonValue));
    }

    /**
     * Creates a payment method
     */
    async createPaymentMethod(requestParameters: CreatePaymentMethodRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PaymentMethod> {
        const response = await this.createPaymentMethodRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * An authorized Stripe specific endpoint that is responsible for the following: 1) Creating a customer if it does not exist 2) Instantiating a setupintent 3) Returning the intent client secret 
     */
    async createSetupIntentRaw(requestParameters: CreateSetupIntentRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<SetupIntent>> {
        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling createSetupIntent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workspaces/{workspaceId}/payment-methods/setup-intent`.replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SetupIntentFromJSON(jsonValue));
    }

    /**
     * An authorized Stripe specific endpoint that is responsible for the following: 1) Creating a customer if it does not exist 2) Instantiating a setupintent 3) Returning the intent client secret 
     */
    async createSetupIntent(requestParameters: CreateSetupIntentRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<SetupIntent> {
        const response = await this.createSetupIntentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a payment method
     */
    async deletePaymentMethodRaw(requestParameters: DeletePaymentMethodRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling deletePaymentMethod.');
        }

        if (requestParameters.paymentMethodId === null || requestParameters.paymentMethodId === undefined) {
            throw new runtime.RequiredError('paymentMethodId','Required parameter requestParameters.paymentMethodId was null or undefined when calling deletePaymentMethod.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workspaces/{workspaceId}/payment-methods/{paymentMethodId}`.replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"paymentMethodId"}}`, encodeURIComponent(String(requestParameters.paymentMethodId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a payment method
     */
    async deletePaymentMethod(requestParameters: DeletePaymentMethodRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.deletePaymentMethodRaw(requestParameters, initOverrides);
    }

    /**
     * Returns a list of payment methods
     */
    async getPaymentMethodsRaw(requestParameters: GetPaymentMethodsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PaymentMethodsResponse>> {
        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling getPaymentMethods.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workspaces/{workspaceId}/payment-methods`.replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentMethodsResponseFromJSON(jsonValue));
    }

    /**
     * Returns a list of payment methods
     */
    async getPaymentMethods(requestParameters: GetPaymentMethodsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PaymentMethodsResponse> {
        const response = await this.getPaymentMethodsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sets the selected payment method for this workspace to the specified payment method
     */
    async selectPaymentMethodRaw(requestParameters: SelectPaymentMethodRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PaymentMethod>> {
        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling selectPaymentMethod.');
        }

        if (requestParameters.paymentMethodId === null || requestParameters.paymentMethodId === undefined) {
            throw new runtime.RequiredError('paymentMethodId','Required parameter requestParameters.paymentMethodId was null or undefined when calling selectPaymentMethod.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workspaces/{workspaceId}/payment-methods/{paymentMethodId}/select`.replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"paymentMethodId"}}`, encodeURIComponent(String(requestParameters.paymentMethodId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentMethodFromJSON(jsonValue));
    }

    /**
     * Sets the selected payment method for this workspace to the specified payment method
     */
    async selectPaymentMethod(requestParameters: SelectPaymentMethodRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PaymentMethod> {
        const response = await this.selectPaymentMethodRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
