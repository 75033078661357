/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JobResultAction,
    JobResultActionFromJSON,
    JobResultActionFromJSONTyped,
    JobResultActionToJSON,
} from './JobResultAction';
import {
    JobResultStatus,
    JobResultStatusFromJSON,
    JobResultStatusFromJSONTyped,
    JobResultStatusToJSON,
} from './JobResultStatus';

/**
 * 
 * @export
 * @interface JobResult
 */
export interface JobResult {
    /**
     * The target of the jobs action
     * @type {string}
     * @memberof JobResult
     */
    subject: string;
    /**
     * 
     * @type {JobResultAction}
     * @memberof JobResult
     */
    action: JobResultAction;
    /**
     * 
     * @type {JobResultStatus}
     * @memberof JobResult
     */
    status: JobResultStatus;
}

export function JobResultFromJSON(json: any): JobResult {
    return JobResultFromJSONTyped(json, false);
}

export function JobResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subject': json['subject'],
        'action': JobResultActionFromJSON(json['action']),
        'status': JobResultStatusFromJSON(json['status']),
    };
}

export function JobResultToJSON(value?: JobResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subject': value.subject,
        'action': JobResultActionToJSON(value.action),
        'status': JobResultStatusToJSON(value.status),
    };
}

