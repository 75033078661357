import { format } from 'date-fns'
import { MdMoreVert } from 'react-icons/md'
import { Cell } from 'react-table'
import {
  Badge,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
} from '@ritualco/jutsu'
import { useContent } from 'lib/content/react'
import {
  IncentivePlan,
  IncentivePlanStatus,
  IncentiveType,
  ScheduleType,
} from 'types/entities'
import { useCheckPermissions } from 'hooks/useCheckPermissions'
import { PortalAdminRole } from 'lib/generated/api'
import { isIncentiveEditable } from 'views/Incentive/utils'

export const Title = ({ value }: { value?: string }) => {
  const { formatMessage } = useContent()
  const displayTitle =
    value ||
    formatMessage({
      id: 'pmportal.incentives.untitledIncentive',
    })
  return (
    <Text isTruncated maxW={['120px', null, '3xs', 'md']} title={displayTitle}>
      {displayTitle}
    </Text>
  )
}

export const Type = ({ value }: { value?: IncentiveType }) => {
  const { formatMessage } = useContent()
  if (!value) return '-'
  switch (value) {
    case 'creditOneTime':
    case 'creditRecurring':
      return formatMessage({ id: 'pmportal.incentives.creditType' })
    case 'discountAmount':
    case 'discountPercentage':
      return formatMessage({ id: 'pmportal.incentives.discountType' })
    default:
      return '-'
  }
}

export const Available = ({
  value,
  row: { original },
}: Cell<IncentivePlan, ScheduleType | undefined>) => {
  const { formatMessage } = useContent()
  if (!value) return '-'
  const startDate = original.incentiveDetails.schedule?.start
  switch (value) {
    case 'none':
      if (!startDate) return '-'
      return format(startDate, 'LLLL d')
    case 'daily':
      return formatMessage({ id: 'pmportal.incentives.daily' })
    case 'weekly':
      return formatMessage({ id: 'pmportal.incentives.weekly' })
    case 'monthly':
      return formatMessage({ id: 'pmportal.incentives.monthly' })
    case 'yearly':
      return formatMessage({ id: 'pmportal.incentives.yearly' })
    case 'custom':
      return formatMessage({ id: 'pmportal.incentives.custom' })
    default:
      return '-'
  }
}

export const Status = ({ value }: { value: IncentivePlanStatus }) => {
  const { formatMessage } = useContent()
  switch (value) {
    case 'draft':
      return (
        <Badge colorScheme="gray">
          {formatMessage({ id: 'pmportal.incentives.draftStatus' })}
        </Badge>
      )
    case 'upcoming':
      return (
        <Badge>
          {formatMessage({ id: 'pmportal.incentives.upcomingStatus' })}
        </Badge>
      )
    case 'live':
      return (
        <Badge>{formatMessage({ id: 'pmportal.incentives.liveStatus' })}</Badge>
      )
    case 'paused':
      return (
        <Badge colorScheme="yellow">
          {formatMessage({ id: 'pmportal.incentives.pausedStatus' })}
        </Badge>
      )
    case 'ended':
      return (
        <Badge colorScheme="gray">
          {formatMessage({ id: 'pmportal.incentives.endedStatus' })}
        </Badge>
      )
    default:
      return null
  }
}

export type ActionCallbacks = {
  onLaunch: (incentivePlanId: string) => void
  onRelaunch: (incentivePlanId: string) => void
  onPause: (incentivePlanId: string) => void
  onResume: (incentivePlanId: string) => void
  onDelete: (incentivePlanId: string) => void
  onClone: (incentivePlanId: string) => void
}

type ActionItemsProps = {
  incentivePlan?: IncentivePlan
} & ActionCallbacks

export const useActionItems = ({
  incentivePlan,
  onLaunch,
  onRelaunch,
  onPause,
  onResume,
  onDelete,
  onClone,
}: ActionItemsProps) => {
  const { formatMessage } = useContent()
  const hasEditPermission = useCheckPermissions(PortalAdminRole.Administrator)
  const isIncentivePlanEditable = isIncentiveEditable(incentivePlan)
  const actionMenuItems = []
  if (hasEditPermission && incentivePlan) {
    if (incentivePlan.status !== 'draft') {
      actionMenuItems.push(
        <MenuItem
          key="clone"
          onClick={() => {
            onClone(incentivePlan.id)
          }}
        >
          {formatMessage({ id: 'pmportal.incentives.cloneAction' })}
        </MenuItem>
      )
    }
    if (incentivePlan.status !== 'ended') {
      if (incentivePlan.canLaunch) {
        actionMenuItems.push(
          <MenuItem
            key="launch"
            onClick={() => {
              onLaunch(incentivePlan.id)
            }}
          >
            {formatMessage({
              id: 'pmportal.incentives.launchAction',
            })}
          </MenuItem>
        )
      }
      if (incentivePlan.canRelaunch && isIncentivePlanEditable) {
        actionMenuItems.push(
          <MenuItem
            key="relaunch"
            onClick={() => {
              onRelaunch(incentivePlan.id)
            }}
          >
            {formatMessage({
              id: 'pmportal.incentives.relaunchAction',
            })}
          </MenuItem>
        )
      }
      if (incentivePlan.canPause) {
        actionMenuItems.push(
          <MenuItem
            key="pause"
            onClick={() => {
              onPause(incentivePlan.id)
            }}
          >
            {formatMessage({ id: 'pmportal.incentives.pauseAction' })}
          </MenuItem>
        )
      }
      if (incentivePlan.canResume) {
        actionMenuItems.push(
          <MenuItem
            key="resume"
            onClick={() => {
              onResume(incentivePlan.id)
            }}
          >
            {formatMessage({
              id: 'pmportal.incentives.resumeAction',
            })}
          </MenuItem>
        )
      }
      if (incentivePlan.canDelete) {
        actionMenuItems.push(
          <MenuItem
            key="delete"
            onClick={() => {
              onDelete(incentivePlan.id)
            }}
          >
            {formatMessage({
              id: 'pmportal.incentives.deleteAction',
            })}
          </MenuItem>
        )
      }
    }
  }
  return actionMenuItems
}

type ActionsProps = {
  incentivePlan: IncentivePlan
} & ActionCallbacks

export const Actions = ({
  incentivePlan,
  onLaunch,
  onRelaunch,
  onPause,
  onResume,
  onDelete,
  onClone,
}: ActionsProps) => {
  const actionMenuItems = useActionItems({
    incentivePlan,
    onLaunch,
    onRelaunch,
    onPause,
    onResume,
    onDelete,
    onClone,
  })
  const hasActions = actionMenuItems.length > 0

  return (
    <HStack spacing={2} justifyContent="flex-end" my="-4px">
      {hasActions && (
        <Menu>
          <MenuButton
            as={IconButton}
            colorScheme="transparent"
            variant="ghost"
            size="sm"
            icon={<Icon as={MdMoreVert} />}
          />
          <Portal>
            <MenuList zIndex="dropdown">{actionMenuItems}</MenuList>
          </Portal>
        </Menu>
      )}
    </HStack>
  )
}
