/**
 * CURRENCY CONVERSION
 */

const MICRO_PER_CENT = 1000000
const CENTS_PER_DOLLAR = 100

type MicroAmountToFixed = (
  microAmount?: number,
  fractionDigits?: number
) => string | undefined
/**
 * Returns a dollar string representing the given micro amount
 * @param microAmount
 * @param fractionDigits Number of digits after the decimal. Must be between 0 and 20, inclusive. Defaults to 2 if not specified.
 */
export const microAmountToFixed: MicroAmountToFixed = (
  microAmount,
  fractionDigits = 2
) => {
  if (microAmount === undefined || microAmount === null) {
    return undefined
  }
  return (microAmount / (MICRO_PER_CENT * CENTS_PER_DOLLAR)).toFixed(
    fractionDigits
  )
}

type ParseMicro = (value?: string) => number | undefined
/**
 * Returns a micro amount representing the given dollar string
 * @param value String representation of a dollar amount
 */
export const parseMicro: ParseMicro = value => {
  if (!value) return undefined
  const float = parseFloat(value)
  if (Number.isNaN(float)) return undefined
  return float * CENTS_PER_DOLLAR * MICRO_PER_CENT
}

/**
 * DISTANCE CONVERSION
 */

export const METERS_PER_KILOMETER = 1000
export const METERS_PER_MILE = 1609.344

/**
 * TIME CONVERSION
 */

export const MINUTES_IN_MILLISECONDS = 60000

type AddMinutesToTimeString = (
  time?: string,
  minToAdd?: number,
  locale?: string
) => string | undefined

/**
 * Adds minutes to a time and returns a time string ex. 9:30AM
 * @param time Initial time
 * @param minToAdd Number of minutes to add to time.
 * @param locale
 */
export const addMinutesToTimeString: AddMinutesToTimeString = (
  time = '09:00',
  minToAdd = 0,
  locale = 'en'
) =>
  new Date(
    new Date(`1970/01/01 ${time}`).getTime() +
      minToAdd * MINUTES_IN_MILLISECONDS
  ).toLocaleTimeString(locale, {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  })
