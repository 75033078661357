/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WorkspaceAdminsResendResponseItem,
    WorkspaceAdminsResendResponseItemFromJSON,
    WorkspaceAdminsResendResponseItemFromJSONTyped,
    WorkspaceAdminsResendResponseItemToJSON,
} from './WorkspaceAdminsResendResponseItem';

/**
 * 
 * @export
 * @interface WorkspaceAdminsResendResponse
 */
export interface WorkspaceAdminsResendResponse {
    /**
     * 
     * @type {Array<WorkspaceAdminsResendResponseItem>}
     * @memberof WorkspaceAdminsResendResponse
     */
    results?: Array<WorkspaceAdminsResendResponseItem>;
}

export function WorkspaceAdminsResendResponseFromJSON(json: any): WorkspaceAdminsResendResponse {
    return WorkspaceAdminsResendResponseFromJSONTyped(json, false);
}

export function WorkspaceAdminsResendResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceAdminsResendResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(WorkspaceAdminsResendResponseItemFromJSON)),
    };
}

export function WorkspaceAdminsResendResponseToJSON(value?: WorkspaceAdminsResendResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(WorkspaceAdminsResendResponseItemToJSON)),
    };
}

