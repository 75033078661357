import { PropsWithChildren } from 'react'
import { Divider, VStack, Text, Button } from '@ritualco/jutsu'
import { CardPage } from 'components/PageTemplates'
import { useAnalytics } from 'lib/simple-analytics'
import { useContent } from 'lib/content/react'
import IdentityProviderButton from 'components/IdentityProviderButton'
import { LoginProps } from './types'

export const LoginShell = ({
  identityProviders,
  onLogin = () => {},
  onRegister = () => {},
  children,
}: PropsWithChildren<LoginProps>) => {
  const { track } = useAnalytics()
  const { formatMessage } = useContent()

  return (
    <CardPage textAlign="center">
      {children}
      <VStack spacing={3} w="100%" mt={8} mb={8}>
        {identityProviders.map(provider => (
          <IdentityProviderButton
            key={provider.id}
            {...provider}
            onClick={() => {
              onLogin(provider)
              track(provider.clickEvent)
            }}
          />
        ))}
      </VStack>
      <Divider mb={8} />
      <Text fontSize="small" color="grayscale.secondary">
        {formatMessage(
          { id: 'pmportal.login.signUpDescription' },
          {
            link: text => (
              <Button variant="link" onClick={() => onRegister()}>
                {text}
              </Button>
            ),
          }
        )}
      </Text>
    </CardPage>
  )
}
