/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncentiveReportRunUrl
 */
export interface IncentiveReportRunUrl {
    /**
     * Url to download a csv of IncentiveReport
     * @type {string}
     * @memberof IncentiveReportRunUrl
     */
    downloadUrl: string;
    /**
     * Unique identifier of the incentive report
     * @type {string}
     * @memberof IncentiveReportRunUrl
     */
    incentiveReportId?: string;
}

export function IncentiveReportRunUrlFromJSON(json: any): IncentiveReportRunUrl {
    return IncentiveReportRunUrlFromJSONTyped(json, false);
}

export function IncentiveReportRunUrlFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncentiveReportRunUrl {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'downloadUrl': json['downloadUrl'],
        'incentiveReportId': !exists(json, 'incentiveReportId') ? undefined : json['incentiveReportId'],
    };
}

export function IncentiveReportRunUrlToJSON(value?: IncentiveReportRunUrl | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'downloadUrl': value.downloadUrl,
        'incentiveReportId': value.incentiveReportId,
    };
}

