import { ReactComponent as AppleIcon } from 'assets/Icons/Apple.svg'
import { ReactComponent as GoogleIcon } from 'assets/Icons/Google.svg'
import { ReactComponent as FacebookIcon } from 'assets/Icons/Facebook.svg'
import { MdSmartphone } from 'react-icons/md'
import { Icon } from '@ritualco/jutsu'
import { useContent } from 'lib/content/react'
import { IdentityProvider } from 'types/entities/IdentityProvider'

const useIdentityProviders = () => {
  const { formatMessage } = useContent()

  const providers: IdentityProvider[] = [
    {
      id: 'phone',
      label: formatMessage({ id: 'pmportal.login.phone' }),
      icon: <Icon as={MdSmartphone} w="24px" h="24px" />,
      clickEvent: 'HERA_SIGNIN_PHONE',
    },
    {
      id: 'google',
      label: formatMessage({ id: 'pmportal.login.google' }),
      icon: <GoogleIcon />,
      clickEvent: 'HERA_SIGNIN_GOOGLE',
    },
    {
      id: 'facebook',
      label: formatMessage({ id: 'pmportal.login.facebook' }),
      icon: <FacebookIcon />,
      clickEvent: 'HERA_SIGNIN_FACEBOOK',
    },
    {
      id: 'apple',
      label: formatMessage({ id: 'pmportal.login.apple' }),
      icon: <AppleIcon />,
      clickEvent: 'HERA_SIGNIN_APPLE',
    },
  ]

  return { providers }
}

export default useIdentityProviders
