/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmailAddress
 */
export interface EmailAddress {
    /**
     * the email address being tokenized
     * @type {string}
     * @memberof EmailAddress
     */
    emailAddress: string;
}

export function EmailAddressFromJSON(json: any): EmailAddress {
    return EmailAddressFromJSONTyped(json, false);
}

export function EmailAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emailAddress': json['emailAddress'],
    };
}

export function EmailAddressToJSON(value?: EmailAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'emailAddress': value.emailAddress,
    };
}

