import { useState } from 'react'
import { EditableSection } from 'components/EditableSection'
import { Pagination } from 'components/Pagination'
import { useContent } from 'lib/content/react'
import Loading from 'components/Loading'
import { INITIAL_ROWS_PER_PAGE } from 'config'
import { FullWidthPage } from 'components/PageTemplates'
import { Error } from 'views/Error'
import { useGetWorkspaceAuditHistory } from 'api/endpoints/workspaces'
import { AuditLogTable } from 'components/Tables/AuditLogTable'
import { PageTrackingNames } from 'types/entities'

export type AuditLogProps = {
  organizationId: string
  workspaceId: string
}

// todo: add Contentful strings
const AuditLog = ({ workspaceId, organizationId }: AuditLogProps) => {
  const { formatMessage } = useContent()
  const [rowsPerPage, setRowsPerPage] = useState<number>(INITIAL_ROWS_PER_PAGE)
  const [paginationTokenOption, setPaginationTokenOption] = useState({})
  const { data, status } = useGetWorkspaceAuditHistory({
    workspaceId,
    organizationId,
    limit: rowsPerPage,
    ...paginationTokenOption,
  })

  if (status === 'loading') {
    return <Loading />
  }

  if (status === 'error') {
    return <Error />
  }

  return (
    <FullWidthPage
      heading={formatMessage({
        id: 'History',
      })}
      showDivider
      trackingName={PageTrackingNames.auditLog}
    >
      <EditableSection title="Logs" disableAction>
        <AuditLogTable events={data?.results} />
        <Pagination
          rowsPerPage={rowsPerPage}
          hasNext={data?.hasNextPage}
          hasPrev={data?.hasPreviousPage}
          onRowsPerPageChange={e => setRowsPerPage(Number(e.target.value))}
          onPrev={() =>
            setPaginationTokenOption({
              previousPageToken: data?.paginationToken,
            })
          }
          onNext={() =>
            setPaginationTokenOption({ nextPageToken: data?.paginationToken })
          }
        />
      </EditableSection>
    </FullWidthPage>
  )
}
export default AuditLog
