/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuditEvent
 */
export interface AuditEvent {
    /**
     * The audit event unique identifier.
     * @type {string}
     * @memberof AuditEvent
     */
    auditEventId: string;
    /**
     * The workspace unique identifier.
     * @type {string}
     * @memberof AuditEvent
     */
    workspaceId: string;
    /**
     * The category of audit event.
     * @type {string}
     * @memberof AuditEvent
     */
    category?: AuditEventCategoryEnum;
    /**
     * A description of the audit event.
     * @type {string}
     * @memberof AuditEvent
     */
    description: string;
    /**
     * The timestamp that the audit event record was created.
     * @type {Date}
     * @memberof AuditEvent
     */
    timestamp: Date;
}


/**
 * @export
 */
export const AuditEventCategoryEnum = {
    Account: 'ACCOUNT',
    CreditCardDeleted: 'CREDIT_CARD_DELETED',
    IncentiveDeleted: 'INCENTIVE_DELETED',
    IncentiveUpdated: 'INCENTIVE_UPDATED',
    InviteSent: 'INVITE_SENT',
    MerchantList: 'MERCHANT_LIST',
    NewCreditCardAdded: 'NEW_CREDIT_CARD_ADDED',
    NewIncentiveAdded: 'NEW_INCENTIVE_ADDED',
    PortalUser: 'PORTAL_USER',
    RestaurantListCreated: 'RESTAURANT_LIST_CREATED',
    RestaurantListDeleted: 'RESTAURANT_LIST_DELETED',
    RestaurantListUpdated: 'RESTAURANT_LIST_UPDATED',
    UserDeleted: 'USER_DELETED'
} as const;
export type AuditEventCategoryEnum = typeof AuditEventCategoryEnum[keyof typeof AuditEventCategoryEnum];


export function AuditEventFromJSON(json: any): AuditEvent {
    return AuditEventFromJSONTyped(json, false);
}

export function AuditEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auditEventId': json['auditEventId'],
        'workspaceId': json['workspaceId'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'description': json['description'],
        'timestamp': (new Date(json['timestamp'])),
    };
}

export function AuditEventToJSON(value?: AuditEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auditEventId': value.auditEventId,
        'workspaceId': value.workspaceId,
        'category': value.category,
        'description': value.description,
        'timestamp': (value.timestamp.toISOString()),
    };
}

