import { Form, useFormikContext } from 'formik'
import { Flex, Radio, RadioGroup, Text } from '@ritualco/jutsu'
import { ReportDownloadModalValues } from 'components/Modals/ReportDownloadModal/types'
import { useContent } from 'lib/content/react'
import { IncentiveReportRunReportTypeEnum } from 'lib/generated/api'

export const ReportDownloadModalForm = () => {
  const { formatMessage } = useContent()
  const {
    values,
    setFieldValue,
  } = useFormikContext<ReportDownloadModalValues>()

  const reportTypes = [
    {
      label: formatMessage({
        id: 'pmportal.incentives.reportTypeIncentiveByUserLabel',
      }),
      value: IncentiveReportRunReportTypeEnum.IncentiveByUser,
      description: formatMessage({
        id: 'pmportal.incentives.reportTypeIncentiveByUserDesc',
      }),
    },
    {
      label: formatMessage({
        id: 'pmportal.incentives.reportTypeIncentiveLabel',
      }),
      value: IncentiveReportRunReportTypeEnum.Incentive,
      description: formatMessage({
        id: 'pmportal.incentives.reportTypeIncentiveDesc',
      }),
    },
  ]

  return (
    <Form id="downloadReport">
      <Text color="grayscale.secondary" fontSize="small" mb={1}>
        {formatMessage({
          id: 'pmportal.incentives.downloadReportFormLabel',
        })}
      </Text>
      <RadioGroup
        value={values.reportType}
        onChange={val => setFieldValue('reportType', val)}
      >
        {reportTypes.map(({ label, value, description }) => (
          <Flex
            key={value}
            py={4}
            borderTop="1px solid"
            borderTopColor="grayscale.quinary"
            justify="space-between"
            align="center"
            flexWrap="wrap"
          >
            <Radio value={value} size="lg">
              <Text fontSize="small" fontWeight="medium" ml={4}>
                {label}
              </Text>
            </Radio>
            <Text fontSize="small" w="415px" ml={12}>
              {description}
            </Text>
          </Flex>
        ))}
      </RadioGroup>
    </Form>
  )
}
