/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * An inclusive date range restriction for when the incentive is unavailable
 * @export
 * @interface DateRestriction
 */
export interface DateRestriction {
    /**
     * 
     * @type {string}
     * @memberof DateRestriction
     */
    id?: string;
    /**
     * A label that represents a date range restriction
     * @type {string}
     * @memberof DateRestriction
     */
    name?: string;
    /**
     * Start date (formatted according to ISO 8601)
     * @type {Date}
     * @memberof DateRestriction
     */
    startDate: Date;
    /**
     * End date (formatted according to ISO 8601)
     * @type {Date}
     * @memberof DateRestriction
     */
    endDate: Date;
}

export function DateRestrictionFromJSON(json: any): DateRestriction {
    return DateRestrictionFromJSONTyped(json, false);
}

export function DateRestrictionFromJSONTyped(json: any, ignoreDiscriminator: boolean): DateRestriction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'startDate': (new Date(json['startDate'])),
        'endDate': (new Date(json['endDate'])),
    };
}

export function DateRestrictionToJSON(value?: DateRestriction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'startDate': (value.startDate.toISOString().substr(0,10)),
        'endDate': (value.endDate.toISOString().substr(0,10)),
    };
}

