/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WorkspaceConnection,
    WorkspaceConnectionFromJSON,
    WorkspaceConnectionFromJSONTyped,
    WorkspaceConnectionToJSON,
} from './WorkspaceConnection';

/**
 * 
 * @export
 * @interface WorkspaceConnectionList
 */
export interface WorkspaceConnectionList {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceConnectionList
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceConnectionList
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceConnectionList
     */
    description?: string;
    /**
     * 
     * @type {Array<WorkspaceConnection>}
     * @memberof WorkspaceConnectionList
     */
    connections: Array<WorkspaceConnection>;
}

export function WorkspaceConnectionListFromJSON(json: any): WorkspaceConnectionList {
    return WorkspaceConnectionListFromJSONTyped(json, false);
}

export function WorkspaceConnectionListFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceConnectionList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'connections': ((json['connections'] as Array<any>).map(WorkspaceConnectionFromJSON)),
    };
}

export function WorkspaceConnectionListToJSON(value?: WorkspaceConnectionList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'connections': ((value.connections as Array<any>).map(WorkspaceConnectionToJSON)),
    };
}

