/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represents details
 * @export
 */
export const ErrorReason = {
    BillingInformationMissing: 'BILLING_INFORMATION_MISSING',
    ResourceVersionConflict: 'RESOURCE_VERSION_CONFLICT',
    Unknown: 'UNKNOWN'
} as const;
export type ErrorReason = typeof ErrorReason[keyof typeof ErrorReason];


export function ErrorReasonFromJSON(json: any): ErrorReason {
    return ErrorReasonFromJSONTyped(json, false);
}

export function ErrorReasonFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorReason {
    return json as ErrorReason;
}

export function ErrorReasonToJSON(value?: ErrorReason | null): any {
    return value as any;
}

