import {
  MerchantList as ApiMerchantList,
  MerchantListResponse,
} from 'lib/generated/api'
import { Merchant, MerchantList } from 'types/entities'

export type MerchantListMapper = (response: ApiMerchantList) => MerchantList

export const mapToMerchantList: MerchantListMapper = response => {
  const {
    merchantListId,
    name,
    type,
    url,
    qrCodeUrl,
    merchants = [],
  } = response
  const mappedMerchants: Merchant[] = merchants.map(merchant => ({
    id: merchant.merchantId,
    name: merchant.name || '',
    address: merchant.address?.addressLine1 || '',
    inMerchantNetwork: merchant.inMerchantNetwork || false,
    position:
      merchant.index === undefined ? merchant.index : merchant.index + 1,
  }))
  return {
    id: merchantListId || '',
    name,
    type,
    url,
    qrCodeUrl,
    merchants: mappedMerchants,
  }
}

type MerchantListsMapper = (response: MerchantListResponse) => MerchantList[]
export const mapToMerchantLists: MerchantListsMapper = response =>
  response.results.map(mapToMerchantList)
