/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CurrencyCode,
    CurrencyCodeFromJSON,
    CurrencyCodeFromJSONTyped,
    CurrencyCodeToJSON,
} from './CurrencyCode';
import {
    IncentiveType,
    IncentiveTypeFromJSON,
    IncentiveTypeFromJSONTyped,
    IncentiveTypeToJSON,
} from './IncentiveType';

/**
 * 
 * @export
 * @interface IncentiveTemplate
 */
export interface IncentiveTemplate {
    /**
     * 
     * @type {IncentiveType}
     * @memberof IncentiveTemplate
     */
    type?: IncentiveType;
    /**
     * The inclusive daily starting time of the incentive (formatted according to ISO 8601). Omit this field for a one time incentive.
     * @type {string}
     * @memberof IncentiveTemplate
     */
    availableFrom?: string;
    /**
     * The inclusive daily ending time of the incentive (formatted according to ISO 8601). Omit this field for a one time incentive.
     * @type {string}
     * @memberof IncentiveTemplate
     */
    availableTo?: string;
    /**
     * The time zone of availableFrom and availableTo (formatted according to IANA time zone database)
     * @type {string}
     * @memberof IncentiveTemplate
     */
    timezone?: string;
    /**
     * The duration of the incentive from when it is issued (formatted according to ISO 8601)
     * @type {string}
     * @memberof IncentiveTemplate
     */
    duration?: string;
    /**
     * Starting date-time for one time incentives. Omit this field for a recurring incentive
     * @type {Date}
     * @memberof IncentiveTemplate
     */
    oneTimeStartDateTime?: Date;
    /**
     * Ending date-time for one time incentives. Omit this field for a recurring incentive
     * @type {Date}
     * @memberof IncentiveTemplate
     */
    oneTimeEndDateTime?: Date;
    /**
     * 
     * @type {CurrencyCode}
     * @memberof IncentiveTemplate
     */
    currencyCode?: CurrencyCode;
    /**
     * If incentive type is CREDIT, indicates the amount of currency in micro to issue as credit, otherwise, this is ignored
     * @type {number}
     * @memberof IncentiveTemplate
     */
    creditAmount?: number;
    /**
     * If incentive type is DISCOUNT_AMOUNT, indicates the amount of currency in micro to issue as discount, otherwise, this is ignored
     * @type {number}
     * @memberof IncentiveTemplate
     */
    discountAmount?: number;
    /**
     * If incentive type is DISCOUNT_PERCENTAGE, indicates the percentage of discount to issue, otherwise, this is ignored
     * @type {number}
     * @memberof IncentiveTemplate
     */
    discountPercentage?: number;
    /**
     * If incentive type is DISCOUNT_PERCENTAGE, indicates the maximum value in micro of a percentage discount, otherwise, this is ignored
     * @type {number}
     * @memberof IncentiveTemplate
     */
    discountValueMax?: number;
    /**
     * If incentive type is DISCOUNT_AMOUNT or DISCOUNT_PERCENTAGE, indicates the minimum order value in micro for discounts to apply, otherwise, this is ignored
     * @type {number}
     * @memberof IncentiveTemplate
     */
    orderValueMin?: number;
}

export function IncentiveTemplateFromJSON(json: any): IncentiveTemplate {
    return IncentiveTemplateFromJSONTyped(json, false);
}

export function IncentiveTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncentiveTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : IncentiveTypeFromJSON(json['type']),
        'availableFrom': !exists(json, 'availableFrom') ? undefined : json['availableFrom'],
        'availableTo': !exists(json, 'availableTo') ? undefined : json['availableTo'],
        'timezone': !exists(json, 'timezone') ? undefined : json['timezone'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'oneTimeStartDateTime': !exists(json, 'oneTimeStartDateTime') ? undefined : (new Date(json['oneTimeStartDateTime'])),
        'oneTimeEndDateTime': !exists(json, 'oneTimeEndDateTime') ? undefined : (new Date(json['oneTimeEndDateTime'])),
        'currencyCode': !exists(json, 'currencyCode') ? undefined : CurrencyCodeFromJSON(json['currencyCode']),
        'creditAmount': !exists(json, 'creditAmount') ? undefined : json['creditAmount'],
        'discountAmount': !exists(json, 'discountAmount') ? undefined : json['discountAmount'],
        'discountPercentage': !exists(json, 'discountPercentage') ? undefined : json['discountPercentage'],
        'discountValueMax': !exists(json, 'discountValueMax') ? undefined : json['discountValueMax'],
        'orderValueMin': !exists(json, 'orderValueMin') ? undefined : json['orderValueMin'],
    };
}

export function IncentiveTemplateToJSON(value?: IncentiveTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': IncentiveTypeToJSON(value.type),
        'availableFrom': value.availableFrom,
        'availableTo': value.availableTo,
        'timezone': value.timezone,
        'duration': value.duration,
        'oneTimeStartDateTime': value.oneTimeStartDateTime === undefined ? undefined : (value.oneTimeStartDateTime.toISOString()),
        'oneTimeEndDateTime': value.oneTimeEndDateTime === undefined ? undefined : (value.oneTimeEndDateTime.toISOString()),
        'currencyCode': CurrencyCodeToJSON(value.currencyCode),
        'creditAmount': value.creditAmount,
        'discountAmount': value.discountAmount,
        'discountPercentage': value.discountPercentage,
        'discountValueMax': value.discountValueMax,
        'orderValueMin': value.orderValueMin,
    };
}

