/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrganizationType,
    OrganizationTypeFromJSON,
    OrganizationTypeFromJSONTyped,
    OrganizationTypeToJSON,
} from './OrganizationType';
import {
    WorkspaceCampaignIncentiveType,
    WorkspaceCampaignIncentiveTypeFromJSON,
    WorkspaceCampaignIncentiveTypeFromJSONTyped,
    WorkspaceCampaignIncentiveTypeToJSON,
} from './WorkspaceCampaignIncentiveType';
import {
    WorkspaceType,
    WorkspaceTypeFromJSON,
    WorkspaceTypeFromJSONTyped,
    WorkspaceTypeToJSON,
} from './WorkspaceType';

/**
 * 
 * @export
 * @interface WorkspaceCampaign
 */
export interface WorkspaceCampaign {
    /**
     * The campaign's internal ID (unique)
     * @type {string}
     * @memberof WorkspaceCampaign
     */
    workspaceCampaignId: string;
    /**
     * An internal description of the campaign. May include meta-data
     * @type {string}
     * @memberof WorkspaceCampaign
     */
    description?: string;
    /**
     * The campaign's custom external ID (unique)
     * @type {string}
     * @memberof WorkspaceCampaign
     */
    externalCampaignId?: string;
    /**
     * Public message for the campaign
     * @type {string}
     * @memberof WorkspaceCampaign
     */
    campaignMessage?: string;
    /**
     * The URL to the member campaign signup flow
     * @type {string}
     * @memberof WorkspaceCampaign
     */
    campaignUrl: string;
    /**
     * List of email domains eligible for this campaign
     * @type {Array<string>}
     * @memberof WorkspaceCampaign
     */
    campaignDomainWhitelist?: Array<string>;
    /**
     * The name of the organization.
     * @type {string}
     * @memberof WorkspaceCampaign
     */
    organizationName: string;
    /**
     * 
     * @type {OrganizationType}
     * @memberof WorkspaceCampaign
     */
    organizationType: OrganizationType;
    /**
     * The name of the workspace.
     * @type {string}
     * @memberof WorkspaceCampaign
     */
    workspaceName: string;
    /**
     * 
     * @type {WorkspaceType}
     * @memberof WorkspaceCampaign
     */
    workspaceType: WorkspaceType;
    /**
     * 
     * @type {WorkspaceCampaignIncentiveType}
     * @memberof WorkspaceCampaign
     */
    incentiveType?: WorkspaceCampaignIncentiveType;
    /**
     * For INCENTIVE_PLAN incentives, the incentive plan defining the incentive to be given
     * @type {string}
     * @memberof WorkspaceCampaign
     */
    incentivePlanId?: string;
    /**
     * The workspace ID of the workspace that is granting the incentive.
     * @type {string}
     * @memberof WorkspaceCampaign
     */
    incentiveWorkspaceId?: string;
}

export function WorkspaceCampaignFromJSON(json: any): WorkspaceCampaign {
    return WorkspaceCampaignFromJSONTyped(json, false);
}

export function WorkspaceCampaignFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceCampaign {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'workspaceCampaignId': json['workspaceCampaignId'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'externalCampaignId': !exists(json, 'externalCampaignId') ? undefined : json['externalCampaignId'],
        'campaignMessage': !exists(json, 'campaignMessage') ? undefined : json['campaignMessage'],
        'campaignUrl': json['campaignUrl'],
        'campaignDomainWhitelist': !exists(json, 'campaignDomainWhitelist') ? undefined : json['campaignDomainWhitelist'],
        'organizationName': json['organizationName'],
        'organizationType': OrganizationTypeFromJSON(json['organizationType']),
        'workspaceName': json['workspaceName'],
        'workspaceType': WorkspaceTypeFromJSON(json['workspaceType']),
        'incentiveType': !exists(json, 'incentiveType') ? undefined : WorkspaceCampaignIncentiveTypeFromJSON(json['incentiveType']),
        'incentivePlanId': !exists(json, 'incentivePlanId') ? undefined : json['incentivePlanId'],
        'incentiveWorkspaceId': !exists(json, 'incentiveWorkspaceId') ? undefined : json['incentiveWorkspaceId'],
    };
}

export function WorkspaceCampaignToJSON(value?: WorkspaceCampaign | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'workspaceCampaignId': value.workspaceCampaignId,
        'description': value.description,
        'externalCampaignId': value.externalCampaignId,
        'campaignMessage': value.campaignMessage,
        'campaignUrl': value.campaignUrl,
        'campaignDomainWhitelist': value.campaignDomainWhitelist,
        'organizationName': value.organizationName,
        'organizationType': OrganizationTypeToJSON(value.organizationType),
        'workspaceName': value.workspaceName,
        'workspaceType': WorkspaceTypeToJSON(value.workspaceType),
        'incentiveType': WorkspaceCampaignIncentiveTypeToJSON(value.incentiveType),
        'incentivePlanId': value.incentivePlanId,
        'incentiveWorkspaceId': value.incentiveWorkspaceId,
    };
}

