/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkspaceConnectionCreateRequest
 */
export interface WorkspaceConnectionCreateRequest {
    /**
     * The workspace id of the workspace to want connected to workspace id
     * @type {string}
     * @memberof WorkspaceConnectionCreateRequest
     */
    connectedWorkspaceId: string;
    /**
     * The workspace id you want to connect to connectedWorkspaceId. This workspace will issue incentives to members for the connectedWorkspaceId.
     * @type {string}
     * @memberof WorkspaceConnectionCreateRequest
     */
    workspaceId: string;
}

export function WorkspaceConnectionCreateRequestFromJSON(json: any): WorkspaceConnectionCreateRequest {
    return WorkspaceConnectionCreateRequestFromJSONTyped(json, false);
}

export function WorkspaceConnectionCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceConnectionCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'connectedWorkspaceId': json['connectedWorkspaceId'],
        'workspaceId': json['workspaceId'],
    };
}

export function WorkspaceConnectionCreateRequestToJSON(value?: WorkspaceConnectionCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'connectedWorkspaceId': value.connectedWorkspaceId,
        'workspaceId': value.workspaceId,
    };
}

