/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AuthorizationType = {
    Invite: 'INVITE',
    InviteDomain: 'INVITE_DOMAIN',
    InviteDomainApi: 'INVITE_DOMAIN_API',
    InviteApi: 'INVITE_API',
    Domain: 'DOMAIN',
    DomainApi: 'DOMAIN_API',
    Api: 'API'
} as const;
export type AuthorizationType = typeof AuthorizationType[keyof typeof AuthorizationType];


export function AuthorizationTypeFromJSON(json: any): AuthorizationType {
    return AuthorizationTypeFromJSONTyped(json, false);
}

export function AuthorizationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthorizationType {
    return json as AuthorizationType;
}

export function AuthorizationTypeToJSON(value?: AuthorizationType | null): any {
    return value as any;
}

