/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The type of incentive users can receive for joining
 * @export
 */
export const WorkspaceCampaignIncentiveType = {
    None: 'NONE',
    IncentivePlan: 'INCENTIVE_PLAN',
    IncentiveWorkspace: 'INCENTIVE_WORKSPACE'
} as const;
export type WorkspaceCampaignIncentiveType = typeof WorkspaceCampaignIncentiveType[keyof typeof WorkspaceCampaignIncentiveType];


export function WorkspaceCampaignIncentiveTypeFromJSON(json: any): WorkspaceCampaignIncentiveType {
    return WorkspaceCampaignIncentiveTypeFromJSONTyped(json, false);
}

export function WorkspaceCampaignIncentiveTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceCampaignIncentiveType {
    return json as WorkspaceCampaignIncentiveType;
}

export function WorkspaceCampaignIncentiveTypeToJSON(value?: WorkspaceCampaignIncentiveType | null): any {
    return value as any;
}

