/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * HTTP request method
 * @export
 */
export const RequestMethod = {
    Get: 'GET',
    Head: 'HEAD',
    Post: 'POST',
    Put: 'PUT',
    Delete: 'DELETE',
    Connect: 'CONNECT',
    Options: 'OPTIONS',
    Trace: 'TRACE',
    Patch: 'PATCH'
} as const;
export type RequestMethod = typeof RequestMethod[keyof typeof RequestMethod];


export function RequestMethodFromJSON(json: any): RequestMethod {
    return RequestMethodFromJSONTyped(json, false);
}

export function RequestMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestMethod {
    return json as RequestMethod;
}

export function RequestMethodToJSON(value?: RequestMethod | null): any {
    return value as any;
}

