/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncentivePlanReadOnlyActions,
    IncentivePlanReadOnlyActionsFromJSON,
    IncentivePlanReadOnlyActionsFromJSONTyped,
    IncentivePlanReadOnlyActionsToJSON,
} from './IncentivePlanReadOnlyActions';
import {
    IncentivePlanStatus,
    IncentivePlanStatusFromJSON,
    IncentivePlanStatusFromJSONTyped,
    IncentivePlanStatusToJSON,
} from './IncentivePlanStatus';
import {
    ScheduleStatus,
    ScheduleStatusFromJSON,
    ScheduleStatusFromJSONTyped,
    ScheduleStatusToJSON,
} from './ScheduleStatus';

/**
 * 
 * @export
 * @interface IncentivePlanReadOnly
 */
export interface IncentivePlanReadOnly {
    /**
     * Unique identifier of the incentive plan
     * @type {string}
     * @memberof IncentivePlanReadOnly
     */
    id: string;
    /**
     * 
     * @type {IncentivePlanStatus}
     * @memberof IncentivePlanReadOnly
     */
    status: IncentivePlanStatus;
    /**
     * 
     * @type {ScheduleStatus}
     * @memberof IncentivePlanReadOnly
     */
    scheduleStatus?: ScheduleStatus;
    /**
     * Creation time of the incentive plan
     * @type {Date}
     * @memberof IncentivePlanReadOnly
     */
    createdAt: Date;
    /**
     * Last modified time of the incentive plan
     * @type {Date}
     * @memberof IncentivePlanReadOnly
     */
    updatedAt: Date;
    /**
     * 
     * @type {IncentivePlanReadOnlyActions}
     * @memberof IncentivePlanReadOnly
     */
    actions: IncentivePlanReadOnlyActions;
}

export function IncentivePlanReadOnlyFromJSON(json: any): IncentivePlanReadOnly {
    return IncentivePlanReadOnlyFromJSONTyped(json, false);
}

export function IncentivePlanReadOnlyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncentivePlanReadOnly {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'status': IncentivePlanStatusFromJSON(json['status']),
        'scheduleStatus': !exists(json, 'scheduleStatus') ? undefined : ScheduleStatusFromJSON(json['scheduleStatus']),
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'actions': IncentivePlanReadOnlyActionsFromJSON(json['actions']),
    };
}

export function IncentivePlanReadOnlyToJSON(value?: IncentivePlanReadOnly | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'status': IncentivePlanStatusToJSON(value.status),
        'scheduleStatus': ScheduleStatusToJSON(value.scheduleStatus),
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'actions': IncentivePlanReadOnlyActionsToJSON(value.actions),
    };
}

