import { Heading, Text } from '@ritualco/jutsu'
import { useContent } from 'lib/content/react'
import { LoginShell } from './LoginShell'
import { LoginProps } from './types'

export const LoginDefault = (loginProps: LoginProps) => {
  const { formatMessage } = useContent()

  return (
    <LoginShell {...loginProps}>
      <Heading fontSize="2xlarge" as="h1">
        {formatMessage({ id: 'pmportal.login.signInTitle' })}
      </Heading>
      <Text fontSize="small" color="grayscale.secondary" mt={5}>
        {formatMessage({ id: 'pmportal.login.signInDescription' })}
      </Text>
    </LoginShell>
  )
}
