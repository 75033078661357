import { startOfTomorrow, startOfDay } from 'date-fns'
import { utcToZonedTime, format } from 'date-fns-tz'
import { parse } from 'iso8601-duration'
import { RRule, Frequency as RRuleFrequency } from 'rrule'
import { mapToMerchantList } from 'api/mappers/merchantLists'
import {
  DateRestriction,
  IncentivePlanStatus as ApiIncentivePlanStatus,
  IncentiveTemplate,
  IncentiveType as ApiIncentiveType,
  MerchantEligibilityType,
  MerchantListGroup,
  Schedule,
  ScheduleStatus,
  WorkspaceConnectionListGroup,
  WorkspaceMemberListGroup,
} from 'lib/generated/api'
import {
  IncentivePlanSchedule,
  IncentivePlanStatus,
  IncentiveType,
  MerchantList,
  MerchantParticipationType,
  ScheduleFrequency,
  ScheduleOccurrence,
  ScheduleType,
} from 'types/entities'

export const TIME_FORMAT = 'HH:mm'

export const scheduleStatusMap: Record<ScheduleStatus, IncentivePlanStatus> = {
  UPCOMING: 'upcoming',
  ONGOING: 'live',
  ENDED: 'ended',
}

export const incentiveTypeMap: Partial<
  Record<ApiIncentiveType, IncentiveType>
> = {
  DISCOUNT_AMOUNT: 'discountAmount',
  DISCOUNT_PERCENTAGE: 'discountPercentage',
}

export const merchantEligibilityTypeMap: Record<
  MerchantEligibilityType,
  MerchantParticipationType
> = {
  ALL_MERCHANTS: 'all_merchants',
  WORKSPACE: 'workspace',
  MERCHANT_LISTS: 'merchant_list',
  GEO_RESTRICTIONS: 'geo_restrictions',
}

export const frequencyMap: Record<RRuleFrequency, ScheduleFrequency> = {
  [RRule.YEARLY]: 'yearly',
  [RRule.MONTHLY]: 'monthly',
  [RRule.WEEKLY]: 'weekly',
  [RRule.DAILY]: 'daily',
  [RRule.HOURLY]: 'daily',
  [RRule.MINUTELY]: 'daily',
  [RRule.SECONDLY]: 'daily',
}

export const scheduleTypeMap: Record<RRuleFrequency, ScheduleType> = {
  [RRule.YEARLY]: 'yearly',
  [RRule.MONTHLY]: 'monthly',
  [RRule.WEEKLY]: 'weekly',
  [RRule.DAILY]: 'daily',
  [RRule.HOURLY]: 'none',
  [RRule.MINUTELY]: 'none',
  [RRule.SECONDLY]: 'none',
}

export const occurrenceMap: Record<number, ScheduleOccurrence> = {
  1: 'first',
  2: 'second',
  3: 'third',
  4: 'fourth',
  [-1]: 'last',
}

export const dayMapper = (rruleDay: number): number => {
  if (rruleDay === 6) return 0
  return rruleDay + 1
}

export const computeCompanies = (
  group?: WorkspaceConnectionListGroup
): string[] => {
  if (!group || group.connectionLists.length < 1) return []
  return group.connectionLists[0].connections.map(
    connection => connection.connectionId
  )
}

export const computeMerchantList = (
  group?: MerchantListGroup
): MerchantList | undefined => {
  if (!group || group.merchantLists.length < 1) return undefined
  return mapToMerchantList(group.merchantLists[0])
}

export const computePeople = (group?: WorkspaceMemberListGroup): string[] => {
  if (!group || group.memberLists.length < 1) return []
  return group.memberLists[0].members.map(member => member.workspaceMemberId)
}

export const computeStatus = (
  status: ApiIncentivePlanStatus,
  scheduleStatus?: ScheduleStatus
): IncentivePlanStatus => {
  if (status === 'PAUSED') return 'paused'
  if (status === 'DRAFT') return 'draft'
  if (scheduleStatus) return scheduleStatusMap[scheduleStatus]
  // This should never happen
  return 'draft'
}

const parseString = (recurrenceRule?: string) => {
  if (!recurrenceRule) return undefined
  try {
    return RRule.parseString(recurrenceRule)
  } catch (e) {
    return undefined
  }
}

export const computeSchedule = (
  defaultTimezone: string,
  schedule?: Schedule,
  availableFrom?: string,
  availableTo?: string,
  duration?: string,
  oneTimeStartDateTime?: Date,
  oneTimeEndDateTime?: Date
): IncentivePlanSchedule | undefined => {
  if (!schedule) return undefined

  const defaultDate = startOfTomorrow()
  const values: IncentivePlanSchedule = {
    type: 'none',
    start: defaultDate,
    oneTimeEndDate: defaultDate,
    oneTimeStartTime: '09:00',
    oneTimeEndTime: '17:00',
    timezone: defaultTimezone,
    fromTime: availableFrom || '09:00',
    toTime: availableTo || '17:00',
    interval: 2,
    customFrequency: 'daily',
    days: [1, 2, 3, 4, 5], // Monday to Friday
    weekRange: 4,
    monthlyOccurrence: 'first',
    customOccurrence: 'sameMonthDay',
    ending: 'never',
    endingOn: defaultDate,
  }

  const { start, recurrenceRule } = schedule

  // Use the provided time zone if time zone is missing from the response
  const tz = schedule?.timezone || defaultTimezone
  values.timezone = tz

  if (start?.dateTime) {
    const startDate = utcToZonedTime(start.dateTime, tz)
    values.start = startDate
    values.endingOn = startDate
    values.oneTimeEndDate = startDate
  }

  const options = parseString(recurrenceRule)
  if (options) {
    const rule = new RRule(options)
    const { interval, byweekday, bynweekday, bymonthday, until } = rule.options

    if (options.freq === RRule.WEEKLY) {
      const parsedDuration = duration ? parse(duration) : undefined
      const durationDays = parsedDuration?.days
      values.weekRange = durationDays !== undefined ? durationDays : 4
    } else if (byweekday) {
      // otherwise, treat byweekday normally
      values.days = byweekday.map(dayMapper)
    }

    if (interval > 1) {
      values.type = 'custom'
      values.interval = interval
      if (options.freq !== undefined && options.freq !== null) {
        values.customFrequency = frequencyMap[options.freq]
      }
      if (options.freq === RRule.MONTHLY) {
        if (bymonthday) {
          values.customOccurrence = 'sameMonthDay'
        }
        if (bynweekday) {
          values.customOccurrence = 'sameWeekDay'
        }
      }
    } else {
      if (options.freq !== undefined && options.freq !== null) {
        values.type = scheduleTypeMap[options.freq]
      }
      if (bynweekday) {
        values.monthlyOccurrence = occurrenceMap[bynweekday[0][1]] || 'first'
      }
    }

    if (until) {
      values.ending = 'date'
      values.endingOn = utcToZonedTime(until, tz)
    }
  }

  // get start and end date and time for one time incentives
  if (oneTimeEndDateTime && oneTimeStartDateTime) {
    const startDateTime = utcToZonedTime(oneTimeStartDateTime, tz)
    const startDate = startOfDay(startDateTime) // Set to 00:00 of the start day
    const startTime = format(startDateTime, 'HH:mm', {
      timeZone: tz,
    })
    const endDateTime = utcToZonedTime(oneTimeEndDateTime, tz)
    const endDate = startOfDay(endDateTime) // Set to 00:00 of the ending day
    const endTime = format(endDateTime, 'HH:mm', {
      timeZone: tz,
    })
    values.start = startDate
    values.oneTimeStartTime = startTime

    values.oneTimeEndDate = endDate
    values.oneTimeEndTime = endTime
  }

  return values
}

export const parseIncentiveType = (
  incentiveTemplate?: IncentiveTemplate,
  scheduleType?: ScheduleType
): IncentiveType | undefined => {
  if (!incentiveTemplate?.type) return undefined
  const { type: incentiveType, oneTimeStartDateTime } = incentiveTemplate
  if (incentiveType === ApiIncentiveType.Credit) {
    if (!scheduleType || scheduleType === 'none') {
      if (oneTimeStartDateTime) {
        return 'creditOneTime'
      }
      // cloned credit incentives will have a credit type, but no schedule and no one time start date since dates are cleared
      return 'creditClone'
    }
    return 'creditRecurring'
  }
  return incentiveTypeMap[incentiveType]
}

/**
 * The dates returned by the API (i.e. "2022-01-20") are strings but are auto-converted to Date due to
 * typing. Since the strings have no timestamp, this incorrectly converts from UTC to 2022-01-19 local time.
 * To fix this we need to adjust the dates to match UTC.
 * @param dateRestrictions Original list of date restrictions.
 * @returns Adjusted list of date restrictions where all dates will be correctly represented.
 */
export const adjustZonedDateRestrictions = (
  dateRestrictions: DateRestriction[]
): DateRestriction[] =>
  dateRestrictions
    .map(item => ({
      ...item,
      startDate: utcToZonedTime(item.startDate, 'UTC'),
      endDate: utcToZonedTime(item.endDate, 'UTC'),
    }))
    // Ignore year and sort by ascending month-day
    .sort((a, b) =>
      format(a.startDate, 'MMdd') < format(b.startDate, 'MMdd') ? -1 : 1
    )
