import { useCallback, useEffect, useState } from 'react'
import { useAuth } from 'auth'
import { OrganizationType, WorkspaceType } from 'lib/generated/api'
import { Organization, Workspace } from 'types/entities'

const INTERNAL_DOMAIN = '@ritual.co'

export const removeInternalOrganizations = (organizations: Organization[]) =>
  organizations.filter(
    ({ type }) =>
      type &&
      [
        `${OrganizationType.Company}`,
        `${OrganizationType.PropertyManager}`,
      ].includes(type)
  )

export const removeInternalWorkspaces = (workspaces: Workspace[]) =>
  workspaces.filter(
    ({ type }) =>
      type &&
      [`${WorkspaceType.Building}`, `${WorkspaceType.Office}`].includes(type)
  )

/**
 * `useIsInternalUser` is a custom hook that asynchronously loads the user Profile
 * from the AuthContext and checks if the email is internal (i.e. @ritual.co).
 * It is *strongly* recommended to check `isInternalUserLoaded` before using the other fields.
 * `isInternalUser` is `boolean | undefined` so it's accurate only when it's a `boolean`.
 * `isInternalUserLoaded` is an optional flag to determine if the value in `isInternalUser`, `filterOrganizations`, and `filterWorkspaces are ready.
 * `filterOrganizations` can filter organizations based on a whitelist. They are not filtered for internal users.
 * `filterWorkspaces` can filter workspaces based on a whitelist. They are not filtered for internal users.
 * @returns `{ isInternalUser, isInternalUserLoaded, filterOrganizations, filterWorkspaces }`
 */
const useIsInternalUser = () => {
  const [isInternalUser, setIsInternalUser] = useState<boolean>()
  const [isInternalUserLoaded, setIsInternalUserLoaded] = useState(false)
  const { loadUserProfile } = useAuth()

  const filterOrganizations = useCallback(
    (organizations: Organization[]) =>
      isInternalUser
        ? organizations
        : removeInternalOrganizations(organizations),
    [isInternalUser]
  )
  const filterWorkspaces = useCallback(
    (workspaces: Workspace[]) =>
      isInternalUser ? workspaces : removeInternalWorkspaces(workspaces),
    [isInternalUser]
  )

  useEffect(() => {
    const fetchEmail = async () => {
      const { username } = await loadUserProfile()
      setIsInternalUser(username?.endsWith(INTERNAL_DOMAIN) || false)
      setIsInternalUserLoaded(true)
    }
    fetchEmail()
  }, [loadUserProfile])

  return {
    isInternalUser,
    isInternalUserLoaded,
    filterOrganizations,
    filterWorkspaces,
  }
}

export default useIsInternalUser
