import { PropsWithChildren, ReactNode } from 'react'
import { useEffectOnce } from 'react-use'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Container,
  Divider,
  DividerProps,
  Flex,
  Heading,
  Spacer,
  Text,
} from '@ritualco/jutsu'
import * as ReactIs from 'react-is'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { Link } from 'lib/router'
import { FormattedMessage } from 'lib/content/react'
import { useActiveRoutes } from 'routes'
import { useAnalytics } from 'lib/simple-analytics'
import { BetaTag } from 'components/BetaTag'

type PageWrapperProps = PropsWithChildren<{
  fullWidth?: boolean
  trackingName?: string
}>

const PageWrapper = ({
  fullWidth = false,
  children,
  trackingName = '',
}: PageWrapperProps) => {
  const { page } = useAnalytics()
  useEffectOnce(() => {
    const eventProperties: Record<any, string> = {}
    if (trackingName) {
      eventProperties.pageName = trackingName
    }
    page(eventProperties)
  })

  if (fullWidth) {
    return (
      <Box as="section" m={12}>
        {children}
      </Box>
    )
  }
  return (
    <Container as="section" maxW="784px" my={12} px={12}>
      {children}
    </Container>
  )
}

export type PageDividerProps = DividerProps

export const PageDivider = (props: PageDividerProps) => (
  <Divider color="grayscale.quinary" {...props} />
)

type PageHeaderProps = {
  /** The alert to show at the top of the page */
  alert?: ReactNode
  /** Content that comes before the heading, e.g. breadcrumbs */
  nav?: ReactNode
  /** Defaults to an h2 tag */
  heading: ReactNode
  /** Page actions adjacent to the heading, e.g. buttons */
  actions?: ReactNode
  /** Content that comes after the heading */
  subheading?: ReactNode
  showDivider?: boolean
  showBeta?: boolean
}

const BreadcrumbNav = () => {
  const routes = useActiveRoutes()
  const breadcrumbs = useBreadcrumbs(routes, {
    disableDefaults: true,
  })

  if (breadcrumbs.length <= 1) return null

  return (
    <Breadcrumb separator={<ChevronRightIcon />} py={4} mb={3}>
      {breadcrumbs.map(({ breadcrumb, key, location, match }) => {
        const isCurrentPage = location.pathname === match.url
        let breadcrumbText = ''

        if (ReactIs.isElement(breadcrumb)) {
          breadcrumbText = breadcrumb?.props?.children
        } else if (typeof breadcrumb === 'string') {
          breadcrumbText = breadcrumb
        }

        return (
          <BreadcrumbItem key={key} isCurrentPage={isCurrentPage}>
            {/* The `isCurrentPage` link normally won't render, but using the `as` prop
            breaks that logic so this removes the link conditionally */}
            {isCurrentPage ? (
              <BreadcrumbLink>
                <FormattedMessage id={breadcrumbText} />
              </BreadcrumbLink>
            ) : (
              <BreadcrumbLink as={Link} to={match.url}>
                <FormattedMessage id={breadcrumbText} />
              </BreadcrumbLink>
            )}
          </BreadcrumbItem>
        )
      })}
    </Breadcrumb>
  )
}

const PageHeader = ({
  alert = null,
  nav = <BreadcrumbNav />,
  heading,
  actions = null,
  subheading,
  showDivider = true,
  showBeta = false,
}: PageHeaderProps) => (
  <>
    {alert && <Box mb={6}>{alert}</Box>}
    {nav}
    <Flex align="center" mb={3} py={2}>
      {typeof heading === 'string' ? (
        <Heading fontSize="4xlarge" display="flex" alignItems="center">
          {heading}
          {showBeta && <BetaTag ml={3} bg="grayscale.primary" />}
        </Heading>
      ) : (
        heading
      )}
      <Spacer />
      {actions}
    </Flex>
    {subheading &&
      (typeof subheading === 'string' ? (
        <Text color="grayscale.secondary" fontSize="small" mb={5}>
          {subheading}
        </Text>
      ) : (
        <Box mb={5}>{subheading}</Box>
      ))}
    {showDivider && (
      <Box pb={6}>
        <PageDivider />
      </Box>
    )}
  </>
)

type PageProps = PropsWithChildren<PageHeaderProps> & {
  trackingName?: string
}

export type MaxWidthPageProps = PageProps

export const MaxWidthPage = ({
  children,
  trackingName,
  ...pageHeaderProps
}: MaxWidthPageProps) => (
  <PageWrapper trackingName={trackingName}>
    <PageHeader {...pageHeaderProps} />
    {children}
  </PageWrapper>
)

export type FullWidthPageProps = PageProps

export const FullWidthPage = ({
  children,
  trackingName,
  ...pageHeaderProps
}: FullWidthPageProps) => (
  <PageWrapper fullWidth trackingName={trackingName}>
    <PageHeader {...pageHeaderProps} />
    {children}
  </PageWrapper>
)
