import { Button, ButtonProps, IconButton } from '@ritualco/jutsu'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { useContent } from 'lib/content/react'

export type NavigationButtonNavType = 'next' | 'prev'
export type NavigationButtonProps = {
  navType: NavigationButtonNavType
} & ButtonProps

export const NavigationButton = ({
  navType,
  children,
  ...props
}: NavigationButtonProps) => {
  const { formatMessage } = useContent()

  const buttonProps = {
    size: 'sm',
    variant: 'outline',
    colorScheme: 'transparent',
    'aria-label':
      navType === 'next'
        ? formatMessage({ id: 'pmportal.global.pagination.nextPage' })
        : formatMessage({ id: 'pmportal.global.pagination.prevPage' }),
    ...props,
  }

  const Icon = navType === 'next' ? ChevronRightIcon : ChevronLeftIcon
  return children ? (
    <Button {...buttonProps}>{children}</Button>
  ) : (
    <IconButton {...buttonProps} icon={<Icon />} />
  )
}
