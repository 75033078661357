/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ConnectedApplicationType = {
    PropertyManager: 'PROPERTY_MANAGER',
    Ritual: 'RITUAL'
} as const;
export type ConnectedApplicationType = typeof ConnectedApplicationType[keyof typeof ConnectedApplicationType];


export function ConnectedApplicationTypeFromJSON(json: any): ConnectedApplicationType {
    return ConnectedApplicationTypeFromJSONTyped(json, false);
}

export function ConnectedApplicationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectedApplicationType {
    return json as ConnectedApplicationType;
}

export function ConnectedApplicationTypeToJSON(value?: ConnectedApplicationType | null): any {
    return value as any;
}

