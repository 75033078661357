/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WorkspaceCampaignIncentiveType,
    WorkspaceCampaignIncentiveTypeFromJSON,
    WorkspaceCampaignIncentiveTypeFromJSONTyped,
    WorkspaceCampaignIncentiveTypeToJSON,
} from './WorkspaceCampaignIncentiveType';

/**
 * 
 * @export
 * @interface WorkspaceCampaignRequest
 */
export interface WorkspaceCampaignRequest {
    /**
     * The campaign's custom external ID (unique)
     * @type {string}
     * @memberof WorkspaceCampaignRequest
     */
    externalCampaignId?: string;
    /**
     * Public message for the campaign
     * @type {string}
     * @memberof WorkspaceCampaignRequest
     */
    campaignMessage?: string;
    /**
     * List of email domains eligible for this campaign
     * @type {Array<string>}
     * @memberof WorkspaceCampaignRequest
     */
    campaignDomainWhitelist?: Array<string>;
    /**
     * The workspace ID of the workspace that is granting the incentive.
     * @type {string}
     * @memberof WorkspaceCampaignRequest
     */
    incentiveWorkspaceId?: string;
    /**
     * 
     * @type {WorkspaceCampaignIncentiveType}
     * @memberof WorkspaceCampaignRequest
     */
    incentiveType?: WorkspaceCampaignIncentiveType;
    /**
     * For INCENTIVE_PLAN incentives, the incentive plan defining the incentive to be given
     * @type {string}
     * @memberof WorkspaceCampaignRequest
     */
    incentivePlanId?: string;
}

export function WorkspaceCampaignRequestFromJSON(json: any): WorkspaceCampaignRequest {
    return WorkspaceCampaignRequestFromJSONTyped(json, false);
}

export function WorkspaceCampaignRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceCampaignRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'externalCampaignId': !exists(json, 'externalCampaignId') ? undefined : json['externalCampaignId'],
        'campaignMessage': !exists(json, 'campaignMessage') ? undefined : json['campaignMessage'],
        'campaignDomainWhitelist': !exists(json, 'campaignDomainWhitelist') ? undefined : json['campaignDomainWhitelist'],
        'incentiveWorkspaceId': !exists(json, 'incentiveWorkspaceId') ? undefined : json['incentiveWorkspaceId'],
        'incentiveType': !exists(json, 'incentiveType') ? undefined : WorkspaceCampaignIncentiveTypeFromJSON(json['incentiveType']),
        'incentivePlanId': !exists(json, 'incentivePlanId') ? undefined : json['incentivePlanId'],
    };
}

export function WorkspaceCampaignRequestToJSON(value?: WorkspaceCampaignRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'externalCampaignId': value.externalCampaignId,
        'campaignMessage': value.campaignMessage,
        'campaignDomainWhitelist': value.campaignDomainWhitelist,
        'incentiveWorkspaceId': value.incentiveWorkspaceId,
        'incentiveType': WorkspaceCampaignIncentiveTypeToJSON(value.incentiveType),
        'incentivePlanId': value.incentivePlanId,
    };
}

