import { Modal, ModalOverlay, ModalContent, ModalProps } from '@ritualco/jutsu'
import { PropsWithChildren } from 'react'

export const HeraModal = (props: PropsWithChildren<ModalProps>) => {
  const { children, ...modalProps } = props

  return (
    <Modal isCentered {...modalProps}>
      <ModalOverlay />
      <ModalContent ml="var(--chakra-space-sidebar-medium)">
        {children}
      </ModalContent>
    </Modal>
  )
}
