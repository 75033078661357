/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateRestriction,
    DateRestrictionFromJSON,
    DateRestrictionFromJSONTyped,
    DateRestrictionToJSON,
} from './DateRestriction';
import {
    IncentivePlanMerchantEligibility,
    IncentivePlanMerchantEligibilityFromJSON,
    IncentivePlanMerchantEligibilityFromJSONTyped,
    IncentivePlanMerchantEligibilityToJSON,
} from './IncentivePlanMerchantEligibility';
import {
    IncentivePlanUserTargeting,
    IncentivePlanUserTargetingFromJSON,
    IncentivePlanUserTargetingFromJSONTyped,
    IncentivePlanUserTargetingToJSON,
} from './IncentivePlanUserTargeting';
import {
    IncentiveTemplate,
    IncentiveTemplateFromJSON,
    IncentiveTemplateFromJSONTyped,
    IncentiveTemplateToJSON,
} from './IncentiveTemplate';
import {
    Notification,
    NotificationFromJSON,
    NotificationFromJSONTyped,
    NotificationToJSON,
} from './Notification';
import {
    Schedule,
    ScheduleFromJSON,
    ScheduleFromJSONTyped,
    ScheduleToJSON,
} from './Schedule';

/**
 * 
 * @export
 * @interface IncentivePlanConfiguration
 */
export interface IncentivePlanConfiguration {
    /**
     * Title of the incentive plan
     * @type {string}
     * @memberof IncentivePlanConfiguration
     */
    name?: string;
    /**
     * Description of the incentive plan
     * @type {string}
     * @memberof IncentivePlanConfiguration
     */
    description?: string;
    /**
     * 
     * @type {IncentiveTemplate}
     * @memberof IncentivePlanConfiguration
     */
    incentiveTemplate?: IncentiveTemplate;
    /**
     * 
     * @type {Schedule}
     * @memberof IncentivePlanConfiguration
     */
    schedule?: Schedule;
    /**
     * 
     * @type {IncentivePlanMerchantEligibility}
     * @memberof IncentivePlanConfiguration
     */
    merchantEligibility?: IncentivePlanMerchantEligibility;
    /**
     * 
     * @type {IncentivePlanUserTargeting}
     * @memberof IncentivePlanConfiguration
     */
    userTargeting?: IncentivePlanUserTargeting;
    /**
     * 
     * @type {Array<Notification>}
     * @memberof IncentivePlanConfiguration
     */
    notifications?: Array<Notification>;
    /**
     * 
     * @type {Array<DateRestriction>}
     * @memberof IncentivePlanConfiguration
     */
    dateRestrictions?: Array<DateRestriction>;
}

export function IncentivePlanConfigurationFromJSON(json: any): IncentivePlanConfiguration {
    return IncentivePlanConfigurationFromJSONTyped(json, false);
}

export function IncentivePlanConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncentivePlanConfiguration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'incentiveTemplate': !exists(json, 'incentiveTemplate') ? undefined : IncentiveTemplateFromJSON(json['incentiveTemplate']),
        'schedule': !exists(json, 'schedule') ? undefined : ScheduleFromJSON(json['schedule']),
        'merchantEligibility': !exists(json, 'merchantEligibility') ? undefined : IncentivePlanMerchantEligibilityFromJSON(json['merchantEligibility']),
        'userTargeting': !exists(json, 'userTargeting') ? undefined : IncentivePlanUserTargetingFromJSON(json['userTargeting']),
        'notifications': !exists(json, 'notifications') ? undefined : ((json['notifications'] as Array<any>).map(NotificationFromJSON)),
        'dateRestrictions': !exists(json, 'dateRestrictions') ? undefined : ((json['dateRestrictions'] as Array<any>).map(DateRestrictionFromJSON)),
    };
}

export function IncentivePlanConfigurationToJSON(value?: IncentivePlanConfiguration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'incentiveTemplate': IncentiveTemplateToJSON(value.incentiveTemplate),
        'schedule': ScheduleToJSON(value.schedule),
        'merchantEligibility': IncentivePlanMerchantEligibilityToJSON(value.merchantEligibility),
        'userTargeting': IncentivePlanUserTargetingToJSON(value.userTargeting),
        'notifications': value.notifications === undefined ? undefined : ((value.notifications as Array<any>).map(NotificationToJSON)),
        'dateRestrictions': value.dateRestrictions === undefined ? undefined : ((value.dateRestrictions as Array<any>).map(DateRestrictionToJSON)),
    };
}

