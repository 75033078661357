import React, { useState, useCallback } from 'react'
import { useToast, AlertStatus } from '@ritualco/jutsu'

export type MessageContextType = {
  message: Message | null
  addError: (e: Message) => void
  addMessage: (e: Message) => void
  removeMessage: () => void
}

export const MessageContext = React.createContext<MessageContextType>({
  message: null,
  addError: () => {},
  addMessage: () => {},
  removeMessage: () => {},
})

type Message = {
  title?: string
  id?: string | number
  description: string
  status?: AlertStatus
  error?: {
    status: number
    message: string
  }
  isClosable?: boolean
  duration?: number
  onCloseComplete?: () => void
}

export const MessageProvider: React.FC = ({ children }) => {
  const toast = useToast()
  const [message, setMessage] = useState<Message | null>(null)

  const removeMessage = (): void => setMessage(null)

  const addMessage = useCallback(
    (props: Message): void => {
      toast(props)
      setMessage(props)
    },
    [toast]
  )

  const contextValue = {
    message,
    addError: useCallback(
      (props: Message) =>
        addMessage({
          status: 'error',
          isClosable: true,
          duration: 4000,
          ...props,
        }),
      [addMessage]
    ),
    addMessage: useCallback(
      props =>
        addMessage({
          status: 'info',
          isClosable: true,
          duration: 4000,
          ...props,
        }),
      [addMessage]
    ),
    removeMessage: useCallback(() => removeMessage(), []),
  }

  return (
    <MessageContext.Provider value={contextValue}>
      {children}
    </MessageContext.Provider>
  )
}

export default MessageProvider

export const useMessage = () => React.useContext(MessageContext)
