/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmailTokenize
 */
export interface EmailTokenize {
    /**
     * the email address being tokenized
     * @type {string}
     * @memberof EmailTokenize
     */
    emailAddress: string;
    /**
     * **Exchange Token** Verifies that this client submitted the email for tokenization
     * @type {string}
     * @memberof EmailTokenize
     */
    exchangeToken: string;
    /**
     * the verification code sent to the user, submitted to verify ownership
     * @type {string}
     * @memberof EmailTokenize
     */
    verificationCode: string;
}

export function EmailTokenizeFromJSON(json: any): EmailTokenize {
    return EmailTokenizeFromJSONTyped(json, false);
}

export function EmailTokenizeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailTokenize {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emailAddress': json['emailAddress'],
        'exchangeToken': json['exchangeToken'],
        'verificationCode': json['verificationCode'],
    };
}

export function EmailTokenizeToJSON(value?: EmailTokenize | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'emailAddress': value.emailAddress,
        'exchangeToken': value.exchangeToken,
        'verificationCode': value.verificationCode,
    };
}

