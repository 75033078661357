/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Eligibility,
    EligibilityFromJSON,
    EligibilityFromJSONTyped,
    EligibilityToJSON,
} from './Eligibility';
import {
    EmailConfirmAction,
    EmailConfirmActionFromJSON,
    EmailConfirmActionFromJSONTyped,
    EmailConfirmActionToJSON,
} from './EmailConfirmAction';

/**
 * 
 * @export
 * @interface EmailConfirmResponse
 */
export interface EmailConfirmResponse {
    /**
     * 
     * @type {EmailConfirmAction}
     * @memberof EmailConfirmResponse
     */
    action?: EmailConfirmAction;
    /**
     * an ID associated with the type of action. i.e. for a MEMBER_CAMPAIGN, this should be the MEMBER_CAMPAIGN_ID
     * @type {string}
     * @memberof EmailConfirmResponse
     */
    actionId?: string;
    /**
     * 
     * @type {Eligibility}
     * @memberof EmailConfirmResponse
     */
    eligibility?: Eligibility;
    /**
     * The reason that a user is ineligible
     * @type {string}
     * @memberof EmailConfirmResponse
     */
    ineligibleReason?: EmailConfirmResponseIneligibleReasonEnum;
    /**
     * The name of the organization associated with this action
     * @type {string}
     * @memberof EmailConfirmResponse
     */
    ownerName?: string;
    /**
     * The ID of the organization associated with this action
     * @type {string}
     * @memberof EmailConfirmResponse
     */
    ownerId?: string;
    /**
     * The icon of the organization associated with this action
     * @type {string}
     * @memberof EmailConfirmResponse
     */
    ownerIconUrl?: string;
    /**
     * The name of the workspace associated with this action
     * @type {string}
     * @memberof EmailConfirmResponse
     */
    ownerLocationName?: string;
    /**
     * The ID of the workspace associated with this action
     * @type {string}
     * @memberof EmailConfirmResponse
     */
    ownerLocationid?: string;
}


/**
 * @export
 */
export const EmailConfirmResponseIneligibleReasonEnum = {
    AccountAlreadyRedeemed: 'ACCOUNT_ALREADY_REDEEMED',
    EmailAlreadyRedeemed: 'EMAIL_ALREADY_REDEEMED',
    EmailIneligible: 'EMAIL_INELIGIBLE',
    CampaignExpired: 'CAMPAIGN_EXPIRED',
    UnknownError: 'UNKNOWN_ERROR'
} as const;
export type EmailConfirmResponseIneligibleReasonEnum = typeof EmailConfirmResponseIneligibleReasonEnum[keyof typeof EmailConfirmResponseIneligibleReasonEnum];


export function EmailConfirmResponseFromJSON(json: any): EmailConfirmResponse {
    return EmailConfirmResponseFromJSONTyped(json, false);
}

export function EmailConfirmResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailConfirmResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action': !exists(json, 'action') ? undefined : EmailConfirmActionFromJSON(json['action']),
        'actionId': !exists(json, 'actionId') ? undefined : json['actionId'],
        'eligibility': !exists(json, 'eligibility') ? undefined : EligibilityFromJSON(json['eligibility']),
        'ineligibleReason': !exists(json, 'ineligibleReason') ? undefined : json['ineligibleReason'],
        'ownerName': !exists(json, 'ownerName') ? undefined : json['ownerName'],
        'ownerId': !exists(json, 'ownerId') ? undefined : json['ownerId'],
        'ownerIconUrl': !exists(json, 'ownerIconUrl') ? undefined : json['ownerIconUrl'],
        'ownerLocationName': !exists(json, 'ownerLocationName') ? undefined : json['ownerLocationName'],
        'ownerLocationid': !exists(json, 'ownerLocationid') ? undefined : json['ownerLocationid'],
    };
}

export function EmailConfirmResponseToJSON(value?: EmailConfirmResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action': EmailConfirmActionToJSON(value.action),
        'actionId': value.actionId,
        'eligibility': EligibilityToJSON(value.eligibility),
        'ineligibleReason': value.ineligibleReason,
        'ownerName': value.ownerName,
        'ownerId': value.ownerId,
        'ownerIconUrl': value.ownerIconUrl,
        'ownerLocationName': value.ownerLocationName,
        'ownerLocationid': value.ownerLocationid,
    };
}

