/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncentivePlanConfiguration,
    IncentivePlanConfigurationFromJSON,
    IncentivePlanConfigurationFromJSONTyped,
    IncentivePlanConfigurationToJSON,
} from './IncentivePlanConfiguration';
import {
    IncentivePlanReadOnly,
    IncentivePlanReadOnlyFromJSON,
    IncentivePlanReadOnlyFromJSONTyped,
    IncentivePlanReadOnlyToJSON,
} from './IncentivePlanReadOnly';

/**
 * 
 * @export
 * @interface IncentivePlan
 */
export interface IncentivePlan {
    /**
     * 
     * @type {IncentivePlanReadOnly}
     * @memberof IncentivePlan
     */
    metadata?: IncentivePlanReadOnly;
    /**
     * 
     * @type {IncentivePlanConfiguration}
     * @memberof IncentivePlan
     */
    draftConfiguration?: IncentivePlanConfiguration;
    /**
     * 
     * @type {IncentivePlanConfiguration}
     * @memberof IncentivePlan
     */
    launchedConfiguration?: IncentivePlanConfiguration;
    /**
     * The current version of the data
     * @type {number}
     * @memberof IncentivePlan
     */
    dataVersion?: number;
}

export function IncentivePlanFromJSON(json: any): IncentivePlan {
    return IncentivePlanFromJSONTyped(json, false);
}

export function IncentivePlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncentivePlan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': !exists(json, 'metadata') ? undefined : IncentivePlanReadOnlyFromJSON(json['metadata']),
        'draftConfiguration': !exists(json, 'draftConfiguration') ? undefined : IncentivePlanConfigurationFromJSON(json['draftConfiguration']),
        'launchedConfiguration': !exists(json, 'launchedConfiguration') ? undefined : IncentivePlanConfigurationFromJSON(json['launchedConfiguration']),
        'dataVersion': !exists(json, 'dataVersion') ? undefined : json['dataVersion'],
    };
}

export function IncentivePlanToJSON(value?: IncentivePlan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': IncentivePlanReadOnlyToJSON(value.metadata),
        'draftConfiguration': IncentivePlanConfigurationToJSON(value.draftConfiguration),
        'launchedConfiguration': IncentivePlanConfigurationToJSON(value.launchedConfiguration),
        'dataVersion': value.dataVersion,
    };
}

