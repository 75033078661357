/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EmailConfirmAction,
    EmailConfirmActionFromJSON,
    EmailConfirmActionFromJSONTyped,
    EmailConfirmActionToJSON,
} from './EmailConfirmAction';

/**
 * 
 * @export
 * @interface EmailSubmit
 */
export interface EmailSubmit {
    /**
     * the email address being tokenized
     * @type {string}
     * @memberof EmailSubmit
     */
    emailAddress: string;
    /**
     * 
     * @type {EmailConfirmAction}
     * @memberof EmailSubmit
     */
    action?: EmailConfirmAction;
    /**
     * an ID associated with the type of action. i.e. for a MEMBER_CAMPAIGN, this should be the MEMBER_CAMPAIGN_ID
     * @type {string}
     * @memberof EmailSubmit
     */
    actionId?: string;
}

export function EmailSubmitFromJSON(json: any): EmailSubmit {
    return EmailSubmitFromJSONTyped(json, false);
}

export function EmailSubmitFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailSubmit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emailAddress': json['emailAddress'],
        'action': !exists(json, 'action') ? undefined : EmailConfirmActionFromJSON(json['action']),
        'actionId': !exists(json, 'actionId') ? undefined : json['actionId'],
    };
}

export function EmailSubmitToJSON(value?: EmailSubmit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'emailAddress': value.emailAddress,
        'action': EmailConfirmActionToJSON(value.action),
        'actionId': value.actionId,
    };
}

