/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessRestriction,
    AccessRestrictionFromJSON,
    AccessRestrictionFromJSONTyped,
    AccessRestrictionToJSON,
} from './AccessRestriction';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import {
    AppAssociation,
    AppAssociationFromJSON,
    AppAssociationFromJSONTyped,
    AppAssociationToJSON,
} from './AppAssociation';

/**
 * 
 * @export
 * @interface CreateWorkspace
 */
export interface CreateWorkspace {
    /**
     * 
     * @type {Address}
     * @memberof CreateWorkspace
     */
    address?: Address;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkspace
     */
    name?: string;
    /**
     * The preferred locale used for communication, in BCP 47 format.
     * @type {string}
     * @memberof CreateWorkspace
     */
    locale?: string;
    /**
     * Google Place ID of the workspace address.
     * @type {string}
     * @memberof CreateWorkspace
     */
    googlePlaceId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkspace
     */
    timezone?: string;
    /**
     * The ritual userId of the user who should be set as the initial owner and admin of the new workspace. If absent, the owner will be attempted to be inferred from the JWT. When a request is made via service account, this field is mandatory.
     * @type {string}
     * @memberof CreateWorkspace
     */
    ownerUserId?: string;
    /**
     * 
     * @type {AccessRestriction}
     * @memberof CreateWorkspace
     */
    accessRestriction?: AccessRestriction;
    /**
     * 
     * @type {AppAssociation}
     * @memberof CreateWorkspace
     */
    appAssociation?: AppAssociation;
}

export function CreateWorkspaceFromJSON(json: any): CreateWorkspace {
    return CreateWorkspaceFromJSONTyped(json, false);
}

export function CreateWorkspaceFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateWorkspace {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'locale': !exists(json, 'locale') ? undefined : json['locale'],
        'googlePlaceId': json['googlePlaceId'],
        'timezone': !exists(json, 'timezone') ? undefined : json['timezone'],
        'ownerUserId': !exists(json, 'ownerUserId') ? undefined : json['ownerUserId'],
        'accessRestriction': !exists(json, 'accessRestriction') ? undefined : AccessRestrictionFromJSON(json['accessRestriction']),
        'appAssociation': !exists(json, 'appAssociation') ? undefined : AppAssociationFromJSON(json['appAssociation']),
    };
}

export function CreateWorkspaceToJSON(value?: CreateWorkspace | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': AddressToJSON(value.address),
        'name': value.name,
        'locale': value.locale,
        'googlePlaceId': value.googlePlaceId,
        'timezone': value.timezone,
        'ownerUserId': value.ownerUserId,
        'accessRestriction': AccessRestrictionToJSON(value.accessRestriction),
        'appAssociation': AppAssociationToJSON(value.appAssociation),
    };
}

