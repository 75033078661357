import { ReactNode } from 'react'
import { useField } from 'formik'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  TimeSelect,
  TimeSelectProps,
} from '@ritualco/jutsu'

export type FormikTimeSelectProps = {
  label?: ReactNode
  isRequired?: boolean
  name: string
} & TimeSelectProps

export const FormikTimeSelect = ({
  label,
  isRequired = false,
  ...props
}: FormikTimeSelectProps) => {
  const [field, { error, touched }, { setValue }] = useField(props.name)
  return (
    <FormControl
      id={props.name}
      isInvalid={!!error && touched}
      isRequired={isRequired}
    >
      {label && <FormLabel>{label}</FormLabel>}
      <TimeSelect
        size="lg"
        {...field}
        onChange={value => setValue(value)}
        {...props}
      />
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  )
}
