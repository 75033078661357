/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    MerchantList,
    MerchantListFromJSON,
    MerchantListToJSON,
    MerchantListEntry,
    MerchantListEntryFromJSON,
    MerchantListEntryToJSON,
    MerchantListResponse,
    MerchantListResponseFromJSON,
    MerchantListResponseToJSON,
    MerchantListType,
    MerchantListTypeFromJSON,
    MerchantListTypeToJSON,
} from '../models';

export interface AddMerchantToMerchantListRequest {
    organizationId: string;
    workspaceId: string;
    merchantListId: string;
    merchantListEntry?: MerchantListEntry;
}

export interface CreateMerchantListRequest {
    organizationId: string;
    workspaceId: string;
    merchantList: MerchantList;
}

export interface DeleteMerchantFromMerchantListRequest {
    organizationId: string;
    workspaceId: string;
    merchantListId: string;
    merchantId: string;
}

export interface DeleteMerchantListRequest {
    organizationId: string;
    workspaceId: string;
    merchantListId: string;
}

export interface GetMerchantListRequest {
    organizationId: string;
    workspaceId: string;
    merchantListId: string;
    excludeMerchants?: boolean;
}

export interface GetMerchantListsRequest {
    organizationId: string;
    workspaceId: string;
    merchantListId?: Array<string>;
    type?: Array<MerchantListType>;
    excludeType?: Array<MerchantListType>;
    excludeMerchants?: boolean;
    limit?: number;
    nextPageToken?: string;
    previousPageToken?: string;
    sortColumn?: string;
    sortDirection?: GetMerchantListsSortDirectionEnum;
}

export interface ReplaceMerchantListRequest {
    organizationId: string;
    workspaceId: string;
    merchantListId: string;
    merchantList: MerchantList;
}

export interface UpdateMerchantListRequest {
    organizationId: string;
    workspaceId: string;
    merchantListId: string;
    merchantList: MerchantList;
}

/**
 * 
 */
export class MerchantListApi extends runtime.BaseAPI {

    /**
     * Adds a merchant to the specified merchant list. If the location in the list is specified, the merchant will be inserted at that location. Otherwise, it will be added to the end of the list. If the merchant is already in the list, a location must be specified, otherwise an error will occur.
     */
    async addMerchantToMerchantListRaw(requestParameters: AddMerchantToMerchantListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MerchantList>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling addMerchantToMerchantList.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling addMerchantToMerchantList.');
        }

        if (requestParameters.merchantListId === null || requestParameters.merchantListId === undefined) {
            throw new runtime.RequiredError('merchantListId','Required parameter requestParameters.merchantListId was null or undefined when calling addMerchantToMerchantList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/merchant-lists/{merchantListId}/entries`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"merchantListId"}}`, encodeURIComponent(String(requestParameters.merchantListId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MerchantListEntryToJSON(requestParameters.merchantListEntry),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MerchantListFromJSON(jsonValue));
    }

    /**
     * Adds a merchant to the specified merchant list. If the location in the list is specified, the merchant will be inserted at that location. Otherwise, it will be added to the end of the list. If the merchant is already in the list, a location must be specified, otherwise an error will occur.
     */
    async addMerchantToMerchantList(requestParameters: AddMerchantToMerchantListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MerchantList> {
        const response = await this.addMerchantToMerchantListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a merchant list.
     */
    async createMerchantListRaw(requestParameters: CreateMerchantListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MerchantList>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling createMerchantList.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling createMerchantList.');
        }

        if (requestParameters.merchantList === null || requestParameters.merchantList === undefined) {
            throw new runtime.RequiredError('merchantList','Required parameter requestParameters.merchantList was null or undefined when calling createMerchantList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/merchant-lists`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MerchantListToJSON(requestParameters.merchantList),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MerchantListFromJSON(jsonValue));
    }

    /**
     * Creates a merchant list.
     */
    async createMerchantList(requestParameters: CreateMerchantListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MerchantList> {
        const response = await this.createMerchantListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the specified merchant from the merchant list.
     */
    async deleteMerchantFromMerchantListRaw(requestParameters: DeleteMerchantFromMerchantListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling deleteMerchantFromMerchantList.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling deleteMerchantFromMerchantList.');
        }

        if (requestParameters.merchantListId === null || requestParameters.merchantListId === undefined) {
            throw new runtime.RequiredError('merchantListId','Required parameter requestParameters.merchantListId was null or undefined when calling deleteMerchantFromMerchantList.');
        }

        if (requestParameters.merchantId === null || requestParameters.merchantId === undefined) {
            throw new runtime.RequiredError('merchantId','Required parameter requestParameters.merchantId was null or undefined when calling deleteMerchantFromMerchantList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/merchant-lists/{merchantListId}/entries/{merchantId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"merchantListId"}}`, encodeURIComponent(String(requestParameters.merchantListId))).replace(`{${"merchantId"}}`, encodeURIComponent(String(requestParameters.merchantId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the specified merchant from the merchant list.
     */
    async deleteMerchantFromMerchantList(requestParameters: DeleteMerchantFromMerchantListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.deleteMerchantFromMerchantListRaw(requestParameters, initOverrides);
    }

    /**
     * Delete the specified merchant list.
     */
    async deleteMerchantListRaw(requestParameters: DeleteMerchantListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling deleteMerchantList.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling deleteMerchantList.');
        }

        if (requestParameters.merchantListId === null || requestParameters.merchantListId === undefined) {
            throw new runtime.RequiredError('merchantListId','Required parameter requestParameters.merchantListId was null or undefined when calling deleteMerchantList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/merchant-lists/{merchantListId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"merchantListId"}}`, encodeURIComponent(String(requestParameters.merchantListId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the specified merchant list.
     */
    async deleteMerchantList(requestParameters: DeleteMerchantListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.deleteMerchantListRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieves the details of an existing merchant list.
     */
    async getMerchantListRaw(requestParameters: GetMerchantListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MerchantList>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getMerchantList.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling getMerchantList.');
        }

        if (requestParameters.merchantListId === null || requestParameters.merchantListId === undefined) {
            throw new runtime.RequiredError('merchantListId','Required parameter requestParameters.merchantListId was null or undefined when calling getMerchantList.');
        }

        const queryParameters: any = {};

        if (requestParameters.excludeMerchants !== undefined) {
            queryParameters['excludeMerchants'] = requestParameters.excludeMerchants;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/merchant-lists/{merchantListId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"merchantListId"}}`, encodeURIComponent(String(requestParameters.merchantListId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MerchantListFromJSON(jsonValue));
    }

    /**
     * Retrieves the details of an existing merchant list.
     */
    async getMerchantList(requestParameters: GetMerchantListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MerchantList> {
        const response = await this.getMerchantListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of merchant lists.
     */
    async getMerchantListsRaw(requestParameters: GetMerchantListsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MerchantListResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getMerchantLists.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling getMerchantLists.');
        }

        const queryParameters: any = {};

        if (requestParameters.merchantListId) {
            queryParameters['merchantListId'] = requestParameters.merchantListId;
        }

        if (requestParameters.type) {
            queryParameters['type'] = requestParameters.type.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.excludeType) {
            queryParameters['excludeType'] = requestParameters.excludeType.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.excludeMerchants !== undefined) {
            queryParameters['excludeMerchants'] = requestParameters.excludeMerchants;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.nextPageToken !== undefined) {
            queryParameters['nextPageToken'] = requestParameters.nextPageToken;
        }

        if (requestParameters.previousPageToken !== undefined) {
            queryParameters['previousPageToken'] = requestParameters.previousPageToken;
        }

        if (requestParameters.sortColumn !== undefined) {
            queryParameters['sortColumn'] = requestParameters.sortColumn;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/merchant-lists`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MerchantListResponseFromJSON(jsonValue));
    }

    /**
     * Returns a list of merchant lists.
     */
    async getMerchantLists(requestParameters: GetMerchantListsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MerchantListResponse> {
        const response = await this.getMerchantListsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the specific merchant list.
     */
    async replaceMerchantListRaw(requestParameters: ReplaceMerchantListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MerchantList>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling replaceMerchantList.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling replaceMerchantList.');
        }

        if (requestParameters.merchantListId === null || requestParameters.merchantListId === undefined) {
            throw new runtime.RequiredError('merchantListId','Required parameter requestParameters.merchantListId was null or undefined when calling replaceMerchantList.');
        }

        if (requestParameters.merchantList === null || requestParameters.merchantList === undefined) {
            throw new runtime.RequiredError('merchantList','Required parameter requestParameters.merchantList was null or undefined when calling replaceMerchantList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/merchant-lists/{merchantListId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"merchantListId"}}`, encodeURIComponent(String(requestParameters.merchantListId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MerchantListToJSON(requestParameters.merchantList),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MerchantListFromJSON(jsonValue));
    }

    /**
     * Updates the specific merchant list.
     */
    async replaceMerchantList(requestParameters: ReplaceMerchantListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MerchantList> {
        const response = await this.replaceMerchantListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the specific merchant list by setting the values of the parameters provided. Any parameters not provided will be left unchanged.
     */
    async updateMerchantListRaw(requestParameters: UpdateMerchantListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MerchantList>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling updateMerchantList.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling updateMerchantList.');
        }

        if (requestParameters.merchantListId === null || requestParameters.merchantListId === undefined) {
            throw new runtime.RequiredError('merchantListId','Required parameter requestParameters.merchantListId was null or undefined when calling updateMerchantList.');
        }

        if (requestParameters.merchantList === null || requestParameters.merchantList === undefined) {
            throw new runtime.RequiredError('merchantList','Required parameter requestParameters.merchantList was null or undefined when calling updateMerchantList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/merchant-lists/{merchantListId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"merchantListId"}}`, encodeURIComponent(String(requestParameters.merchantListId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: MerchantListToJSON(requestParameters.merchantList),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MerchantListFromJSON(jsonValue));
    }

    /**
     * Updates the specific merchant list by setting the values of the parameters provided. Any parameters not provided will be left unchanged.
     */
    async updateMerchantList(requestParameters: UpdateMerchantListRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MerchantList> {
        const response = await this.updateMerchantListRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetMerchantListsSortDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type GetMerchantListsSortDirectionEnum = typeof GetMerchantListsSortDirectionEnum[keyof typeof GetMerchantListsSortDirectionEnum];
