import { Organization as OrganizationModal } from 'lib/generated/api'
import { Organization } from 'types/entities'

export const mapToOrganization = (x: OrganizationModal) => ({
  id: x.organizationId,
  name: x.name,
  iconUrl: x.iconUrl,
  timezone: x.timezone,
  locale: x.locale,
  ownerId: x.ownerId,
  type: x.organizationType,
})

export type OrganizationMapper = (
  response: OrganizationModal[]
) => Organization[]
export const mapToOrganizationList: OrganizationMapper = response =>
  response.map(mapToOrganization)
