/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WorkspaceMemberStatus,
    WorkspaceMemberStatusFromJSON,
    WorkspaceMemberStatusFromJSONTyped,
    WorkspaceMemberStatusToJSON,
} from './WorkspaceMemberStatus';

/**
 * 
 * @export
 * @interface WorkspaceMember
 */
export interface WorkspaceMember {
    /**
     * A workspace member unique identifier.
     * @type {string}
     * @memberof WorkspaceMember
     */
    workspaceMemberId: string;
    /**
     * The unique identifier the workspace member belongs to
     * @type {string}
     * @memberof WorkspaceMember
     */
    workspaceId?: string;
    /**
     * The time at which the workspace member was initially invited.
     * @type {Date}
     * @memberof WorkspaceMember
     */
    createdAt: Date;
    /**
     * The email address of the workspace member.
     * @type {string}
     * @memberof WorkspaceMember
     */
    email?: string;
    /**
     * The full name of the workspace member
     * @type {string}
     * @memberof WorkspaceMember
     */
    fullName?: string;
    /**
     * 
     * @type {WorkspaceMemberStatus}
     * @memberof WorkspaceMember
     */
    status: WorkspaceMemberStatus;
    /**
     * The timestamp that the audit event record was created.
     * @type {Date}
     * @memberof WorkspaceMember
     */
    lastUpdatedInviteStatus?: Date;
    /**
     * The department code of the workspace member
     * @type {string}
     * @memberof WorkspaceMember
     */
    departmentCode?: string;
}

export function WorkspaceMemberFromJSON(json: any): WorkspaceMember {
    return WorkspaceMemberFromJSONTyped(json, false);
}

export function WorkspaceMemberFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceMember {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'workspaceMemberId': json['workspaceMemberId'],
        'workspaceId': !exists(json, 'workspaceId') ? undefined : json['workspaceId'],
        'createdAt': (new Date(json['createdAt'])),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'status': WorkspaceMemberStatusFromJSON(json['status']),
        'lastUpdatedInviteStatus': !exists(json, 'lastUpdatedInviteStatus') ? undefined : (new Date(json['lastUpdatedInviteStatus'])),
        'departmentCode': !exists(json, 'departmentCode') ? undefined : json['departmentCode'],
    };
}

export function WorkspaceMemberToJSON(value?: WorkspaceMember | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'workspaceMemberId': value.workspaceMemberId,
        'workspaceId': value.workspaceId,
        'createdAt': (value.createdAt.toISOString()),
        'email': value.email,
        'fullName': value.fullName,
        'status': WorkspaceMemberStatusToJSON(value.status),
        'lastUpdatedInviteStatus': value.lastUpdatedInviteStatus === undefined ? undefined : (value.lastUpdatedInviteStatus.toISOString()),
        'departmentCode': value.departmentCode,
    };
}

