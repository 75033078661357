/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrganizationType,
    OrganizationTypeFromJSON,
    OrganizationTypeFromJSONTyped,
    OrganizationTypeToJSON,
} from './OrganizationType';

/**
 * 
 * @export
 * @interface WorkspaceConnectionInviteInfo
 */
export interface WorkspaceConnectionInviteInfo {
    /**
     * The unique identifier for the connection.
     * @type {string}
     * @memberof WorkspaceConnectionInviteInfo
     */
    connectionId: string;
    /**
     * The new status for the ticket.
     * @type {string}
     * @memberof WorkspaceConnectionInviteInfo
     */
    status: WorkspaceConnectionInviteInfoStatusEnum;
    /**
     * The name of the inviting organization
     * @type {string}
     * @memberof WorkspaceConnectionInviteInfo
     */
    inviterOrganizationName: string;
    /**
     * 
     * @type {OrganizationType}
     * @memberof WorkspaceConnectionInviteInfo
     */
    inviterOrganizationType: OrganizationType;
    /**
     * The name of the inviting workspace
     * @type {string}
     * @memberof WorkspaceConnectionInviteInfo
     */
    inviterWorkspaceName: string;
    /**
     * The icon of the inviting organization. A square 128px x 128px
     * @type {string}
     * @memberof WorkspaceConnectionInviteInfo
     */
    inviterIcon?: string;
    /**
     * A prompt for the invitee, to encourage them to accept the invitation
     * @type {string}
     * @memberof WorkspaceConnectionInviteInfo
     */
    inviteText?: string;
    /**
     * The last modified time of the workspace connection.
     * @type {Date}
     * @memberof WorkspaceConnectionInviteInfo
     */
    updatedAt?: Date;
}


/**
 * @export
 */
export const WorkspaceConnectionInviteInfoStatusEnum = {
    Pending: 'PENDING',
    Approved: 'APPROVED',
    Expired: 'EXPIRED',
    Revoked: 'REVOKED',
    Rejected: 'REJECTED'
} as const;
export type WorkspaceConnectionInviteInfoStatusEnum = typeof WorkspaceConnectionInviteInfoStatusEnum[keyof typeof WorkspaceConnectionInviteInfoStatusEnum];


export function WorkspaceConnectionInviteInfoFromJSON(json: any): WorkspaceConnectionInviteInfo {
    return WorkspaceConnectionInviteInfoFromJSONTyped(json, false);
}

export function WorkspaceConnectionInviteInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceConnectionInviteInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'connectionId': json['connectionId'],
        'status': json['status'],
        'inviterOrganizationName': json['inviterOrganizationName'],
        'inviterOrganizationType': OrganizationTypeFromJSON(json['inviterOrganizationType']),
        'inviterWorkspaceName': json['inviterWorkspaceName'],
        'inviterIcon': !exists(json, 'inviterIcon') ? undefined : json['inviterIcon'],
        'inviteText': !exists(json, 'inviteText') ? undefined : json['inviteText'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
    };
}

export function WorkspaceConnectionInviteInfoToJSON(value?: WorkspaceConnectionInviteInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'connectionId': value.connectionId,
        'status': value.status,
        'inviterOrganizationName': value.inviterOrganizationName,
        'inviterOrganizationType': OrganizationTypeToJSON(value.inviterOrganizationType),
        'inviterWorkspaceName': value.inviterWorkspaceName,
        'inviterIcon': value.inviterIcon,
        'inviteText': value.inviteText,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
    };
}

