/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PortalAdminStatus = {
    Active: 'ACTIVE',
    Invited: 'INVITED',
    Rejected: 'REJECTED',
    Revoked: 'REVOKED',
    Expired: 'EXPIRED'
} as const;
export type PortalAdminStatus = typeof PortalAdminStatus[keyof typeof PortalAdminStatus];


export function PortalAdminStatusFromJSON(json: any): PortalAdminStatus {
    return PortalAdminStatusFromJSONTyped(json, false);
}

export function PortalAdminStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortalAdminStatus {
    return json as PortalAdminStatus;
}

export function PortalAdminStatusToJSON(value?: PortalAdminStatus | null): any {
    return value as any;
}

