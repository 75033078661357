/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    OrganizationAdminInvitationInfo,
    OrganizationAdminInvitationInfoFromJSON,
    OrganizationAdminInvitationInfoToJSON,
    OrganizationAdminsResendResponse,
    OrganizationAdminsResendResponseFromJSON,
    OrganizationAdminsResendResponseToJSON,
    PortalAdmin,
    PortalAdminFromJSON,
    PortalAdminToJSON,
    PortalAdminInviteResponse,
    PortalAdminInviteResponseFromJSON,
    PortalAdminInviteResponseToJSON,
    PortalAdminResponse,
    PortalAdminResponseFromJSON,
    PortalAdminResponseToJSON,
    WorkspaceAdminInvitationInfo,
    WorkspaceAdminInvitationInfoFromJSON,
    WorkspaceAdminInvitationInfoToJSON,
    WorkspaceAdminsResendResponse,
    WorkspaceAdminsResendResponseFromJSON,
    WorkspaceAdminsResendResponseToJSON,
} from '../models';

export interface CreateOrganizationAdminRequest {
    organizationId: string;
    portalAdmin: Array<PortalAdmin>;
}

export interface CreateWorkspaceAdminRequest {
    organizationId: string;
    workspaceId: string;
    portalAdmin: Array<PortalAdmin>;
}

export interface DeleteOrganizationAdminRequest {
    organizationId: string;
    portalAdminId: string;
}

export interface DeleteWorkspaceAdminRequest {
    organizationId: string;
    workspaceId: string;
    portalAdminId: string;
}

export interface GetAllWorkspaceAdminsRequest {
    organizationId: string;
    externalUserIds?: Array<string>;
    query?: string;
    limit?: number;
    nextPageToken?: string;
    previousPageToken?: string;
    sortColumn?: string;
    sortDirection?: GetAllWorkspaceAdminsSortDirectionEnum;
}

export interface GetOrganizationAdminRequest {
    organizationId: string;
    portalAdminId: string;
}

export interface GetOrganizationAdminInviteDetailsRequest {
    invitationId: string;
}

export interface GetOrganizationAdminsRequest {
    organizationId: string;
    portalAdminIds?: Array<string>;
    externalUserIds?: Array<string>;
    query?: string;
    limit?: number;
    nextPageToken?: string;
    previousPageToken?: string;
    sortColumn?: string;
    sortDirection?: GetOrganizationAdminsSortDirectionEnum;
}

export interface GetWorkspaceAdminRequest {
    organizationId: string;
    workspaceId: string;
    portalAdminId: string;
}

export interface GetWorkspaceAdminInviteDetailsRequest {
    invitationId: string;
}

export interface GetWorkspaceAdminsRequest {
    organizationId: string;
    workspaceId: string;
    portalAdminIds?: Array<string>;
    externalUserIds?: Array<string>;
    query?: string;
    limit?: number;
    nextPageToken?: string;
    previousPageToken?: string;
    sortColumn?: string;
    sortDirection?: GetWorkspaceAdminsSortDirectionEnum;
}

export interface ResendInvitationToOrganizationAdminRequest {
    organizationId: string;
    portalAdminId: string;
}

export interface ResendInvitationToOrganizationAdminsRequest {
    organizationId: string;
    portalAdminId: string;
}

export interface ResendInvitationToWorkspaceAdminsRequest {
    organizationId: string;
    workspaceId: string;
}

export interface ResendInvitationWorkspaceAdminRequest {
    organizationId: string;
    workspaceId: string;
    portalAdminId: string;
}

export interface UpdateOrganizationAdminRequest {
    organizationId: string;
    portalAdminId: string;
    portalAdmin: PortalAdmin;
}

export interface UpdateWorkspaceAdminRequest {
    organizationId: string;
    workspaceId: string;
    portalAdminId: string;
    portalAdmin: PortalAdmin;
}

/**
 * 
 */
export class PortalAdminApi extends runtime.BaseAPI {

    /**
     * Creates one or more new organization admins. An invite will be sent to the specified email(s).
     */
    async createOrganizationAdminRaw(requestParameters: CreateOrganizationAdminRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PortalAdminInviteResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling createOrganizationAdmin.');
        }

        if (requestParameters.portalAdmin === null || requestParameters.portalAdmin === undefined) {
            throw new runtime.RequiredError('portalAdmin','Required parameter requestParameters.portalAdmin was null or undefined when calling createOrganizationAdmin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/admins`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.portalAdmin.map(PortalAdminToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PortalAdminInviteResponseFromJSON(jsonValue));
    }

    /**
     * Creates one or more new organization admins. An invite will be sent to the specified email(s).
     */
    async createOrganizationAdmin(requestParameters: CreateOrganizationAdminRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PortalAdminInviteResponse> {
        const response = await this.createOrganizationAdminRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates one or more new portal admins. An invite will be sent to the specified email(s).
     */
    async createWorkspaceAdminRaw(requestParameters: CreateWorkspaceAdminRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PortalAdminInviteResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling createWorkspaceAdmin.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling createWorkspaceAdmin.');
        }

        if (requestParameters.portalAdmin === null || requestParameters.portalAdmin === undefined) {
            throw new runtime.RequiredError('portalAdmin','Required parameter requestParameters.portalAdmin was null or undefined when calling createWorkspaceAdmin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/admins`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.portalAdmin.map(PortalAdminToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PortalAdminInviteResponseFromJSON(jsonValue));
    }

    /**
     * Creates one or more new portal admins. An invite will be sent to the specified email(s).
     */
    async createWorkspaceAdmin(requestParameters: CreateWorkspaceAdminRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PortalAdminInviteResponse> {
        const response = await this.createWorkspaceAdminRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the specified portal administrator.
     */
    async deleteOrganizationAdminRaw(requestParameters: DeleteOrganizationAdminRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling deleteOrganizationAdmin.');
        }

        if (requestParameters.portalAdminId === null || requestParameters.portalAdminId === undefined) {
            throw new runtime.RequiredError('portalAdminId','Required parameter requestParameters.portalAdminId was null or undefined when calling deleteOrganizationAdmin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/admins/{portalAdminId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"portalAdminId"}}`, encodeURIComponent(String(requestParameters.portalAdminId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the specified portal administrator.
     */
    async deleteOrganizationAdmin(requestParameters: DeleteOrganizationAdminRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.deleteOrganizationAdminRaw(requestParameters, initOverrides);
    }

    /**
     * Delete the specified portal administrator.
     */
    async deleteWorkspaceAdminRaw(requestParameters: DeleteWorkspaceAdminRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling deleteWorkspaceAdmin.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling deleteWorkspaceAdmin.');
        }

        if (requestParameters.portalAdminId === null || requestParameters.portalAdminId === undefined) {
            throw new runtime.RequiredError('portalAdminId','Required parameter requestParameters.portalAdminId was null or undefined when calling deleteWorkspaceAdmin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/admins/{portalAdminId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"portalAdminId"}}`, encodeURIComponent(String(requestParameters.portalAdminId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the specified portal administrator.
     */
    async deleteWorkspaceAdmin(requestParameters: DeleteWorkspaceAdminRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.deleteWorkspaceAdminRaw(requestParameters, initOverrides);
    }

    /**
     * Returns a list of all portal workspace admins for the given organization.
     */
    async getAllWorkspaceAdminsRaw(requestParameters: GetAllWorkspaceAdminsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PortalAdminResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getAllWorkspaceAdmins.');
        }

        const queryParameters: any = {};

        if (requestParameters.externalUserIds) {
            queryParameters['externalUserIds'] = requestParameters.externalUserIds;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.nextPageToken !== undefined) {
            queryParameters['nextPageToken'] = requestParameters.nextPageToken;
        }

        if (requestParameters.previousPageToken !== undefined) {
            queryParameters['previousPageToken'] = requestParameters.previousPageToken;
        }

        if (requestParameters.sortColumn !== undefined) {
            queryParameters['sortColumn'] = requestParameters.sortColumn;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/admins`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PortalAdminResponseFromJSON(jsonValue));
    }

    /**
     * Returns a list of all portal workspace admins for the given organization.
     */
    async getAllWorkspaceAdmins(requestParameters: GetAllWorkspaceAdminsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PortalAdminResponse> {
        const response = await this.getAllWorkspaceAdminsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves the details of an existing portal administrator.
     */
    async getOrganizationAdminRaw(requestParameters: GetOrganizationAdminRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PortalAdmin>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getOrganizationAdmin.');
        }

        if (requestParameters.portalAdminId === null || requestParameters.portalAdminId === undefined) {
            throw new runtime.RequiredError('portalAdminId','Required parameter requestParameters.portalAdminId was null or undefined when calling getOrganizationAdmin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/admins/{portalAdminId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"portalAdminId"}}`, encodeURIComponent(String(requestParameters.portalAdminId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PortalAdminFromJSON(jsonValue));
    }

    /**
     * Retrieves the details of an existing portal administrator.
     */
    async getOrganizationAdmin(requestParameters: GetOrganizationAdminRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PortalAdmin> {
        const response = await this.getOrganizationAdminRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the public details of an organization administrator invitation.
     */
    async getOrganizationAdminInviteDetailsRaw(requestParameters: GetOrganizationAdminInviteDetailsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrganizationAdminInvitationInfo>> {
        if (requestParameters.invitationId === null || requestParameters.invitationId === undefined) {
            throw new runtime.RequiredError('invitationId','Required parameter requestParameters.invitationId was null or undefined when calling getOrganizationAdminInviteDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invitations/organization-admins/{invitationId}`.replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters.invitationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationAdminInvitationInfoFromJSON(jsonValue));
    }

    /**
     * Get the public details of an organization administrator invitation.
     */
    async getOrganizationAdminInviteDetails(requestParameters: GetOrganizationAdminInviteDetailsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrganizationAdminInvitationInfo> {
        const response = await this.getOrganizationAdminInviteDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of the portal admins for the given organization
     */
    async getOrganizationAdminsRaw(requestParameters: GetOrganizationAdminsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PortalAdminResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getOrganizationAdmins.');
        }

        const queryParameters: any = {};

        if (requestParameters.portalAdminIds) {
            queryParameters['portalAdminIds'] = requestParameters.portalAdminIds;
        }

        if (requestParameters.externalUserIds) {
            queryParameters['externalUserIds'] = requestParameters.externalUserIds;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.nextPageToken !== undefined) {
            queryParameters['nextPageToken'] = requestParameters.nextPageToken;
        }

        if (requestParameters.previousPageToken !== undefined) {
            queryParameters['previousPageToken'] = requestParameters.previousPageToken;
        }

        if (requestParameters.sortColumn !== undefined) {
            queryParameters['sortColumn'] = requestParameters.sortColumn;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/admins`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PortalAdminResponseFromJSON(jsonValue));
    }

    /**
     * Returns a list of the portal admins for the given organization
     */
    async getOrganizationAdmins(requestParameters: GetOrganizationAdminsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PortalAdminResponse> {
        const response = await this.getOrganizationAdminsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves the details of an existing portal administrator.
     */
    async getWorkspaceAdminRaw(requestParameters: GetWorkspaceAdminRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PortalAdmin>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getWorkspaceAdmin.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling getWorkspaceAdmin.');
        }

        if (requestParameters.portalAdminId === null || requestParameters.portalAdminId === undefined) {
            throw new runtime.RequiredError('portalAdminId','Required parameter requestParameters.portalAdminId was null or undefined when calling getWorkspaceAdmin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/admins/{portalAdminId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"portalAdminId"}}`, encodeURIComponent(String(requestParameters.portalAdminId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PortalAdminFromJSON(jsonValue));
    }

    /**
     * Retrieves the details of an existing portal administrator.
     */
    async getWorkspaceAdmin(requestParameters: GetWorkspaceAdminRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PortalAdmin> {
        const response = await this.getWorkspaceAdminRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the public details of a workspace administrator invitation.
     */
    async getWorkspaceAdminInviteDetailsRaw(requestParameters: GetWorkspaceAdminInviteDetailsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WorkspaceAdminInvitationInfo>> {
        if (requestParameters.invitationId === null || requestParameters.invitationId === undefined) {
            throw new runtime.RequiredError('invitationId','Required parameter requestParameters.invitationId was null or undefined when calling getWorkspaceAdminInviteDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invitations/workspace-admins/{invitationId}`.replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters.invitationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceAdminInvitationInfoFromJSON(jsonValue));
    }

    /**
     * Get the public details of a workspace administrator invitation.
     */
    async getWorkspaceAdminInviteDetails(requestParameters: GetWorkspaceAdminInviteDetailsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WorkspaceAdminInvitationInfo> {
        const response = await this.getWorkspaceAdminInviteDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of the portal admins for the given workspace.
     */
    async getWorkspaceAdminsRaw(requestParameters: GetWorkspaceAdminsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PortalAdminResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getWorkspaceAdmins.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling getWorkspaceAdmins.');
        }

        const queryParameters: any = {};

        if (requestParameters.portalAdminIds) {
            queryParameters['portalAdminIds'] = requestParameters.portalAdminIds;
        }

        if (requestParameters.externalUserIds) {
            queryParameters['externalUserIds'] = requestParameters.externalUserIds;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.nextPageToken !== undefined) {
            queryParameters['nextPageToken'] = requestParameters.nextPageToken;
        }

        if (requestParameters.previousPageToken !== undefined) {
            queryParameters['previousPageToken'] = requestParameters.previousPageToken;
        }

        if (requestParameters.sortColumn !== undefined) {
            queryParameters['sortColumn'] = requestParameters.sortColumn;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/admins`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PortalAdminResponseFromJSON(jsonValue));
    }

    /**
     * Returns a list of the portal admins for the given workspace.
     */
    async getWorkspaceAdmins(requestParameters: GetWorkspaceAdminsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PortalAdminResponse> {
        const response = await this.getWorkspaceAdminsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Resend the invitation to join the portal administration admin team.
     */
    async resendInvitationToOrganizationAdminRaw(requestParameters: ResendInvitationToOrganizationAdminRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling resendInvitationToOrganizationAdmin.');
        }

        if (requestParameters.portalAdminId === null || requestParameters.portalAdminId === undefined) {
            throw new runtime.RequiredError('portalAdminId','Required parameter requestParameters.portalAdminId was null or undefined when calling resendInvitationToOrganizationAdmin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/admins/{portalAdminId}/resend`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"portalAdminId"}}`, encodeURIComponent(String(requestParameters.portalAdminId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Resend the invitation to join the portal administration admin team.
     */
    async resendInvitationToOrganizationAdmin(requestParameters: ResendInvitationToOrganizationAdminRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.resendInvitationToOrganizationAdminRaw(requestParameters, initOverrides);
    }

    /**
     * Resend the invitation to join the portal administration admin team.
     */
    async resendInvitationToOrganizationAdminsRaw(requestParameters: ResendInvitationToOrganizationAdminsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<OrganizationAdminsResendResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling resendInvitationToOrganizationAdmins.');
        }

        if (requestParameters.portalAdminId === null || requestParameters.portalAdminId === undefined) {
            throw new runtime.RequiredError('portalAdminId','Required parameter requestParameters.portalAdminId was null or undefined when calling resendInvitationToOrganizationAdmins.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/admins/resend`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"portalAdminId"}}`, encodeURIComponent(String(requestParameters.portalAdminId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationAdminsResendResponseFromJSON(jsonValue));
    }

    /**
     * Resend the invitation to join the portal administration admin team.
     */
    async resendInvitationToOrganizationAdmins(requestParameters: ResendInvitationToOrganizationAdminsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<OrganizationAdminsResendResponse> {
        const response = await this.resendInvitationToOrganizationAdminsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Resend the invitations to workspace admins. By default, this will resend invitations to admins in pending state.
     * Resend the invitations to workspace admins. By default, this will resend invitations to admins in pending state.
     */
    async resendInvitationToWorkspaceAdminsRaw(requestParameters: ResendInvitationToWorkspaceAdminsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WorkspaceAdminsResendResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling resendInvitationToWorkspaceAdmins.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling resendInvitationToWorkspaceAdmins.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/admins/resend`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceAdminsResendResponseFromJSON(jsonValue));
    }

    /**
     * Resend the invitations to workspace admins. By default, this will resend invitations to admins in pending state.
     * Resend the invitations to workspace admins. By default, this will resend invitations to admins in pending state.
     */
    async resendInvitationToWorkspaceAdmins(requestParameters: ResendInvitationToWorkspaceAdminsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WorkspaceAdminsResendResponse> {
        const response = await this.resendInvitationToWorkspaceAdminsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Resend the invitation to the specified workspace admin. By default, this will resend invitation to admins in pending state.
     */
    async resendInvitationWorkspaceAdminRaw(requestParameters: ResendInvitationWorkspaceAdminRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling resendInvitationWorkspaceAdmin.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling resendInvitationWorkspaceAdmin.');
        }

        if (requestParameters.portalAdminId === null || requestParameters.portalAdminId === undefined) {
            throw new runtime.RequiredError('portalAdminId','Required parameter requestParameters.portalAdminId was null or undefined when calling resendInvitationWorkspaceAdmin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/admins/{portalAdminId}/resend`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"portalAdminId"}}`, encodeURIComponent(String(requestParameters.portalAdminId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Resend the invitation to the specified workspace admin. By default, this will resend invitation to admins in pending state.
     */
    async resendInvitationWorkspaceAdmin(requestParameters: ResendInvitationWorkspaceAdminRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.resendInvitationWorkspaceAdminRaw(requestParameters, initOverrides);
    }

    /**
     * Updates the portal administrator by setting the values of the parameters provided. Any parameters not provided will be left unchanged.
     */
    async updateOrganizationAdminRaw(requestParameters: UpdateOrganizationAdminRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PortalAdmin>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling updateOrganizationAdmin.');
        }

        if (requestParameters.portalAdminId === null || requestParameters.portalAdminId === undefined) {
            throw new runtime.RequiredError('portalAdminId','Required parameter requestParameters.portalAdminId was null or undefined when calling updateOrganizationAdmin.');
        }

        if (requestParameters.portalAdmin === null || requestParameters.portalAdmin === undefined) {
            throw new runtime.RequiredError('portalAdmin','Required parameter requestParameters.portalAdmin was null or undefined when calling updateOrganizationAdmin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/admins/{portalAdminId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"portalAdminId"}}`, encodeURIComponent(String(requestParameters.portalAdminId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PortalAdminToJSON(requestParameters.portalAdmin),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PortalAdminFromJSON(jsonValue));
    }

    /**
     * Updates the portal administrator by setting the values of the parameters provided. Any parameters not provided will be left unchanged.
     */
    async updateOrganizationAdmin(requestParameters: UpdateOrganizationAdminRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PortalAdmin> {
        const response = await this.updateOrganizationAdminRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the portal administrator by setting the values of the parameters provided. Any parameters not provided will be left unchanged.
     */
    async updateWorkspaceAdminRaw(requestParameters: UpdateWorkspaceAdminRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PortalAdmin>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling updateWorkspaceAdmin.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling updateWorkspaceAdmin.');
        }

        if (requestParameters.portalAdminId === null || requestParameters.portalAdminId === undefined) {
            throw new runtime.RequiredError('portalAdminId','Required parameter requestParameters.portalAdminId was null or undefined when calling updateWorkspaceAdmin.');
        }

        if (requestParameters.portalAdmin === null || requestParameters.portalAdmin === undefined) {
            throw new runtime.RequiredError('portalAdmin','Required parameter requestParameters.portalAdmin was null or undefined when calling updateWorkspaceAdmin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/admins/{portalAdminId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"portalAdminId"}}`, encodeURIComponent(String(requestParameters.portalAdminId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PortalAdminToJSON(requestParameters.portalAdmin),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PortalAdminFromJSON(jsonValue));
    }

    /**
     * Updates the portal administrator by setting the values of the parameters provided. Any parameters not provided will be left unchanged.
     */
    async updateWorkspaceAdmin(requestParameters: UpdateWorkspaceAdminRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PortalAdmin> {
        const response = await this.updateWorkspaceAdminRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetAllWorkspaceAdminsSortDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type GetAllWorkspaceAdminsSortDirectionEnum = typeof GetAllWorkspaceAdminsSortDirectionEnum[keyof typeof GetAllWorkspaceAdminsSortDirectionEnum];
/**
 * @export
 */
export const GetOrganizationAdminsSortDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type GetOrganizationAdminsSortDirectionEnum = typeof GetOrganizationAdminsSortDirectionEnum[keyof typeof GetOrganizationAdminsSortDirectionEnum];
/**
 * @export
 */
export const GetWorkspaceAdminsSortDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type GetWorkspaceAdminsSortDirectionEnum = typeof GetWorkspaceAdminsSortDirectionEnum[keyof typeof GetWorkspaceAdminsSortDirectionEnum];
