/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrganizationType,
    OrganizationTypeFromJSON,
    OrganizationTypeFromJSONTyped,
    OrganizationTypeToJSON,
} from './OrganizationType';

/**
 * 
 * @export
 * @interface OrganizationOnboarding
 */
export interface OrganizationOnboarding {
    /**
     * 
     * @type {string}
     * @memberof OrganizationOnboarding
     */
    organizationName?: string;
    /**
     * 
     * @type {OrganizationType}
     * @memberof OrganizationOnboarding
     */
    organizationType?: OrganizationType;
    /**
     * The preferred locale used for communication, in BCP 47 format.
     * @type {string}
     * @memberof OrganizationOnboarding
     */
    organizationLocale?: string;
    /**
     * Google Place ID
     * @type {string}
     * @memberof OrganizationOnboarding
     */
    firstWorkspaceLocation?: string;
    /**
     * Name of the first workspace, optional. If not set, this will be the street address from firstWorkspaceLocation
     * @type {string}
     * @memberof OrganizationOnboarding
     */
    firstWorkspaceName?: string;
    /**
     * The unique identifier for the connection.
     * @type {string}
     * @memberof OrganizationOnboarding
     */
    connectionId?: string;
    /**
     * The token for the email address of the owning user/first admin. For companies, the domain of the email address will also be associated with this organization and no other companies can use it.
     * @type {string}
     * @memberof OrganizationOnboarding
     */
    ownerEmailToken?: string;
    /**
     * The ID for a relevant promotional campaign. Requires campaignType to be set
     * @type {string}
     * @memberof OrganizationOnboarding
     */
    campaignId?: string;
    /**
     * The type of the promotional campaign. Only relevant when campaignId is set
     * @type {string}
     * @memberof OrganizationOnboarding
     */
    campaignType?: string;
    /**
     * Number of employees in the organization
     * @type {string}
     * @memberof OrganizationOnboarding
     */
    organizationSize?: OrganizationOnboardingOrganizationSizeEnum;
}


/**
 * @export
 */
export const OrganizationOnboardingOrganizationSizeEnum = {
    PreferNotToSay: 'PREFER_NOT_TO_SAY',
    _1To10: '1_TO_10',
    _11To50: '11_TO_50',
    _51To200: '51_TO_200',
    _201To500: '201_TO_500',
    _501To1000: '501_TO_1000',
    _1001To5000: '1001_TO_5000',
    _5001OrMore: '5001_OR_MORE'
} as const;
export type OrganizationOnboardingOrganizationSizeEnum = typeof OrganizationOnboardingOrganizationSizeEnum[keyof typeof OrganizationOnboardingOrganizationSizeEnum];


export function OrganizationOnboardingFromJSON(json: any): OrganizationOnboarding {
    return OrganizationOnboardingFromJSONTyped(json, false);
}

export function OrganizationOnboardingFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationOnboarding {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organizationName': !exists(json, 'organizationName') ? undefined : json['organizationName'],
        'organizationType': !exists(json, 'organizationType') ? undefined : OrganizationTypeFromJSON(json['organizationType']),
        'organizationLocale': !exists(json, 'organizationLocale') ? undefined : json['organizationLocale'],
        'firstWorkspaceLocation': !exists(json, 'firstWorkspaceLocation') ? undefined : json['firstWorkspaceLocation'],
        'firstWorkspaceName': !exists(json, 'firstWorkspaceName') ? undefined : json['firstWorkspaceName'],
        'connectionId': !exists(json, 'connectionId') ? undefined : json['connectionId'],
        'ownerEmailToken': !exists(json, 'ownerEmailToken') ? undefined : json['ownerEmailToken'],
        'campaignId': !exists(json, 'campaignId') ? undefined : json['campaignId'],
        'campaignType': !exists(json, 'campaignType') ? undefined : json['campaignType'],
        'organizationSize': !exists(json, 'organizationSize') ? undefined : json['organizationSize'],
    };
}

export function OrganizationOnboardingToJSON(value?: OrganizationOnboarding | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organizationName': value.organizationName,
        'organizationType': OrganizationTypeToJSON(value.organizationType),
        'organizationLocale': value.organizationLocale,
        'firstWorkspaceLocation': value.firstWorkspaceLocation,
        'firstWorkspaceName': value.firstWorkspaceName,
        'connectionId': value.connectionId,
        'ownerEmailToken': value.ownerEmailToken,
        'campaignId': value.campaignId,
        'campaignType': value.campaignType,
        'organizationSize': value.organizationSize,
    };
}

