import { Flex, Text } from '@ritualco/jutsu'
import { PageTrackingNames } from 'types/entities'
import { FullWidthPage } from '../../components/PageTemplates'
import { ReactComponent as Graph } from '../../assets/Icons/Graph.svg'
import { useContent } from '../../lib/content/react'

export const Home = () => {
  const { formatMessage } = useContent()
  return (
    <FullWidthPage
      heading="Home" // todo (h2): UPDATE IN CONTENTFUL
      subheading={formatMessage({ id: 'pmportal.home.description' })}
      showDivider
      trackingName={PageTrackingNames.placeholderHome}
    >
      <Flex justify="center" mt={10}>
        <Flex
          direction="column"
          align="center"
          gridGap={3}
          border="0px solid black"
        >
          <Graph />
          <Text fontWeight="medium" fontSize="medium">
            {formatMessage({ id: 'pmportal.home.noData' })}
          </Text>
        </Flex>
      </Flex>
    </FullWidthPage>
  )
}
