/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessRestriction,
    AccessRestrictionFromJSON,
    AccessRestrictionFromJSONTyped,
    AccessRestrictionToJSON,
} from './AccessRestriction';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import {
    AppAssociation,
    AppAssociationFromJSON,
    AppAssociationFromJSONTyped,
    AppAssociationToJSON,
} from './AppAssociation';
import {
    CurrencyCode,
    CurrencyCodeFromJSON,
    CurrencyCodeFromJSONTyped,
    CurrencyCodeToJSON,
} from './CurrencyCode';
import {
    WorkspaceType,
    WorkspaceTypeFromJSON,
    WorkspaceTypeFromJSONTyped,
    WorkspaceTypeToJSON,
} from './WorkspaceType';

/**
 * 
 * @export
 * @interface Workspace
 */
export interface Workspace {
    /**
     * The workspace unique identifier.
     * @type {string}
     * @memberof Workspace
     */
    workspaceId: string;
    /**
     * The organization's unique identifier.
     * @type {string}
     * @memberof Workspace
     */
    organizationId: string;
    /**
     * 
     * @type {Address}
     * @memberof Workspace
     */
    address: Address;
    /**
     * The preferred locale used for communication, in BCP 47 format.
     * @type {string}
     * @memberof Workspace
     */
    locale: string;
    /**
     * 
     * @type {CurrencyCode}
     * @memberof Workspace
     */
    currencyCode: CurrencyCode;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    name: string;
    /**
     * The portal admin owner unique identifier. The owner is an administrator that cannot be removed or altered by other portal admins.
     * @type {string}
     * @memberof Workspace
     */
    ownerId: string;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    timezone: string;
    /**
     * 
     * @type {WorkspaceType}
     * @memberof Workspace
     */
    workspaceType: WorkspaceType;
    /**
     * 
     * @type {AccessRestriction}
     * @memberof Workspace
     */
    accessRestriction?: AccessRestriction;
    /**
     * 
     * @type {AppAssociation}
     * @memberof Workspace
     */
    appAssociation?: AppAssociation;
}

export function WorkspaceFromJSON(json: any): Workspace {
    return WorkspaceFromJSONTyped(json, false);
}

export function WorkspaceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Workspace {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'workspaceId': json['workspaceId'],
        'organizationId': json['organizationId'],
        'address': AddressFromJSON(json['address']),
        'locale': json['locale'],
        'currencyCode': CurrencyCodeFromJSON(json['currencyCode']),
        'name': json['name'],
        'ownerId': json['ownerId'],
        'timezone': json['timezone'],
        'workspaceType': WorkspaceTypeFromJSON(json['workspaceType']),
        'accessRestriction': !exists(json, 'accessRestriction') ? undefined : AccessRestrictionFromJSON(json['accessRestriction']),
        'appAssociation': !exists(json, 'appAssociation') ? undefined : AppAssociationFromJSON(json['appAssociation']),
    };
}

export function WorkspaceToJSON(value?: Workspace | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'workspaceId': value.workspaceId,
        'organizationId': value.organizationId,
        'address': AddressToJSON(value.address),
        'locale': value.locale,
        'currencyCode': CurrencyCodeToJSON(value.currencyCode),
        'name': value.name,
        'ownerId': value.ownerId,
        'timezone': value.timezone,
        'workspaceType': WorkspaceTypeToJSON(value.workspaceType),
        'accessRestriction': AccessRestrictionToJSON(value.accessRestriction),
        'appAssociation': AppAssociationToJSON(value.appAssociation),
    };
}

