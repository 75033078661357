/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorFromJSONTyped,
    ApiErrorToJSON,
} from './ApiError';

/**
 * If a workspace member is successfully added, in addition to the workspace member email, the workspaceMemberId will be returned. If there is an error adding a particular workspace member, then no workspaceMemberId will be returned.
 * @export
 * @interface IncentivePlanWorkspaceMembersResponseItem
 */
export interface IncentivePlanWorkspaceMembersResponseItem {
    /**
     * HTTP status code indicating the status of the request for the workspace member
     * @type {number}
     * @memberof IncentivePlanWorkspaceMembersResponseItem
     */
    statusCode: number;
    /**
     * A workspace member unique identifier.
     * @type {string}
     * @memberof IncentivePlanWorkspaceMembersResponseItem
     */
    workspaceMemberId?: string;
    /**
     * The email address of the workspace member.
     * @type {string}
     * @memberof IncentivePlanWorkspaceMembersResponseItem
     */
    email: string;
    /**
     * 
     * @type {ApiError}
     * @memberof IncentivePlanWorkspaceMembersResponseItem
     */
    error?: ApiError;
}

export function IncentivePlanWorkspaceMembersResponseItemFromJSON(json: any): IncentivePlanWorkspaceMembersResponseItem {
    return IncentivePlanWorkspaceMembersResponseItemFromJSONTyped(json, false);
}

export function IncentivePlanWorkspaceMembersResponseItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncentivePlanWorkspaceMembersResponseItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'statusCode': json['statusCode'],
        'workspaceMemberId': !exists(json, 'workspaceMemberId') ? undefined : json['workspaceMemberId'],
        'email': json['email'],
        'error': !exists(json, 'error') ? undefined : ApiErrorFromJSON(json['error']),
    };
}

export function IncentivePlanWorkspaceMembersResponseItemToJSON(value?: IncentivePlanWorkspaceMembersResponseItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'statusCode': value.statusCode,
        'workspaceMemberId': value.workspaceMemberId,
        'email': value.email,
        'error': ApiErrorToJSON(value.error),
    };
}

