import { useState } from 'react'

export enum SortDirectionEnum {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type ColumnMap = {
  columnId: string
  value: string
}

export type UseHandleSortProps = {
  defaultColumnId?: string
}

export type UseHandleSort = (
  props: UseHandleSortProps
) => [
  sortColumn: string,
  sortDirection: SortDirectionEnum,
  handleSort: (id: string) => void
]

// Syncs the selected Jutsu Tab with the current URL
export const useHandleSort: UseHandleSort = ({ defaultColumnId }) => {
  const [sortDirection, setSortDirection] = useState<SortDirectionEnum>(
    SortDirectionEnum.Desc
  )
  const [sortColumn, setSortColumn] = useState(defaultColumnId || '')

  const handleSort = (id: string) => {
    if (sortDirection === SortDirectionEnum.Asc && id === sortColumn) {
      setSortColumn(defaultColumnId || '')
      setSortDirection(SortDirectionEnum.Desc)
    } else if (id === sortColumn) {
      // Same sort column.
      setSortDirection(SortDirectionEnum.Asc)
    } else {
      // New sort column.
      // Default to DESC
      setSortColumn(id)
      setSortDirection(SortDirectionEnum.Desc)
    }
  }

  return [sortColumn, sortDirection, handleSort]
}
