/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessRestriction,
    AccessRestrictionFromJSON,
    AccessRestrictionFromJSONTyped,
    AccessRestrictionToJSON,
} from './AccessRestriction';
import {
    AppAssociation,
    AppAssociationFromJSON,
    AppAssociationFromJSONTyped,
    AppAssociationToJSON,
} from './AppAssociation';
import {
    OrganizationType,
    OrganizationTypeFromJSON,
    OrganizationTypeFromJSONTyped,
    OrganizationTypeToJSON,
} from './OrganizationType';

/**
 * 
 * @export
 * @interface OrganizationCreationRequest
 */
export interface OrganizationCreationRequest {
    /**
     * 
     * @type {OrganizationType}
     * @memberof OrganizationCreationRequest
     */
    organizationType: OrganizationType;
    /**
     * The name of the organization.
     * @type {string}
     * @memberof OrganizationCreationRequest
     */
    name: string;
    /**
     * An icon URL for the organization. A square 128px x 128px.
     * @type {string}
     * @memberof OrganizationCreationRequest
     */
    iconUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationCreationRequest
     */
    timezone?: string;
    /**
     * The preferred locale used for communication, in BCP 47 format.
     * @type {string}
     * @memberof OrganizationCreationRequest
     */
    locale?: string;
    /**
     * The ritual userId of the user making this request. Will be set as the initial owner and admin of all created resources
     * @type {string}
     * @memberof OrganizationCreationRequest
     */
    ownerUserId: string;
    /**
     * The email for the owner of the organization. If this is not set, it is inferred from the associated account for ownerUserId.
     * @type {string}
     * @memberof OrganizationCreationRequest
     */
    ownerEmail?: string;
    /**
     * The owning user's first name to use as profile. If not present, will be populated from their main userRecord.
     * @type {string}
     * @memberof OrganizationCreationRequest
     */
    ownerFirstName?: string;
    /**
     * The owning user's last name to use as profile. If not present, will be populated from their main userRecord.
     * @type {string}
     * @memberof OrganizationCreationRequest
     */
    ownerLastName?: string;
    /**
     * Google Place ID
     * @type {string}
     * @memberof OrganizationCreationRequest
     */
    firstWorkspaceLocation: string;
    /**
     * Name of the first workspace, optional. If not set, this will be the street address from firstWorkspaceLocation
     * @type {string}
     * @memberof OrganizationCreationRequest
     */
    firstWorkspaceName?: string;
    /**
     * The unique identifier for the connection.
     * @type {string}
     * @memberof OrganizationCreationRequest
     */
    connectionId?: string;
    /**
     * The subdomain desired to be reserved for this org's usage.Not guaranteed to be applied or respected based on Org type eligibility. Will be built as follows: https://<subdomain>.ritual.co
     * @type {string}
     * @memberof OrganizationCreationRequest
     */
    subdomain?: string;
    /**
     * 
     * @type {AccessRestriction}
     * @memberof OrganizationCreationRequest
     */
    accessRestriction?: AccessRestriction;
    /**
     * 
     * @type {AppAssociation}
     * @memberof OrganizationCreationRequest
     */
    appAssociation?: AppAssociation;
}

export function OrganizationCreationRequestFromJSON(json: any): OrganizationCreationRequest {
    return OrganizationCreationRequestFromJSONTyped(json, false);
}

export function OrganizationCreationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationCreationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organizationType': OrganizationTypeFromJSON(json['organizationType']),
        'name': json['name'],
        'iconUrl': !exists(json, 'iconUrl') ? undefined : json['iconUrl'],
        'timezone': !exists(json, 'timezone') ? undefined : json['timezone'],
        'locale': !exists(json, 'locale') ? undefined : json['locale'],
        'ownerUserId': json['ownerUserId'],
        'ownerEmail': !exists(json, 'ownerEmail') ? undefined : json['ownerEmail'],
        'ownerFirstName': !exists(json, 'ownerFirstName') ? undefined : json['ownerFirstName'],
        'ownerLastName': !exists(json, 'ownerLastName') ? undefined : json['ownerLastName'],
        'firstWorkspaceLocation': json['firstWorkspaceLocation'],
        'firstWorkspaceName': !exists(json, 'firstWorkspaceName') ? undefined : json['firstWorkspaceName'],
        'connectionId': !exists(json, 'connectionId') ? undefined : json['connectionId'],
        'subdomain': !exists(json, 'subdomain') ? undefined : json['subdomain'],
        'accessRestriction': !exists(json, 'accessRestriction') ? undefined : AccessRestrictionFromJSON(json['accessRestriction']),
        'appAssociation': !exists(json, 'appAssociation') ? undefined : AppAssociationFromJSON(json['appAssociation']),
    };
}

export function OrganizationCreationRequestToJSON(value?: OrganizationCreationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organizationType': OrganizationTypeToJSON(value.organizationType),
        'name': value.name,
        'iconUrl': value.iconUrl,
        'timezone': value.timezone,
        'locale': value.locale,
        'ownerUserId': value.ownerUserId,
        'ownerEmail': value.ownerEmail,
        'ownerFirstName': value.ownerFirstName,
        'ownerLastName': value.ownerLastName,
        'firstWorkspaceLocation': value.firstWorkspaceLocation,
        'firstWorkspaceName': value.firstWorkspaceName,
        'connectionId': value.connectionId,
        'subdomain': value.subdomain,
        'accessRestriction': AccessRestrictionToJSON(value.accessRestriction),
        'appAssociation': AppAssociationToJSON(value.appAssociation),
    };
}

