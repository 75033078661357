/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PortalAdmin,
    PortalAdminFromJSON,
    PortalAdminFromJSONTyped,
    PortalAdminToJSON,
} from './PortalAdmin';
import {
    PortalAdminRole,
    PortalAdminRoleFromJSON,
    PortalAdminRoleFromJSONTyped,
    PortalAdminRoleToJSON,
} from './PortalAdminRole';
import {
    PortalAdminStatus,
    PortalAdminStatusFromJSON,
    PortalAdminStatusFromJSONTyped,
    PortalAdminStatusToJSON,
} from './PortalAdminStatus';
import {
    WorkspacePortalAdminAllOf,
    WorkspacePortalAdminAllOfFromJSON,
    WorkspacePortalAdminAllOfFromJSONTyped,
    WorkspacePortalAdminAllOfToJSON,
} from './WorkspacePortalAdminAllOf';

/**
 * 
 * @export
 * @interface WorkspacePortalAdmin
 */
export interface WorkspacePortalAdmin {
    /**
     * A portal admin unique identifier.
     * @type {string}
     * @memberof WorkspacePortalAdmin
     */
    portalAdminId?: string;
    /**
     * The email address of the workspace admin.
     * @type {string}
     * @memberof WorkspacePortalAdmin
     */
    email: string;
    /**
     * The first name, displayed only within the portal workspace.
     * @type {string}
     * @memberof WorkspacePortalAdmin
     */
    firstName?: string;
    /**
     * The last name, displayed only within the portal workspace.
     * @type {string}
     * @memberof WorkspacePortalAdmin
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspacePortalAdmin
     */
    timezone?: string;
    /**
     * The preferred locale used for communication, in BCP 47 format.
     * @type {string}
     * @memberof WorkspacePortalAdmin
     */
    locale?: string;
    /**
     * 
     * @type {PortalAdminRole}
     * @memberof WorkspacePortalAdmin
     */
    role: PortalAdminRole;
    /**
     * 
     * @type {PortalAdminStatus}
     * @memberof WorkspacePortalAdmin
     */
    status?: PortalAdminStatus;
    /**
     * The timestamp that the audit event record was created.
     * @type {Date}
     * @memberof WorkspacePortalAdmin
     */
    lastUpdatedInviteStatus?: Date;
    /**
     * The number of milliseconds that have elapsed since the Unix epoch. Last seen is an approximate of the user's last login / session.
     * @type {number}
     * @memberof WorkspacePortalAdmin
     */
    lastSeen?: number;
    /**
     * The workspace's unique identifier.
     * @type {string}
     * @memberof WorkspacePortalAdmin
     */
    workspaceId: string;
    /**
     * The workspace's type
     * @type {string}
     * @memberof WorkspacePortalAdmin
     */
    workspaceType?: string;
}

export function WorkspacePortalAdminFromJSON(json: any): WorkspacePortalAdmin {
    return WorkspacePortalAdminFromJSONTyped(json, false);
}

export function WorkspacePortalAdminFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspacePortalAdmin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'portalAdminId': !exists(json, 'portalAdminId') ? undefined : json['portalAdminId'],
        'email': json['email'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'timezone': !exists(json, 'timezone') ? undefined : json['timezone'],
        'locale': !exists(json, 'locale') ? undefined : json['locale'],
        'role': PortalAdminRoleFromJSON(json['role']),
        'status': !exists(json, 'status') ? undefined : PortalAdminStatusFromJSON(json['status']),
        'lastUpdatedInviteStatus': !exists(json, 'lastUpdatedInviteStatus') ? undefined : (new Date(json['lastUpdatedInviteStatus'])),
        'lastSeen': !exists(json, 'lastSeen') ? undefined : json['lastSeen'],
        'workspaceId': json['workspaceId'],
        'workspaceType': !exists(json, 'workspaceType') ? undefined : json['workspaceType'],
    };
}

export function WorkspacePortalAdminToJSON(value?: WorkspacePortalAdmin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'portalAdminId': value.portalAdminId,
        'email': value.email,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'timezone': value.timezone,
        'locale': value.locale,
        'role': PortalAdminRoleToJSON(value.role),
        'status': PortalAdminStatusToJSON(value.status),
        'lastUpdatedInviteStatus': value.lastUpdatedInviteStatus === undefined ? undefined : (value.lastUpdatedInviteStatus.toISOString()),
        'lastSeen': value.lastSeen,
        'workspaceId': value.workspaceId,
        'workspaceType': value.workspaceType,
    };
}

