import { useMutation, useQuery } from 'react-query'
import {
  CreateOrganizationOnboardingRequest,
  GetOrganizationAuditEventRequest,
  GetOrganizationAuditHistoryRequest,
  GetOrganizationOnboardingRequest,
  GetOrganizationsRequest,
  GetOrganizationUserProfilesRequest,
  GetWorkspaceUserProfilesRequest,
  PublishOrganizationOnboardingRequest,
  UpdateOrganizationOnboardingRequest,
} from 'lib/generated/api'
import { useApiClient } from 'contexts/ApiClientContext'
import { useAuth } from 'auth'
import { useHandleApiError } from 'api/utils'

export const QUERY_KEYS = {
  organizations: 'organizations',
  workspaceUserProfiles: 'workspaceUserProfiles',
  organizationUserProfiles: 'organizationUserProfiles',
  organizationOnboarding: 'organizationOnboarding',
} as const

export const organizationsQueryKey = ({
  ...queryParams
}: GetOrganizationsRequest) => [QUERY_KEYS.organizations, queryParams]

export const workspaceUserProfilesQueryKey = ({
  organizationId,
  externalUserId,
  ...queryParams
}: GetWorkspaceUserProfilesRequest) => [
  QUERY_KEYS.workspaceUserProfiles,
  organizationId,
  externalUserId,
  queryParams,
]

export const organizationUserProfilesQueryKey = ({
  organizationId,
  externalUserId,
  ...queryParams
}: GetOrganizationUserProfilesRequest) => [
  QUERY_KEYS.organizationUserProfiles,
  organizationId,
  externalUserId,
  queryParams,
]

export const organizationOnboardingQueryKey = ({
  onboardingRecordId,
}: GetOrganizationOnboardingRequest) => [
  QUERY_KEYS.organizationOnboarding,
  onboardingRecordId,
]

export const useGetOrganizations = (
  request: GetOrganizationsRequest,
  queryOptions = {}
) => {
  const { organizationApi } = useApiClient()
  return useQuery(
    organizationsQueryKey(request),
    () => organizationApi.getOrganizations(request),
    queryOptions
  )
}

export const useGetOrganizationWorkspaceUserProfiles = (
  request: GetWorkspaceUserProfilesRequest
) => {
  const { workspaceApi } = useApiClient()
  return useQuery(
    workspaceUserProfilesQueryKey(request),
    () => workspaceApi.getWorkspaceUserProfiles(request),
    { enabled: !!request.externalUserId && !!request.organizationId }
  )
}

export const useGetOrganizationUserProfiles = (
  request: GetOrganizationUserProfilesRequest
) => {
  const { organizationApi } = useApiClient()
  return useQuery(
    organizationUserProfilesQueryKey(request),
    () => organizationApi.getOrganizationUserProfiles(request),
    { enabled: !!request.organizationId && !!request.externalUserId }
  )
}

export const useCreateOrganizationOnboarding = () => {
  const { organizationApi } = useApiClient()
  return useMutation((request: CreateOrganizationOnboardingRequest) =>
    organizationApi.createOrganizationOnboarding(request)
  )
}

export const useUpdateOnboardingRecordId = () => {
  const { organizationApi } = useApiClient()
  const onError = useHandleApiError({ showMessage: false })

  return useMutation(
    (request: UpdateOrganizationOnboardingRequest) =>
      organizationApi.updateOrganizationOnboarding(request),
    { onError }
  )
}

export const usePublishOrganizationOnboarding = () => {
  const { organizationApi } = useApiClient()
  const { updateToken } = useAuth()
  const onError = useHandleApiError({ showMessage: false })
  return useMutation(
    (request: PublishOrganizationOnboardingRequest) =>
      organizationApi.publishOrganizationOnboarding(request),
    { onSuccess: () => updateToken(-1), onError }
  )
}

export const useGetOrganizationOnboarding = (
  request: GetOrganizationOnboardingRequest
) => {
  const { organizationApi } = useApiClient()
  const onError = useHandleApiError({ showMessage: false })

  return useQuery(
    organizationOnboardingQueryKey(request),
    () => organizationApi.getOrganizationOnboarding(request),
    { enabled: !!request.onboardingRecordId, onError }
  )
}

export const useGetOrganizationAuditHistory = (
  request: GetOrganizationAuditHistoryRequest
) => {
  const { organizationApi } = useApiClient()
  return useQuery([request.organizationId], () =>
    organizationApi.getOrganizationAuditHistory(request)
  )
}

export const useGetOrganizationAuditEvent = (
  request: GetOrganizationAuditEventRequest
) => {
  const { organizationApi } = useApiClient()
  return useQuery([request.organizationId, request.auditEventId], () =>
    organizationApi.getOrganizationAuditEvent(request)
  )
}
