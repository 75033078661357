/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MerchantStatus = {
    Active: 'ACTIVE',
    TemporarilyUnavailable: 'TEMPORARILY_UNAVAILABLE',
    PermanentlyUnavailable: 'PERMANENTLY_UNAVAILABLE',
    InvitationSent: 'INVITATION_SENT'
} as const;
export type MerchantStatus = typeof MerchantStatus[keyof typeof MerchantStatus];


export function MerchantStatusFromJSON(json: any): MerchantStatus {
    return MerchantStatusFromJSONTyped(json, false);
}

export function MerchantStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantStatus {
    return json as MerchantStatus;
}

export function MerchantStatusToJSON(value?: MerchantStatus | null): any {
    return value as any;
}

