import React, { useContext, useState, useEffect } from 'react'
import firebase from 'firebase/app'
import firebaseClient from './firebase'

import { RemoteConfigContext, RemoteConfigType } from './RemoteConfigContext'

const defaultConfig: { [key: string]: string } = {
  hera_search_enabled: 'false',
  hera_workspace_history_enabled: 'false',
  hera_group_orders_tabs_enabled: 'false',
  hera_publish_add_employees_enabled: 'false',
  hera_publish_add_all_employees_enabled: 'false',
}

const useRemoteConfigValue: () => RemoteConfigType = () => {
  const [
    remoteConfig,
    setRemoteConfig,
  ] = useState<firebase.remoteConfig.RemoteConfig>()
  const [configLoaded, setConfigLoaded] = useState(false)
  useEffect(() => {
    const initialize = async () => {
      const firebaseImpl = await firebaseClient.getClient()
      if (firebaseClient.getIsEnabled()) {
        const newRemoteConfig = firebaseImpl.remoteConfig()
        const minimumFetchIntervalMillis = process.env
          .RT_FIREBASE_REFRESH_INTERVAL
          ? Number(process.env.RT_FIREBASE_REFRESH_INTERVAL)
          : 0
        const fetchTimeoutMillis = process.env.REACT_APP_FIREBASE_TIMEOUT_MILLIS
          ? Number(process.env.REACT_APP_FIREBASE_TIMEOUT_MILLIS)
          : 0
        newRemoteConfig.settings = {
          minimumFetchIntervalMillis,
          fetchTimeoutMillis,
        }
        newRemoteConfig.defaultConfig = defaultConfig
        newRemoteConfig.fetchAndActivate().then(() => setConfigLoaded(true))
        setRemoteConfig(newRemoteConfig)
      } else {
        setConfigLoaded(true)
      }
    }
    initialize()
  }, [])
  const getBoolean = (key: string) =>
    firebaseClient.getIsEnabled() && configLoaded && remoteConfig
      ? remoteConfig.getBoolean(key)
      : defaultConfig[key] === 'true'
  const getString = (key: string) =>
    firebaseClient.getIsEnabled() && configLoaded && remoteConfig
      ? remoteConfig.getString(key)
      : defaultConfig[key]
  const getJSON = (key: string) => {
    try {
      const str = getString(key)
      return JSON.parse(str as string)
    } catch (e) {
      return null
    }
  }

  return {
    configLoaded,
    getBoolean,
    getString,
    getJSON,
  }
}

export const RemoteConfigProvider: React.FC = ({ children }) => {
  const remoteConfigValue = useRemoteConfigValue()
  return (
    <RemoteConfigContext.Provider value={remoteConfigValue}>
      {children}
    </RemoteConfigContext.Provider>
  )
}

export const useRemoteConfig = () => useContext(RemoteConfigContext)
