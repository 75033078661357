import { useEffect, useState } from 'react'
import { RouteEntry } from 'lib/router'
import { SideNavItemProps } from 'components/SideNavItem'
import { useActiveRoutes } from '../routes'
import { useContent } from '../lib/content/react'

export type IndexEntry = {
  path: string
  navTitle?: string
  breadcrumb?: string
}

export type Index = IndexEntry[]

type RouteExclusion = (route: string) => boolean

type RouteExclusions = RouteExclusion[]
const WildcardExclusion: RouteExclusion = (route: string) => route === '*'
const DetailPageExclusion: RouteExclusion = (route: string) => {
  const routePaths = route.split('/')
  const finalPath = routePaths[routePaths.length - 1]
  return finalPath ? finalPath.startsWith(':') : false
}
const EXCLUSIONS: RouteExclusions = [WildcardExclusion, DetailPageExclusion]

export const useSearchIndex = () => {
  const { formatMessage } = useContent()
  const [index, setIndex] = useState<Index>([])
  const activeRoutes = useActiveRoutes()

  const shouldExclude = (route: string) =>
    EXCLUSIONS.some(exclusion => !!exclusion(route))

  const getBreadcrumb = (route: RouteEntry<SideNavItemProps>) => {
    if (route.breadcrumb) {
      return formatMessage({ id: route.breadcrumb })
    }
    if (route.navTitle) {
      return formatMessage({ id: route.navTitle })
    }
    return undefined
  }

  useEffect(() => {
    const routesToIndex = activeRoutes.reduce((acc: Index, route) => {
      if (shouldExclude(route.path)) {
        return acc
      }

      const index: IndexEntry = {
        path: route.path,
        navTitle: route.navTitle
          ? formatMessage({ id: route.navTitle })
          : undefined,
        breadcrumb: getBreadcrumb(route),
      }
      return [...acc, index]
    }, [])
    setIndex(routesToIndex)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRoutes])
  return { index }
}
