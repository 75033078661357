/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ScheduleEnd,
    ScheduleEndFromJSON,
    ScheduleEndFromJSONTyped,
    ScheduleEndToJSON,
} from './ScheduleEnd';
import {
    ScheduleStart,
    ScheduleStartFromJSON,
    ScheduleStartFromJSONTyped,
    ScheduleStartToJSON,
} from './ScheduleStart';

/**
 * 
 * @export
 * @interface Schedule
 */
export interface Schedule {
    /**
     * 
     * @type {ScheduleStart}
     * @memberof Schedule
     */
    start?: ScheduleStart;
    /**
     * 
     * @type {ScheduleEnd}
     * @memberof Schedule
     */
    end?: ScheduleEnd;
    /**
     * The time zone of start and end (formatted according to IANA time zone database)
     * @type {string}
     * @memberof Schedule
     */
    timezone?: string;
    /**
     * The string representation of recurring events (formatted according to iCalendar Recurrence Rule). Omit this field for single events
     * @type {string}
     * @memberof Schedule
     */
    recurrenceRule?: string;
}

export function ScheduleFromJSON(json: any): Schedule {
    return ScheduleFromJSONTyped(json, false);
}

export function ScheduleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Schedule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'start': !exists(json, 'start') ? undefined : ScheduleStartFromJSON(json['start']),
        'end': !exists(json, 'end') ? undefined : ScheduleEndFromJSON(json['end']),
        'timezone': !exists(json, 'timezone') ? undefined : json['timezone'],
        'recurrenceRule': !exists(json, 'recurrenceRule') ? undefined : json['recurrenceRule'],
    };
}

export function ScheduleToJSON(value?: Schedule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start': ScheduleStartToJSON(value.start),
        'end': ScheduleEndToJSON(value.end),
        'timezone': value.timezone,
        'recurrenceRule': value.recurrenceRule,
    };
}

