import { Divider, Flex, FlexProps } from '@ritualco/jutsu'

export const AppBarDivider = () => (
  <Divider orientation="vertical" color="grayscale.quinary" h="47px" mx={6} />
)

export type AppBarProps = FlexProps

export const AppBar = ({ children, ...rest }: AppBarProps) => (
  <Flex
    align="center"
    bgColor="grayscale.white"
    boxShadow="inset 0px -1px 0px #E0E0E2"
    px={6}
    py={4}
    {...rest}
  >
    {children}
  </Flex>
)
