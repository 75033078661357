import { MessageLoader, Resource, ResourceSet } from '../types'

const initialResourceSets: ResourceSet[] = new Array(3)
  .fill({})
  .map<ResourceSet>((_, i) => ({
    id: i.toString(),
    locale: 'en',
    name: `resourceSet.${i}`,
    resources: new Array(5).fill({}).map<Resource>((_, idx) => ({
      id: idx.toString(),
      contentType: 'resource',
      name: `resourceSet.${i}.resource${idx}`,
      fields: {
        key: `resourceSet.${i}.resource${idx}`,
        value: `lorem ipsum for resourceSet.${i}.resource${idx}`,
      },
    })),
  }))

type CreateFakeContentLoader = (resourceSets?: ResourceSet[]) => MessageLoader
export const createFakeContentLoader: CreateFakeContentLoader = (
  resourceSets = initialResourceSets
) => ({
  async fetchSelectedResourceSets() {
    return resourceSets
  },
})
