/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ScheduleStart
 */
export interface ScheduleStart {
    /**
     * Start date for all-day events (formatted according to ISO 8601). Omit this field for timed events
     * @type {Date}
     * @memberof ScheduleStart
     */
    date?: Date;
    /**
     * Start date-time without time zone offset for timed events (formatted according to ISO 8601). Omit this field for all-day events
     * @type {Date}
     * @memberof ScheduleStart
     */
    dateTime?: Date;
}

export function ScheduleStartFromJSON(json: any): ScheduleStart {
    return ScheduleStartFromJSONTyped(json, false);
}

export function ScheduleStartFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleStart {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'dateTime': !exists(json, 'dateTime') ? undefined : (new Date(json['dateTime'])),
    };
}

export function ScheduleStartToJSON(value?: ScheduleStart | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date === undefined ? undefined : (value.date.toISOString().substr(0,10)),
        'dateTime': value.dateTime === undefined ? undefined : (value.dateTime.toISOString()),
    };
}

