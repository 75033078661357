import { ContentfulLocale } from 'lib/content/loaders/contentful/types'
import { useContent } from 'lib/content/react'
import { useEffect } from 'react'
import { useStore } from 'state/store'
import { DEFAULT_LOCALE, Locale } from 'types/locale'
import { setCookie } from 'utils/cookie'

const LOCALE_MAP: Record<Locale, ContentfulLocale> = {
  'en-AU': 'en',
  'en-CA': 'en',
  'en-GB': 'en',
  'en-US': 'en',
  'fr-CA': 'fr',
  'zh-HK': 'zh',
}

type ContentfulLocaleMapper = (locale?: Locale) => ContentfulLocale
export const mapToContentfulLocale: ContentfulLocaleMapper = (
  locale = 'en-CA'
) => LOCALE_MAP[locale]

export const useLocaleSetup = () => {
  const organizationUserProfiles = useStore(
    state => state.organizationUserProfiles
  )
  const organizationWorkspaceUserProfiles = useStore(
    state => state.organizationWorkspaceUserProfiles
  )
  const { locale: userLocale } = useStore(state => state.userInfo)
  const { locale, setLocale } = useContent()

  useEffect(() => {
    const mostSpecificLocale =
      userLocale ||
      organizationUserProfiles[0]?.locale ||
      organizationWorkspaceUserProfiles[0]?.locale ||
      DEFAULT_LOCALE
    const contentfulLocale = mapToContentfulLocale(mostSpecificLocale as Locale)
    setLocale(contentfulLocale)
    setCookie('locale', contentfulLocale)
  }, [
    locale,
    setLocale,
    userLocale,
    organizationUserProfiles,
    organizationWorkspaceUserProfiles,
  ])
}
