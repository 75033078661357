/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    Fulfillment,
    FulfillmentFromJSON,
    FulfillmentToJSON,
    FulfillmentRequest,
    FulfillmentRequestFromJSON,
    FulfillmentRequestToJSON,
    GetFulfillmentsResponse,
    GetFulfillmentsResponseFromJSON,
    GetFulfillmentsResponseToJSON,
} from '../models';

export interface CreateFulfillmentRequest {
    workspaceId: string;
    fulfillmentRequest?: FulfillmentRequest;
}

export interface GetFulfillmentsRequest {
    workspaceId: string;
    maxMinutesAfterComplete?: number;
    minMinutesAfterComplete?: number;
    query?: string;
    limit?: number;
    nextPageToken?: string;
    previousPageToken?: string;
    sortColumn?: string;
    sortDirection?: GetFulfillmentsSortDirectionEnum;
}

/**
 * 
 */
export class FulfillmentApi extends runtime.BaseAPI {

    /**
     * Create a fulfillment with the necessary location configuration for the workspace
     */
    async createFulfillmentRaw(requestParameters: CreateFulfillmentRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Fulfillment>> {
        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling createFulfillment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workspaces/{workspaceId}/fulfillments/standalone`.replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FulfillmentRequestToJSON(requestParameters.fulfillmentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FulfillmentFromJSON(jsonValue));
    }

    /**
     * Create a fulfillment with the necessary location configuration for the workspace
     */
    async createFulfillment(requestParameters: CreateFulfillmentRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Fulfillment> {
        const response = await this.createFulfillmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sorting is currently supported for the following fields: `scheduledTime`, `orderByTime`, `completeTime`, `fulfillmentType`, `fulfillmentState`, `merchantName`.  The `query` parameter supports searching by merchant name and smart queries with the table\'s columns. (Example: `fulfillmentType:IN_STORE`)  
     * Gets fulfillments associated with the workspace
     */
    async getFulfillmentsRaw(requestParameters: GetFulfillmentsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<GetFulfillmentsResponse>> {
        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling getFulfillments.');
        }

        const queryParameters: any = {};

        if (requestParameters.maxMinutesAfterComplete !== undefined) {
            queryParameters['maxMinutesAfterComplete'] = requestParameters.maxMinutesAfterComplete;
        }

        if (requestParameters.minMinutesAfterComplete !== undefined) {
            queryParameters['minMinutesAfterComplete'] = requestParameters.minMinutesAfterComplete;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.nextPageToken !== undefined) {
            queryParameters['nextPageToken'] = requestParameters.nextPageToken;
        }

        if (requestParameters.previousPageToken !== undefined) {
            queryParameters['previousPageToken'] = requestParameters.previousPageToken;
        }

        if (requestParameters.sortColumn !== undefined) {
            queryParameters['sortColumn'] = requestParameters.sortColumn;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workspaces/{workspaceId}/fulfillments`.replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFulfillmentsResponseFromJSON(jsonValue));
    }

    /**
     * Sorting is currently supported for the following fields: `scheduledTime`, `orderByTime`, `completeTime`, `fulfillmentType`, `fulfillmentState`, `merchantName`.  The `query` parameter supports searching by merchant name and smart queries with the table\'s columns. (Example: `fulfillmentType:IN_STORE`)  
     * Gets fulfillments associated with the workspace
     */
    async getFulfillments(requestParameters: GetFulfillmentsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<GetFulfillmentsResponse> {
        const response = await this.getFulfillmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetFulfillmentsSortDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type GetFulfillmentsSortDirectionEnum = typeof GetFulfillmentsSortDirectionEnum[keyof typeof GetFulfillmentsSortDirectionEnum];
