import {
  DateRestriction,
  IncentivePlanMerchantEligibility,
  IncentivePlanUserTargeting,
  Notification,
  ScheduleStatus,
} from 'lib/generated/api'
import { LatLong } from './LatLong'
import { MerchantList } from './MerchantList'

export const scheduleTypes = [
  'none',
  'daily',
  'weekly',
  'monthly',
  'yearly',
  'custom',
] as const
export type ScheduleType = typeof scheduleTypes[number]

export const scheduleFrequencies = [
  'daily',
  'weekly',
  'monthly',
  'yearly',
] as const
export type ScheduleFrequency = typeof scheduleFrequencies[number]

export const scheduleOccurrences = [
  'first',
  'second',
  'third',
  'fourth',
  'last',
] as const
export type ScheduleOccurrence = typeof scheduleOccurrences[number]

export const scheduleCustomOccurrences = [
  'sameMonthDay',
  'sameWeekDay',
] as const
export type ScheduleCustomOccurrence = typeof scheduleCustomOccurrences[number]

export const scheduleEndings = ['never', 'date'] as const
export type ScheduleEnding = typeof scheduleEndings[number]

export interface IncentivePlanSchedule {
  type: ScheduleType
  start: Date
  oneTimeStartTime: string
  oneTimeEndDate: Date
  oneTimeEndTime: string
  timezone: string
  fromTime: string
  toTime: string
  interval?: number
  customFrequency: ScheduleFrequency
  days: number[]
  weekRange: number
  monthlyOccurrence: ScheduleOccurrence
  customOccurrence: ScheduleCustomOccurrence
  ending: ScheduleEnding
  endingOn: Date
}

export const incentiveTypes = [
  'creditClone',
  'creditOneTime',
  'creditRecurring',
  'discountAmount',
  'discountPercentage',
] as const
export type IncentiveType = typeof incentiveTypes[number]

export const merchantParticipationTypes = [
  'all_merchants',
  'workspace',
  'merchant_list',
  'geo_restrictions',
] as const
export type MerchantParticipationType = typeof merchantParticipationTypes[number]

export const incentivePlanStatus = [
  'draft',
  'upcoming',
  'live',
  'paused',
  'ended',
] as const
export type IncentivePlanStatus = typeof incentivePlanStatus[number]

export interface IncentiveDetails {
  name?: string
  description?: string
  type?: IncentiveType
  currencyCode?: string
  creditAmount?: string
  discountAmount?: string
  discountPercentage?: number
  discountValueMax?: string
  orderValueMin?: string
  schedule?: IncentivePlanSchedule
}

export interface Polygon {
  id?: string
  name?: string
  coordinates: string
}

export interface Radius {
  id?: string
  name?: string
  radius: number
  latLong: LatLong
  googlePlaceId?: string
}

export interface MerchantParticipation {
  original?: IncentivePlanMerchantEligibility
  type: MerchantParticipationType
  merchantList?: MerchantList
  polygonRestrictions?: Polygon[]
  radiusRestrictions?: Radius[]
}

export interface IncentiveRecipients {
  original?: IncentivePlanUserTargeting
  allCompanies: boolean
  companies: string[]
  allPeople: boolean
  people: string[]
}

export interface IncentivePlan {
  id: string
  status: IncentivePlanStatus
  scheduleStatus?: ScheduleStatus
  canLaunch: boolean
  canRelaunch: boolean
  canPause: boolean
  canResume: boolean
  canDelete: boolean
  incentiveDetails: IncentiveDetails
  merchantParticipation: MerchantParticipation
  recipients: IncentiveRecipients
  notifications: Notification[]
  dateRestrictions: DateRestriction[]
  dataVersion?: number
}
