import { format } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import { ByWeekday, Frequency, Options, RRule, Weekday } from 'rrule'
import {
  CurrencyCodeFromJSON,
  IncentivePlanConfiguration,
  IncentiveTemplate,
  IncentiveType,
  Schedule,
  ScheduleStatus,
} from 'lib/generated/api'
import {
  IncentiveDetails,
  IncentivePlan,
  ScheduleFrequency,
  ScheduleOccurrence,
} from 'types/entities'
import { parseMicro } from 'utils/conversion'

const frequencyMap: Record<ScheduleFrequency, Frequency> = {
  daily: Frequency.DAILY,
  weekly: Frequency.WEEKLY,
  monthly: Frequency.MONTHLY,
  yearly: Frequency.YEARLY,
}

const weekdayMap: Record<number, Weekday> = {
  0: RRule.SU,
  1: RRule.MO,
  2: RRule.TU,
  3: RRule.WE,
  4: RRule.TH,
  5: RRule.FR,
  6: RRule.SA,
}

const ordinalMap: Record<ScheduleOccurrence, number> = {
  first: 1,
  second: 2,
  third: 3,
  fourth: 4,
  last: -1,
}

export const byWeekdayMapper = (day: number): ByWeekday => {
  if (day === 0) return 6
  return day - 1
}

const getDateParts = (date: Date) => ({
  monthDay: date.getDate(),
  weekDay: date.getDay(),
  weekDayOrdinal: Math.ceil(date.getDate() / 7),
})

export const mapToIncentivePlanConfiguration = (
  incentiveDetails: IncentiveDetails
): IncentivePlanConfiguration => {
  const {
    name,
    description,
    type,
    currencyCode,
    creditAmount,
    schedule: scheduleData,
  } = incentiveDetails

  const template: IncentiveTemplate = {}
  if (type === 'creditOneTime' || type === 'creditRecurring') {
    template.type = IncentiveType.Credit
    template.currencyCode = CurrencyCodeFromJSON(currencyCode)
    template.creditAmount = parseMicro(creditAmount)
  }

  if (!scheduleData) {
    return {
      name,
      description,
      incentiveTemplate: template,
    }
  }

  const {
    type: scheduleType,
    start,
    oneTimeStartTime,
    oneTimeEndDate,
    oneTimeEndTime,
    timezone,
    fromTime,
    toTime,
    interval,
    customFrequency,
    days,
    weekRange,
    monthlyOccurrence,
    customOccurrence,
    ending,
    endingOn,
  } = scheduleData

  template.timezone = timezone

  // availableFrom and availableTo indicates the time range an incentive will be
  // available for use PER DAY. For example, a MONTHLY recurring incentive with
  // availableFrom of "09:00" and availableTo of "17:00" would be available from
  // 9AM to 5PM each day it occurs
  // for one-time credits, the time span is marked with oneTimeStartDateTime
  // and oneTimeEndDateTime.
  if (type === 'creditOneTime') {
    const startDate = format(start, 'yyyy-MM-dd')
    const endDate = format(oneTimeEndDate, 'yyyy-MM-dd')
    template.oneTimeStartDateTime = zonedTimeToUtc(
      `${startDate}T${oneTimeStartTime}:00`,
      timezone
    )
    template.oneTimeEndDateTime = zonedTimeToUtc(
      `${endDate}T${oneTimeEndTime}:00`,
      timezone
    )
    // availableFrom is needed for the schedule
    template.availableFrom = oneTimeStartTime
  } else {
    template.availableFrom = fromTime
    template.availableTo = toTime
  }

  // Duration of the resulting incentive
  if (type === 'creditOneTime') {
    // do not set duration for one-time incentive
  } else if (
    scheduleType === 'weekly' ||
    (scheduleType === 'custom' && customFrequency === 'weekly')
  ) {
    template.duration = `P${weekRange}D`
  } else {
    template.duration = 'P0D'
  }

  // Incentive schedule
  const startDate = format(start, 'yyyy-MM-dd')
  const scheduleDateTime = zonedTimeToUtc(
    `${startDate}T${template.availableFrom}:00`,
    timezone
  )
  const schedule: Schedule = {
    start: {
      dateTime: scheduleDateTime,
    },
    timezone,
  }

  // Incentive schedule recurrence
  if (type !== 'creditOneTime' && scheduleType !== 'none') {
    const { monthDay, weekDay, weekDayOrdinal } = getDateParts(start)
    const options: Partial<Options> = {}
    if (scheduleType === 'custom') {
      options.freq = frequencyMap[customFrequency]
      options.interval = interval
      if (options.freq === RRule.WEEKLY) {
        options.wkst = weekdayMap[weekDay]
        options.byweekday = byWeekdayMapper(weekDay)
      }
      if (options.freq === RRule.MONTHLY) {
        if (customOccurrence === 'sameMonthDay') {
          options.bymonthday = monthDay
        } else {
          // customOccurrence === 'sameWeekDay'
          options.byweekday = weekdayMap[weekDay].nth(weekDayOrdinal)
        }
      }
    } else {
      options.freq = frequencyMap[scheduleType]
      if (options.freq === RRule.DAILY) {
        options.byweekday = days.map(byWeekdayMapper)
      }
      if (options.freq === RRule.WEEKLY) {
        options.wkst = weekdayMap[weekDay]
        options.byweekday = byWeekdayMapper(weekDay)
      }
      if (options.freq === RRule.MONTHLY) {
        options.byweekday = weekdayMap[weekDay].nth(
          ordinalMap[monthlyOccurrence]
        )
      }
    }
    if (ending === 'date') {
      const endingOnDatetime = `${format(endingOn, 'yyyy-MM-dd')}T23:59:59`
      options.until = zonedTimeToUtc(endingOnDatetime, timezone)
      schedule.end = {
        dateTime: zonedTimeToUtc(endingOnDatetime, timezone),
      }
    }

    schedule.recurrenceRule = RRule.optionsToString(options)
  }

  return {
    name,
    description,
    incentiveTemplate: template,
    schedule,
  }
}

export const countryToRadiusDisplayUnit: (
  countryCode?: string
) => 'km' | 'mi' = countryCode => {
  if (!countryCode) {
    return 'km'
  }
  if (['US', 'GB'].includes(countryCode)) {
    return 'mi'
  }
  return 'km'
}

export const isIncentiveEditable = (incentive?: IncentivePlan): boolean => {
  if (
    incentive &&
    incentive.incentiveDetails.type === 'creditOneTime' &&
    incentive.status !== 'draft' &&
    incentive.scheduleStatus !== ScheduleStatus.Upcoming
  ) {
    return false
  }
  return true
}
