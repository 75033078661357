/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    Billing,
    BillingFromJSON,
    BillingToJSON,
    BillingCard,
    BillingCardFromJSON,
    BillingCardToJSON,
    EntryCriteria,
    EntryCriteriaFromJSON,
    EntryCriteriaToJSON,
} from '../models';

export interface GetWorkspaceBillingRequest {
    workspaceId: string;
}

export interface GetWorkspaceEntryCriteriaRequest {
    organizationId: string;
    workspaceId: string;
}

export interface SetWorkspaceBillingCardRequest {
    organizationId: string;
    workspaceId: string;
    billingCard: BillingCard;
}

export interface UpdateWorkspaceEntryCriteriaRequest {
    organizationId: string;
    workspaceId: string;
    entryCriteria: EntryCriteria;
}

/**
 * 
 */
export class WorkspaceSettingsApi extends runtime.BaseAPI {

    /**
     * Returns the workspace billing details.
     */
    async getWorkspaceBillingRaw(requestParameters: GetWorkspaceBillingRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Billing>> {
        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling getWorkspaceBilling.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workspaces/{workspaceId}/settings/billing`.replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BillingFromJSON(jsonValue));
    }

    /**
     * Returns the workspace billing details.
     */
    async getWorkspaceBilling(requestParameters: GetWorkspaceBillingRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Billing> {
        const response = await this.getWorkspaceBillingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the workspace entry criteria.
     */
    async getWorkspaceEntryCriteriaRaw(requestParameters: GetWorkspaceEntryCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<EntryCriteria>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getWorkspaceEntryCriteria.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling getWorkspaceEntryCriteria.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/settings/entry-criteria`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EntryCriteriaFromJSON(jsonValue));
    }

    /**
     * Returns the workspace entry criteria.
     */
    async getWorkspaceEntryCriteria(requestParameters: GetWorkspaceEntryCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<EntryCriteria> {
        const response = await this.getWorkspaceEntryCriteriaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set the workspace billing credit card.
     */
    async setWorkspaceBillingCardRaw(requestParameters: SetWorkspaceBillingCardRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Billing>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling setWorkspaceBillingCard.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling setWorkspaceBillingCard.');
        }

        if (requestParameters.billingCard === null || requestParameters.billingCard === undefined) {
            throw new runtime.RequiredError('billingCard','Required parameter requestParameters.billingCard was null or undefined when calling setWorkspaceBillingCard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/settings/billing/card`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BillingCardToJSON(requestParameters.billingCard),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BillingFromJSON(jsonValue));
    }

    /**
     * Set the workspace billing credit card.
     */
    async setWorkspaceBillingCard(requestParameters: SetWorkspaceBillingCardRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Billing> {
        const response = await this.setWorkspaceBillingCardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the workspace entry criteria.
     */
    async updateWorkspaceEntryCriteriaRaw(requestParameters: UpdateWorkspaceEntryCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<EntryCriteria>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling updateWorkspaceEntryCriteria.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling updateWorkspaceEntryCriteria.');
        }

        if (requestParameters.entryCriteria === null || requestParameters.entryCriteria === undefined) {
            throw new runtime.RequiredError('entryCriteria','Required parameter requestParameters.entryCriteria was null or undefined when calling updateWorkspaceEntryCriteria.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/settings/entry-criteria`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EntryCriteriaToJSON(requestParameters.entryCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EntryCriteriaFromJSON(jsonValue));
    }

    /**
     * Update the workspace entry criteria.
     */
    async updateWorkspaceEntryCriteria(requestParameters: UpdateWorkspaceEntryCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<EntryCriteria> {
        const response = await this.updateWorkspaceEntryCriteriaRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
