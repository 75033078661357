import { Tag, TagProps, TagLabel } from '@ritualco/jutsu'
import { useContent } from 'lib/content/react'

export const BetaTag = (props: TagProps) => {
  const { formatMessage } = useContent()

  return (
    <Tag size="md" borderRadius="full" variant="solid" bg="black" {...props}>
      <TagLabel lineHeight="xsmall" fontWeight="medium">
        {formatMessage({ id: 'pmportal.global.tagBeta' })}
      </TagLabel>
    </Tag>
  )
}
