/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BillingType,
    BillingTypeFromJSON,
    BillingTypeFromJSONTyped,
    BillingTypeToJSON,
} from './BillingType';
import {
    PaymentMethod,
    PaymentMethodFromJSON,
    PaymentMethodFromJSONTyped,
    PaymentMethodToJSON,
} from './PaymentMethod';

/**
 * 
 * @export
 * @interface Billing
 */
export interface Billing {
    /**
     * 
     * @type {BillingType}
     * @memberof Billing
     */
    billingType?: BillingType;
    /**
     * Returns false if the current payment method is expired or invalid for any other reason
     * @type {boolean}
     * @memberof Billing
     */
    isCurrentBillingMethodValid?: boolean;
    /**
     * 
     * @type {Array<PaymentMethod>}
     * @memberof Billing
     */
    paymentMethods?: Array<PaymentMethod>;
}

export function BillingFromJSON(json: any): Billing {
    return BillingFromJSONTyped(json, false);
}

export function BillingFromJSONTyped(json: any, ignoreDiscriminator: boolean): Billing {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billingType': !exists(json, 'billingType') ? undefined : BillingTypeFromJSON(json['billingType']),
        'isCurrentBillingMethodValid': !exists(json, 'isCurrentBillingMethodValid') ? undefined : json['isCurrentBillingMethodValid'],
        'paymentMethods': !exists(json, 'paymentMethods') ? undefined : ((json['paymentMethods'] as Array<any>).map(PaymentMethodFromJSON)),
    };
}

export function BillingToJSON(value?: Billing | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billingType': BillingTypeToJSON(value.billingType),
        'isCurrentBillingMethodValid': value.isCurrentBillingMethodValid,
        'paymentMethods': value.paymentMethods === undefined ? undefined : ((value.paymentMethods as Array<any>).map(PaymentMethodToJSON)),
    };
}

