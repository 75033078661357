/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WorkspaceMemberDepartmentCode,
    WorkspaceMemberDepartmentCodeFromJSON,
    WorkspaceMemberDepartmentCodeFromJSONTyped,
    WorkspaceMemberDepartmentCodeToJSON,
} from './WorkspaceMemberDepartmentCode';

/**
 * 
 * @export
 * @interface WorkspaceMembersBatchUpdateDepartmentCodesRequest
 */
export interface WorkspaceMembersBatchUpdateDepartmentCodesRequest {
    /**
     * 
     * @type {Array<WorkspaceMemberDepartmentCode>}
     * @memberof WorkspaceMembersBatchUpdateDepartmentCodesRequest
     */
    workspaceMembers: Array<WorkspaceMemberDepartmentCode>;
    /**
     * Indicates that workspace invitations should be sent to emails not associated with any existing workspace members
     * @type {boolean}
     * @memberof WorkspaceMembersBatchUpdateDepartmentCodesRequest
     */
    shouldSendInvitations?: boolean;
}

export function WorkspaceMembersBatchUpdateDepartmentCodesRequestFromJSON(json: any): WorkspaceMembersBatchUpdateDepartmentCodesRequest {
    return WorkspaceMembersBatchUpdateDepartmentCodesRequestFromJSONTyped(json, false);
}

export function WorkspaceMembersBatchUpdateDepartmentCodesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceMembersBatchUpdateDepartmentCodesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'workspaceMembers': ((json['workspaceMembers'] as Array<any>).map(WorkspaceMemberDepartmentCodeFromJSON)),
        'shouldSendInvitations': !exists(json, 'shouldSendInvitations') ? undefined : json['shouldSendInvitations'],
    };
}

export function WorkspaceMembersBatchUpdateDepartmentCodesRequestToJSON(value?: WorkspaceMembersBatchUpdateDepartmentCodesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'workspaceMembers': ((value.workspaceMembers as Array<any>).map(WorkspaceMemberDepartmentCodeToJSON)),
        'shouldSendInvitations': value.shouldSendInvitations,
    };
}

