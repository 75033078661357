import { Heading, Text } from '@ritualco/jutsu'
import queryString from 'query-string'
import { useGetWorkspaceInvite } from 'api/endpoints/connections'
import { useContent } from 'lib/content/react'
import {
  OrganizationType,
  WorkspaceConnectionInviteInfoStatusEnum,
} from 'lib/generated/api'
import { useLocation } from 'lib/router'
import { useStore } from 'state/store'
import { useEffectOnce } from 'utils/react'
import { useHistory } from 'react-router-dom'
import { LoginShell } from './LoginShell'
import { LoginProps } from './types'
import { LoginDefault } from './LoginDefault'

export const LoginWorkspaceConnections = (loginProps: LoginProps) => {
  const { formatMessage } = useContent()
  const setRitualLogoType = useStore(state => state.setRitualLogoType)
  const location = useLocation()
  const history = useHistory()

  const { connectionId: id } = queryString.parse(location.search)
  const connectionId = (id as string) || ''
  const { status, data } = useGetWorkspaceInvite({ connectionId })
  const NOT_ALLOWED_STATUSES: Set<WorkspaceConnectionInviteInfoStatusEnum> = new Set(
    [
      WorkspaceConnectionInviteInfoStatusEnum.Expired,
      WorkspaceConnectionInviteInfoStatusEnum.Revoked,
      WorkspaceConnectionInviteInfoStatusEnum.Rejected,
    ]
  )

  useEffectOnce(() => {
    setRitualLogoType(OrganizationType.Company)
  })

  if (status === 'error' || status === 'idle')
    return <LoginDefault {...loginProps} />

  if (status === 'loading' || !data) return null

  if (NOT_ALLOWED_STATUSES.has(data.status)) history.push('/')

  return (
    <LoginShell {...loginProps}>
      <Heading fontSize="2xlarge" as="h1">
        {formatMessage({ id: 'pmportal.login.connectionsSignInTitle' })}
      </Heading>
      <Text fontSize="small" color="grayscale.secondary" mt={5}>
        {formatMessage(
          { id: 'pmportal.login.connectionsSignInDescription' },
          {
            organization: data.inviterOrganizationName,
            workspace: data.inviterWorkspaceName,
          }
        )}
      </Text>
    </LoginShell>
  )
}
