import { useMemo } from 'react'
import { Column, Cell } from 'react-table'
import { BasicTable } from 'components/BasicTable'
import { useContent } from 'lib/content/react'
import { ProtectedButton } from 'components/ProtectedButton'
import { HStack } from '@chakra-ui/layout'
import { PortalAdminRole } from 'lib/generated/api'
import { IncentivePlan } from 'types/entities'
import { Icon } from '@ritualco/jutsu'

import { MdFileDownload } from 'react-icons/md'
import { Title } from '../IncentivePlansTable/utils'

export type ReportsTableProps = {
  reports?: IncentivePlan[]
  onDownload: (report: IncentivePlan) => void
}

export type ActionsProps = {
  report: IncentivePlan
  onDownload: (report: IncentivePlan) => void
}

const Actions = ({ report, onDownload }: ActionsProps) => {
  const { formatMessage } = useContent()

  return (
    <HStack spacing={2} justifyContent="flex-end">
      <ProtectedButton
        adminRole={PortalAdminRole.ViewOnly}
        variant="outline"
        colorScheme="transparent"
        size="sm"
        onClick={() => onDownload(report)}
        leftIcon={<Icon w="18px" h="18px" as={MdFileDownload} />}
      >
        {formatMessage({ id: 'pmportal.incentives.reportDownload' })}
      </ProtectedButton>
    </HStack>
  )
}

export const ReportsTable = ({
  reports = [],
  onDownload,
}: ReportsTableProps) => {
  const { formatMessage } = useContent()
  const columns = useMemo<Column<IncentivePlan>[]>(
    () => [
      {
        id: 'title',
        accessor: ({ incentiveDetails }) => incentiveDetails.name,
        Header: formatMessage({ id: 'pmportal.incentives.nameColumnReports' }),
        Cell: Title,
      },
      {
        id: 'actions',
        Cell: ({ row: { original } }: Cell<IncentivePlan>) => (
          <Actions report={original} onDownload={onDownload} />
        ),
      },
    ],
    [formatMessage, onDownload]
  )

  return (
    <BasicTable
      columns={columns}
      data={reports}
      placeholder={formatMessage({
        id: 'pmportal.incentives.noIncentiveReports',
      })}
      tdProps={() => ({ lineHeight: 'inherit' })}
    />
  )
}
