import {
  ComponentType,
  FC,
  useState,
  useEffect,
  useContext,
  useRef,
  createContext,
  SetStateAction,
  Dispatch,
  useCallback,
} from 'react'
import {
  IntlProvider,
  useIntl,
  injectIntl,
  FormattedDate,
  FormattedDateParts,
  FormattedDateTimeRange,
  FormattedDisplayName,
  FormattedList,
  FormattedMessage,
  FormattedNumber,
  FormattedNumberParts,
  FormattedPlural,
  FormattedRelativeTime,
  FormattedTime,
  FormattedTimeParts,
} from 'react-intl'
import { useQuery, QueryClientProvider, QueryClient } from 'react-query'
import { ContentManager, MessageRecord } from '../types'

type ContentContext = {
  locale: string
  setLocale: Dispatch<SetStateAction<string>>
}
const contentContext = createContext<ContentContext>({} as ContentContext)

export type ContentProviderProps = {
  contentManager: ContentManager
  LoadingComponent: ComponentType
  ErrorComponent: ComponentType<{ error?: Error }>
  /** Initial resource sets to fetch */
  initialResourceSets: string[]
  initialLocale: string
  onLocaleChange?: (newLocale: string) => void
}

const InnerClient: FC<ContentProviderProps> = ({
  children,
  initialLocale = 'en',
  contentManager,
  initialResourceSets,
  ErrorComponent,
  LoadingComponent,
  onLocaleChange,
}) => {
  const [locale, setLocale] = useState(initialLocale)
  const prevLocale = useRef<string>(initialLocale)
  const [resourceSets] = useState(initialResourceSets)

  const fetchMessages = useCallback(
    () =>
      contentManager.messagesLoader
        .fetchSelectedResourceSets({ locale, resourceSets })
        .then(sets =>
          sets.reduce(
            (messageObj, x) => ({
              ...messageObj,
              ...contentManager.toMessageRecord(x),
            }),
            {} as MessageRecord
          )
        ),
    [contentManager, locale, resourceSets]
  )

  const { data: messages, status, error } = useQuery<MessageRecord, Error>(
    ['__content_resourceSets', resourceSets],
    fetchMessages
  )

  useEffect(() => {
    if (locale !== prevLocale.current) {
      if (onLocaleChange) onLocaleChange(locale)
    }
  }, [locale, onLocaleChange])

  useEffect(() => {
    prevLocale.current = locale
  })

  return (
    <contentContext.Provider value={{ locale, setLocale }}>
      <IntlProvider messages={messages} locale={locale}>
        {status === 'loading' && <LoadingComponent />}
        {status === 'error' && <ErrorComponent error={error || undefined} />}

        {['success', 'idle'].includes(status) && children}
      </IntlProvider>
    </contentContext.Provider>
  )
}
export const ContentProvider: FC<
  ContentProviderProps & {
    queryClient?: QueryClient
  }
> = ({ queryClient, ...props }) =>
  queryClient ? (
    <QueryClientProvider client={queryClient}>
      <InnerClient {...props} />
    </QueryClientProvider>
  ) : (
    <InnerClient {...props} />
  )

export const useContent = () => {
  const intl = useIntl()
  const { locale, setLocale } = useContext(contentContext)

  return { ...intl, locale, setLocale }
}

export const injectContent = injectIntl

// reexport react-intl components these should be considered **escape hatches** if you cannot leverage the `useContent` hook
export {
  FormattedDate,
  FormattedDateParts,
  FormattedDateTimeRange,
  FormattedDisplayName,
  FormattedList,
  FormattedMessage,
  FormattedNumber,
  FormattedNumberParts,
  FormattedPlural,
  FormattedRelativeTime,
  FormattedTime,
  FormattedTimeParts,
}
