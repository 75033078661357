import { FC, Suspense, useState } from 'react'
import { useEffectOnce } from 'react-use'
import { extendTheme, theme, ThemeProvider } from '@ritualco/jutsu'
import '@ritualco/jutsu/fonts'
import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider, useQueryClient } from 'react-query'

import { ApiClient } from 'api/clients'
import { getExternalUserId, useHandleApiError } from 'api/utils'
import { AuthProvider, useAuth } from 'auth'
import ErrorPage from 'components/ErrorPage'
import { AppShell } from 'components/AppShell'
import { CONTENTFUL, CONTENTFUL_RESOURCESETS } from 'config'
import { LoginContainer } from 'containers/LoginContainer'
import { ApiClientProvider } from 'contexts/ApiClientContext'
import { MessageProvider } from 'contexts/MessageContext'
import AnalyticsProvider from 'lib/simple-analytics'
import { createContentManager } from 'lib/content'
import { createContentfulLoader } from 'lib/content/loaders'
import { ContentProvider } from 'lib/content/react'
import { Configuration } from 'lib/generated/api'
import {
  BrowserRouter as Router,
  ProtectedRoute,
  Route,
  Switch,
} from 'lib/router'
import { useStore } from 'state/store'
import { getCookie } from 'utils/cookie'
import { mapToContentfulLocale } from 'utils/locale'
import { RemoteConfigProvider } from 'lib/remoteConfig'
import { Locale } from 'types/locale'
import { globalRoutes, useActiveRoutes } from 'routes'
import { getAnonymousAccessToken } from 'utils/anonymousToken'
import { LoadingScreen } from 'components/Loading'
import { Error } from './views/Error'

const AnalyticsSetup: FC = ({ children }) => {
  const workspaceId = useStore(state => state.workspace.id) || ''
  const organizationId = useStore(state => state.organization.id) || ''
  const organizationType = useStore(state => state.organization.type) || ''
  const eventProperties = { workspaceId, organizationId, organizationType }

  return (
    <AnalyticsProvider globalEventProperties={eventProperties}>
      {children}
    </AnalyticsProvider>
  )
}

// Mounts after the auth provider renders the remaining component tree
const AuthAwareApp = () => {
  const { authenticated, token } = useAuth()
  const routes = useActiveRoutes()
  const [anonymousToken, setAnonymousToken] = useState()

  // This hook is dependent on the Content, Message, and Auth contexts
  const onError = useHandleApiError()
  const queryClient = useQueryClient()
  queryClient.setDefaultOptions({
    queries: { onError },
    mutations: { onError },
  })

  useEffectOnce(() => {
    getAnonymousAccessToken({
      realmUrl: `${process.env.REACT_APP_KEYCLOAK_AUTH_URL}/realms/${process.env.REACT_APP_KEYCLOAK_AUTH_REALM}`,
      clientId: process.env.REACT_APP_KEYCLOAK_ANON_CLIENT,
      clientSecret: process.env.REACT_APP_KEYCLOAK_ANON_SECRET,
    }).then(response => {
      setAnonymousToken(response.token)
    })
  })

  const accessToken = authenticated ? token : anonymousToken
  const externalUserId = authenticated ? getExternalUserId(token) : ''

  // Backend requires that the client have an access token.
  // Wait until we have either an anonymous token or a user's token
  if (!accessToken) return null

  const config = new Configuration({
    accessToken,
    basePath: process.env.REACT_APP_BACKEND_URL,
  })
  const apiClient = new ApiClient(config)

  return (
    <ApiClientProvider apiClient={apiClient}>
      <Suspense fallback={<LoadingScreen />}>
        <Switch>
          {globalRoutes.map(route =>
            route.protected ? (
              <ProtectedRoute
                challenge={() => authenticated}
                fallbackComponent={
                  route.fallBackComponent
                    ? route.fallBackComponent
                    : LoginContainer
                }
                key={route.path}
                {...route}
              />
            ) : (
              <Route key={route.path} {...route} />
            )
          )}
          <ProtectedRoute
            challenge={() => authenticated}
            fallbackComponent={LoginContainer}
            path="*"
          >
            <AppShell routes={routes} externalUserId={externalUserId} />
          </ProtectedRoute>
        </Switch>
      </Suspense>
    </ApiClientProvider>
  )
}

const App = () => {
  const queryClient = new QueryClient()
  const locale = (getCookie('locale') || 'en-CA') as Locale
  const extendedTheme = extendTheme(
    {
      styles: {
        global: {
          '.off-white-background': {
            background: 'grayscale.offWhite',
          },
        },
      },
      space: {
        sidebar: {
          medium: '300px',
        },
      },
    },
    theme
  )

  return (
    <ThemeProvider theme={extendedTheme}>
      <QueryClientProvider client={queryClient}>
        <ContentProvider
          initialLocale={mapToContentfulLocale(locale)}
          initialResourceSets={CONTENTFUL_RESOURCESETS}
          contentManager={createContentManager({
            messageLoader: createContentfulLoader(CONTENTFUL),
          })}
          LoadingComponent={() => <LoadingScreen />}
          ErrorComponent={({ error }) => <h1>Error {console.error(error)}</h1>}
        >
          <Sentry.ErrorBoundary fallback={ErrorPage}>
            <MessageProvider>
              <AnalyticsSetup>
                <AuthProvider
                  LoadingComponent={() => <LoadingScreen />}
                  // TODO: add error state
                  ErrorComponent={err => (
                    <>
                      <Error />
                      <pre>{JSON.stringify(err)}</pre>
                    </>
                  )}
                >
                  <RemoteConfigProvider>
                    <Router>
                      <AuthAwareApp />
                    </Router>
                  </RemoteConfigProvider>
                </AuthProvider>
              </AnalyticsSetup>
            </MessageProvider>
          </Sentry.ErrorBoundary>
        </ContentProvider>
      </QueryClientProvider>
    </ThemeProvider>
  )
}

export default App
