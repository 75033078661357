import { Box, Spinner, SpinnerProps } from '@ritualco/jutsu'

export const LoadingSpinner = (props: SpinnerProps) => {
  const defaultProps = {
    thickness: '6px',
    speed: '0.75s',
    emptyColor: 'grayscale.quinary',
    color: 'teal.normal',
    size: 'xl',
    m: 'auto auto',
  }

  const mergedProps = {
    ...defaultProps,
    ...props,
  }

  return <Spinner {...mergedProps} />
}

const Loading = (props: SpinnerProps) => (
  <Box w="100%" h="100%" d="flex" data-testid="loading">
    <LoadingSpinner {...props} />
  </Box>
)

export const LoadingScreen = (props: SpinnerProps) => (
  <Box w="100vw" h="100vh" d="flex" data-testid="loading-screen">
    <LoadingSpinner {...props} />
  </Box>
)

export default Loading
