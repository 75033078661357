/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AuthorizationType,
    AuthorizationTypeFromJSON,
    AuthorizationTypeFromJSONTyped,
    AuthorizationTypeToJSON,
} from './AuthorizationType';

/**
 * 
 * @export
 * @interface EntryCriteria
 */
export interface EntryCriteria {
    /**
     * 
     * @type {AuthorizationType}
     * @memberof EntryCriteria
     */
    authorizationType?: AuthorizationType;
    /**
     * Comma separated list of whitelisted domains.
     * @type {string}
     * @memberof EntryCriteria
     */
    domainWhitelist?: string;
}

export function EntryCriteriaFromJSON(json: any): EntryCriteria {
    return EntryCriteriaFromJSONTyped(json, false);
}

export function EntryCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntryCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'authorizationType': !exists(json, 'authorizationType') ? undefined : AuthorizationTypeFromJSON(json['authorizationType']),
        'domainWhitelist': !exists(json, 'domainWhitelist') ? undefined : json['domainWhitelist'],
    };
}

export function EntryCriteriaToJSON(value?: EntryCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'authorizationType': AuthorizationTypeToJSON(value.authorizationType),
        'domainWhitelist': value.domainWhitelist,
    };
}

