/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * DOMAIN_VERIFIED: All domain verified users have privileged access.
 * PRIVATE: Disable privilege access - only administrators can perform privileged actions.
 * PUBLIC: Any Ritual user can perform privileged actions.
 * @export
 */
export const AccessRestriction = {
    DomainVerified: 'DOMAIN_VERIFIED',
    Private: 'PRIVATE',
    Public: 'PUBLIC'
} as const;
export type AccessRestriction = typeof AccessRestriction[keyof typeof AccessRestriction];


export function AccessRestrictionFromJSON(json: any): AccessRestriction {
    return AccessRestrictionFromJSONTyped(json, false);
}

export function AccessRestrictionFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessRestriction {
    return json as AccessRestriction;
}

export function AccessRestrictionToJSON(value?: AccessRestriction | null): any {
    return value as any;
}

