/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrganizationType,
    OrganizationTypeFromJSON,
    OrganizationTypeFromJSONTyped,
    OrganizationTypeToJSON,
} from './OrganizationType';
import {
    WorkspaceType,
    WorkspaceTypeFromJSON,
    WorkspaceTypeFromJSONTyped,
    WorkspaceTypeToJSON,
} from './WorkspaceType';

/**
 * 
 * @export
 * @interface WorkspaceMemberCampaign
 */
export interface WorkspaceMemberCampaign {
    /**
     * The campaign's internal ID (unique)
     * @type {string}
     * @memberof WorkspaceMemberCampaign
     */
    memberCampaignId: string;
    /**
     * The campaign's custom external ID (unique)
     * @type {string}
     * @memberof WorkspaceMemberCampaign
     */
    externalCampaignId?: string;
    /**
     * Public message for the campaign
     * @type {string}
     * @memberof WorkspaceMemberCampaign
     */
    campaignMessage?: string;
    /**
     * The URL to the member campaign signup flow
     * @type {string}
     * @memberof WorkspaceMemberCampaign
     */
    campaignUrl: string;
    /**
     * List of email domains eligible for this campaign
     * @type {Array<string>}
     * @memberof WorkspaceMemberCampaign
     */
    campaignDomainWhitelist?: Array<string>;
    /**
     * The name of the organization.
     * @type {string}
     * @memberof WorkspaceMemberCampaign
     */
    organizationName: string;
    /**
     * 
     * @type {OrganizationType}
     * @memberof WorkspaceMemberCampaign
     */
    organizationType: OrganizationType;
    /**
     * The name of the workspace.
     * @type {string}
     * @memberof WorkspaceMemberCampaign
     */
    workspaceName: string;
    /**
     * 
     * @type {WorkspaceType}
     * @memberof WorkspaceMemberCampaign
     */
    workspaceType: WorkspaceType;
    /**
     * The workspace ID of the workspace that is granting the incentive.
     * @type {string}
     * @memberof WorkspaceMemberCampaign
     */
    incentiveWorkspaceId?: string;
}

export function WorkspaceMemberCampaignFromJSON(json: any): WorkspaceMemberCampaign {
    return WorkspaceMemberCampaignFromJSONTyped(json, false);
}

export function WorkspaceMemberCampaignFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceMemberCampaign {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'memberCampaignId': json['memberCampaignId'],
        'externalCampaignId': !exists(json, 'externalCampaignId') ? undefined : json['externalCampaignId'],
        'campaignMessage': !exists(json, 'campaignMessage') ? undefined : json['campaignMessage'],
        'campaignUrl': json['campaignUrl'],
        'campaignDomainWhitelist': !exists(json, 'campaignDomainWhitelist') ? undefined : json['campaignDomainWhitelist'],
        'organizationName': json['organizationName'],
        'organizationType': OrganizationTypeFromJSON(json['organizationType']),
        'workspaceName': json['workspaceName'],
        'workspaceType': WorkspaceTypeFromJSON(json['workspaceType']),
        'incentiveWorkspaceId': !exists(json, 'incentiveWorkspaceId') ? undefined : json['incentiveWorkspaceId'],
    };
}

export function WorkspaceMemberCampaignToJSON(value?: WorkspaceMemberCampaign | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'memberCampaignId': value.memberCampaignId,
        'externalCampaignId': value.externalCampaignId,
        'campaignMessage': value.campaignMessage,
        'campaignUrl': value.campaignUrl,
        'campaignDomainWhitelist': value.campaignDomainWhitelist,
        'organizationName': value.organizationName,
        'organizationType': OrganizationTypeToJSON(value.organizationType),
        'workspaceName': value.workspaceName,
        'workspaceType': WorkspaceTypeToJSON(value.workspaceType),
        'incentiveWorkspaceId': value.incentiveWorkspaceId,
    };
}

