import { PropsWithChildren, Context, useContext, ComponentType } from 'react'

// based off of: "keycloak-js/dist/keycloak.d.ts#KeycloakProfile"
interface Profile {
  id?: string
  username?: string
  email?: string
  firstName?: string
  lastName?: string
  enabled?: boolean
  emailVerified?: boolean
  /**
   * Checks whether one time password is supported
   * @reference https://en.wikipedia.org/wiki/Time-based_One-Time_Password
   */
  supportsTotp?: boolean
  /** format: iso 8601 */
  createdTimestamp?: number
}

export type AuthError = { code: string; message?: string; payload?: unknown }
export type AuthProviderProps = PropsWithChildren<{
  onTokenExpiry?: () => void
  onAuthLogout?: () => void
  LoadingComponent?: ComponentType
  ErrorComponent?: ComponentType<{ error: AuthError }>
}>
export type AuthProviderComp = (props: AuthProviderProps) => JSX.Element

type AuthBaseOptions = { redirectUri?: string }

type AuthLoginOptions = AuthBaseOptions & {
  idpHint?: string
}
type AuthLogoutOptions = AuthBaseOptions
type AuthRegisterUrlOptions = AuthBaseOptions
type AuthAccountUrlOptions = AuthBaseOptions
type AuthProvider = ComponentType<AuthProviderProps>

export interface AuthInstance {
  authenticated: boolean
  token: string
  refreshToken?: string
  /**
   * Update the token if it's invalid within `minValidity` seconds
   * (note: `minValidity` of `-1` will update the token immediately without conditions)
   */
  updateToken(minValidity?: number): Promise<any>
  login(options?: AuthLoginOptions): void
  createLoginUrl(options?: AuthLoginOptions): string
  createLogoutUrl(options?: AuthLogoutOptions): string
  createRegisterUrl(options?: AuthRegisterUrlOptions): string
  createAccountUrl(options?: AuthAccountUrlOptions): string
  accountManagement(): void
  loadUserProfile(): Promise<Profile>
  handleTokenRefresh: () => void
}

export interface Adapter {
  getContext(): Context<AuthInstance>
  getProvider(): AuthProvider
}

interface AuthStrategy {
  AuthProvider: AuthProvider
  useAuth: () => AuthInstance
}

type FnCreateAuthStrategy = (adapter: Adapter) => AuthStrategy
export const createAuthStrategy: FnCreateAuthStrategy = adapter => ({
  AuthProvider: adapter.getProvider(),
  useAuth: () => useContext(adapter.getContext()),
})
