/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * confirmation status and eligibility for the requested action
 * @export
 */
export const Eligibility = {
    IneligibleForAction: 'INELIGIBLE_FOR_ACTION',
    EligibleForAction: 'ELIGIBLE_FOR_ACTION'
} as const;
export type Eligibility = typeof Eligibility[keyof typeof Eligibility];


export function EligibilityFromJSON(json: any): Eligibility {
    return EligibilityFromJSONTyped(json, false);
}

export function EligibilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): Eligibility {
    return json as Eligibility;
}

export function EligibilityToJSON(value?: Eligibility | null): any {
    return value as any;
}

