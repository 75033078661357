/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WorkspaceMemberStatus,
    WorkspaceMemberStatusFromJSON,
    WorkspaceMemberStatusFromJSONTyped,
    WorkspaceMemberStatusToJSON,
} from './WorkspaceMemberStatus';
import {
    WorkspaceStatus,
    WorkspaceStatusFromJSON,
    WorkspaceStatusFromJSONTyped,
    WorkspaceStatusToJSON,
} from './WorkspaceStatus';

/**
 * 
 * @export
 * @interface UpdateWorkspaceMember
 */
export interface UpdateWorkspaceMember {
    /**
     * Workspace member profile email
     * @type {string}
     * @memberof UpdateWorkspaceMember
     */
    email?: string;
    /**
     * Workspace member full name
     * @type {string}
     * @memberof UpdateWorkspaceMember
     */
    fullName?: string;
    /**
     * Ritual internal user id
     * @type {string}
     * @memberof UpdateWorkspaceMember
     */
    internalUserId?: string;
    /**
     * workspace floor id
     * @type {string}
     * @memberof UpdateWorkspaceMember
     */
    workspaceFloorId?: string;
    /**
     * workspace drop location id
     * @type {string}
     * @memberof UpdateWorkspaceMember
     */
    dropLocationId?: string;
    /**
     * 
     * @type {WorkspaceMemberStatus}
     * @memberof UpdateWorkspaceMember
     */
    workspaceMemberStatus?: WorkspaceMemberStatus;
    /**
     * 
     * @type {WorkspaceStatus}
     * @memberof UpdateWorkspaceMember
     */
    workspaceStatus?: WorkspaceStatus;
    /**
     * The department code of the workspace member
     * @type {string}
     * @memberof UpdateWorkspaceMember
     */
    departmentCode?: string;
}

export function UpdateWorkspaceMemberFromJSON(json: any): UpdateWorkspaceMember {
    return UpdateWorkspaceMemberFromJSONTyped(json, false);
}

export function UpdateWorkspaceMemberFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateWorkspaceMember {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'internalUserId': !exists(json, 'internalUserId') ? undefined : json['internalUserId'],
        'workspaceFloorId': !exists(json, 'workspaceFloorId') ? undefined : json['workspaceFloorId'],
        'dropLocationId': !exists(json, 'dropLocationId') ? undefined : json['dropLocationId'],
        'workspaceMemberStatus': !exists(json, 'workspaceMemberStatus') ? undefined : WorkspaceMemberStatusFromJSON(json['workspaceMemberStatus']),
        'workspaceStatus': !exists(json, 'workspaceStatus') ? undefined : WorkspaceStatusFromJSON(json['workspaceStatus']),
        'departmentCode': !exists(json, 'departmentCode') ? undefined : json['departmentCode'],
    };
}

export function UpdateWorkspaceMemberToJSON(value?: UpdateWorkspaceMember | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'fullName': value.fullName,
        'internalUserId': value.internalUserId,
        'workspaceFloorId': value.workspaceFloorId,
        'dropLocationId': value.dropLocationId,
        'workspaceMemberStatus': WorkspaceMemberStatusToJSON(value.workspaceMemberStatus),
        'workspaceStatus': WorkspaceStatusToJSON(value.workspaceStatus),
        'departmentCode': value.departmentCode,
    };
}

