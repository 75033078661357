/* eslint-disable no-param-reassign */
import {
  createContext,
  useState,
  useEffect,
  ComponentType,
  useCallback,
} from 'react'
import Keycloak, {
  KeycloakInstance,
  KeycloakConfig,
  KeycloakLoginOptions,
} from 'keycloak-js'
import { camelCase } from 'lodash'
import { useInterval } from 'hooks/useInterval'
import { AuthInstance, AuthProviderProps, AuthError } from '..'

type ProviderProps = AuthProviderProps & {
  instance: KeycloakInstance
}

type RawAttributes = {
  first_name: string[]
  is_signup_complete: Array<'true' | 'false'>
  last_name: string[]
  phone_number: string[]
  signup_origin: string[]
  user_id: string[]
}
const flattenAttributes = (attributes: RawAttributes) => {
  if (!attributes) return {}
  return Object.entries(attributes).reduce((result, entry) => {
    const key = camelCase(entry[0])
    const value = entry[1][0]

    try {
      result[key] = JSON.parse(value)
    } catch (e) {
      result[key] = value
    }

    return result
  }, {} as Record<string, string>)
}

const authContext = createContext<AuthInstance>({} as AuthInstance)

const AuthProvider = ({
  instance,
  children,
  onTokenExpiry = () => {},
  onAuthLogout = () => {},
  LoadingComponent = () => null,
  ErrorComponent = () => null,
}: ProviderProps) => {
  const [status, setStatus] = useState<'errored' | 'loading' | 'rendered'>(
    'loading'
  )
  const [error, setError] = useState<null | AuthError>(null)
  const [token, setToken] = useState<string>('')

  const init = useCallback(
    async () =>
      instance.init({
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
        checkLoginIframe: false,
      }),
    [instance]
  )

  useEffect(() => {
    setToken(instance.token || '')
  }, [instance.token, setToken])

  // Context didn't reliably update `token` just because Keycloak updated the token. This stateful version should update as needed
  instance.onAuthRefreshSuccess = () => {
    setToken(instance.token || '')
  }

  const handleTokenExpiry = useCallback(() => {
    instance.updateToken(60).catch(() => {
      instance.clearToken()

      // trigger a token expiry handler
      onTokenExpiry()

      // After the application completes cleanup, log the user out and redirect to homepage
      window.location.href = instance.createLogoutUrl({
        redirectUri: `${window.location.protocol}//${window.location.host}`,
      })
    })
  }, [instance, onTokenExpiry])

  const refreshIfAuthed = useCallback(() => {
    if (instance.authenticated) {
      handleTokenExpiry()
    }
  }, [instance, handleTokenExpiry])

  // On Keycloak's tokenExpired event, attempt to refresh the token
  useEffect(() => {
    instance.onTokenExpired = () => handleTokenExpiry()
    instance.onAuthLogout = () => onAuthLogout()
  }, [instance, handleTokenExpiry, onAuthLogout])

  // Attempt to refresh the token every 10 minutes
  useInterval(
    () => {
      handleTokenExpiry()
    },
    status === 'rendered' && instance.authenticated ? 60000 : null
  )

  // Attempt to refresh the token when the tab regains focus
  useEffect(() => {
    window.addEventListener('focus', refreshIfAuthed)

    return () => {
      window.removeEventListener('focus', refreshIfAuthed)
    }
  }, [refreshIfAuthed])

  // Initialize Keycloak, show error on timeout
  useEffect(() => {
    const timerId = setTimeout(() => {
      setStatus('errored')
      setError({ code: 'instanceTimedOut' })
    }, 60000)

    setError(null)

    init()
      .then(() => setStatus('rendered'))
      .catch(err => setError({ code: 'unhandledError', payload: err }))
      .finally(() => {
        clearTimeout(timerId)
      })
  }, [init])

  const login = (options: KeycloakLoginOptions) => {
    let loginUrl = instance.createLoginUrl(options)
    if (options.idpHint === 'phone') {
      loginUrl += '&login_method=zen'
    }
    window.location.assign(loginUrl)
  }

  return (
    <>
      {status === 'loading' && <LoadingComponent />}
      {status === 'rendered' && (
        <authContext.Provider
          value={
            {
              ...instance,
              token,
              login,
              handleTokenRefresh: handleTokenExpiry,
              loadUserProfile: async () => {
                const {
                  attributes,
                  ...profile
                } = (await instance.loadUserProfile()) as {
                  username: string
                  emailVerified: boolean
                  attributes: RawAttributes
                }

                return {
                  ...profile,
                  ...flattenAttributes(attributes),
                }
              },
            } as AuthInstance
          }
        >
          {children}
        </authContext.Provider>
      )}
      {status === 'errored' && (
        <ErrorComponent error={error || { code: 'unknownError' }} />
      )}
    </>
  )
}

const createInstance = (
  config: KeycloakConfig | string,
  Component: ComponentType<ProviderProps>,
  initialProps: AuthProviderProps
) => {
  const instance = Keycloak(config)

  return (props: AuthProviderProps) => (
    <Component {...initialProps} {...props} instance={instance} />
  )
}

const getProvider = (config: KeycloakConfig, props: AuthProviderProps) => () =>
  createInstance(config, AuthProvider, props)

const getContext = () => authContext

const initAdapter = (
  config: KeycloakConfig,
  // a bit hacky, defaults are passed to the provider to bypass the type compiler
  // the required props will be request when we invoke the `AuthProvider` component in a application
  // TODO: We should rework the dependency inversion so we don't have to do this weird type persuasion
  props: AuthProviderProps = {
    onTokenExpiry: () => {},
    onAuthLogout: () => {},
  }
) => ({ getProvider: getProvider(config, props), getContext })

export type { KeycloakConfig }

export default initAdapter
