import { RouteMap } from 'lib/router'
import { RouteChildrenProps } from 'react-router-dom'
import { ComponentType, lazy } from 'react'

const WorkspaceSwitcherContainer = lazy(
  () => import('containers/WorkspaceSwitcherContainer')
)
const ConnectionsSelectorContainer = lazy(
  () => import('containers/ConnectionsSelectorContainer')
)
const AdminInviteConfirmContainer = lazy(
  () => import('containers/AdminInviteConfirmContainer')
)
const OnboardingGateContainer = lazy(
  () => import('containers/OnboardingGateContainer')
)
const OnboardingPublishContainer = lazy(
  () => import('containers/OnboardingPublishContainer')
)
const OnboardingWelcomeContainer = lazy(
  () => import('containers/OnboardingWelcomeContainer')
)
const OnboardingStartContainer = lazy(
  () => import('containers/OnboardingStartContainer')
)
const OnboardingFormContainer = lazy(
  () => import('containers/OnboardingFormContainer')
)

const InviteMemberLandingPage = lazy(
  () => import('containers/InviteMemberLandingPageContainer')
)

const MemberAcceptedContainer = lazy(
  () => import('containers/MemberAcceptedContainer')
)
const InviteMemberLoginContainer = lazy(
  () => import('containers/InviteMemberLoginContainer')
)
const MemberCampaignInviteContainer = lazy(
  () => import('containers/MemberCampaignInviteContainer')
)
const MemberCampaignLoginContainer = lazy(
  () => import('containers/MemberCampaignLoginContainer')
)
const HowToJoinContainer = lazy(() => import('containers/HowToJoinContainer'))
const HowToJoinAdminContainer = lazy(
  () => import('containers/HowToJoinAdminContainer')
)

export const globalRoutes: RouteMap<{
  protected?: boolean
  fallBackComponent?: ComponentType<RouteChildrenProps>
}> = [
  {
    path: '/workspace-selector',
    component: WorkspaceSwitcherContainer,
    protected: true,
  },
  {
    path: '/invites/connections',
    component: ConnectionsSelectorContainer,
    protected: true,
  },
  {
    path: '/invites/organizations/:onboardingRecordId?',
    component: OnboardingGateContainer,
    protected: true,
  },
  {
    path: '/invites/admins',
    component: AdminInviteConfirmContainer,
    protected: true,
  },
  {
    path: '/welcome/:onboardingRecordId/form',
    component: OnboardingFormContainer,
    protected: true,
  },
  {
    path: '/welcome/:onboardingRecordId/publish',
    component: OnboardingPublishContainer,
    protected: true,
  },
  {
    path: '/welcome/start/:onboardingRecordId?',
    component: OnboardingStartContainer,
    protected: true,
  },
  {
    path: '/welcome/:onboardingRecordId?',
    component: OnboardingWelcomeContainer,
    protected: false,
  },
  {
    path: '/invites/members/landing',
    component: InviteMemberLandingPage,
    protected: false,
  },
  {
    path: '/invites/members',
    component: MemberAcceptedContainer,
    protected: true,
    fallBackComponent: InviteMemberLoginContainer,
  },
  {
    path: '/join-workspace',
    component: MemberCampaignInviteContainer,
    protected: true,
    fallBackComponent: MemberCampaignLoginContainer,
  },
  {
    path: '/how-to-join/admins',
    component: HowToJoinAdminContainer,
    protected: false,
  },
  {
    path: '/how-to-join',
    component: HowToJoinContainer,
    protected: false,
  },
]
