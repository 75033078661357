/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncentiveReportColumnKnownIds,
    IncentiveReportColumnKnownIdsFromJSON,
    IncentiveReportColumnKnownIdsFromJSONTyped,
    IncentiveReportColumnKnownIdsToJSON,
} from './IncentiveReportColumnKnownIds';

/**
 * 
 * @export
 * @interface AllIncentivesReportRun
 */
export interface AllIncentivesReportRun {
    /**
     * The starting timestamp in GMT for the report. When omitted, it implies the start time is the start of the first incentive
     * @type {Date}
     * @memberof AllIncentivesReportRun
     */
    startDateTime?: Date;
    /**
     * The ending timestamp in GMT for the report. When omitted, it implies the end time is now
     * @type {Date}
     * @memberof AllIncentivesReportRun
     */
    endDateTime?: Date;
    /**
     * The time zone of the start and end date time selected by the user (formatted according to IANA time zone database)
     * @type {string}
     * @memberof AllIncentivesReportRun
     */
    timezone?: string;
    /**
     * The type of incentive report that you want to run. Passed down to the reporting service.
     * @type {string}
     * @memberof AllIncentivesReportRun
     */
    reportType?: AllIncentivesReportRunReportTypeEnum;
    /**
     * A list of known column identifiers that should be additionally included in the results of this report run
     * @type {Array<IncentiveReportColumnKnownIds>}
     * @memberof AllIncentivesReportRun
     */
    includeExtraColumns?: Array<IncentiveReportColumnKnownIds>;
}


/**
 * @export
 */
export const AllIncentivesReportRunReportTypeEnum = {
    Incentive: 'incentive',
    IncentiveByUser: 'incentiveByUser'
} as const;
export type AllIncentivesReportRunReportTypeEnum = typeof AllIncentivesReportRunReportTypeEnum[keyof typeof AllIncentivesReportRunReportTypeEnum];


export function AllIncentivesReportRunFromJSON(json: any): AllIncentivesReportRun {
    return AllIncentivesReportRunFromJSONTyped(json, false);
}

export function AllIncentivesReportRunFromJSONTyped(json: any, ignoreDiscriminator: boolean): AllIncentivesReportRun {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startDateTime': !exists(json, 'startDateTime') ? undefined : (new Date(json['startDateTime'])),
        'endDateTime': !exists(json, 'endDateTime') ? undefined : (new Date(json['endDateTime'])),
        'timezone': !exists(json, 'timezone') ? undefined : json['timezone'],
        'reportType': !exists(json, 'reportType') ? undefined : json['reportType'],
        'includeExtraColumns': !exists(json, 'includeExtraColumns') ? undefined : ((json['includeExtraColumns'] as Array<any>).map(IncentiveReportColumnKnownIdsFromJSON)),
    };
}

export function AllIncentivesReportRunToJSON(value?: AllIncentivesReportRun | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'startDateTime': value.startDateTime === undefined ? undefined : (value.startDateTime.toISOString()),
        'endDateTime': value.endDateTime === undefined ? undefined : (value.endDateTime.toISOString()),
        'timezone': value.timezone,
        'reportType': value.reportType,
        'includeExtraColumns': value.includeExtraColumns === undefined ? undefined : ((value.includeExtraColumns as Array<any>).map(IncentiveReportColumnKnownIdsToJSON)),
    };
}

