import { useContent } from 'lib/content/react'
import { useLocation } from 'lib/router'
import queryString from 'query-string'
import { useGetWorkspaceAdminInviteDetails } from 'api/endpoints/portalAdmins'

const AdminInviteAccepted = () => {
  const { formatMessage } = useContent()

  const { accessCode = '' } = queryString.parse(useLocation().search)

  const workspaceInvite = useGetWorkspaceAdminInviteDetails({
    invitationId: accessCode as string,
  })

  if (!workspaceInvite.data) {
    return null
  }

  const { inviterWorkspaceName } = workspaceInvite.data

  return (
    <span>
      {formatMessage(
        { id: 'pmportal.alert.adminInviteAccepted' },
        { workspace: inviterWorkspaceName }
      )}
    </span>
  )
}

export default AdminInviteAccepted
