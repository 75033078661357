import { FC, useMemo } from 'react'
import { Badge } from '@ritualco/jutsu'
import { format } from 'date-fns'
import { Column } from 'react-table'
import { BasicTable } from 'components/BasicTable'
import { useContent } from 'lib/content/react'
import { AuditEvent } from 'lib/generated/api'

type AuditLogTableProps = {
  events?: AuditEvent[]
}

// todo: use contentful for header names and column categories
export const AuditLogTable: FC<AuditLogTableProps> = ({ events = [] }) => {
  const { formatMessage } = useContent()
  const columns = useMemo<Array<Column<AuditEvent>>>(
    () => [
      {
        accessor: 'description',
        Header: 'Action',
      },
      {
        accessor: 'category',
        Header: 'Category',
        Cell: ({ value }) => <Badge>{value}</Badge>,
      },
      {
        id: 'timestamp',
        Header: 'Time',
        accessor: ({ timestamp }) =>
          !timestamp ? '-' : format(timestamp, 'MMM dd, h:mm a'),
      },
    ],
    []
  )

  return (
    <BasicTable
      columns={columns}
      data={events}
      placeholder={formatMessage({ id: 'pmportal.global.emptyTable' })}
      tdProps={() => ({ lineHeight: 'inherit' })}
    />
  )
}
