/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrganizationType,
    OrganizationTypeFromJSON,
    OrganizationTypeFromJSONTyped,
    OrganizationTypeToJSON,
} from './OrganizationType';

/**
 * 
 * @export
 * @interface OrganizationAdminInvitationInfo
 */
export interface OrganizationAdminInvitationInfo {
    /**
     * The icon of the inviting organization. A square 128px x 128px.
     * @type {string}
     * @memberof OrganizationAdminInvitationInfo
     */
    inviterIcon?: string;
    /**
     * The inviting organization's unique identifier.
     * @type {string}
     * @memberof OrganizationAdminInvitationInfo
     */
    inviterOrganizationId?: string;
    /**
     * The name of the inviting organization.
     * @type {string}
     * @memberof OrganizationAdminInvitationInfo
     */
    inviterOrganizationName?: string;
    /**
     * 
     * @type {OrganizationType}
     * @memberof OrganizationAdminInvitationInfo
     */
    inviterOrganizationType?: OrganizationType;
    /**
     * A prompt for the invitee, to encourage them to accept the invitation.
     * @type {string}
     * @memberof OrganizationAdminInvitationInfo
     */
    inviteText?: string;
    /**
     * The last modified time of the invitation.
     * @type {Date}
     * @memberof OrganizationAdminInvitationInfo
     */
    updatedAt?: Date;
}

export function OrganizationAdminInvitationInfoFromJSON(json: any): OrganizationAdminInvitationInfo {
    return OrganizationAdminInvitationInfoFromJSONTyped(json, false);
}

export function OrganizationAdminInvitationInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationAdminInvitationInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inviterIcon': !exists(json, 'inviterIcon') ? undefined : json['inviterIcon'],
        'inviterOrganizationId': !exists(json, 'inviterOrganizationId') ? undefined : json['inviterOrganizationId'],
        'inviterOrganizationName': !exists(json, 'inviterOrganizationName') ? undefined : json['inviterOrganizationName'],
        'inviterOrganizationType': !exists(json, 'inviterOrganizationType') ? undefined : OrganizationTypeFromJSON(json['inviterOrganizationType']),
        'inviteText': !exists(json, 'inviteText') ? undefined : json['inviteText'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
    };
}

export function OrganizationAdminInvitationInfoToJSON(value?: OrganizationAdminInvitationInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inviterIcon': value.inviterIcon,
        'inviterOrganizationId': value.inviterOrganizationId,
        'inviterOrganizationName': value.inviterOrganizationName,
        'inviterOrganizationType': OrganizationTypeToJSON(value.inviterOrganizationType),
        'inviteText': value.inviteText,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
    };
}

