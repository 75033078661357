/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * the type of action for which the user is confirming their email
 * @export
 */
export const EmailConfirmAction = {
    MemberCampaign: 'MEMBER_CAMPAIGN',
    WorkspaceCampaign: 'WORKSPACE_CAMPAIGN',
    JoinSecuredCompany: 'JOIN_SECURED_COMPANY',
    JoinSecuredLocation: 'JOIN_SECURED_LOCATION',
    JoinSecuredFloor: 'JOIN_SECURED_FLOOR'
} as const;
export type EmailConfirmAction = typeof EmailConfirmAction[keyof typeof EmailConfirmAction];


export function EmailConfirmActionFromJSON(json: any): EmailConfirmAction {
    return EmailConfirmActionFromJSONTyped(json, false);
}

export function EmailConfirmActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailConfirmAction {
    return json as EmailConfirmAction;
}

export function EmailConfirmActionToJSON(value?: EmailConfirmAction | null): any {
    return value as any;
}

