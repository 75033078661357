import {
  Configuration,
  ConnectionsApi,
  EmailApi,
  IncentivePlanApi,
  IncentiveReportsApi,
  IncentivesApi,
  JobsApi,
  MerchantApi,
  MerchantListApi,
  OrganizationApi,
  PaymentMethodsApi,
  PermissionTicketApi,
  PortalAdminApi,
  WorkspaceApi,
  WorkspaceMembersApi,
  WorkspaceSettingsApi,
  WorkspaceCampaignsApi,
  FulfillmentApi,
} from 'lib/generated/api'

export interface IApiClient {
  connectionsApi: ConnectionsApi
  emailApi: EmailApi
  incentivePlanApi: IncentivePlanApi
  incentiveReportsApi: IncentiveReportsApi
  jobsApi: JobsApi
  merchantApi: MerchantApi
  merchantListApi: MerchantListApi
  organizationApi: OrganizationApi
  paymentMethodsApi: PaymentMethodsApi
  permissionTicketApi: PermissionTicketApi
  portalAdminApi: PortalAdminApi
  workspaceApi: WorkspaceApi
  workspaceMemberIncentivesApi: IncentivesApi
  workspaceMembersApi: WorkspaceMembersApi
  workspaceBillingApi: WorkspaceSettingsApi
  workspaceCampaignsApi: WorkspaceCampaignsApi
  fulfillmentApi: FulfillmentApi
}

export class ApiClient implements IApiClient {
  constructor(private configuration: Configuration) {}

  connectionsApi = new ConnectionsApi(this.configuration)

  emailApi = new EmailApi(this.configuration)

  incentivePlanApi = new IncentivePlanApi(this.configuration)

  incentiveReportsApi = new IncentiveReportsApi(this.configuration)

  jobsApi = new JobsApi(this.configuration)

  merchantApi = new MerchantApi(this.configuration)

  merchantListApi = new MerchantListApi(this.configuration)

  organizationApi = new OrganizationApi(this.configuration)

  paymentMethodsApi = new PaymentMethodsApi(this.configuration)

  permissionTicketApi = new PermissionTicketApi(this.configuration)

  portalAdminApi = new PortalAdminApi(this.configuration)

  workspaceApi = new WorkspaceApi(this.configuration)

  workspaceMemberIncentivesApi = new IncentivesApi(this.configuration)

  workspaceMembersApi = new WorkspaceMembersApi(this.configuration)

  workspaceBillingApi = new WorkspaceSettingsApi(this.configuration)

  fulfillmentApi = new FulfillmentApi(this.configuration)

  workspaceCampaignsApi = new WorkspaceCampaignsApi(this.configuration)
}
