/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BillingCard
 */
export interface BillingCard {
    /**
     * 
     * @type {string}
     * @memberof BillingCard
     */
    token?: string;
}

export function BillingCardFromJSON(json: any): BillingCard {
    return BillingCardFromJSONTyped(json, false);
}

export function BillingCardFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingCard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': !exists(json, 'token') ? undefined : json['token'],
    };
}

export function BillingCardToJSON(value?: BillingCard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
    };
}

