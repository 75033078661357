export enum PageTrackingNames {
  home = 'HERA_HOME_PAGE',
  incentives = 'HERA_INCENTIVES_PAGE',
  incentive = 'HERA_INCENTIVE_PAGE',
  merchantLists = 'HERA_MERCHANT_LISTS_PAGE',
  merchantList = 'HERA_MERCHANT_LIST_PAGE',
  employee = 'HERA_EMPLOYEE_PROFILE_PAGE',
  employees = 'HERA_EMPLOYEES_PAGE',
  groupOrders = 'HERA_GROUP_ORDERS_PAGE',
  incentiveHistory = 'HERA_INCENTIVE_HISTORY',
  job = 'HERA_JOB_PAGE',
  jobs = 'HERA_JOBS_PAGE',
  tenants = 'HERA_TENANTS_PAGE',
  reports = 'HERA_REPORTS_PAGE',

  //   Settings pages
  settings = 'HERA_SETTINGS_PAGE',
  team = 'HERA_TEAM_PAGE',
  connections = 'HERA_EXTERNAL_CONNECTIONS_PAGE',
  workspaceSettings = 'HERA_WORKSPACE_SETTINGS_PAGE',
  billing = 'HERA_BILLING_PAGE',
  help = 'HERA_HELP_PAGE',
  profile = 'HERA_PROFILE_PAGE',
  auditLog = 'HERA_AUDIT_LOG_PAGE',
  memberCampaigns = 'HERA_MEMBER_CAMPAIGNS_PAGE',
  memberCampaign = 'HERA_MEMBER_CAMPAIGN_PAGE',
  workspaceCampaigns = 'HERA_WORKSPACE_CAMPAIGNS_PAGE',
  workspaceCampaign = 'HERA_WORKSPACE_CAMPAIGN_PAGE',

  // Workspace member join flows
  memberInviteLanding = 'HERA_MEMBER_INVITE_LANDING_PAGE',
  memberInviteLogin = 'HERA_MEMBER_INVITE_LOGIN_PAGE',
  memberInviteAccepted = 'HERA_MEMBER_INVITE_ACCEPTED_PAGE',
  memberCampaignLogin = 'HERA_MEMBER_CAMPAIGN_LOGIN_PAGE',
  memberCampaignJoinPage = 'HERA_MEMBER_CAMPAIGN_JOIN_PAGE',
  howToJoin = 'HERA_HOW_TO_JOIN_PAGE',
  howToJoinAdmin = 'HERA_HOW_TO_JOIN_ADMIN_PAGE',

  // Others
  comingSoon = 'HERA_COMING_SOON_PAGE',
  notFound = 'HERA_NOT_FOUND_PAGE',
  placeholderHome = 'HERA_PLACEHOLDER_HOME_PAGE',
}

export enum AnalyticEvents {
  // PaymentMethod
  billingActionAddSuccess = 'HERA_BILLING_ACTION_ADD_SUCCESS',
  billingActionRemoveSuccess = 'HERA_BILLING_ACTION_REMOVE_SUCCESS',
  billingActionSwitchSuccess = 'HERA_BILLING_ACTION_SWITCH_SUCCESS',
  billingActionAddError = 'HERA_BILLING_ACTION_ADD_ERROR',

  // Authentication
  login = 'HERA_LOGIN_CLICK',
  signUp = 'HERA_SIGN_UP_CLICK',

  // Incentives
  incentiveLaunch = 'HERA_INCENTIVE_LAUNCH_CLICK',
  incentiveRelaunch = 'HERA_INCENTIVE_RELAUNCH_CLICK',
  incentiveView = 'HERA_INCENTIVE_VIEW_CLICK',
  incentivePause = 'HERA_INCENTIVE_PAUSE_CLICK',
  incentiveResume = 'HERA_INCENTIVE_RESUME_CLICK',
  incentiveCreate = 'HERA_INCENTIVE_CREATE_CLICK',
  incentiveDelete = 'HERA_INCENTIVE_DELETE_CLICK',
  incentiveClone = 'HERA_INCENTIVE_CLONE_CLICK',

  incentiveLaunchError = 'HERA_INCENTIVE_LAUNCH_ERROR',
  incentiveRelaunchError = 'HERA_INCENTIVE_RELAUNCH_ERROR',
  incentiveViewError = 'HERA_INCENTIVE_VIEW_ERROR',
  incentivePauseError = 'HERA_INCENTIVE_PAUSE_ERROR',
  incentiveResumeError = 'HERA_INCENTIVE_RESUME_ERROR',
  incentiveCreateError = 'HERA_INCENTIVE_CREATE_ERROR',
  incentiveDeleteError = 'HERA_INCENTIVE_DELETE_ERROR',
  incentiveCloneError = 'HERA_INCENTIVE_CLONE_ERROR',

  // Merchant list
  myListSaveQRCode = 'HERA_MY_LIST_SAVE_QR_CLICK',
  myListPreview = 'HERA_MY_LIST_PREVIEW_CLICK',
  myListView = 'HERA_MY_LIST_VIEW_CLICK',
  myListCreate = 'HERA_MY_LIST_CREATE_CLICKED', // original MY_LIST_TABLE_CREATE_NEW_LIST_CLICKED
  myListCopyUrl = 'HERA_MY_LIST_COPY_URL_CLICK', // original MY_LIST_TABLE_ROW_ACTION_COPY_LINK_CLICKED
  myListDelete = 'HERA_MY_LIST_DELETE_CLICK', // original MY_LIST_TABLE_ROW_ACTION_DELETE_CLICKED

  // Reports
  reportsDownloadAll = 'HERA_REPORT_DOWNLOAD_ALL_CLICK',
  reportsDownloadCsv = 'HERA_REPORT_DOWNLOAD_CSV_CLICK',

  reportsDownloadAllError = 'HERA_REPORT_DOWNLOAD_ALL_ERROR',
  reportsDownloadCsvError = 'HERA_REPORT_DOWNLOAD_CSV_ERROR',

  // Tenants
  tenantCompanyInvite = 'HERA_TENANT_COMPANY_INVITE_CLICK',
  tenantPeopleInvite = 'HERA_TENANT_PEOPLE_INVITE_CLICK',
  tenantPeopleBatchInviteJob = 'HERA_TENANT_PEOPLE_BATCH_INVITE_JOB_CLICK',

  tenantPeopleBatchInviteJobSuccess = 'HERA_TENANT_PEOPLE_BATCH_INVITE_JOB_SUCCESS',

  tenantCompanyInviteError = 'HERA_TENANT_COMPANY_INVITE_ERROR',
  tenantPeopleInviteError = 'HERA_TENANT_PEOPLE_INVITE_ERROR',
  tenantPeopleBatchInviteJobError = 'HERA_TENANT_PEOPLE_BATCH_INVITE_JOB_ERROR',

  // Employees
  employeeBatchInviteJob = 'HERA_EMPLOYEE_BATCH_INVITE_JOB_CLICK',
  employeeInvite = 'HERA_EMPLOYEE_INVITE_CLICK',
  employeeRemove = 'HERA_EMPLOYEE_REMOVE_CLICK',
  employeeRemoveBatch = 'HERA_EMPLOYEE_REMOVE_BATCH_CLICK',
  employeeResend = 'HERA_EMPLOYEE_RESEND_CLICK',
  employeeResendAll = 'HERA_EMPLOYEE_RESEND_ALL_CLICK',
  employeeUpdate = 'HERA_EMPLOYEE_UPDATE_CLICK',

  employeeInviteError = 'HERA_EMPLOYEE_INVITE_ERROR',
  employeeBatchInviteJobError = 'HERA_EMPLOYEE_BATCH_INVITE_JOB_ERROR',
  employeeRemoveError = 'HERA_EMPLOYEE_REMOVE_ERROR',
  employeeRemoveBatchError = 'HERA_EMPLOYEE_REMOVE_BATCH_ERROR',
  employeeResendError = 'HERA_EMPLOYEE_RESEND_ERROR',
  employeeResendAllError = 'HERA_EMPLOYEE_RESEND_ALL_ERROR',
  employeeUpdateError = 'HERA_EMPLOYEE_UPDATE_ERROR',

  employeeInviteSuccess = 'HERA_EMPLOYEE_INVITE_SUCCESS',
  employeeBatchInviteJobSuccess = 'HERA_EMPLOYEE_BATCH_INVITE_JOB_SUCCESS',
  employeeRemoveSuccess = 'HERA_EMPLOYEE_REMOVE_SUCCESS',
  employeeRemoveBatchSuccess = 'HERA_EMPLOYEE_REMOVE_BATCH_SUCCESS',
  employeeResendSuccess = 'HERA_EMPLOYEE_RESEND_SUCCESS',
  employeeResendAllSuccess = 'HERA_EMPLOYEE_RESEND_ALL_SUCCESS',
  employeeUpdateSuccess = 'HERA_EMPLOYEE_UPDATE_SUCCESS',

  employeeInvitePartialError = 'HERA_EMPLOYEE_INVITE_PARTIAL_ERROR',
  employeeRemoveBatchPartialError = 'HERA_EMPLOYEE_REMOVE_BATCH_PARTIAL_ERROR',
  employeeResendAllPartialError = 'HERA_EMPLOYEE_RESEND_ALL_PARTIAL_ERROR',

  // Workspace member join flows
  memberInviteContinueInBrowserClick = 'HERA_MEMEBER_INVITE_CONTINUE_IN_BROWSER_CLICK',
  memberInviteContinueInAppClick = 'HERA_MEMEBER_INVITE_CONTINUE_IN_APP_CLICK',
  memberCampaignSendEmailClick = 'HERA_MEMBER_CAMPAIGN_SEND_EMAIL_CLICK',
  memberCampaignSendEmailSuccess = 'HERA_MEMBER_CAMPAIGN_SEND_EMAIL_SUCCESS',
  memberCampaignSendEmailError = 'HERA_MEMBER_CAMPAIGN_SEND_EMAIL_ERROR',
  memberCampaignResendEmailClick = 'HERA_MEMBER_CAMPAIGN_RESEND_EMAIL_CLICK',
  memberCampaignChangeEmailClick = 'HERA_MEMBER_CAMPAIGN_CHANGE_EMAIL_CLICK',
  memberCampaignEmailConfirmSuccess = 'HERA_MEMBER_CAMPAIGN_EMAIL_CONFIRM_SUCCESS',
  memberCampaignEmailConfirmError = 'HERA_MEMBER_CAMPAIGN_EMAIL_CONFIRM_ERROR',
  howToJoinAdminLinkClick = 'HERA_HOW_TO_JOIN_ADMIN_LINK_CLICK',
  howToJoinAdminHelpLinkClick = 'HERA_HOW_TO_JOIN_ADMIN_HELP_LINK_CLICK',
  howToJoinAdminLoginLinkClick = 'HERA_HOW_TO_JOIN_ADMIN_LOGIN_LINK_CLICK',
  howToJoinAdminCreateTeamLinkClick = 'HERA_HOW_TO_JOIN_ADMIN_CREATE_TEAM_LINK_CLICK',

  // Group Orders
  groupOrdersTabClick = 'HERA_GROUP_ORDERS_TAB_CLICK',
  groupOrdersOrderHelpClick = 'HERA_GROUP_ORDERS_ORDER_HELP_CLICK',
  groupOrdersCopyOrderLinkClick = 'HERA_GROUP_ORDERS_COPY_ORDER_LINK_CLICK',
  groupOrdersViewOrderClick = 'HERA_GROUP_ORDERS_VIEW_ORDER_CLICK',
  groupOrdersActiveOrdersSearch = 'HERA_GROUP_ORDERS_ACTIVE_ORDERS_SEARCH',
  groupOrdersPastOrdersSearch = 'HERA_GROUP_ORDERS_PAST_ORDERS_SEARCH',
  groupOrdersActiveOrdersNextPageClick = 'HERA_GROUP_ORDERS_ACTIVE_ORDERS_NEXT_PAGE_CLICK',
  groupOrdersActiveOrdersPreviousPageClick = 'HERA_GROUP_ORDERS_ACTIVE_ORDERS_PREVIOUS_PAGE_CLICK',
  groupOrdersPastOrdersNextPageClick = 'HERA_GROUP_ORDERS_PAST_ORDERS_NEXT_PAGE_CLICK',
  groupOrdersPastOrdersPreviousPageClick = 'HERA_GROUP_ORDERS_PAST_ORDERS_PREVIOUS_PAGE_CLICK',
}
