/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * How the food will be received
 * @export
 */
export const FulfillmentType = {
    InStore: 'IN_STORE',
    Delivery: 'DELIVERY'
} as const;
export type FulfillmentType = typeof FulfillmentType[keyof typeof FulfillmentType];


export function FulfillmentTypeFromJSON(json: any): FulfillmentType {
    return FulfillmentTypeFromJSONTyped(json, false);
}

export function FulfillmentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FulfillmentType {
    return json as FulfillmentType;
}

export function FulfillmentTypeToJSON(value?: FulfillmentType | null): any {
    return value as any;
}

