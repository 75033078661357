import React, { PropsWithChildren } from 'react'
import { useEffectOnce } from 'react-use'
import { Box, Container, ContainerProps } from '@ritualco/jutsu'
import { useAnalytics } from 'lib/simple-analytics'

export type CardPageProps = PropsWithChildren<{
  maxW?: string
  trackingName?: string
}> &
  ContainerProps

export const CardPage = ({
  children,
  maxW = '428px',
  trackingName = '',
  ...containerProps
}: CardPageProps) => {
  const { page } = useAnalytics()

  useEffectOnce(() => {
    const eventProperties: Record<any, string> = {}
    if (trackingName) {
      eventProperties.pageName = trackingName
    }
    page(eventProperties)
  })

  return (
    <Container maxW={maxW} my={20} p={0} centerContent {...containerProps}>
      <Box
        bg="grayscale.white"
        borderRadius="lg"
        boxShadow="0px 1px 4px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(219, 222, 224, 0.9)"
        w="100%"
        p={12}
        pt={18}
      >
        {children}
      </Box>
    </Container>
  )
}
