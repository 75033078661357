import { Switch, Route } from 'lib/router'
import useQueryParam from 'hooks/useQueryParam'
import { LoginAdminInvites } from './LoginAdminInvites'
import { LoginWorkspaceConnections } from './LoginWorkspaceConnections'
import { LoginDefault } from './LoginDefault'
import { LoginProps } from './types'

const Login = (loginProps: LoginProps) => {
  const query = useQueryParam()

  return (
    <Switch>
      <Route path="/invites/admins">
        <LoginAdminInvites {...loginProps} />
      </Route>
      <Route path="/invites/connections">
        <LoginWorkspaceConnections {...loginProps} />
      </Route>
      <Route
        path={[
          '/welcome/:onboardingRecordId?',
          '/invites/organizations/:onboardingRecordId?',
        ]}
      >
        {query.get('connectionId') ? (
          <LoginWorkspaceConnections {...loginProps} />
        ) : (
          <LoginDefault {...loginProps} />
        )}
      </Route>
      <Route path="*">
        <LoginDefault {...loginProps} />
      </Route>
    </Switch>
  )
}

export default Login
