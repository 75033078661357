import { FullWidthPage } from 'components/PageTemplates'
import { useContent } from 'lib/content/react'
import { PageTrackingNames } from 'types/entities'

const NotFound = () => {
  const { formatMessage } = useContent()
  return (
    <FullWidthPage
      heading={formatMessage({ id: 'pmportal.error.pageNotFound' })}
      subheading={formatMessage({
        id: 'pmportal.error.pageNotFoundDescription',
      })}
      trackingName={PageTrackingNames.notFound}
    />
  )
}

export default NotFound
