/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LatLong,
    LatLongFromJSON,
    LatLongFromJSONTyped,
    LatLongToJSON,
} from './LatLong';

/**
 * 
 * @export
 * @interface Radius
 */
export interface Radius {
    /**
     * 
     * @type {string}
     * @memberof Radius
     */
    id?: string;
    /**
     * The internal display name
     * @type {string}
     * @memberof Radius
     */
    name?: string;
    /**
     * The radius in meters
     * @type {number}
     * @memberof Radius
     */
    radius: number;
    /**
     * 
     * @type {LatLong}
     * @memberof Radius
     */
    latLong: LatLong;
    /**
     * The Google Place ID of the center of the circle
     * @type {string}
     * @memberof Radius
     */
    googlePlaceId?: string;
}

export function RadiusFromJSON(json: any): Radius {
    return RadiusFromJSONTyped(json, false);
}

export function RadiusFromJSONTyped(json: any, ignoreDiscriminator: boolean): Radius {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'radius': json['radius'],
        'latLong': LatLongFromJSON(json['latLong']),
        'googlePlaceId': !exists(json, 'googlePlaceId') ? undefined : json['googlePlaceId'],
    };
}

export function RadiusToJSON(value?: Radius | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'radius': value.radius,
        'latLong': LatLongToJSON(value.latLong),
        'googlePlaceId': value.googlePlaceId,
    };
}

