import { FC } from 'react'
import { Box, Flex, List, ListProps, Text } from '@ritualco/jutsu'
import { useContent } from 'lib/content/react'
import { BetaTag } from 'components/BetaTag'

const SideNavFooter = () => {
  const { formatMessage } = useContent()

  return (
    <Flex p={6} alignItems="center" justifyContent="space-between">
      <BetaTag />
      <Text fontSize="xs" fontWeight="strong" color="grayscale.tertiary">
        {formatMessage(
          { id: 'pmportal.global.version' },
          {
            versionNumber: process.env.REACT_APP_VERSION,
          }
        )}
      </Text>
    </Flex>
  )
}

export type SideNavProps = ListProps
export const SideNav: FC<SideNavProps> = ({ children, ...props }) => (
  <List
    h="100%"
    overflow="auto"
    bg="grayscale.offWhite"
    boxShadow="inset -1px 0px 0px #E0E0E2, inset 0px -1px 0px #E0E0E2;"
    {...props}
  >
    <Flex direction="column" justify="space-between" h="100%" pt={12}>
      <Box>{children}</Box>
      <SideNavFooter />
    </Flex>
  </List>
)
