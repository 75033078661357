import { Text } from '@ritualco/jutsu'
import { FullWidthPage } from 'components/PageTemplates'
import { useContent } from 'lib/content/react'
import { PageTrackingNames } from 'types/entities'

export type ComingSoonProps = {
  title: string
  description: string
}

const ComingSoon = ({ title, description }: ComingSoonProps) => {
  const { formatMessage } = useContent()

  return (
    <FullWidthPage
      heading={formatMessage({ id: title })}
      subheading={formatMessage({ id: 'pmportal.global.comingSoon' })}
      showDivider
      trackingName={PageTrackingNames.comingSoon}
    >
      {formatMessage({ id: description })
        .split('\n')
        .map(content => (
          <Text key={content}>{content}</Text>
        ))}
    </FullWidthPage>
  )
}

export default ComingSoon
