import {
  CreateWorkspaceRequest,
  GetWorkspaceAuditEventRequest,
  GetWorkspaceAuditHistoryRequest,
  GetWorkspaceConnectedApplicationsRequest,
  GetWorkspaceRequest,
  GetWorkspacesRequest,
  UpdateWorkspaceRequest,
} from 'lib/generated/api'
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query'
import { useApiClient } from 'contexts/ApiClientContext'
import { useAuth } from 'auth'

export const QUERY_KEYS = {
  workspace: 'workspace',
  workspaces: 'workspaces',
  workspaceConnectedApplications: 'workspaceConnectedApplications',
  auditHistory: 'auditHistory',
  auditEvent: 'auditEvent',
} as const

export const workspaceQueryKey = ({
  organizationId,
  workspaceId,
}: GetWorkspaceRequest) => [QUERY_KEYS.workspaces, organizationId, workspaceId]

export const workspacesQueryKey = ({
  organizationId,
  token,
  ...queryParams
}: GetWorkspacesRequest & { token?: string }) => [
  QUERY_KEYS.workspaces,
  organizationId,
  token,
  queryParams,
]

export const workspaceConnectedApplicationsQueryKey = ({
  organizationId,
  workspaceId,
  ...queryParams
}: GetWorkspaceConnectedApplicationsRequest) => [
  QUERY_KEYS.workspaceConnectedApplications,
  organizationId,
  workspaceId,
  queryParams,
]

export const useCreateWorkspace = () => {
  const queryClient = useQueryClient()
  const { workspaceApi } = useApiClient()
  const { updateToken } = useAuth()

  return useMutation(
    (request: CreateWorkspaceRequest) => workspaceApi.createWorkspace(request),
    {
      onSuccess: async (_, variables) => {
        await updateToken(-1)
        queryClient.invalidateQueries(
          workspacesQueryKey({
            organizationId: variables.organizationId,
          })
        )
      },
    }
  )
}

export const useGetWorkspaces = (request: GetWorkspacesRequest) => {
  const { workspaceApi } = useApiClient()
  const { token } = useAuth()
  return useQuery(
    workspacesQueryKey({ ...request, token }),
    () => workspaceApi.getWorkspaces(request),
    {
      // do not fetch unless manually requested
      refetchOnWindowFocus: false,
      enabled: !!request.organizationId,
    }
  )
}

export const useGetOrganizationsWorkspaces = (
  requests: { organizationId: string | undefined }[]
) => {
  const { workspaceApi } = useApiClient()
  const queries = requests.filter(
    request => !!request.organizationId
  ) as GetWorkspacesRequest[]
  return useQueries(
    queries.map(request => ({
      queryKey: workspacesQueryKey(request),
      queryFn: () => workspaceApi.getWorkspaces(request),
      enabled: !!request.organizationId,
    }))
  )
}

export const useGetWorkspace = (request: GetWorkspaceRequest) => {
  const { workspaceApi } = useApiClient()
  return useQuery(
    workspaceQueryKey(request),
    () => workspaceApi.getWorkspace(request),
    {
      enabled: !!request.workspaceId && !!request.organizationId,
    }
  )
}

export const useGetWorkspaceConnectedApplications = (
  request: GetWorkspaceConnectedApplicationsRequest
) => {
  const { workspaceApi } = useApiClient()
  return useQuery(
    workspaceConnectedApplicationsQueryKey(request),
    () => workspaceApi.getWorkspaceConnectedApplications(request),
    {
      // Do not fetch unless manually requested
      enabled: false,
    }
  )
}

export const workspaceAuditHistoryQueryKey = ({
  organizationId,
  workspaceId,
  ...queryParams
}: GetWorkspaceAuditHistoryRequest) => [
  QUERY_KEYS.auditHistory,
  organizationId,
  workspaceId,
  queryParams,
]

export const useGetWorkspaceAuditHistory = (
  request: GetWorkspaceAuditHistoryRequest
) => {
  const { workspaceApi } = useApiClient()
  return useQuery(workspaceAuditHistoryQueryKey(request), () =>
    workspaceApi.getWorkspaceAuditHistory(request)
  )
}

export const workspaceAuditEventQueryKey = ({
  organizationId,
  workspaceId,
  auditEventId,
  ...queryParams
}: GetWorkspaceAuditEventRequest) => [
  QUERY_KEYS.auditEvent,
  organizationId,
  workspaceId,
  queryParams,
]

export const useGetWorkspaceAuditEvent = (
  request: GetWorkspaceAuditEventRequest
) => {
  const { workspaceApi } = useApiClient()
  return useQuery(workspaceAuditEventQueryKey(request), () =>
    workspaceApi.getWorkspaceAuditEvent(request)
  )
}

export const useUpdateWorkspace = () => {
  const queryClient = useQueryClient()
  const { workspaceApi } = useApiClient()

  return useMutation(
    (request: UpdateWorkspaceRequest) => workspaceApi.updateWorkspace(request),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(
          workspaceQueryKey({
            organizationId: variables.organizationId,
            workspaceId: variables.workspaceId,
          })
        )
      },
    }
  )
}
