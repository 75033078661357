/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorFromJSONTyped,
    ApiErrorToJSON,
} from './ApiError';

/**
 * 
 * @export
 * @interface PortalAdminInviteResponseEntry
 */
export interface PortalAdminInviteResponseEntry {
    /**
     * 
     * @type {number}
     * @memberof PortalAdminInviteResponseEntry
     */
    statusCode: number;
    /**
     * The portal administrator unique identifier.
     * @type {string}
     * @memberof PortalAdminInviteResponseEntry
     */
    portalAdminId?: string;
    /**
     * 
     * @type {ApiError}
     * @memberof PortalAdminInviteResponseEntry
     */
    error?: ApiError;
}

export function PortalAdminInviteResponseEntryFromJSON(json: any): PortalAdminInviteResponseEntry {
    return PortalAdminInviteResponseEntryFromJSONTyped(json, false);
}

export function PortalAdminInviteResponseEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortalAdminInviteResponseEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'statusCode': json['statusCode'],
        'portalAdminId': !exists(json, 'portalAdminId') ? undefined : json['portalAdminId'],
        'error': !exists(json, 'error') ? undefined : ApiErrorFromJSON(json['error']),
    };
}

export function PortalAdminInviteResponseEntryToJSON(value?: PortalAdminInviteResponseEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'statusCode': value.statusCode,
        'portalAdminId': value.portalAdminId,
        'error': ApiErrorToJSON(value.error),
    };
}

