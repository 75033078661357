import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  AcceptWorkspaceConnectionCodeRequest,
  CreateWorkspaceConnectionsRequest,
  DeleteExternalWorkspaceConnectionRequest,
  DeleteWorkspaceConnectionRequest,
  GetExternalWorkspaceConnectionsRequest,
  GetWorkspaceConnectionsRequest,
  ResendWorkspaceConnectionRequest,
  ResendWorkspaceConnectionsRequest,
  GetWorkspaceInviteRequest,
} from 'lib/generated/api'
import { useApiClient } from 'contexts/ApiClientContext'
import { mapToExternalConnectionsList } from 'api/mappers/connections'
import { useHandleApiError } from 'api/utils'

export const QUERY_KEYS = {
  externalWorkspaceConnections: 'externalWorkspaceConnections',
  workspaceConnections: 'workspaceConnections',
  workspaceInvite: 'workspaceInvite',
} as const

export const workspaceConnectionsQueryKey = ({
  organizationId,
  workspaceId,
  ...queryParams
}: GetWorkspaceConnectionsRequest) => [
  QUERY_KEYS.workspaceConnections,
  organizationId,
  workspaceId,
  queryParams,
]

export const externalWorkspaceConnectionsQueryKey = ({
  organizationId,
  workspaceId,
  ...queryParams
}: GetExternalWorkspaceConnectionsRequest) => [
  QUERY_KEYS.externalWorkspaceConnections,
  organizationId,
  workspaceId,
  queryParams,
]

export const workspaceInviteQueryKey = ({
  connectionId,
}: GetWorkspaceInviteRequest) => [QUERY_KEYS.workspaceInvite, connectionId]

export const useGetWorkspaceConnections = (
  request: GetWorkspaceConnectionsRequest
) => {
  const { connectionsApi } = useApiClient()
  return useQuery(
    workspaceConnectionsQueryKey(request),
    () => connectionsApi.getWorkspaceConnections(request),
    {
      enabled: !!request.organizationId && !!request.workspaceId,
    }
  )
}

export const useExternalConnectionsQuery = (
  request: GetExternalWorkspaceConnectionsRequest
) => {
  const { connectionsApi } = useApiClient()
  return useQuery(
    externalWorkspaceConnectionsQueryKey(request),
    () => connectionsApi.getExternalWorkspaceConnections(request),
    {
      enabled: !!request.organizationId && !!request.workspaceId,
      select: mapToExternalConnectionsList,
    }
  )
}

export const useCreateWorkspaceConnections = () => {
  const queryClient = useQueryClient()
  const { connectionsApi } = useApiClient()

  return useMutation(
    (request: CreateWorkspaceConnectionsRequest) =>
      connectionsApi.createWorkspaceConnections(request),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries([
          QUERY_KEYS.workspaceConnections,
          variables.organizationId,
          variables.workspaceId,
        ])
      },
    }
  )
}

export const useDeleteWorkspaceConnection = () => {
  const queryClient = useQueryClient()
  const { connectionsApi } = useApiClient()

  return useMutation(
    (request: DeleteWorkspaceConnectionRequest) =>
      connectionsApi.deleteWorkspaceConnection(request),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries([
          QUERY_KEYS.workspaceConnections,
          variables.organizationId,
          variables.workspaceId,
        ])
      },
    }
  )
}

export const useDeleteExternalWorkspaceConnection = () => {
  const queryClient = useQueryClient()
  const { connectionsApi } = useApiClient()

  return useMutation(
    (request: DeleteExternalWorkspaceConnectionRequest) =>
      connectionsApi.deleteExternalWorkspaceConnection(request),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries([
          QUERY_KEYS.externalWorkspaceConnections,
          variables.organizationId,
          variables.workspaceId,
        ])
      },
    }
  )
}

export const useResendWorkspaceConnections = () => {
  const { connectionsApi } = useApiClient()

  return useMutation((request: ResendWorkspaceConnectionsRequest) =>
    connectionsApi.resendWorkspaceConnections(request)
  )
}

export const useResendWorkspaceConnection = () => {
  const { connectionsApi } = useApiClient()

  return useMutation((request: ResendWorkspaceConnectionRequest) =>
    connectionsApi.resendWorkspaceConnection(request)
  )
}

export const useAcceptExternalWorkspaceConnectionCode = () => {
  const { connectionsApi } = useApiClient()
  const queryClient = useQueryClient()
  const onError = useHandleApiError({ showMessage: false })

  queryClient.setMutationDefaults('acceptExternalWorkspaceConnectionCode', {
    mutationFn: (request: AcceptWorkspaceConnectionCodeRequest) =>
      connectionsApi.acceptWorkspaceConnectionCode(request),
    onError,
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries([
        QUERY_KEYS.externalWorkspaceConnections,
        variables.organizationId,
        variables.workspaceId,
      ])
    },
  })

  return useMutation<void, unknown, AcceptWorkspaceConnectionCodeRequest>(
    'acceptExternalWorkspaceConnectionCode'
  )
}

export const useGetWorkspaceInvite = (request: GetWorkspaceInviteRequest) => {
  const { connectionsApi } = useApiClient()
  const queryClient = useQueryClient()
  const onError = useHandleApiError({ refreshToken: false })
  queryClient.setQueryDefaults(workspaceInviteQueryKey(request), {
    onError,
  })
  return useQuery(
    workspaceInviteQueryKey(request),
    () => connectionsApi.getWorkspaceInvite(request),
    { enabled: !!request.connectionId, refetchOnWindowFocus: false }
  )
}
