/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConnectedApplicationPlatform,
    ConnectedApplicationPlatformFromJSON,
    ConnectedApplicationPlatformFromJSONTyped,
    ConnectedApplicationPlatformToJSON,
} from './ConnectedApplicationPlatform';
import {
    ConnectedApplicationType,
    ConnectedApplicationTypeFromJSON,
    ConnectedApplicationTypeFromJSONTyped,
    ConnectedApplicationTypeToJSON,
} from './ConnectedApplicationType';

/**
 * A workspace connected application.
 * @export
 * @interface ConnectedApplication
 */
export interface ConnectedApplication {
    /**
     * The application name.
     * @type {string}
     * @memberof ConnectedApplication
     */
    name: string;
    /**
     * 
     * @type {ConnectedApplicationPlatform}
     * @memberof ConnectedApplication
     */
    platform: ConnectedApplicationPlatform;
    /**
     * 
     * @type {ConnectedApplicationType}
     * @memberof ConnectedApplication
     */
    type: ConnectedApplicationType;
    /**
     * URL to the download location of the application.
     * @type {string}
     * @memberof ConnectedApplication
     */
    url: string;
}

export function ConnectedApplicationFromJSON(json: any): ConnectedApplication {
    return ConnectedApplicationFromJSONTyped(json, false);
}

export function ConnectedApplicationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectedApplication {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'platform': ConnectedApplicationPlatformFromJSON(json['platform']),
        'type': ConnectedApplicationTypeFromJSON(json['type']),
        'url': json['url'],
    };
}

export function ConnectedApplicationToJSON(value?: ConnectedApplication | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'platform': ConnectedApplicationPlatformToJSON(value.platform),
        'type': ConnectedApplicationTypeToJSON(value.type),
        'url': value.url,
    };
}

