import { EntryCollection } from 'contentful'
import { pick } from 'lodash'
import { Resource, Image, ImageField, ResourceSet } from 'lib/content/types'
import {
  optionalImgSizes,
  ContentfulImage,
  ContentfulResource,
  ContentfulResourceSet,
  ImageKeys,
  OptionalImageRecord,
  OptionalImageKey,
} from './types'

const formatUrl = (url: string): string => {
  if (url.endsWith('.jpg')) {
    return `${url}?fm=jpg&fl=progressive`
  }

  return url
}

type FnMapImage = (image: ContentfulImage) => Image
type FnCreateImg = (
  name: ImageKeys,
  image: ContentfulImage
) => ImageField | null

const createImg: FnCreateImg = (name, image) => {
  const field = image.fields[name] as ContentfulImage['fields'][ImageKeys]

  if (field) {
    const { fields, sys } = field
    return {
      id: sys.id,
      ...fields,
      file: {
        ...fields.file,
        url: formatUrl(fields.file.url),
      },
    }
  }
  return null
}

const mapImage: FnMapImage = image => {
  const extraImgEntries = optionalImgSizes.reduce<OptionalImageRecord>(
    (obj, size) => {
      const key = `${size}Img` as OptionalImageKey
      const result = createImg(key, image)

      if (result) return { ...obj, [key]: result }

      return obj
    },
    {} as OptionalImageRecord
  )

  return {
    id: image.sys.id,
    contentType: 'image',
    name: image.fields.entryTitle,
    fields: {
      ...pick(image.fields, ['title', 'alt']),
      mobileImg: createImg('mobileImg', image) as ImageField,
      ...extraImgEntries,
    },
  }
}

type FnMapResource = (resource: ContentfulResource) => Resource
const mapResource: FnMapResource = resource => ({
  id: resource.sys.id,
  contentType: 'resource',
  name: resource.fields.key,
  fields: resource.fields,
})

type FnMapResources = (
  x: ContentfulResource | ContentfulImage
) => Resource | Image | undefined
const mapResources: FnMapResources = x => {
  try {
    if (!x.sys?.contentType) return undefined

    const base = {
      id: x.sys.id,
      contentType: x.sys.contentType.sys.id,
    }

    switch (base.contentType) {
      case 'image':
        return mapImage(x as ContentfulImage)
      case 'resource':
        return mapResource(x as ContentfulResource)
      default:
        return undefined
    }
  } catch (e) {
    console.error(x)
    throw e
  }
}

export const mapToResourceSet = (
  resources: EntryCollection<ContentfulResourceSet['fields']>
) => {
  const resourceSets = resources.items.map<ResourceSet>(x => ({
    id: x.sys.id,
    locale: x.sys.locale,
    name: x.fields.name,
    resources: x.fields.resources.map(mapResources).filter(Boolean) as Array<
      Image | Resource
    >,
  }))

  return resourceSets
}
