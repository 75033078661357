/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import {
    MerchantStatus,
    MerchantStatusFromJSON,
    MerchantStatusFromJSONTyped,
    MerchantStatusToJSON,
} from './MerchantStatus';

/**
 * 
 * @export
 * @interface Merchant
 */
export interface Merchant {
    /**
     * The merchant unique identifier.
     * @type {string}
     * @memberof Merchant
     */
    merchantId: string;
    /**
     * The merchant name.
     * @type {string}
     * @memberof Merchant
     */
    name?: string;
    /**
     * Merchant's name, excluding any location or intersection information
     * @type {string}
     * @memberof Merchant
     */
    nameWithoutLocation?: string;
    /**
     * Nearby major intersection
     * @type {string}
     * @memberof Merchant
     */
    intersection?: string;
    /**
     * List of search tags for this merchant
     * @type {Array<string>}
     * @memberof Merchant
     */
    tags?: Array<string>;
    /**
     * 
     * @type {Address}
     * @memberof Merchant
     */
    address?: Address;
    /**
     * 
     * @type {MerchantStatus}
     * @memberof Merchant
     */
    merchantStatus?: MerchantStatus;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    timezone?: string;
    /**
     * The preferred locale used for communication, in BCP 47 format.
     * @type {string}
     * @memberof Merchant
     */
    locale?: string;
    /**
     * The full ROO menu URL for the merchant
     * @type {string}
     * @memberof Merchant
     */
    menuUrl?: string;
}

export function MerchantFromJSON(json: any): Merchant {
    return MerchantFromJSONTyped(json, false);
}

export function MerchantFromJSONTyped(json: any, ignoreDiscriminator: boolean): Merchant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'merchantId': json['merchantId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'nameWithoutLocation': !exists(json, 'nameWithoutLocation') ? undefined : json['nameWithoutLocation'],
        'intersection': !exists(json, 'intersection') ? undefined : json['intersection'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'merchantStatus': !exists(json, 'merchantStatus') ? undefined : MerchantStatusFromJSON(json['merchantStatus']),
        'timezone': !exists(json, 'timezone') ? undefined : json['timezone'],
        'locale': !exists(json, 'locale') ? undefined : json['locale'],
        'menuUrl': !exists(json, 'menuUrl') ? undefined : json['menuUrl'],
    };
}

export function MerchantToJSON(value?: Merchant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'merchantId': value.merchantId,
        'name': value.name,
        'nameWithoutLocation': value.nameWithoutLocation,
        'intersection': value.intersection,
        'tags': value.tags,
        'address': AddressToJSON(value.address),
        'merchantStatus': MerchantStatusToJSON(value.merchantStatus),
        'timezone': value.timezone,
        'locale': value.locale,
        'menuUrl': value.menuUrl,
    };
}

