/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * deprecated status of the fulfillment, see `status` instead
 * @export
 */
export const FulfillmentState = {
    Created: 'CREATED',
    Open: 'OPEN',
    Closed: 'CLOSED',
    Placed: 'PLACED',
    Accepted: 'ACCEPTED',
    InTransit: 'IN_TRANSIT',
    Received: 'RECEIVED',
    Cancelled: 'CANCELLED'
} as const;
export type FulfillmentState = typeof FulfillmentState[keyof typeof FulfillmentState];


export function FulfillmentStateFromJSON(json: any): FulfillmentState {
    return FulfillmentStateFromJSONTyped(json, false);
}

export function FulfillmentStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): FulfillmentState {
    return json as FulfillmentState;
}

export function FulfillmentStateToJSON(value?: FulfillmentState | null): any {
    return value as any;
}

