/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TargetingListType,
    TargetingListTypeFromJSON,
    TargetingListTypeFromJSONTyped,
    TargetingListTypeToJSON,
} from './TargetingListType';
import {
    WorkspaceConnectionListGroup,
    WorkspaceConnectionListGroupFromJSON,
    WorkspaceConnectionListGroupFromJSONTyped,
    WorkspaceConnectionListGroupToJSON,
} from './WorkspaceConnectionListGroup';
import {
    WorkspaceMemberListGroup,
    WorkspaceMemberListGroupFromJSON,
    WorkspaceMemberListGroupFromJSONTyped,
    WorkspaceMemberListGroupToJSON,
} from './WorkspaceMemberListGroup';

/**
 * 
 * @export
 * @interface IncentivePlanUserTargeting
 */
export interface IncentivePlanUserTargeting {
    /**
     * 
     * @type {WorkspaceMemberListGroup}
     * @memberof IncentivePlanUserTargeting
     */
    userGroup?: WorkspaceMemberListGroup;
    /**
     * 
     * @type {TargetingListType}
     * @memberof IncentivePlanUserTargeting
     */
    userGroupListType?: TargetingListType;
    /**
     * 
     * @type {WorkspaceConnectionListGroup}
     * @memberof IncentivePlanUserTargeting
     */
    workspaceGroup?: WorkspaceConnectionListGroup;
    /**
     * 
     * @type {TargetingListType}
     * @memberof IncentivePlanUserTargeting
     */
    worspaceGroupListType?: TargetingListType;
}

export function IncentivePlanUserTargetingFromJSON(json: any): IncentivePlanUserTargeting {
    return IncentivePlanUserTargetingFromJSONTyped(json, false);
}

export function IncentivePlanUserTargetingFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncentivePlanUserTargeting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userGroup': !exists(json, 'userGroup') ? undefined : WorkspaceMemberListGroupFromJSON(json['userGroup']),
        'userGroupListType': !exists(json, 'userGroupListType') ? undefined : TargetingListTypeFromJSON(json['userGroupListType']),
        'workspaceGroup': !exists(json, 'workspaceGroup') ? undefined : WorkspaceConnectionListGroupFromJSON(json['workspaceGroup']),
        'worspaceGroupListType': !exists(json, 'worspaceGroupListType') ? undefined : TargetingListTypeFromJSON(json['worspaceGroupListType']),
    };
}

export function IncentivePlanUserTargetingToJSON(value?: IncentivePlanUserTargeting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userGroup': WorkspaceMemberListGroupToJSON(value.userGroup),
        'userGroupListType': TargetingListTypeToJSON(value.userGroupListType),
        'workspaceGroup': WorkspaceConnectionListGroupToJSON(value.workspaceGroup),
        'worspaceGroupListType': TargetingListTypeToJSON(value.worspaceGroupListType),
    };
}

