import {
  Avatar,
  Box,
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  MenuItem,
  MenuItemProps,
  Text,
  useStyleConfig,
  CSSObject,
} from '@ritualco/jutsu'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { Link } from 'lib/router'
import { useContent } from 'lib/content/react'

type ExtraMenuItemProps = {
  label: string
  href: string
  isExternal?: boolean
}

export type ProfileMenuProps = {
  email: string
  name: string
  avatar?: string
  items: Array<MenuItemProps & ExtraMenuItemProps>
  onSignOut: Function
}

export const ProfileMenu = ({
  email,
  name,
  avatar,
  items,
  onSignOut,
}: ProfileMenuProps) => {
  const { formatMessage } = useContent()
  const styles = useStyleConfig('Button', {
    colorScheme: 'transparent',
    variant: 'outline',
  }) as CSSObject & { _hover: CSSObject; _focus: CSSObject }
  const overrideMenuItemStyles = {
    _hover: {
      ...styles._hover,
      boxShadow: 'none',
    },
    _focus: {
      ...styles._focus,
      bg: 'transparent',
      color: 'grayscale.primary',
    },
  }

  return (
    <Menu>
      <MenuButton
        border="none"
        outline="revert"
        color="grayscale.tertiary"
        p={2}
      >
        <Avatar
          size="sm"
          name={name}
          src={avatar}
          bgColor="grayscale.secondary"
        />
        <ChevronDownIcon ml={4} />
      </MenuButton>
      <MenuList zIndex="dropdown">
        <Box px={3} py={2}>
          <Text fontSize="small" fontWeight="medium">
            {name}
          </Text>
          <Text fontSize="xsmall" color="grayscale.secondary">
            {email}
          </Text>
        </Box>
        {items.map(({ label, href, isExternal = false, ...rest }, index) => {
          const menuItemProps = { key: index, p: 3, ...rest }
          if (isExternal) {
            return (
              <MenuItem as="a" href={href} {...menuItemProps}>
                {label}
              </MenuItem>
            )
          }
          return (
            <MenuItem as={Link} to={href} {...menuItemProps}>
              {label}
            </MenuItem>
          )
        })}
        <MenuDivider />
        <Box px={3} pb={2}>
          <Button
            as={MenuItem}
            colorScheme="transparent"
            variant="outline"
            isFullWidth
            onClick={e => onSignOut(e)}
            sx={overrideMenuItemStyles}
          >
            {formatMessage({ id: 'pmportal.global.signOut' })}
          </Button>
        </Box>
      </MenuList>
    </Menu>
  )
}
