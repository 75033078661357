/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    id?: string;
    /**
     * Title of the incentive notification
     * @type {string}
     * @memberof Notification
     */
    title: string;
    /**
     * Message of the incentive notifications
     * @type {string}
     * @memberof Notification
     */
    message: string;
    /**
     * Number of minutes after the incentive has been issued to send this notification
     * @type {number}
     * @memberof Notification
     */
    delayMinutes: number;
}

export function NotificationFromJSON(json: any): Notification {
    return NotificationFromJSONTyped(json, false);
}

export function NotificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Notification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': json['title'],
        'message': json['message'],
        'delayMinutes': json['delayMinutes'],
    };
}

export function NotificationToJSON(value?: Notification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'message': value.message,
        'delayMinutes': value.delayMinutes,
    };
}

