import { useAuth } from 'auth'
import { GlobalShell } from 'components/GlobalShell'
import Login from 'views/Login'
import useIdentityProviders from 'hooks/useIdentityProviders'
import { useAnalytics } from 'lib/simple-analytics'
import { useStore } from 'state/store'
import { useEffectOnce } from 'react-use'
import { AnalyticEvents } from 'types/entities'

export const LoginContainer = () => {
  const { login, createRegisterUrl } = useAuth()
  const { providers } = useIdentityProviders()
  const { track } = useAnalytics()
  const { clearStorage } = useStore()

  useEffectOnce(() => {
    clearStorage()
  })

  return (
    <GlobalShell>
      <Login
        identityProviders={providers}
        onLogin={provider => {
          track(AnalyticEvents.login, {
            identity_provider: provider.id,
          })
          login({
            idpHint: provider.id || 'email',
            redirectUri: window.location.href,
          })
        }}
        onRegister={() => {
          track(AnalyticEvents.signUp)
          window.location.href = createRegisterUrl({
            redirectUri: window.location.href,
          })
        }}
      />
    </GlobalShell>
  )
}
