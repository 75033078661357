/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    BatchInviteJobResponse,
    BatchInviteJobResponseFromJSON,
    BatchInviteJobResponseToJSON,
    UpdateWorkspaceMember,
    UpdateWorkspaceMemberFromJSON,
    UpdateWorkspaceMemberToJSON,
    WorkspaceMember,
    WorkspaceMemberFromJSON,
    WorkspaceMemberToJSON,
    WorkspaceMemberCampaign,
    WorkspaceMemberCampaignFromJSON,
    WorkspaceMemberCampaignToJSON,
    WorkspaceMemberCampaignInvitationResponse,
    WorkspaceMemberCampaignInvitationResponseFromJSON,
    WorkspaceMemberCampaignInvitationResponseToJSON,
    WorkspaceMemberCampaignRequest,
    WorkspaceMemberCampaignRequestFromJSON,
    WorkspaceMemberCampaignRequestToJSON,
    WorkspaceMemberCampaignsResponse,
    WorkspaceMemberCampaignsResponseFromJSON,
    WorkspaceMemberCampaignsResponseToJSON,
    WorkspaceMemberStatus,
    WorkspaceMemberStatusFromJSON,
    WorkspaceMemberStatusToJSON,
    WorkspaceMembersBatchDeleteRequest,
    WorkspaceMembersBatchDeleteRequestFromJSON,
    WorkspaceMembersBatchDeleteRequestToJSON,
    WorkspaceMembersBatchDeleteResponse,
    WorkspaceMembersBatchDeleteResponseFromJSON,
    WorkspaceMembersBatchDeleteResponseToJSON,
    WorkspaceMembersBatchInviteRequest,
    WorkspaceMembersBatchInviteRequestFromJSON,
    WorkspaceMembersBatchInviteRequestToJSON,
    WorkspaceMembersBatchUpdateDepartmentCodesRequest,
    WorkspaceMembersBatchUpdateDepartmentCodesRequestFromJSON,
    WorkspaceMembersBatchUpdateDepartmentCodesRequestToJSON,
    WorkspaceMembersBatchUpdateDepartmentCodesResponse,
    WorkspaceMembersBatchUpdateDepartmentCodesResponseFromJSON,
    WorkspaceMembersBatchUpdateDepartmentCodesResponseToJSON,
    WorkspaceMembersInviteRequest,
    WorkspaceMembersInviteRequestFromJSON,
    WorkspaceMembersInviteRequestToJSON,
    WorkspaceMembersInviteResponse,
    WorkspaceMembersInviteResponseFromJSON,
    WorkspaceMembersInviteResponseToJSON,
    WorkspaceMembersResendResponse,
    WorkspaceMembersResendResponseFromJSON,
    WorkspaceMembersResendResponseToJSON,
    WorkspaceMembersResponse,
    WorkspaceMembersResponseFromJSON,
    WorkspaceMembersResponseToJSON,
} from '../models';

export interface BatchDeleteWorkspaceMembersRequest {
    organizationId: string;
    workspaceId: string;
    workspaceMembersBatchDeleteRequest?: WorkspaceMembersBatchDeleteRequest;
}

export interface BatchInviteWorkspaceMembersRequest {
    organizationId: string;
    workspaceId: string;
    workspaceMembersBatchInviteRequest?: WorkspaceMembersBatchInviteRequest;
}

export interface BatchUpdateDepartmentCodesRequest {
    organizationId: string;
    workspaceId: string;
    workspaceMembersBatchUpdateDepartmentCodesRequest?: WorkspaceMembersBatchUpdateDepartmentCodesRequest;
}

export interface CreateMemberCampaignRequest {
    organizationId: string;
    workspaceId: string;
    workspaceMemberCampaignRequest: WorkspaceMemberCampaignRequest;
}

export interface DeleteMemberCampaignRequest {
    organizationId: string;
    workspaceId: string;
    memberCampaignId: string;
}

export interface GetMemberCampaignRequest {
    organizationId: string;
    workspaceId: string;
    memberCampaignId: string;
}

export interface GetMemberCampaignInvitationRequest {
    externalCampaignId: string;
}

export interface GetMemberCampaignsRequest {
    organizationId: string;
    workspaceId: string;
    limit?: number;
    nextPageToken?: string;
    previousPageToken?: string;
}

export interface GetWorkspaceMemberProfileByIdRequest {
    organizationId: string;
    workspaceId: string;
    workspaceMemberId: string;
}

export interface GetWorkspaceMembersRequest {
    organizationId: string;
    workspaceId: string;
    excludeStatus?: Array<WorkspaceMemberStatus>;
    includeStatus?: Array<WorkspaceMemberStatus>;
    limit?: number;
    nextPageToken?: string;
    previousPageToken?: string;
    sortColumn?: string;
    sortDirection?: GetWorkspaceMembersSortDirectionEnum;
    query?: Array<string>;
}

export interface InviteWorkspaceMembersRequest {
    organizationId: string;
    workspaceId: string;
    workspaceMembersInviteRequest?: WorkspaceMembersInviteRequest;
}

export interface RemoveWorkspaceMemberRequest {
    organizationId: string;
    workspaceId: string;
    workspaceMemberId: string;
}

export interface ResendInvitationToWorkspaceMemberRequest {
    organizationId: string;
    workspaceId: string;
    workspaceMemberId: string;
}

export interface ResendInvitationToWorkspaceMembersRequest {
    organizationId: string;
    workspaceId: string;
}

export interface UpdateMemberCampaignRequest {
    organizationId: string;
    workspaceId: string;
    memberCampaignId: string;
    workspaceMemberCampaignRequest: WorkspaceMemberCampaignRequest;
}

export interface UpdateWorkspaceMemberProfileRequest {
    organizationId: string;
    workspaceId: string;
    workspaceMemberId: string;
    updateWorkspaceMember: UpdateWorkspaceMember;
}

/**
 * 
 */
export class WorkspaceMembersApi extends runtime.BaseAPI {

    /**
     * Remove one or more members from the workspace.
     */
    async batchDeleteWorkspaceMembersRaw(requestParameters: BatchDeleteWorkspaceMembersRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WorkspaceMembersBatchDeleteResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling batchDeleteWorkspaceMembers.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling batchDeleteWorkspaceMembers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/workspace-members/batch-delete`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WorkspaceMembersBatchDeleteRequestToJSON(requestParameters.workspaceMembersBatchDeleteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceMembersBatchDeleteResponseFromJSON(jsonValue));
    }

    /**
     * Remove one or more members from the workspace.
     */
    async batchDeleteWorkspaceMembers(requestParameters: BatchDeleteWorkspaceMembersRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WorkspaceMembersBatchDeleteResponse> {
        const response = await this.batchDeleteWorkspaceMembersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Invite batch of members to the workspace. This is an asynchronous operation that returns a jobID that can be polled against.
     */
    async batchInviteWorkspaceMembersRaw(requestParameters: BatchInviteWorkspaceMembersRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<BatchInviteJobResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling batchInviteWorkspaceMembers.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling batchInviteWorkspaceMembers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/workspace-members/batch-invite-job`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WorkspaceMembersBatchInviteRequestToJSON(requestParameters.workspaceMembersBatchInviteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BatchInviteJobResponseFromJSON(jsonValue));
    }

    /**
     * Invite batch of members to the workspace. This is an asynchronous operation that returns a jobID that can be polled against.
     */
    async batchInviteWorkspaceMembers(requestParameters: BatchInviteWorkspaceMembersRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<BatchInviteJobResponse> {
        const response = await this.batchInviteWorkspaceMembersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update department code(s) of one or more members from the workspace.
     */
    async batchUpdateDepartmentCodesRaw(requestParameters: BatchUpdateDepartmentCodesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WorkspaceMembersBatchUpdateDepartmentCodesResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling batchUpdateDepartmentCodes.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling batchUpdateDepartmentCodes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/workspace-members/department-codes`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WorkspaceMembersBatchUpdateDepartmentCodesRequestToJSON(requestParameters.workspaceMembersBatchUpdateDepartmentCodesRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceMembersBatchUpdateDepartmentCodesResponseFromJSON(jsonValue));
    }

    /**
     * Update department code(s) of one or more members from the workspace.
     */
    async batchUpdateDepartmentCodes(requestParameters: BatchUpdateDepartmentCodesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WorkspaceMembersBatchUpdateDepartmentCodesResponse> {
        const response = await this.batchUpdateDepartmentCodesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a member campaign
     */
    async createMemberCampaignRaw(requestParameters: CreateMemberCampaignRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WorkspaceMemberCampaign>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling createMemberCampaign.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling createMemberCampaign.');
        }

        if (requestParameters.workspaceMemberCampaignRequest === null || requestParameters.workspaceMemberCampaignRequest === undefined) {
            throw new runtime.RequiredError('workspaceMemberCampaignRequest','Required parameter requestParameters.workspaceMemberCampaignRequest was null or undefined when calling createMemberCampaign.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/member-campaigns`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WorkspaceMemberCampaignRequestToJSON(requestParameters.workspaceMemberCampaignRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceMemberCampaignFromJSON(jsonValue));
    }

    /**
     * Creates a member campaign
     */
    async createMemberCampaign(requestParameters: CreateMemberCampaignRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WorkspaceMemberCampaign> {
        const response = await this.createMemberCampaignRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a member campaign
     */
    async deleteMemberCampaignRaw(requestParameters: DeleteMemberCampaignRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling deleteMemberCampaign.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling deleteMemberCampaign.');
        }

        if (requestParameters.memberCampaignId === null || requestParameters.memberCampaignId === undefined) {
            throw new runtime.RequiredError('memberCampaignId','Required parameter requestParameters.memberCampaignId was null or undefined when calling deleteMemberCampaign.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/member-campaigns/{memberCampaignId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"memberCampaignId"}}`, encodeURIComponent(String(requestParameters.memberCampaignId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a member campaign
     */
    async deleteMemberCampaign(requestParameters: DeleteMemberCampaignRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.deleteMemberCampaignRaw(requestParameters, initOverrides);
    }

    /**
     * Gets the member campaign
     */
    async getMemberCampaignRaw(requestParameters: GetMemberCampaignRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WorkspaceMemberCampaign>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getMemberCampaign.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling getMemberCampaign.');
        }

        if (requestParameters.memberCampaignId === null || requestParameters.memberCampaignId === undefined) {
            throw new runtime.RequiredError('memberCampaignId','Required parameter requestParameters.memberCampaignId was null or undefined when calling getMemberCampaign.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/member-campaigns/{memberCampaignId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"memberCampaignId"}}`, encodeURIComponent(String(requestParameters.memberCampaignId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceMemberCampaignFromJSON(jsonValue));
    }

    /**
     * Gets the member campaign
     */
    async getMemberCampaign(requestParameters: GetMemberCampaignRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WorkspaceMemberCampaign> {
        const response = await this.getMemberCampaignRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets the public details of the campaign
     */
    async getMemberCampaignInvitationRaw(requestParameters: GetMemberCampaignInvitationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WorkspaceMemberCampaignInvitationResponse>> {
        if (requestParameters.externalCampaignId === null || requestParameters.externalCampaignId === undefined) {
            throw new runtime.RequiredError('externalCampaignId','Required parameter requestParameters.externalCampaignId was null or undefined when calling getMemberCampaignInvitation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invitations/member-campaigns/{externalCampaignId}`.replace(`{${"externalCampaignId"}}`, encodeURIComponent(String(requestParameters.externalCampaignId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceMemberCampaignInvitationResponseFromJSON(jsonValue));
    }

    /**
     * Gets the public details of the campaign
     */
    async getMemberCampaignInvitation(requestParameters: GetMemberCampaignInvitationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WorkspaceMemberCampaignInvitationResponse> {
        const response = await this.getMemberCampaignInvitationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets list of member campaigns
     */
    async getMemberCampaignsRaw(requestParameters: GetMemberCampaignsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WorkspaceMemberCampaignsResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getMemberCampaigns.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling getMemberCampaigns.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.nextPageToken !== undefined) {
            queryParameters['nextPageToken'] = requestParameters.nextPageToken;
        }

        if (requestParameters.previousPageToken !== undefined) {
            queryParameters['previousPageToken'] = requestParameters.previousPageToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/member-campaigns`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceMemberCampaignsResponseFromJSON(jsonValue));
    }

    /**
     * Gets list of member campaigns
     */
    async getMemberCampaigns(requestParameters: GetMemberCampaignsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WorkspaceMemberCampaignsResponse> {
        const response = await this.getMemberCampaignsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a workspace member profile.
     */
    async getWorkspaceMemberProfileByIdRaw(requestParameters: GetWorkspaceMemberProfileByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WorkspaceMember>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getWorkspaceMemberProfileById.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling getWorkspaceMemberProfileById.');
        }

        if (requestParameters.workspaceMemberId === null || requestParameters.workspaceMemberId === undefined) {
            throw new runtime.RequiredError('workspaceMemberId','Required parameter requestParameters.workspaceMemberId was null or undefined when calling getWorkspaceMemberProfileById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/workspace-members/{workspaceMemberId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"workspaceMemberId"}}`, encodeURIComponent(String(requestParameters.workspaceMemberId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceMemberFromJSON(jsonValue));
    }

    /**
     * Get a workspace member profile.
     */
    async getWorkspaceMemberProfileById(requestParameters: GetWorkspaceMemberProfileByIdRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WorkspaceMember> {
        const response = await this.getWorkspaceMemberProfileByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of members of the workspace. Both `excludeStatus` and `includeStatus` are optional, but you should only use one at a time.
     * Get list of members of the workspace.
     */
    async getWorkspaceMembersRaw(requestParameters: GetWorkspaceMembersRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WorkspaceMembersResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getWorkspaceMembers.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling getWorkspaceMembers.');
        }

        const queryParameters: any = {};

        if (requestParameters.excludeStatus) {
            queryParameters['excludeStatus'] = requestParameters.excludeStatus.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.includeStatus) {
            queryParameters['includeStatus'] = requestParameters.includeStatus.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.nextPageToken !== undefined) {
            queryParameters['nextPageToken'] = requestParameters.nextPageToken;
        }

        if (requestParameters.previousPageToken !== undefined) {
            queryParameters['previousPageToken'] = requestParameters.previousPageToken;
        }

        if (requestParameters.sortColumn !== undefined) {
            queryParameters['sortColumn'] = requestParameters.sortColumn;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.query) {
            queryParameters['query'] = requestParameters.query;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/workspace-members`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceMembersResponseFromJSON(jsonValue));
    }

    /**
     * Get a list of members of the workspace. Both `excludeStatus` and `includeStatus` are optional, but you should only use one at a time.
     * Get list of members of the workspace.
     */
    async getWorkspaceMembers(requestParameters: GetWorkspaceMembersRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WorkspaceMembersResponse> {
        const response = await this.getWorkspaceMembersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Invite one or more members to the workspace.
     */
    async inviteWorkspaceMembersRaw(requestParameters: InviteWorkspaceMembersRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WorkspaceMembersInviteResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling inviteWorkspaceMembers.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling inviteWorkspaceMembers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/workspace-members`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WorkspaceMembersInviteRequestToJSON(requestParameters.workspaceMembersInviteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceMembersInviteResponseFromJSON(jsonValue));
    }

    /**
     * Invite one or more members to the workspace.
     */
    async inviteWorkspaceMembers(requestParameters: InviteWorkspaceMembersRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WorkspaceMembersInviteResponse> {
        const response = await this.inviteWorkspaceMembersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove a workspace member.
     */
    async removeWorkspaceMemberRaw(requestParameters: RemoveWorkspaceMemberRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling removeWorkspaceMember.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling removeWorkspaceMember.');
        }

        if (requestParameters.workspaceMemberId === null || requestParameters.workspaceMemberId === undefined) {
            throw new runtime.RequiredError('workspaceMemberId','Required parameter requestParameters.workspaceMemberId was null or undefined when calling removeWorkspaceMember.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/workspace-members/{workspaceMemberId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"workspaceMemberId"}}`, encodeURIComponent(String(requestParameters.workspaceMemberId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove a workspace member.
     */
    async removeWorkspaceMember(requestParameters: RemoveWorkspaceMemberRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.removeWorkspaceMemberRaw(requestParameters, initOverrides);
    }

    /**
     * Resend the invitation to the specified workspace member. By default, this will resend invitations to members in a pending state.
     */
    async resendInvitationToWorkspaceMemberRaw(requestParameters: ResendInvitationToWorkspaceMemberRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling resendInvitationToWorkspaceMember.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling resendInvitationToWorkspaceMember.');
        }

        if (requestParameters.workspaceMemberId === null || requestParameters.workspaceMemberId === undefined) {
            throw new runtime.RequiredError('workspaceMemberId','Required parameter requestParameters.workspaceMemberId was null or undefined when calling resendInvitationToWorkspaceMember.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/workspace-members/{workspaceMemberId}/resend`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"workspaceMemberId"}}`, encodeURIComponent(String(requestParameters.workspaceMemberId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Resend the invitation to the specified workspace member. By default, this will resend invitations to members in a pending state.
     */
    async resendInvitationToWorkspaceMember(requestParameters: ResendInvitationToWorkspaceMemberRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<void> {
        await this.resendInvitationToWorkspaceMemberRaw(requestParameters, initOverrides);
    }

    /**
     * Resend invitations to the members of a workspace. By default, this will resend invitations to members in a pending state. This request can be updated to take a request body that is a list of specified states that a workspace member must be in to be resent an invitation.
     * Resend invitations to the members of a workspace. By default, this will resend invitations to members in a pending state.
     */
    async resendInvitationToWorkspaceMembersRaw(requestParameters: ResendInvitationToWorkspaceMembersRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WorkspaceMembersResendResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling resendInvitationToWorkspaceMembers.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling resendInvitationToWorkspaceMembers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/workspace-members/resend`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceMembersResendResponseFromJSON(jsonValue));
    }

    /**
     * Resend invitations to the members of a workspace. By default, this will resend invitations to members in a pending state. This request can be updated to take a request body that is a list of specified states that a workspace member must be in to be resent an invitation.
     * Resend invitations to the members of a workspace. By default, this will resend invitations to members in a pending state.
     */
    async resendInvitationToWorkspaceMembers(requestParameters: ResendInvitationToWorkspaceMembersRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WorkspaceMembersResendResponse> {
        const response = await this.resendInvitationToWorkspaceMembersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a member campaign
     */
    async updateMemberCampaignRaw(requestParameters: UpdateMemberCampaignRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WorkspaceMemberCampaign>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling updateMemberCampaign.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling updateMemberCampaign.');
        }

        if (requestParameters.memberCampaignId === null || requestParameters.memberCampaignId === undefined) {
            throw new runtime.RequiredError('memberCampaignId','Required parameter requestParameters.memberCampaignId was null or undefined when calling updateMemberCampaign.');
        }

        if (requestParameters.workspaceMemberCampaignRequest === null || requestParameters.workspaceMemberCampaignRequest === undefined) {
            throw new runtime.RequiredError('workspaceMemberCampaignRequest','Required parameter requestParameters.workspaceMemberCampaignRequest was null or undefined when calling updateMemberCampaign.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/member-campaigns/{memberCampaignId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"memberCampaignId"}}`, encodeURIComponent(String(requestParameters.memberCampaignId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: WorkspaceMemberCampaignRequestToJSON(requestParameters.workspaceMemberCampaignRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceMemberCampaignFromJSON(jsonValue));
    }

    /**
     * Update a member campaign
     */
    async updateMemberCampaign(requestParameters: UpdateMemberCampaignRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WorkspaceMemberCampaign> {
        const response = await this.updateMemberCampaignRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a workspace member profile.
     */
    async updateWorkspaceMemberProfileRaw(requestParameters: UpdateWorkspaceMemberProfileRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WorkspaceMember>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling updateWorkspaceMemberProfile.');
        }

        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling updateWorkspaceMemberProfile.');
        }

        if (requestParameters.workspaceMemberId === null || requestParameters.workspaceMemberId === undefined) {
            throw new runtime.RequiredError('workspaceMemberId','Required parameter requestParameters.workspaceMemberId was null or undefined when calling updateWorkspaceMemberProfile.');
        }

        if (requestParameters.updateWorkspaceMember === null || requestParameters.updateWorkspaceMember === undefined) {
            throw new runtime.RequiredError('updateWorkspaceMember','Required parameter requestParameters.updateWorkspaceMember was null or undefined when calling updateWorkspaceMemberProfile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/{organizationId}/workspaces/{workspaceId}/workspace-members/{workspaceMemberId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"workspaceMemberId"}}`, encodeURIComponent(String(requestParameters.workspaceMemberId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateWorkspaceMemberToJSON(requestParameters.updateWorkspaceMember),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceMemberFromJSON(jsonValue));
    }

    /**
     * Update a workspace member profile.
     */
    async updateWorkspaceMemberProfile(requestParameters: UpdateWorkspaceMemberProfileRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WorkspaceMember> {
        const response = await this.updateWorkspaceMemberProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetWorkspaceMembersSortDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type GetWorkspaceMembersSortDirectionEnum = typeof GetWorkspaceMembersSortDirectionEnum[keyof typeof GetWorkspaceMembersSortDirectionEnum];
