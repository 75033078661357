/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ListPosition,
    ListPositionFromJSON,
    ListPositionFromJSONTyped,
    ListPositionToJSON,
} from './ListPosition';
import {
    MerchantListEntryAllOf,
    MerchantListEntryAllOfFromJSON,
    MerchantListEntryAllOfFromJSONTyped,
    MerchantListEntryAllOfToJSON,
} from './MerchantListEntryAllOf';

/**
 * 
 * @export
 * @interface MerchantListEntry
 */
export interface MerchantListEntry {
    /**
     * The zero-based index in the list.
     * @type {number}
     * @memberof MerchantListEntry
     */
    index?: number;
    /**
     * **Merchant ID**. The merchant unique identifier.
     * @type {string}
     * @memberof MerchantListEntry
     */
    merchantId: string;
}

export function MerchantListEntryFromJSON(json: any): MerchantListEntry {
    return MerchantListEntryFromJSONTyped(json, false);
}

export function MerchantListEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantListEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'index': !exists(json, 'index') ? undefined : json['index'],
        'merchantId': json['merchantId'],
    };
}

export function MerchantListEntryToJSON(value?: MerchantListEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'index': value.index,
        'merchantId': value.merchantId,
    };
}

