import { ReactNode } from 'react'
import { useField } from 'formik'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputProps,
} from '@ritualco/jutsu'

export type FormikInputProps = {
  label?: ReactNode
  name: string
  isDisabled?: boolean
} & InputProps

export const FormikInput = ({
  label,
  isDisabled = false,
  isRequired = false,
  ...props
}: FormikInputProps) => {
  const [field, { error, touched }] = useField(props.name)
  return (
    <FormControl
      id={props.name}
      isInvalid={!!error && touched}
      isDisabled={isDisabled}
      isRequired={isRequired}
    >
      {label && <FormLabel>{label}</FormLabel>}
      <Input size="lg" {...field} {...props} />
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  )
}
