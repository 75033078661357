import {
  Workspace as WorkspaceFromApi,
  WorkspacesResponse,
} from 'lib/generated/api'
import { Workspace } from 'types/entities'

export const mapToWorkspace = (x: WorkspaceFromApi): Workspace => ({
  id: x.workspaceId,
  name: x.name,
  organizationId: x.organizationId,
  type: x.workspaceType,
  address: x.address,
  locale: x.locale,
  currencyCode: x.currencyCode,
  timezone: x.timezone,
})

export type WorkspaceMapper = (response: WorkspacesResponse) => Workspace[]
export const mapToWorkspaceList: WorkspaceMapper = response =>
  response.results.map(x => mapToWorkspace(x))
