/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentMethodConfiguration
 */
export interface PaymentMethodConfiguration {
    /**
     * Stripe payment method id associated with setup intent
     * @type {string}
     * @memberof PaymentMethodConfiguration
     */
    stripePaymentMethodId: string;
}

export function PaymentMethodConfigurationFromJSON(json: any): PaymentMethodConfiguration {
    return PaymentMethodConfigurationFromJSONTyped(json, false);
}

export function PaymentMethodConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentMethodConfiguration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stripePaymentMethodId': json['stripePaymentMethodId'],
    };
}

export function PaymentMethodConfigurationToJSON(value?: PaymentMethodConfiguration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stripePaymentMethodId': value.stripePaymentMethodId,
    };
}

