import { Alert, AlertIcon, AlertProps, useDisclosure } from '@ritualco/jutsu'
import queryString from 'query-string'
import { MdCheck } from 'react-icons/md'
import { useLocation } from 'lib/router'
import { useEffect } from 'react'
import WorkspaceConnected from './WorkspaceConnected'
import AdminInviteAccepted from './AdminInviteAccepted'

export const alertTypes = {
  adminInviteAccepted: 'adminInviteAccepted',
  workspaceConnected: 'workspaceConnected',
}

export type RouteQueryAlertProps = {
  alertParam?: string
} & AlertProps

export const RouteQueryAlert = ({
  alertParam = 'notice',
  ...alertProps
}: RouteQueryAlertProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const location = useLocation()
  const result = queryString.parse(location.search)
  const alertType = result[alertParam]
  let content = null

  useEffect(() => {
    if (alertType) {
      onOpen()
    }
  }, [alertType, onOpen])

  if (!isOpen) return null

  switch (alertType) {
    case alertTypes.adminInviteAccepted:
      content = <AdminInviteAccepted />
      break
    case alertTypes.workspaceConnected:
      return <WorkspaceConnected {...alertProps} onClose={onClose} />
    default:
      break
  }

  return content ? (
    <Alert variant="subtle" status="info" fontSize="sm" {...alertProps}>
      <AlertIcon as={MdCheck} />
      {content}
    </Alert>
  ) : null
}
