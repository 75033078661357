/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CurrencyCode,
    CurrencyCodeFromJSON,
    CurrencyCodeFromJSONTyped,
    CurrencyCodeToJSON,
} from './CurrencyCode';

/**
 * 
 * @export
 * @interface WorkspaceIncentive
 */
export interface WorkspaceIncentive {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceIncentive
     */
    incentiveId: string;
    /**
     * id that links to the incentive plan that issued the incentive
     * @type {string}
     * @memberof WorkspaceIncentive
     */
    incentivePlanId: string;
    /**
     * Incentive credit total
     * @type {number}
     * @memberof WorkspaceIncentive
     */
    creditAmountTotal: number;
    /**
     * Incentive credit redeemed
     * @type {number}
     * @memberof WorkspaceIncentive
     */
    creditAmountRedeemed: number;
    /**
     * 
     * @type {CurrencyCode}
     * @memberof WorkspaceIncentive
     */
    currencyCode: CurrencyCode;
    /**
     * The time zone
     * @type {string}
     * @memberof WorkspaceIncentive
     */
    timezone: string;
    /**
     * 
     * @type {Date}
     * @memberof WorkspaceIncentive
     */
    expiry: Date;
    /**
     * 
     * @type {Date}
     * @memberof WorkspaceIncentive
     */
    issuedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceIncentive
     */
    incentiveSourceName?: string;
}

export function WorkspaceIncentiveFromJSON(json: any): WorkspaceIncentive {
    return WorkspaceIncentiveFromJSONTyped(json, false);
}

export function WorkspaceIncentiveFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceIncentive {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incentiveId': json['incentiveId'],
        'incentivePlanId': json['incentivePlanId'],
        'creditAmountTotal': json['creditAmountTotal'],
        'creditAmountRedeemed': json['creditAmountRedeemed'],
        'currencyCode': CurrencyCodeFromJSON(json['currencyCode']),
        'timezone': json['timezone'],
        'expiry': (new Date(json['expiry'])),
        'issuedAt': (new Date(json['issuedAt'])),
        'incentiveSourceName': !exists(json, 'incentiveSourceName') ? undefined : json['incentiveSourceName'],
    };
}

export function WorkspaceIncentiveToJSON(value?: WorkspaceIncentive | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incentiveId': value.incentiveId,
        'incentivePlanId': value.incentivePlanId,
        'creditAmountTotal': value.creditAmountTotal,
        'creditAmountRedeemed': value.creditAmountRedeemed,
        'currencyCode': CurrencyCodeToJSON(value.currencyCode),
        'timezone': value.timezone,
        'expiry': (value.expiry.toISOString()),
        'issuedAt': (value.issuedAt.toISOString()),
        'incentiveSourceName': value.incentiveSourceName,
    };
}

