import { useEffect } from 'react'
import { Grid, GridItem, GridProps } from '@ritualco/jutsu'
import { ReactComponent as BuildingsLogo } from 'assets/Ritual-For-Buildings-Logo.svg'
import { ReactComponent as TeamsLogo } from 'assets/Ritual-For-Teams-Logo.svg'
import { ReactComponent as DefaultLogo } from 'assets/Ritual-Logo.svg'
import { AppBar } from 'components/AppBar'
import { useStore } from 'state/store'
import { OrganizationType } from 'lib/generated/api'

const RitualLogo = () => {
  const ritualLogoType = useStore(state => state.ritualLogoType)
  switch (ritualLogoType) {
    case OrganizationType.PropertyManager:
      return <BuildingsLogo />
    case OrganizationType.Company:
      return <TeamsLogo />
    default:
      return <DefaultLogo />
  }
}

export type GlobalShellProps = GridProps

export const GlobalShell = ({ children, ...rest }: GridProps) => {
  useEffect(() => {
    document.body.classList.add('off-white-background')
    return () => {
      document.body.classList.remove('off-white-background')
    }
  })

  return (
    <Grid templateRows="68px 1fr" h="100vh" {...rest}>
      <GridItem>
        <AppBar h="100%" justify="center">
          <RitualLogo />
        </AppBar>
      </GridItem>
      <GridItem>{children}</GridItem>
    </Grid>
  )
}
