/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorFromJSONTyped,
    ApiErrorToJSON,
} from './ApiError';

/**
 * 
 * @export
 * @interface WorkspaceMembersResendResponseItem
 */
export interface WorkspaceMembersResendResponseItem {
    /**
     * An HTTP status code indicating the status of the request for an invited, removed, or updated workspace member.
     * @type {number}
     * @memberof WorkspaceMembersResendResponseItem
     */
    statusCode: number;
    /**
     * A workspace member unique identifier.
     * @type {string}
     * @memberof WorkspaceMembersResendResponseItem
     */
    workspaceMemberId: string;
    /**
     * The email address of the workspace member.
     * @type {string}
     * @memberof WorkspaceMembersResendResponseItem
     */
    email?: string;
    /**
     * 
     * @type {ApiError}
     * @memberof WorkspaceMembersResendResponseItem
     */
    error?: ApiError;
}

export function WorkspaceMembersResendResponseItemFromJSON(json: any): WorkspaceMembersResendResponseItem {
    return WorkspaceMembersResendResponseItemFromJSONTyped(json, false);
}

export function WorkspaceMembersResendResponseItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceMembersResendResponseItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'statusCode': json['statusCode'],
        'workspaceMemberId': json['workspaceMemberId'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'error': !exists(json, 'error') ? undefined : ApiErrorFromJSON(json['error']),
    };
}

export function WorkspaceMembersResendResponseItemToJSON(value?: WorkspaceMembersResendResponseItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'statusCode': value.statusCode,
        'workspaceMemberId': value.workspaceMemberId,
        'email': value.email,
        'error': ApiErrorToJSON(value.error),
    };
}

