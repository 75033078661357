/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorFromJSONTyped,
    ApiErrorToJSON,
} from './ApiError';

/**
 * 
 * @export
 * @interface OrganizationAdminsResendResponseItem
 */
export interface OrganizationAdminsResendResponseItem {
    /**
     * An HTTP status code indicating the status of the request for an invited organization admin.
     * @type {number}
     * @memberof OrganizationAdminsResendResponseItem
     */
    statusCode: number;
    /**
     * A portal admin unique identifier.
     * @type {string}
     * @memberof OrganizationAdminsResendResponseItem
     */
    portalAdminId: string;
    /**
     * The email address of the organization admin.
     * @type {string}
     * @memberof OrganizationAdminsResendResponseItem
     */
    email?: string;
    /**
     * 
     * @type {ApiError}
     * @memberof OrganizationAdminsResendResponseItem
     */
    error?: ApiError;
}

export function OrganizationAdminsResendResponseItemFromJSON(json: any): OrganizationAdminsResendResponseItem {
    return OrganizationAdminsResendResponseItemFromJSONTyped(json, false);
}

export function OrganizationAdminsResendResponseItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationAdminsResendResponseItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'statusCode': json['statusCode'],
        'portalAdminId': json['portalAdminId'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'error': !exists(json, 'error') ? undefined : ApiErrorFromJSON(json['error']),
    };
}

export function OrganizationAdminsResendResponseItemToJSON(value?: OrganizationAdminsResendResponseItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'statusCode': value.statusCode,
        'portalAdminId': value.portalAdminId,
        'email': value.email,
        'error': ApiErrorToJSON(value.error),
    };
}

