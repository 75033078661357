import { PortalAdminResponse } from 'lib/generated/api'
import { TeamMember } from 'types/entities'

export type TeamMembersMapper = (
  data: PortalAdminResponse
) => Omit<PortalAdminResponse, 'results'> & { results: TeamMember[] }

export const mapToTeamMembers: TeamMembersMapper = data => {
  const { results: portalAdmins, ...rest } = data

  return {
    results: portalAdmins.map<TeamMember>(admin => ({
      email: admin.email,
      name: [admin.firstName, admin.lastName].join(' ').trim(),
      portalAdminId: admin.portalAdminId,
      role: admin.role,
      status: admin.status,
    })),
    ...rest,
  }
}
