/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConnectionNode
 */
export interface ConnectionNode {
    /**
     * The name of the organization
     * @type {string}
     * @memberof ConnectionNode
     */
    organizationName?: string;
    /**
     * Unique ID for the organization
     * @type {string}
     * @memberof ConnectionNode
     */
    organizationId?: string;
    /**
     * Unique ID for the workspace
     * @type {string}
     * @memberof ConnectionNode
     */
    workspaceId?: string;
    /**
     * The name of the workspace
     * @type {string}
     * @memberof ConnectionNode
     */
    workspaceName?: string;
    /**
     * The formatted address of the workspace
     * @type {string}
     * @memberof ConnectionNode
     */
    workspaceFormattedLocation?: string;
    /**
     * Contact email of the connection
     * @type {string}
     * @memberof ConnectionNode
     */
    contactEmail?: string;
    /**
     * A label for the entity that is the node for this connection (office in a building, employee of an office). This field is deprecated and being replaced with workspaceName, workspaceAddress, and contactEmail.
     * @type {string}
     * @memberof ConnectionNode
     * @deprecated
     */
    entityLabel?: string;
    /**
     * Unique ID for the entity that is the node for this connection. This field is deprecated and being replaced with workspaceId.
     * @type {string}
     * @memberof ConnectionNode
     * @deprecated
     */
    entityId?: string;
    /**
     * The icon of the organization
     * @type {string}
     * @memberof ConnectionNode
     */
    organizationIcon?: string;
}

export function ConnectionNodeFromJSON(json: any): ConnectionNode {
    return ConnectionNodeFromJSONTyped(json, false);
}

export function ConnectionNodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectionNode {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organizationName': !exists(json, 'organizationName') ? undefined : json['organizationName'],
        'organizationId': !exists(json, 'organizationId') ? undefined : json['organizationId'],
        'workspaceId': !exists(json, 'workspaceId') ? undefined : json['workspaceId'],
        'workspaceName': !exists(json, 'workspaceName') ? undefined : json['workspaceName'],
        'workspaceFormattedLocation': !exists(json, 'workspaceFormattedLocation') ? undefined : json['workspaceFormattedLocation'],
        'contactEmail': !exists(json, 'contactEmail') ? undefined : json['contactEmail'],
        'entityLabel': !exists(json, 'entityLabel') ? undefined : json['entityLabel'],
        'entityId': !exists(json, 'entityId') ? undefined : json['entityId'],
        'organizationIcon': !exists(json, 'organizationIcon') ? undefined : json['organizationIcon'],
    };
}

export function ConnectionNodeToJSON(value?: ConnectionNode | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organizationName': value.organizationName,
        'organizationId': value.organizationId,
        'workspaceId': value.workspaceId,
        'workspaceName': value.workspaceName,
        'workspaceFormattedLocation': value.workspaceFormattedLocation,
        'contactEmail': value.contactEmail,
        'entityLabel': value.entityLabel,
        'entityId': value.entityId,
        'organizationIcon': value.organizationIcon,
    };
}

