/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WorkspaceMemberList,
    WorkspaceMemberListFromJSON,
    WorkspaceMemberListFromJSONTyped,
    WorkspaceMemberListToJSON,
} from './WorkspaceMemberList';

/**
 * 
 * @export
 * @interface WorkspaceMemberListGroup
 */
export interface WorkspaceMemberListGroup {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceMemberListGroup
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceMemberListGroup
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceMemberListGroup
     */
    description?: string;
    /**
     * 
     * @type {Array<WorkspaceMemberList>}
     * @memberof WorkspaceMemberListGroup
     */
    memberLists: Array<WorkspaceMemberList>;
}

export function WorkspaceMemberListGroupFromJSON(json: any): WorkspaceMemberListGroup {
    return WorkspaceMemberListGroupFromJSONTyped(json, false);
}

export function WorkspaceMemberListGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceMemberListGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'memberLists': ((json['memberLists'] as Array<any>).map(WorkspaceMemberListFromJSON)),
    };
}

export function WorkspaceMemberListGroupToJSON(value?: WorkspaceMemberListGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'memberLists': ((value.memberLists as Array<any>).map(WorkspaceMemberListToJSON)),
    };
}

