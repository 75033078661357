import { ReactNode } from 'react'
import { useField } from 'formik'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
} from '@ritualco/jutsu'
import { CurrencyCode } from 'lib/generated/api'

export const currencySymbolMap: Record<string, string> = {
  [CurrencyCode.Aud]: '$',
  [CurrencyCode.Cad]: '$',
  [CurrencyCode.Eur]: '€',
  [CurrencyCode.Gbp]: '£',
  [CurrencyCode.Hkd]: '$',
  [CurrencyCode.Jpy]: '¥',
  [CurrencyCode.Nzd]: '$',
  [CurrencyCode.Sgd]: 'S$',
  [CurrencyCode.UnknownCurrencyCode]: '$',
  [CurrencyCode.Usd]: '$',
}

export type FormikCurrencyInputProps = {
  label?: ReactNode
  name: string
  isDisabled?: boolean
  isRequired?: boolean
  currencyCode?: string | null
} & InputProps

export const FormikCurrencyInput = ({
  label,
  isDisabled = false,
  isRequired = false,
  currencyCode,
  ...props
}: FormikCurrencyInputProps) => {
  const [field, { error, touched }] = useField(props.name)
  const currencySymbol =
    currencyCode && currencySymbolMap[currencyCode]
      ? currencySymbolMap[currencyCode]
      : '$'

  return (
    <FormControl
      id={props.name}
      isInvalid={!!error && touched}
      isDisabled={isDisabled}
      isRequired={isRequired}
    >
      {label && <FormLabel>{label}</FormLabel>}
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          color="grayscale.tertiary"
          h={12}
          fontSize="medium"
        >
          {currencySymbol}
        </InputLeftElement>
        <Input size="lg" {...field} {...props} />
      </InputGroup>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  )
}
