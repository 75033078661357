/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WorkspaceIncentive,
    WorkspaceIncentiveFromJSON,
    WorkspaceIncentiveFromJSONTyped,
    WorkspaceIncentiveToJSON,
} from './WorkspaceIncentive';

/**
 * 
 * @export
 * @interface WorkspaceIncentivesResponse
 */
export interface WorkspaceIncentivesResponse {
    /**
     * 
     * @type {Array<WorkspaceIncentive>}
     * @memberof WorkspaceIncentivesResponse
     */
    userIncentives: Array<WorkspaceIncentive>;
    /**
     * Internal user id
     * @type {string}
     * @memberof WorkspaceIncentivesResponse
     */
    internalUserId: string;
    /**
     * workspace member id
     * @type {string}
     * @memberof WorkspaceIncentivesResponse
     */
    workspaceMemberId: string;
    /**
     * workspace id
     * @type {string}
     * @memberof WorkspaceIncentivesResponse
     */
    workspaceId: string;
}

export function WorkspaceIncentivesResponseFromJSON(json: any): WorkspaceIncentivesResponse {
    return WorkspaceIncentivesResponseFromJSONTyped(json, false);
}

export function WorkspaceIncentivesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceIncentivesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userIncentives': ((json['userIncentives'] as Array<any>).map(WorkspaceIncentiveFromJSON)),
        'internalUserId': json['internalUserId'],
        'workspaceMemberId': json['workspaceMemberId'],
        'workspaceId': json['workspaceId'],
    };
}

export function WorkspaceIncentivesResponseToJSON(value?: WorkspaceIncentivesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userIncentives': ((value.userIncentives as Array<any>).map(WorkspaceIncentiveToJSON)),
        'internalUserId': value.internalUserId,
        'workspaceMemberId': value.workspaceMemberId,
        'workspaceId': value.workspaceId,
    };
}

