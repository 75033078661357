/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncentivePlanWorkspaceMembersRequest
 */
export interface IncentivePlanWorkspaceMembersRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof IncentivePlanWorkspaceMembersRequest
     */
    emails: Array<string>;
    /**
     * Indicates that workspace invitations should be sent to emails not associated with any existing workspace members
     * @type {boolean}
     * @memberof IncentivePlanWorkspaceMembersRequest
     */
    shouldSendInvitations?: boolean;
}

export function IncentivePlanWorkspaceMembersRequestFromJSON(json: any): IncentivePlanWorkspaceMembersRequest {
    return IncentivePlanWorkspaceMembersRequestFromJSONTyped(json, false);
}

export function IncentivePlanWorkspaceMembersRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncentivePlanWorkspaceMembersRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emails': json['emails'],
        'shouldSendInvitations': !exists(json, 'shouldSendInvitations') ? undefined : json['shouldSendInvitations'],
    };
}

export function IncentivePlanWorkspaceMembersRequestToJSON(value?: IncentivePlanWorkspaceMembersRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'emails': value.emails,
        'shouldSendInvitations': value.shouldSendInvitations,
    };
}

