/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MerchantEligibilityType,
    MerchantEligibilityTypeFromJSON,
    MerchantEligibilityTypeFromJSONTyped,
    MerchantEligibilityTypeToJSON,
} from './MerchantEligibilityType';
import {
    MerchantListGroup,
    MerchantListGroupFromJSON,
    MerchantListGroupFromJSONTyped,
    MerchantListGroupToJSON,
} from './MerchantListGroup';
import {
    Polygon,
    PolygonFromJSON,
    PolygonFromJSONTyped,
    PolygonToJSON,
} from './Polygon';
import {
    Radius,
    RadiusFromJSON,
    RadiusFromJSONTyped,
    RadiusToJSON,
} from './Radius';

/**
 * 
 * @export
 * @interface IncentivePlanMerchantEligibility
 */
export interface IncentivePlanMerchantEligibility {
    /**
     * 
     * @type {MerchantEligibilityType}
     * @memberof IncentivePlanMerchantEligibility
     */
    type?: MerchantEligibilityType;
    /**
     * 
     * @type {MerchantListGroup}
     * @memberof IncentivePlanMerchantEligibility
     */
    merchantListGroup?: MerchantListGroup;
    /**
     * 
     * @type {Array<Polygon>}
     * @memberof IncentivePlanMerchantEligibility
     */
    polygonRestrictions?: Array<Polygon>;
    /**
     * 
     * @type {Array<Radius>}
     * @memberof IncentivePlanMerchantEligibility
     */
    radiusRestrictions?: Array<Radius>;
}

export function IncentivePlanMerchantEligibilityFromJSON(json: any): IncentivePlanMerchantEligibility {
    return IncentivePlanMerchantEligibilityFromJSONTyped(json, false);
}

export function IncentivePlanMerchantEligibilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncentivePlanMerchantEligibility {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : MerchantEligibilityTypeFromJSON(json['type']),
        'merchantListGroup': !exists(json, 'merchantListGroup') ? undefined : MerchantListGroupFromJSON(json['merchantListGroup']),
        'polygonRestrictions': !exists(json, 'polygonRestrictions') ? undefined : ((json['polygonRestrictions'] as Array<any>).map(PolygonFromJSON)),
        'radiusRestrictions': !exists(json, 'radiusRestrictions') ? undefined : ((json['radiusRestrictions'] as Array<any>).map(RadiusFromJSON)),
    };
}

export function IncentivePlanMerchantEligibilityToJSON(value?: IncentivePlanMerchantEligibility | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': MerchantEligibilityTypeToJSON(value.type),
        'merchantListGroup': MerchantListGroupToJSON(value.merchantListGroup),
        'polygonRestrictions': value.polygonRestrictions === undefined ? undefined : ((value.polygonRestrictions as Array<any>).map(PolygonToJSON)),
        'radiusRestrictions': value.radiusRestrictions === undefined ? undefined : ((value.radiusRestrictions as Array<any>).map(RadiusToJSON)),
    };
}

