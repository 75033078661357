/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ErrorReason,
    ErrorReasonFromJSON,
    ErrorReasonFromJSONTyped,
    ErrorReasonToJSON,
} from './ErrorReason';

/**
 * 
 * @export
 * @interface ApiError
 */
export interface ApiError {
    /**
     * Error code
     * @type {number}
     * @memberof ApiError
     */
    code: number;
    /**
     * Error message
     * @type {string}
     * @memberof ApiError
     */
    message: string;
    /**
     * 
     * @type {ErrorReason}
     * @memberof ApiError
     */
    errorReason?: ErrorReason;
}

export function ApiErrorFromJSON(json: any): ApiError {
    return ApiErrorFromJSONTyped(json, false);
}

export function ApiErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'message': json['message'],
        'errorReason': !exists(json, 'errorReason') ? undefined : ErrorReasonFromJSON(json['errorReason']),
    };
}

export function ApiErrorToJSON(value?: ApiError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'message': value.message,
        'errorReason': ErrorReasonToJSON(value.errorReason),
    };
}

