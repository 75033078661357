/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MerchantListType = {
    SystemAllMerchants: 'SYSTEM_ALL_MERCHANTS',
    SystemPropertyManagerMerchantNetwork: 'SYSTEM_PROPERTY_MANAGER_MERCHANT_NETWORK',
    SystemCorporateLoyaltyNetwork: 'SYSTEM_CORPORATE_LOYALTY_NETWORK',
    SystemEmployeePricingNetwork: 'SYSTEM_EMPLOYEE_PRICING_NETWORK',
    UserDefined: 'USER_DEFINED'
} as const;
export type MerchantListType = typeof MerchantListType[keyof typeof MerchantListType];


export function MerchantListTypeFromJSON(json: any): MerchantListType {
    return MerchantListTypeFromJSONTyped(json, false);
}

export function MerchantListTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantListType {
    return json as MerchantListType;
}

export function MerchantListTypeToJSON(value?: MerchantListType | null): any {
    return value as any;
}

