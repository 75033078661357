import { useCallback, useEffect } from 'react'
import create from 'zustand'
import { mapToWorkspaceList } from 'api/mappers/workspace'
import { mapToOrganizationList } from 'api/mappers/organization'
import { useGetWorkspaces } from 'api/endpoints/workspaces'
import {
  useGetOrganizations,
  useGetOrganizationUserProfiles,
  useGetOrganizationWorkspaceUserProfiles,
} from 'api/endpoints/organizations'
import { PortalAdminStatus } from 'lib/generated/api'
import { Organization, User, Workspace } from 'types/entities'
import { DEFAULT_LOCALE, Locale } from 'types/locale'
import useIsInternalUser from 'hooks/useIsInternalUser'
import { persist } from 'zustand/middleware'
import { useAuth } from '../auth'
import {
  OrganizationPortalAdmin,
  OrganizationType,
  WorkspacePortalAdmin,
} from '../lib/generated/api'

export const STORE_NAME = 'rtl-datastore'
export type AttachedWorkspaces = {
  id: string | undefined
  name: string | undefined
}

export interface StoreState {
  organization: Organization
  setOrganization: (organization: Organization) => void
  deleteOrganization: () => void
  deleteRequestedWorkspaceId: () => void
  requestedWorkspaceId: string
  setRequestedWorkspaceId: (workspaceId: string) => void
  workspace: Workspace
  setWorkspace: (workspace: Workspace) => void
  attachedWorkspaces: Array<AttachedWorkspaces>
  setAttachedWorkspaces: (attachedWorkspaces: Array<AttachedWorkspaces>) => void
  attachedOrganizations: Array<Organization>
  setAttachedOrganizations: (attachedOrganizations: Array<Organization>) => void
  userInfo: User
  setUserInfo: (userInfo: User) => void
  organizationUserProfiles: OrganizationPortalAdmin[]
  setOrganizationUserProfiles: (
    organizationPortalAdmins: OrganizationPortalAdmin[]
  ) => void
  organizationWorkspaceUserProfiles: WorkspacePortalAdmin[]
  setOrganizationWorkspaceUserProfiles: (
    organizationWorkspaceUserProfiles: WorkspacePortalAdmin[]
  ) => void
  ritualLogoType?: OrganizationType
  setRitualLogoType: (ritualLogoType?: OrganizationType) => void
  clearStorage: () => void
  activeOrganizationProfile: OrganizationPortalAdmin | undefined
  setActiveOrganizationProfile: (profile: OrganizationPortalAdmin) => void
}

const emptyOrganization: Organization = {
  id: undefined,
  name: undefined,
  type: OrganizationType.Company,
  iconUrl: undefined,
}

const emptyWorkspace: Workspace = {
  id: undefined,
  name: undefined,
  type: undefined,
  organizationId: undefined,
}

const emptyUserInfo: User = {
  email: undefined,
  role: undefined,
}

export const useStore = create<StoreState>(
  persist(
    (set, get) => ({
      organization: ((get() && get().organization) ||
        emptyOrganization) as Organization,
      setOrganization: organization => set({ organization }),
      deleteOrganization: () => set({ organization: emptyOrganization }),
      requestedWorkspaceId: (get() && get().requestedWorkspaceId) || '',
      setRequestedWorkspaceId: requestedWorkspaceId =>
        set({ requestedWorkspaceId }),
      deleteRequestedWorkspaceId: () => set({ requestedWorkspaceId: '' }),
      workspace: ((get() && get().requestedWorkspaceId) ||
        emptyWorkspace) as Workspace,
      setWorkspace: workspace => set({ workspace }),
      attachedWorkspaces: [],
      setAttachedWorkspaces: attachedWorkspaces => set({ attachedWorkspaces }),
      attachedOrganizations: [],
      setAttachedOrganizations: attachedOrganizations =>
        set({ attachedOrganizations }),
      userInfo: emptyUserInfo,
      setUserInfo: userInfo => set({ userInfo }),
      organizationUserProfiles: [],
      setOrganizationUserProfiles: organizationUserProfiles =>
        set({ organizationUserProfiles }),
      organizationWorkspaceUserProfiles: [],
      setOrganizationWorkspaceUserProfiles: organizationWorkspaceUserProfiles =>
        set({ organizationWorkspaceUserProfiles }),
      ritualLogoType: undefined,
      setRitualLogoType: ritualLogoType => set({ ritualLogoType }),
      activeOrganizationProfile: get() && get().activeOrganizationProfile,
      setActiveOrganizationProfile: profile =>
        set({ activeOrganizationProfile: profile }),
      clearStorage: () => localStorage.removeItem(STORE_NAME),
    }),
    {
      name: STORE_NAME,
      getStorage: () => localStorage,
    }
  )
)

// Sets necessary data in the global store, make sure the user is authenticated before this
export const useHydrateStore = (externalUserId: string) => {
  const organizationId = useStore(state => state.organization.id) as string
  const workspaceId = useStore(state => state.workspace.id)
  const {
    filterOrganizations,
    filterWorkspaces,
    isInternalUser,
    isInternalUserLoaded,
  } = useIsInternalUser()

  const setWorkspace = useStore(state => state.setWorkspace)
  const setAttachedWorkspaces = useStore(state => state.setAttachedWorkspaces)
  const organization = useStore(state => state.organization)
  const setAttachedOrganizations = useStore(
    state => state.setAttachedOrganizations
  )
  const setOrganization = useStore(state => state.setOrganization)
  const userInfo = useStore(state => state.userInfo)
  const setUserInfo = useStore(state => state.setUserInfo)
  const setOrganizationProfiles = useStore(
    state => state.setOrganizationUserProfiles
  )
  const setOrganizationWorkspaceUserProfiles = useStore(
    state => state.setOrganizationWorkspaceUserProfiles
  )
  const setActiveOrganizationProfile = useStore(
    state => state.setActiveOrganizationProfile
  )
  const requestedWorkspaceId = useStore(state => state.requestedWorkspaceId)
  const { createLogoutUrl } = useAuth()
  const redirectTo = `${window.location.origin}/how-to-join`
  const handleNoOrganizationOrWorkspace = useCallback(() => {
    window.location.href = createLogoutUrl({
      redirectUri: redirectTo,
    })
  }, [createLogoutUrl])

  const {
    data: organizationsData,
    isSuccess: areOrganizationsFetched,
  } = useGetOrganizations({
    limit: 100,
  })
  const {
    data: workspacesData,
    isSuccess: areWorkspacesFetched,
  } = useGetWorkspaces({
    organizationId,
    limit: 100,
  })
  const { data: organizationUserProfiles } = useGetOrganizationUserProfiles({
    organizationId,
    externalUserId,
  })
  const {
    data: organizationWorkspaceUserProfiles,
  } = useGetOrganizationWorkspaceUserProfiles({
    externalUserId,
    organizationId: organization.id!,
  })

  useEffect(() => {
    if (isInternalUserLoaded) {
      if (
        areOrganizationsFetched &&
        (!organizationsData ||
          organizationsData.results.length === 0 ||
          filterOrganizations(mapToOrganizationList(organizationsData.results))
            .length === 0)
      ) {
        handleNoOrganizationOrWorkspace()
      } else if (organizationsData?.results.length) {
        const data = mapToOrganizationList(organizationsData.results)
        const filteredOrganizations = filterOrganizations(data)

        if (!organization.id) {
          setOrganization(filteredOrganizations[0])
        }

        setAttachedOrganizations(filteredOrganizations)
      }
    }
  }, [
    organizationsData,
    setOrganization,
    setAttachedOrganizations,
    organization.id,
    filterOrganizations,
    isInternalUserLoaded,
    areOrganizationsFetched,
    handleNoOrganizationOrWorkspace,
  ])

  useEffect(() => {
    if (isInternalUserLoaded) {
      if (
        areWorkspacesFetched &&
        (!workspacesData ||
          workspacesData.results.length === 0 ||
          filterWorkspaces(mapToWorkspaceList(workspacesData)).length === 0)
      ) {
        handleNoOrganizationOrWorkspace()
      } else if (workspacesData?.results.length) {
        const workspaces = mapToWorkspaceList(workspacesData)
        const requestedWorkspace = workspaces.find(
          workspace => workspace.id === requestedWorkspaceId
        )

        setWorkspace(requestedWorkspace || workspaces[0])
        const filteredWorkspaces = filterWorkspaces(workspaces)

        setAttachedWorkspaces(
          filteredWorkspaces.map(workspace => ({
            id: workspace.id,
            name: workspace.name,
          }))
        )
      }
    }
  }, [
    workspacesData,
    setWorkspace,
    setAttachedWorkspaces,
    requestedWorkspaceId,
    areWorkspacesFetched,
    handleNoOrganizationOrWorkspace,
    isInternalUser,
    isInternalUserLoaded,
    filterWorkspaces,
  ])

  useEffect(() => {
    if (!userInfo.role && organizationUserProfiles?.results?.length) {
      const firstResult = organizationUserProfiles!.results[0]
      setUserInfo({
        firstName: firstResult.firstName,
        lastName: firstResult.lastName,
        email: firstResult.email,
        role: firstResult.role,
        locale: (firstResult.locale || DEFAULT_LOCALE) as Locale,
      })
    }
  }, [organizationUserProfiles, setUserInfo, userInfo.role])

  useEffect(() => {
    if (organizationWorkspaceUserProfiles?.results?.length) {
      const activePortalAdmin = organizationWorkspaceUserProfiles?.results.find(
        portalAdmin =>
          portalAdmin.workspaceId === workspaceId &&
          portalAdmin.status === PortalAdminStatus.Active
      )
      if (activePortalAdmin) {
        setUserInfo({
          portalAdminId: activePortalAdmin.portalAdminId,
          firstName: activePortalAdmin.firstName,
          lastName: activePortalAdmin.lastName,
          email: activePortalAdmin.email,
          role: activePortalAdmin.role,
          locale: (activePortalAdmin.locale || DEFAULT_LOCALE) as Locale,
        })
      }
    }
  }, [organizationWorkspaceUserProfiles, workspaceId, setUserInfo])

  useEffect(() => {
    if (externalUserId && organizationUserProfiles?.results?.length) {
      const { results } = organizationUserProfiles
      setOrganizationProfiles(results)
    }
  }, [externalUserId, organizationUserProfiles, setOrganizationProfiles])

  useEffect(() => {
    if (organizationWorkspaceUserProfiles?.results?.length && organization.id) {
      const data = organizationWorkspaceUserProfiles.results
      setOrganizationWorkspaceUserProfiles(data)
    }
  }, [
    organizationWorkspaceUserProfiles,
    organization.id,
    setOrganizationWorkspaceUserProfiles,
  ])

  useEffect(() => {
    const organizationUserProfilesResults = organizationUserProfiles?.results
    if (
      !organizationUserProfilesResults ||
      !organization ||
      organizationUserProfilesResults.length < 1
    ) {
      return
    }

    const activeProfileForOrganization = organizationUserProfilesResults.filter(
      profile => profile.organizationId === organization.id
    )[0]
    if (activeProfileForOrganization)
      setActiveOrganizationProfile(activeProfileForOrganization)
    else setActiveOrganizationProfile(organizationUserProfilesResults[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization, organizationUserProfiles])
}
