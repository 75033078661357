import React from 'react'

export type RemoteConfigType = {
  configLoaded: boolean
  getBoolean: (key: string) => boolean | undefined
  getString: (key: string) => string | undefined
  getJSON: (key: string) => any
}

export const RemoteConfigContext = React.createContext<RemoteConfigType>({
  configLoaded: false,
  getBoolean: () => undefined,
  getString: () => undefined,
  getJSON: () => {},
})
