/* tslint:disable */
/* eslint-disable */
/**
 * Organization & Workspace Gateway
 * Methods to manage all organizations and workspaces
 *
 * The version of the OpenAPI document: 22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    JobResultsResponse,
    JobResultsResponseFromJSON,
    JobResultsResponseToJSON,
    JobsResponse,
    JobsResponseFromJSON,
    JobsResponseToJSON,
} from '../models';

export interface GetJobResultsRequest {
    workspaceId: string;
    jobId: string;
    sortColumn?: string;
    sortDirection?: GetJobResultsSortDirectionEnum;
}

export interface GetJobsRequest {
    workspaceId: string;
    limit?: number;
    nextPageToken?: string;
    previousPageToken?: string;
    sortColumn?: string;
    sortDirection?: GetJobsSortDirectionEnum;
}

/**
 * 
 */
export class JobsApi extends runtime.BaseAPI {

    /**
     * Get a job and its results
     * Get a job and its results. Sorting applies to the results of the job.
     */
    async getJobResultsRaw(requestParameters: GetJobResultsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<JobResultsResponse>> {
        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling getJobResults.');
        }

        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling getJobResults.');
        }

        const queryParameters: any = {};

        if (requestParameters.sortColumn !== undefined) {
            queryParameters['sortColumn'] = requestParameters.sortColumn;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workspaces/{workspaceId}/jobs/{jobId}`.replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))).replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JobResultsResponseFromJSON(jsonValue));
    }

    /**
     * Get a job and its results
     * Get a job and its results. Sorting applies to the results of the job.
     */
    async getJobResults(requestParameters: GetJobResultsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<JobResultsResponse> {
        const response = await this.getJobResultsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of jobs for the workspace.
     * Get list of jobs for the workspace.
     */
    async getJobsRaw(requestParameters: GetJobsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<JobsResponse>> {
        if (requestParameters.workspaceId === null || requestParameters.workspaceId === undefined) {
            throw new runtime.RequiredError('workspaceId','Required parameter requestParameters.workspaceId was null or undefined when calling getJobs.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.nextPageToken !== undefined) {
            queryParameters['nextPageToken'] = requestParameters.nextPageToken;
        }

        if (requestParameters.previousPageToken !== undefined) {
            queryParameters['previousPageToken'] = requestParameters.previousPageToken;
        }

        if (requestParameters.sortColumn !== undefined) {
            queryParameters['sortColumn'] = requestParameters.sortColumn;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workspaces/{workspaceId}/jobs`.replace(`{${"workspaceId"}}`, encodeURIComponent(String(requestParameters.workspaceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JobsResponseFromJSON(jsonValue));
    }

    /**
     * Get a list of jobs for the workspace.
     * Get list of jobs for the workspace.
     */
    async getJobs(requestParameters: GetJobsRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<JobsResponse> {
        const response = await this.getJobsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetJobResultsSortDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type GetJobResultsSortDirectionEnum = typeof GetJobResultsSortDirectionEnum[keyof typeof GetJobResultsSortDirectionEnum];
/**
 * @export
 */
export const GetJobsSortDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type GetJobsSortDirectionEnum = typeof GetJobsSortDirectionEnum[keyof typeof GetJobsSortDirectionEnum];
